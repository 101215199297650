import { toPng } from 'html-to-image';
import React from 'react';

export const downloadRelationChartOnClick = (
  flowRef: React.RefObject<HTMLDivElement>,
  name: string,
  category: string
) => {
  if (flowRef.current === null) return;

  toPng(flowRef.current, {
    backgroundColor: 'white',
    filter: (node: Element) => !(
      node?.classList?.contains('react-flow__minimap') ||
      node?.classList?.contains('react-flow__controls')
    )
  })
    .then((dataUrl) => {
      const a = document.createElement('a');
      alert(`Downloading ${name} ${category}.png...`);
      a.setAttribute('download', `${name} ${category}.png`);
      a.setAttribute('href', dataUrl);
      a.click();
    })
    .catch((error) => {
      console.log(error);
    });
};

/**
 * Takes a screenshot of the element referenced by `elementRef` and downloads it as a PNG file.
 *
 * @param elementRef - A React reference object pointing to the element you want to capture.
 * @param fileName - The name for the downloaded PNG file.
 * @param backgroundColor - Optional: The background color for the image (default is 'white').
 * @param filterClassNames - Optional: An array of class names that should be excluded from the screenshot.
 */
export const downloadScreenshot = (
  elementRef: React.RefObject<HTMLElement>,
  fileName: string,
  backgroundColor: string = 'white',
  filterClassNames: string[] = []
) => {
  if (elementRef.current === null) return;

  toPng(elementRef.current, {
    backgroundColor,
    filter: (node: HTMLElement) => !filterClassNames.some(className => node.classList.contains(className))
  })
    .then((dataUrl) => {
      const link = document.createElement('a');
      link.setAttribute('download', `${fileName}.png`);
      link.setAttribute('href', dataUrl);
      link.click();
    })
    .catch((error) => {
      console.error('Error capturing screenshot:', error);
    });
};
