import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import { AnyEntity } from '@deecision/dna-interfaces';
import CompanySmallCards from './company';
import PersonSmallCards from './person';

interface EntitiesSmallCardsProps {
  bgColor?: string,
  sideColor?: string,
  chips?: ReactElement[],
  entity: AnyEntity
}

function EntitiesSmallCards(props: EntitiesSmallCardsProps): ReactElement {
  const { t } = useTranslation();
  let card: ReactElement | undefined;

  if (props.entity.entityType === 'deecCompany') {
    card = <CompanySmallCards {...props} entity={props.entity} />;
  } else if (props.entity.entityType === 'deecPerson') {
    card = <PersonSmallCards {...props} entity={props.entity} />;
  }

  return (
    <Paper
      variant='hoverElevation1'
      sx={{
        width: '100%',
        whiteSpace: 'nowrap',
        p: 0,
        backgroundColor: props.sideColor
      }}
    >
      <Paper
        elevation={0}
        sx={{
          backgroundColor: props.bgColor || undefined,
          whiteSpace: 'nowrap',
          width: 'calc(100% - 12px)',
          p: 1,
          pl: 2,
          ml: props.sideColor ? 3 : 0
        }}
      >
        {card || t('error.entityTypeNotFound')}
      </Paper>
    </Paper>
  );
}

export default EntitiesSmallCards;
