import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { PersonEntity } from '@deecision/dna-interfaces';
import KeyValueWithCard from '@/components/keyvalue/keyvaluewithcard';
import { PersonGetter } from '@/main/providers/getter';
import BaseWidget from '@/main/containers/widgets/base-widget';
import { WidgetProps } from '@/main/containers/widgets/types';

function PersonWidgetsFinancial(props: WidgetProps<PersonEntity>): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const personGetter = new PersonGetter({
    data: props.data,
    errorMsg: t('common.utils.unknown')
  });

  const financialDatas = props.data.dataViews?.data?.companies ? [
    {
      key: t('common.financial.turnover.sumShort'),
      fullKey: t('common.financial.turnover.sum'),
      value: personGetter.getTurnoverSum(),
      completeness: personGetter.getTurnoverSumCompleteness(),
      date: personGetter.getTurnoverSumDate(),
      align: 'right' as const
    },
    {
      key: t('common.financial.weasse.short'),
      fullKey: t('common.financial.weasse.label'),
      value: personGetter.getWeasse(),
      completeness: personGetter.getWeasseCompleteness(),
      date: personGetter.getWeasseDate(),
      align: 'right' as const
    },
    {
      key: t('common.financial.weasseMax.short'),
      fullKey: t('common.financial.weasseMax.label'),
      value: personGetter.getWeasseMax(),
      completeness: personGetter.getWeasseMaxCompleteness(),
      date: personGetter.getWeasseMaxDate(),
      align: 'right' as const
    },
    {
      key: t('common.financial.extrapolatedWeasse.short'),
      fullKey: t('common.financial.extrapolatedWeasse.label'),
      value: personGetter.getExtrapolatedWeasse(),
      completeness: personGetter.getExtrapolatedWeasseCompleteness(),
      accuracy: personGetter.getExtrapolatedWeasseAccuracy(),
      align: 'right' as const
    },
    {
      key: t('common.financial.fortune.label'),
      value: personGetter.getFortune(),
      align: 'right' as const
    }
  ] : [];

  return (
    <BaseWidget {...props} title={t('common.financial.label')}>
      <KeyValueWithCard
        values={[financialDatas]}
        keyValueCard={
          {
            Key: `${t('common.financial.turnover.sumShort')}`,
            fullKey: t('common.financial.turnover.sum'),
            value: personGetter.getTurnoverSum(),
            size: 'big',
            bgColor: personGetter.getTurnoverSumDate() ? Math.abs(new Date(Date.now() - (personGetter.getTurnoverSumDate()?.getTime() || 0)).getUTCFullYear() - 1970) < 1 ? theme.palette.success.light : Math.abs(new Date(Date.now() - (personGetter.getTurnoverSumDate()?.getTime() || 0)).getUTCFullYear() - 1970) < 2 ? theme.palette.warning.light : theme.palette.error.light : theme.primaryColors?.light || theme.palette.primary.light,
            color: personGetter.getTurnoverSumDate() ? Math.abs(new Date(Date.now() - (personGetter.getTurnoverSumDate()?.getTime() || 0)).getUTCFullYear() - 1970) < 1 ? theme.palette.success.dark : Math.abs(new Date(Date.now() - (personGetter.getTurnoverSumDate()?.getTime() || 0)).getUTCFullYear() - 1970) < 2 ? theme.palette.warning.dark : theme.palette.error.dark : theme.palette.primary.dark
          }
        }
      />
    </BaseWidget>
  );
}

export default PersonWidgetsFinancial;
