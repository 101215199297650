import React from 'react';
import { IconFolderPlus } from '@tabler/icons-react';
import { CustomRouteObject } from '@/router/types';
import CreateComponent from './index';
import dnaConfig from '../../../../../config/dna.config.json';

const routesCreate: CustomRouteObject = {
  i18nKey: dnaConfig.routes.portfolios.create.i18nKey,
  path: dnaConfig.routes.portfolios.create.baseUrl,
  handle: {
    icon: IconFolderPlus
  },
  element: <CreateComponent />
};

export default routesCreate;
