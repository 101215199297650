import React, { ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IconSearch, IconX } from '@tabler/icons-react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import SearchSubmitBtn, { SearchSubmitBtnProps } from './submit';

type SearchBarProps = Omit<SearchSubmitBtnProps, 'btnRef'> & {
  focus: boolean,
  setFocus: (focus: boolean) => void,
  setQuery: (query: string | undefined) => void,
  selectedFromAutocomplete: string | undefined
}

function SearchBar(props: SearchBarProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const btnRef = useRef<HTMLButtonElement>(null);

  return (
    <Box minWidth={200} width='100%'>
      <Stack direction='row' spacing={2} height='100%' alignItems='center'>
        <Box height='1.4rem'>
          <IconSearch size='1.4rem' />
        </Box>
        <InputBase
          placeholder={t('search.placeholder') || 'Type a name ...'}
          value={props.query || ''}
          onChange={e => props.setQuery(e.target.value)}
          onFocus={() => props.setFocus(true)}
          onBlur={() => props.setFocus(false)}
          onKeyDown={(e) => {
            if (!props.hideSubmit) {
              if (
                (e.code === 'Enter' || e.code === 'NumpadEnter') &&
                !props.disabled
              ) {
                props.query &&
                  props.query.length > 0 &&
                  props.onSubmit(props.query);
              }
            }
          }}
          sx={{
            fontSize: '1rem'
          }}
          fullWidth
        />
        {props.query && props.query.length > 0 && (
          <Box
            sx={{
              ml: '0px !important'
            }}
          >
            <Button
              variant='textIcon'
              size='small'
              sx={{
                '&:hover': {
                  color: theme.palette.error.main
                }
              }}
              onClick={() => {
                props.setQuery(undefined);
              }}
            >
              <IconX size='1.1rem' />
            </Button>
          </Box>
        )}
        <SearchSubmitBtn {...props} btnRef={btnRef} />
      </Stack>
    </Box>
  );
}

export default SearchBar;
