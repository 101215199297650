import React, { ReactElement, useEffect, useState } from 'react';
import { IconChevronRight } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { IDataBlock } from '@deecision/dna-interfaces';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { CurrencyFormatterProps } from '@/utils';
import EvenOddTable from '@/components/tables/evenodd/table';
import { DataBlocksQueryService, DataElementsQueryService } from '@/api/services/query';
import { Column } from '@/components/tables/types';
import DisplayJson from '@/components/displayjson';

type RowsType = {
  id: string,
  path: string,
  dataType: string,
  entityType: string,
  provider: string | undefined,
  sourceDataElementId: string | undefined,
  voirPlus: JSX.Element
}[][]

type DataBlockWithId = IDataBlock & { _id: string }

function DataBlocksTabs(): ReactElement {
  const dataElementId = window.location.pathname.split('/')[4];
  const dataElementService = new DataElementsQueryService({ entityType: 'deecCompany' });
  const dataBlockService = new DataBlocksQueryService({ entityType: 'deecCompany' });
  const navigate = useNavigate();
  const [rows, setRows] = useState<RowsType>([[]]);

  const parseDataRow = (dataRow: DataBlockWithId[][]) => {
    const rowsParsed = dataRow.map(dataBlocksInfo => (
      dataBlocksInfo.map(dataBlockInfo => (
        {
          id: dataBlockInfo._id,
          path: dataBlockInfo.dataInfo.path,
          dataType: dataBlockInfo.dataInfo.dataType,
          entityType: dataBlockInfo.entityRef.entityType,
          data:
            <Box width='350px'>
              <DisplayJson data={dataBlockInfo.data} />
            </Box>,
          provider: dataBlockInfo._source?.provider.id,
          sourceDataElementId: dataBlockInfo._source?.dataElementId,
          voirPlus:
            <Tooltip title={dataBlockInfo._id} >
              <IconButton onClick={() => {
                navigate(`../../../datablocks/${dataBlockInfo._id}`);
              }}>
                <IconChevronRight size='1.6rem' />
              </IconButton>
            </Tooltip>
        }
      ))
    ));
    setRows(rowsParsed);
  };

  useEffect(() => {
    dataElementService.findInAllEntities({ _id: dataElementId })
      .then((res) => {
        if (res.data) {
          const tmpDataBlocks: { key: string, value: string }[] = [];
          const tmpDataBlocksQueryList: DataBlockWithId[][] = [];

          res.data[0].provides.dataBlocks.forEach((dataBlock) => {
            tmpDataBlocks.push({ key: dataBlock.path, value: dataBlock.type });
          });

          tmpDataBlocks.forEach((dataBlock) => {
            dataBlockService.findByCustomQuery({
              dataType: dataBlock.value
            }).then((resDataBlock) => {
              if (resDataBlock.data) {
                const elementsWithId = resDataBlock.data as (DataBlockWithId)[];

                tmpDataBlocksQueryList.push(elementsWithId.map(item => ({
                  ...item,
                  _id: item._id
                })));
                parseDataRow(tmpDataBlocksQueryList);
              }
            });
          });
        }
      });

  }, []);

  const columns: Column<CurrencyFormatterProps>[] = [
    {
      id: 'path',
      label: 'Path'
    },
    {
      id: 'dataType',
      label: 'Data Type'
    },
    {
      id: 'entityType',
      label: 'Entity Type'
    },
    {
      id: 'data',
      label: 'Data'
    },
    {
      id: 'provider',
      label: 'Provider Id'
    },
    {
      id: 'sourceDataElementId',
      label: 'dataElementId'
    },
    {
      id: 'voirPlus',
      label: 'Voir Plus'
    }
  ];

  return (
    <Grid size={12}>
      <EvenOddTable<CurrencyFormatterProps> label='dataBlocks' columns={columns} rows={rows.flat() || []} totalCount={rows.flat().length}/>
    </Grid>
  );
}

export default DataBlocksTabs;
