import React, { ReactElement, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import {
  IconArrowLeft,
  IconArrowRight,
  IconClockDollar,
  IconPremiumRights
} from '@tabler/icons-react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import dnaConfig from '../../../../../../../config/dna.config.json';

function ChoicesStepsComponentsCreateFolders(): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const [activeStep, setActiveStep] = useState(0);
  const [folderType, setFolderType] = useState<string>();

  // const selectChevron = (params: Record<string, unknown>) => <IconChevronDown size='1rem' style={{ marginRight: '8px' }} {...params} />;

  useEffect(() => {
    const activeStepTmp = searchParams.get('step');

    if (activeStepTmp) {
      setActiveStep(parseInt(activeStepTmp, 10) - 1);
    }
  }, [searchParams]);

  return (
    <Grid
      container
      spacing={6}
      maxWidth='800px'
      justifyContent='center'
      alignItems='top'
      mt={8}
      ml='auto !important'
      mr='auto !important'
    >
      <Grid size={12}>
        <Stack spacing={4} direction='row' justifyContent='center'>
          <Paper
            variant='hoverElevation3'
            onClick={() => setFolderType('1')}
            sx={{
              bgcolor: folderType === '1' ? theme.palette.primary.main : folderType ? theme.palette.grey['300'] : theme.palette.grey['400'],
              width: 180,
              height: 180,
              color: folderType === '1' ? theme.palette.primary.contrastText : undefined,
              boxShadow: folderType === '1' ? 2 : 1,
              cursor: 'pointer'
            }}
          >
            <Stack spacing={4} width='100%' height='100%' alignItems='center' justifyContent='center'>
              <IconPremiumRights size='3em' />
              <Typography width='140px' height='40px' variant='h4' textAlign='center'>Dossier de Prêt</Typography>
            </Stack>
          </Paper>
          <Paper
            variant='hoverElevation3'
            onClick={() => setFolderType('2')}
            sx={{
              bgcolor: folderType === '2' ? theme.palette.primary.main : folderType ? theme.palette.grey['300'] : theme.palette.grey['400'],
              width: 180,
              height: 180,
              color: folderType === '2' ? theme.palette.primary.contrastText : undefined,
              boxShadow: folderType === '2' ? 2 : 1,
              cursor: 'pointer'
            }}
          >
            <Stack spacing={4} width='100%' height='100%' alignItems='center' justifyContent='center'>
              <IconClockDollar size='3em' />
              <Typography width='140px' height='40px' variant='h4' textAlign='center'>Dossier de Prêt longue durée</Typography>
            </Stack>
          </Paper>
        </Stack>
      </Grid>

      <Grid size={12} textAlign='center' mt={4}>
        <Stack direction='row' spacing={2} justifyContent='center'>
          <Button variant='textIcon' href={`${dnaConfig.routes.folders.create.relativeUrl}?step=${activeStep}`}>
            <IconArrowLeft size='1.6rem' />
          </Button>
          <Button
            variant='contained'
            sx={{
              width: '50%',
              '& .MuiButton-startIcon': {
                marginRight: 'auto !important'
              },
              '& .MuiButton-endIcon': {
                marginLeft: 'auto !important'
              }
            }}
            href={`${dnaConfig.routes.folders.create.relativeUrl}?step=${activeStep + 2}`}
            startIcon={<Box width={24} height={24} />}
            endIcon={<IconArrowRight size='1.6rem' />}
            disabled={!folderType}
          >
            {t('folder.steps.nextStep')}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default ChoicesStepsComponentsCreateFolders;
