import { ThemeOptions } from '@mui/material/styles';

export const mainColor = 'rgb(69, 122, 201)';
export const secondaryColor = 'rgb(58, 199, 189)';
export const errorColor = 'rgb(244, 68, 54)';
export const warningColor = 'rgb(255, 173, 33)';
export const successColor = 'rgb(59, 161, 66)';
export const mainRadius = 12;
export const mainLargeRadius = mainRadius * 2;

declare module '@mui/material/styles' {
  // fix the type error when referencing the Theme object in your styled component
  interface Theme {
    primaryColors?: {
      lighter?: string,
      light?: string,
      lightest?: string,
      main?: string,
      darkest?: string,
      dark?: string,
      darker?: string
    }
  }
  // fix the type error when calling `createTheme()` with a custom theme option
  interface ThemeOptions {
    primaryColors?: {
      lighter?: string,
      light?: string,
      lightest?: string,
      main?: string,
      darkest?: string,
      dark?: string,
      darker?: string
    }
  }
}

const themeMui: ThemeOptions = {
  primaryColors: {
    lighter: 'rgb(222, 233, 248)',
    light: 'rgb(196, 216, 246)',
    lightest: 'rgb(119, 161, 223)',
    main: mainColor,
    darkest: 'rgb(25, 88, 181)',
    dark: 'rgb(10, 55, 121)',
    darker: 'rgb(8, 40, 88)'
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: '2rem',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 1.16
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 1.2
    },
    h3: {
      fontSize: '1.2rem',
      fontWeight: 700,
      letterSpacing: 0.5,
      lineHeight: 1.16
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.33
    },
    h5: {
      fontSize: '0.9rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.33
    },
    h6: {
      fontSize: '0.75rem',
      fontWeight: 500,
      letterSpacing: 0.15,
      lineHeight: 1.6
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 400,
      letterSpacing: 0.15,
      lineHeight: 1.75
    },
    subtitle2: {
      fontSize: '0.8rem',
      fontWeight: 400,
      letterSpacing: 0.1,
      lineHeight: 1.5
    },
    body1: {
      fontSize: '0.9rem',
      fontWeight: 400,
      letterSpacing: 0.15,
      lineHeight: 1.5
    },
    body2: {
      fontSize: '0.8rem',
      fontWeight: 400,
      letterSpacing: 0.1,
      lineHeight: 1.43
    },
    caption: {
      fontSize: '0.7rem',
      fontWeight: 300,
      letterSpacing: 0.4,
      lineHeight: 1.66
    },
    button: {
      fontSize: '1rem',
      fontWeight: 500,
      letterSpacing: 1.25,
      lineHeight: 1.75
    },
    overline: {
      fontSize: '0.8rem',
      fontWeight: 400,
      letterSpacing: 1.5,
      lineHeight: 2.66
    }
  },
  palette: {
    primary: {
      light: 'rgb(222, 233, 248)',
      main: mainColor,
      dark: 'rgb(8, 40, 88)',
      contrastText: 'rgb(248, 249, 250)'
    },
    secondary: {
      light: 'rgb(177, 242, 237)',
      main: secondaryColor,
      dark: 'rgb(0, 82, 76)',
      contrastText: 'rgb(248, 249, 250)'
    },
    error: {
      light: 'rgb(255, 181, 175)',
      main: errorColor,
      dark: 'rgb(131, 10, 0)',
      contrastText: 'rgb(248, 249, 250)'
    },
    warning: {
      light: 'rgb(255, 238, 190)',
      main: warningColor,
      dark: 'rgb(170, 107, 0)',
      contrastText: 'rgb(248, 249, 250)'
    },
    success: {
      light: 'rgb(190, 234, 193)',
      main: successColor,
      dark: 'rgb(0, 75, 5)',
      contrastText: 'rgb(248, 249, 250)'
    },
    info: {
      light: 'rgb(196, 216, 246)',
      main: mainColor,
      dark: 'rgb(10, 55, 121)',
      contrastText: 'rgb(248, 249, 250)'
    },
    text: {
      primary: 'rgb(102, 102, 102)',
      secondary: 'rgb(148, 148, 148)',
      disabled: 'rgb(196, 195, 195)'
    },
    background: {
      paper: 'rgb(248, 249, 250)',
      default: 'rgb(233, 236, 239)'

    },
    grey: {
      50: 'rgb(248, 249, 250)',
      100: 'rgb(241, 243, 245)',
      200: 'rgb(233, 236, 239)',
      300: 'rgb(222, 226, 230)',
      400: 'rgb(206, 212, 218)',
      500: 'rgb(196, 195, 195)',
      600: 'rgb(148, 148, 148)',
      700: 'rgb(102, 102, 102)',
      800: 'rgb(45, 45, 45)',
      900: 'rgb(4, 3, 3)'
    },
    divider: 'rgba(0, 0, 0, 0.08)',
    common: {
      white: 'rgb(248, 249, 250)'
    }
  },
  shape: {
    borderRadius: mainRadius
  },
  spacing: [0, 4, 8, 12, 16, 24, 32, 48, 56, 64],
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195
    },
    easing: {
      // This is the most common easing curve.
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
    }
  },
  shadows: [
    'none',
    '0px 2px 1px rgba(0, 0, 0, 0.02), 0px 3px 2px rgba(0, 0, 0, 0.08)',
    '0px 3px 4px rgba(0, 0, 0, 0.10), 0px 3px 6px rgba(0, 0, 0, 0.18)',
    '0px 5px 7px rgba(0, 0, 0, 0.15), 0px 7px 15px rgba(0, 0, 0, 0.25)',
    '0px 12px 24px rgba(0, 0, 0, 0.32)',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  ],
  zIndex: {
    appBar: 999
  }
};

export default themeMui;
