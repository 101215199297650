import React, { ReactElement } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IconSettings, IconAlertTriangleFilled } from '@tabler/icons-react';
import deecisionLogo from '@/assets/logos/Deecision.svg';

const RotatingBox = styled(Grid)(() => ({
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(359deg)' }
  },
  transform: 'rotate(359deg)',
  animation: `spin infinite 4s linear`
}));

function MaintenancePage(props: { app: string }): ReactElement {

  return (
    <Grid
      container
      sx={{
        height: '100vh',
        width: '100vw',
        backgroundColor: '#F1F5FB'
      }}
      justifyContent='center'
      alignContent='center'
    >
      <Grid sx={{ position: 'relative', top: '-40px' }}>
        <Grid container spacing={2} justifyContent='center'>
          <Grid size={12}>
            <RotatingBox>
              <IconSettings style={{
                display: 'block',
                width: '64px',
                height: '64px',
                margin: '0 auto 24px auto',
                opacity: '30%'
              }}
              />
            </RotatingBox>
          </Grid>
          <Grid size={12}>
            <img
              src={deecisionLogo}
              alt='Deecision-Logo'
              style={{
                position: 'relative',
                left: '16px',
                width: '300px',
                display: 'block',
                margin: '0 auto 16px auto' }}
            />
          </Grid>
          <Grid>
            <Grid container direction='column' spacing={2} alignItems='center'>
              <Grid>
                <Grid container >
                  <Grid>
                    <IconAlertTriangleFilled />
                  </Grid>
                  <Grid>
                    <Typography variant='h4' sx={{ verticalAlign: 'baseline', ml: 2 }}>
                      Sorry, we are currently down for maintenance
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid>
                <Typography variant='subtitle1' sx={{ verticalAlign: 'baseline', ml: 2 }}>
                  We apologise for the inconvenience and will be back up shortly.
                </Typography>
              </Grid>
            </Grid>
            <Grid size={4} sx={{ m: 'auto', mt: 6, mb: 0 }}>
              <Button
                variant='contained'
                fullWidth
                onClick={() => window.location.assign(`${window.location.origin}/${props.app}`)}
              >
                Try Again
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MaintenancePage;
