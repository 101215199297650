import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { IconNews } from '@tabler/icons-react';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import TitleComponent from '@/components/title';
import { WidgetProps } from '@/main/containers/widgets/types';
import BaseWidget from '@/main/containers/widgets/base-widget';

function WidgetsNewsList(props: WidgetProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const news = props.data.dataViews?.data?.news;

  return (
    <BaseWidget
      {...props}
      title={
        <TitleComponent title={t('entities.companies.company.tabs.news')} icon={IconNews} small />
      }
    >
      <Stack spacing={3} p={2}>
        {news && news.length > 0 ?
          news.map((n, index) => (
            <Link
              key={n.title}
              href={n.link}
              target='_blank'
              sx={{
                textDecoration: 'none',
                textDecorationColor: `${theme.palette.text.primary} !important`,
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              {index !== 0 &&
                <Divider sx={{ borderBottomWidth: 1.5, borderRadius: 1, mb: 3 }}/>
              }
              <Typography sx={{ color: 'text.primary' }}>
                {n.title.split(' - ')[0]}
              </Typography>
              <Typography variant='body2' color='primary'>{n.title.split(' - ')[1]}</Typography>
            </Link>
          )) :
          <Typography textAlign='center' mt={4} mb={4}>
            {t('common.news.noNewsFound')}
          </Typography>
        }
      </Stack>
    </BaseWidget>
  );
}

export default WidgetsNewsList;
