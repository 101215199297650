import React, { ReactElement } from 'react';
import { getCountryISO2, getFlagEmoji } from '@/utils';

function CountriesFlags(props: { countries: string[], size?: 'small' | 'medium' | 'large' }): ReactElement[] {

  return (
    props.countries.map((country) => {
      const countryCodeISO2 = getCountryISO2(country);

      return (
        <span key={country} className='emojiFlag' style={{ fontFamily: 'NotoColorEmojiLimited', fontSize: props.size === 'small' ? '1.2rem' : '1.6rem' }}>{`${getFlagEmoji(countryCodeISO2)}`}</span>
      );
    })
  );
}

export default CountriesFlags;
