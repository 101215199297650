(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports, require('d3-selection'), require('d3-transition')) :
    typeof define === 'function' && define.amd ? define(['exports', 'd3-selection', 'd3-transition'], factory) :
      (factory((global.venn = global.venn || {}), global.d3, global.d3));
}(this, (exports, d3Selection, d3Transition) => {
  let areaError = [];
  const SMALL = 1e-10;

  /** Returns the intersection area of a bunch of circles (where each circle
     is an object having an x,y and radius property) */
  function intersectionArea(circles, stats) {
    // get all the intersection points of the circles
    const intersectionPoints = getIntersectionPoints(circles);

    // filter out points that aren't included in all the circles
    const innerPoints = intersectionPoints.filter(p => containedInCircles(p, circles));

    let arcArea = 0; let polygonArea = 0; const arcs = []; let i;

    // if we have intersection points that are within all the circles,
    // then figure out the area contained by them
    if (innerPoints.length > 1) {
      // sort the points by angle from the center of the polygon, which lets
      // us just iterate over points to get the edges
      const center = getCenter(innerPoints);
      for (i = 0; i < innerPoints.length; ++i ) {
        const p = innerPoints[i];
        p.angle = Math.atan2(p.x - center.x, p.y - center.y);
      }
      innerPoints.sort((a, b) => b.angle - a.angle);

      // iterate over all points, get arc between the points
      // and update the areas
      let p2 = innerPoints[innerPoints.length - 1];
      for (i = 0; i < innerPoints.length; ++i) {
        const p1 = innerPoints[i];

        // polygon area updates easily ...
        polygonArea += (p2.x + p1.x) * (p1.y - p2.y);

        // updating the arc area is a little more involved
        const midPoint = { x: (p1.x + p2.x) / 2,
          y: (p1.y + p2.y) / 2 };
        let arc = null;

        for (let j = 0; j < p1.parentIndex.length; ++j) {
          if (p2.parentIndex.indexOf(p1.parentIndex[j]) > -1) {
            // figure out the angle halfway between the two points
            // on the current circle
            const circle = circles[p1.parentIndex[j]];
            const a1 = Math.atan2(p1.x - circle.x, p1.y - circle.y);
            const a2 = Math.atan2(p2.x - circle.x, p2.y - circle.y);

            let angleDiff = (a2 - a1);
            if (angleDiff < 0) {
              angleDiff += 2*Math.PI;
            }

            // and use that angle to figure out the width of the
            // arc
            const a = a2 - angleDiff/2;
            const width = distance(midPoint, {
              x: circle.x + circle.radius * Math.sin(a),
              y: circle.y + circle.radius * Math.cos(a)
            });

            // pick the circle whose arc has the smallest width
            if ((arc === null) || (arc.width > width)) {
              arc = { circle,
                width,
                p1,
                p2 };
            }
          }
        }

        if (arc !== null) {
          arcs.push(arc);
          arcArea += circleArea(arc.circle.radius, arc.width);
          p2 = p1;
        }
      }
    } else {
      // no intersection points, is either disjoint - or is completely
      // overlapped. figure out which by examining the smallest circle
      let smallest = circles[0];
      for (i = 1; i < circles.length; ++i) {
        if (circles[i].radius < smallest.radius) {
          smallest = circles[i];
        }
      }

      // make sure the smallest circle is completely contained in all
      // the other circles
      let disjoint = false;
      for (i = 0; i < circles.length; ++i) {
        if (distance(circles[i], smallest) > Math.abs(smallest.radius - circles[i].radius)) {
          disjoint = true;
          break;
        }
      }

      if (disjoint) {
        arcArea = polygonArea = 0;

      } else {
        arcArea = smallest.radius * smallest.radius * Math.PI;
        arcs.push({ circle: smallest,
          p1: { x: smallest.x,        y: smallest.y + smallest.radius },
          p2: { x: smallest.x - SMALL, y: smallest.y + smallest.radius },
          width: smallest.radius * 2 });
      }
    }

    polygonArea /= 2;
    if (stats) {
      stats.area = arcArea + polygonArea;
      stats.arcArea = arcArea;
      stats.polygonArea = polygonArea;
      stats.arcs = arcs;
      stats.innerPoints = innerPoints;
      stats.intersectionPoints = intersectionPoints;
    }

    return arcArea + polygonArea;
  }

  /** returns whether a point is contained by all of a list of circles */
  function containedInCircles(point, circles) {
    for (let i = 0; i < circles.length; ++i) {
      if (distance(point, circles[i]) > circles[i].radius + SMALL) {
        return false;
      }
    }

    return true;
  }

  /** Gets all intersection points between a bunch of circles */
  function getIntersectionPoints(circles) {
    const ret = [];
    for (let i = 0; i < circles.length; ++i) {
      for (let j = i + 1; j < circles.length; ++j) {
        const intersect = circleCircleIntersection(circles[i],
          circles[j]);
        for (let k = 0; k < intersect.length; ++k) {
          const p = intersect[k];
          p.parentIndex = [i, j];
          ret.push(p);
        }
      }
    }

    return ret;
  }

  function circleIntegral(r, x) {
    const y = Math.sqrt(r * r - x * x);

    return x * y + r * r * Math.atan2(x, y);
  }

  /** Returns the area of a circle of radius r - up to width */
  function circleArea(r, width) {
    return circleIntegral(r, width - r) - circleIntegral(r, -r);
  }

  /** euclidean distance between two points */
  function distance(p1, p2) {
    return Math.sqrt((p1.x - p2.x) * (p1.x - p2.x) +
                         (p1.y - p2.y) * (p1.y - p2.y));
  }

  /** Returns the overlap area of two circles of radius r1 and r2 - that
    have their centers separated by distance d. Simpler faster
    circle intersection for only two circles */
  function circleOverlap(r1, r2, d) {
    // no overlap
    if (d >= r1 + r2) {
      return 0;
    }

    // completely overlapped
    if (d <= Math.abs(r1 - r2)) {
      return Math.PI * Math.min(r1, r2) * Math.min(r1, r2);
    }

    const w1 = r1 - (d * d - r2 * r2 + r1 * r1) / (2 * d);
    const w2 = r2 - (d * d - r1 * r1 + r2 * r2) / (2 * d);

    return circleArea(r1, w1) + circleArea(r2, w2);
  }

  /** Given two circles (containing a x/y/radius attributes),
    returns the intersecting points if possible.
    note: doesn't handle cases where there are infinitely many
    intersection points (circles are equivalent):, or only one intersection point */
  function circleCircleIntersection(p1, p2) {
    const d = distance(p1, p2);
    const r1 = p1.radius;
    const r2 = p2.radius;

    // if to far away, or self contained - can't be done
    if ((d >= (r1 + r2)) || (d <= Math.abs(r1 - r2))) {
      return [];
    }

    const a = (r1 * r1 - r2 * r2 + d * d) / (2 * d);
    const h = Math.sqrt(r1 * r1 - a * a);
    const x0 = p1.x + a * (p2.x - p1.x) / d;
    const y0 = p1.y + a * (p2.y - p1.y) / d;
    const rx = -(p2.y - p1.y) * (h / d);
    const ry = -(p2.x - p1.x) * (h / d);

    return [{ x: x0 + rx, y: y0 - ry },
      { x: x0 - rx, y: y0 + ry }];
  }

  /** Returns the center of a bunch of points */
  function getCenter(points) {
    const center = { x: 0, y: 0 };
    for (let i =0; i < points.length; ++i ) {
      center.x += points[i].x;
      center.y += points[i].y;
    }
    center.x /= points.length;
    center.y /= points.length;

    return center;
  }

  /** finds the zeros of a function, given two starting points (which must
     * have opposite signs */
  function bisect(f, a, b, parameters) {
    parameters = parameters || {};
    const maxIterations = parameters.maxIterations || 100;
    const tolerance = parameters.tolerance || 1e-10;
    const fA = f(a);
    const fB = f(b);
    let delta = b - a;

    if (fA * fB > 0) {
      throw "Initial bisect points must have opposite signs";
    }

    if (fA === 0) return a;
    if (fB === 0) return b;

    for (let i = 0; i < maxIterations; ++i) {
      delta /= 2;
      const mid = a + delta;
      const fMid = f(mid);

      if (fMid * fA >= 0) {
        a = mid;
      }

      if ((Math.abs(delta) < tolerance) || (fMid === 0)) {
        return mid;
      }
    }

    return a + delta;
  }

  // need some basic operations on vectors, rather than adding a dependency,
  // just define here
  function zeros(x) {
    const r = new Array(x); for (let i = 0; i < x; ++i) {
      r[i] = 0;
    }

    return r;
  }
  function zerosM(x, y) {
    return zeros(x).map(() => zeros(y));
  }

  function dot(a, b) {
    let ret = 0;
    for (let i = 0; i < a.length; ++i) {
      ret += a[i] * b[i];
    }

    return ret;
  }

  function norm2(a)  {
    return Math.sqrt(dot(a, a));
  }

  function scale(ret, value, c) {
    for (let i = 0; i < value.length; ++i) {
      ret[i] = value[i] * c;
    }
  }

  function weightedSum(ret, w1, v1, w2, v2) {
    for (let j = 0; j < ret.length; ++j) {
      ret[j] = w1 * v1[j] + w2 * v2[j];
    }
  }

  /** minimizes a function using the downhill simplex method */
  function nelderMead(f, x0, parameters) {
    parameters = parameters || {};

    const maxIterations = parameters.maxIterations || x0.length * 200;
    const nonZeroDelta = parameters.nonZeroDelta || 1.05;
    const zeroDelta = parameters.zeroDelta || 0.001;
    const minErrorDelta = parameters.minErrorDelta || 1e-6;
    const minTolerance = parameters.minErrorDelta || 1e-5;
    const rho = (parameters.rho !== undefined) ? parameters.rho : 1;
    const chi = (parameters.chi !== undefined) ? parameters.chi : 2;
    const psi = (parameters.psi !== undefined) ? parameters.psi : -0.5;
    const sigma = (parameters.sigma !== undefined) ? parameters.sigma : 0.5;
    let maxDiff;

    // initialize simplex.
    const N = x0.length;
    const simplex = new Array(N + 1);
    simplex[0] = x0;
    simplex[0].fx = f(x0);
    simplex[0].id = 0;
    for (var i = 0; i < N; ++i) {
      const point = x0.slice();
      point[i] = point[i] ? point[i] * nonZeroDelta : zeroDelta;
      simplex[i+1] = point;
      simplex[i+1].fx = f(point);
      simplex[i+1].id = i+1;
    }

    function updateSimplex(value) {
      for (let i = 0; i < value.length; i++) {
        simplex[N][i] = value[i];
      }
      simplex[N].fx = value.fx;
    }

    const sortOrder = function(a, b) {
      return a.fx - b.fx;
    };

    const centroid = x0.slice();
    const reflected = x0.slice();
    const contracted = x0.slice();
    const expanded = x0.slice();

    for (let iteration = 0; iteration < maxIterations; ++iteration) {
      simplex.sort(sortOrder);

      if (parameters.history) {
        // copy the simplex (since later iterations will mutate) and
        // sort it to have a consistent order between iterations
        const sortedSimplex = simplex.map((x) => {
          const state = x.slice();
          state.fx = x.fx;
          state.id = x.id;

          return state;
        });
        sortedSimplex.sort((a, b) => a.id - b.id);

        parameters.history.push({ x: simplex[0].slice(),
          fx: simplex[0].fx,
          simplex: sortedSimplex });
      }

      maxDiff = 0;
      for (i = 0; i < N; ++i) {
        maxDiff = Math.max(maxDiff, Math.abs(simplex[0][i] - simplex[1][i]));
      }

      if ((Math.abs(simplex[0].fx - simplex[N].fx) < minErrorDelta) &&
                (maxDiff < minTolerance)) {
        break;
      }

      // compute the centroid of all but the worst point in the simplex
      for (i = 0; i < N; ++i) {
        centroid[i] = 0;
        for (let j = 0; j < N; ++j) {
          centroid[i] += simplex[j][i];
        }
        centroid[i] /= N;
      }

      // reflect the worst point past the centroid  and compute loss at reflected
      // point
      const worst = simplex[N];
      weightedSum(reflected, 1+rho, centroid, -rho, worst);
      reflected.fx = f(reflected);

      // if the reflected point is the best seen, then possibly expand
      if (reflected.fx < simplex[0].fx) {
        weightedSum(expanded, 1+chi, centroid, -chi, worst);
        expanded.fx = f(expanded);
        if (expanded.fx < reflected.fx) {
          updateSimplex(expanded);
        }  else {
          updateSimplex(reflected);
        }
      }

      // if the reflected point is worse than the second worst, we need to
      // contract
      else if (reflected.fx >= simplex[N-1].fx) {
        let shouldReduce = false;

        if (reflected.fx > worst.fx) {
          // do an inside contraction
          weightedSum(contracted, 1+psi, centroid, -psi, worst);
          contracted.fx = f(contracted);
          if (contracted.fx < worst.fx) {
            updateSimplex(contracted);
          } else {
            shouldReduce = true;
          }
        } else {
          // do an outside contraction
          weightedSum(contracted, 1-psi * rho, centroid, psi*rho, worst);
          contracted.fx = f(contracted);
          if (contracted.fx < reflected.fx) {
            updateSimplex(contracted);
          } else {
            shouldReduce = true;
          }
        }

        if (shouldReduce) {
          // if we don't contract here, we're done
          if (sigma >= 1) break;

          // do a reduction
          for (i = 1; i < simplex.length; ++i) {
            weightedSum(simplex[i], 1 - sigma, simplex[0], sigma, simplex[i]);
            simplex[i].fx = f(simplex[i]);
          }
        }
      } else {
        updateSimplex(reflected);
      }
    }

    simplex.sort(sortOrder);

    return { fx: simplex[0].fx,
      x: simplex[0] };
  }

  /// searches along line 'pk' for a point that satifies the wolfe conditions
  /// See 'Numerical Optimization' by Nocedal and Wright p59-60
  /// f : objective function
  /// pk : search direction
  /// current: object containing current gradient/loss
  /// next: output: contains next gradient/loss
  /// returns a: step size taken
  function wolfeLineSearch(f, pk, current, next, a, c1, c2) {
    const phi0 = current.fx; const phiPrime0 = dot(current.fxprime, pk);
    let phi = phi0; let phi_old = phi0;
    let phiPrime = phiPrime0;
    let a0 = 0;

    a = a || 1;
    c1 = c1 || 1e-6;
    c2 = c2 || 0.1;

    function zoom(a_lo, a_high, phi_lo) {
      for (let iteration = 0; iteration < 16; ++iteration) {
        a = (a_lo + a_high)/2;
        weightedSum(next.x, 1.0, current.x, a, pk);
        phi = next.fx = f(next.x, next.fxprime);
        phiPrime = dot(next.fxprime, pk);

        if ((phi > (phi0 + c1 * a * phiPrime0)) ||
                    (phi >= phi_lo)) {
          a_high = a;

        } else  {
          if (Math.abs(phiPrime) <= -c2 * phiPrime0) {
            return a;
          }

          if (phiPrime * (a_high - a_lo) >=0) {
            a_high = a_lo;
          }

          a_lo = a;
          phi_lo = phi;
        }
      }

      return 0;
    }

    for (let iteration = 0; iteration < 10; ++iteration) {
      weightedSum(next.x, 1.0, current.x, a, pk);
      phi = next.fx = f(next.x, next.fxprime);
      phiPrime = dot(next.fxprime, pk);
      if ((phi > (phi0 + c1 * a * phiPrime0)) ||
                (iteration && (phi >= phi_old))) {
        return zoom(a0, a, phi_old);
      }

      if (Math.abs(phiPrime) <= -c2 * phiPrime0) {
        return a;
      }

      if (phiPrime >= 0 ) {
        return zoom(a, a0, phi);
      }

      phi_old = phi;
      a0 = a;
      a *= 2;
    }

    return a;
  }

  function conjugateGradient(f, initial, params) {
    // allocate all memory up front here, keep out of the loop for perfomance
    // reasons
    let current = { x: initial.slice(), fx: 0, fxprime: initial.slice() };
    let next = { x: initial.slice(), fx: 0, fxprime: initial.slice() };
    const yk = initial.slice();
    let pk; let temp;
    let a = 1;
    let maxIterations;

    params = params || {};
    maxIterations = params.maxIterations || initial.length * 20;

    current.fx = f(current.x, current.fxprime);
    pk = current.fxprime.slice();
    scale(pk, current.fxprime, -1);

    for (let i = 0; i < maxIterations; ++i) {
      a = wolfeLineSearch(f, pk, current, next, a);

      // todo: history in wrong spot?
      if (params.history) {
        params.history.push({ x: current.x.slice(),
          fx: current.fx,
          fxprime: current.fxprime.slice(),
          alpha: a });
      }

      if (!a) {
        // faiiled to find point that satifies wolfe conditions.
        // reset direction for next iteration
        scale(pk, current.fxprime, -1);

      } else {
        // update direction using Polak–Ribiere CG method
        weightedSum(yk, 1, next.fxprime, -1, current.fxprime);

        const delta_k = dot(current.fxprime, current.fxprime);
        const beta_k = Math.max(0, dot(yk, next.fxprime) / delta_k);

        weightedSum(pk, beta_k, pk, -1, next.fxprime);

        temp = current;
        current = next;
        next = temp;
      }

      if (norm2(current.fxprime) <= 1e-5) {
        break;
      }
    }

    if (params.history) {
      params.history.push({ x: current.x.slice(),
        fx: current.fx,
        fxprime: current.fxprime.slice(),
        alpha: a });
    }

    return current;
  }

  /** given a list of set objects, and their corresponding overlaps.
    updates the (x, y, radius) attribute on each set such that their positions
    roughly correspond to the desired overlaps */
  function venn(areas, parameters) {
    parameters = parameters || {};
    parameters.maxIterations = parameters.maxIterations || 500;
    const initialLayout = parameters.initialLayout || bestInitialLayout;

    // add in missing pairwise areas as having 0 size
    areas = addMissingAreas(areas);

    // initial layout is done greedily
    const circles = initialLayout(areas);

    // transform x/y coordinates to a vector to optimize
    const initial = []; const setids = []; let setid;
    for (setid in circles) {
      if (circles.hasOwnProperty(setid)) {
        initial.push(circles[setid].x);
        initial.push(circles[setid].y);
        setids.push(setid);
      }
    }

    // optimize initial layout from our loss function
    let totalFunctionCalls = 0;
    const solution = nelderMead(
      (values) => {
        totalFunctionCalls += 1;
        const current = {};
        for (let i = 0; i < setids.length; ++i) {
          const setid = setids[i];
          current[setid] = { x: values[2 * i],
            y: values[2 * i + 1],
            radius: circles[setid].radius
            // size : circles[setid].size
          };
        }

        return lossFunction(current, areas);
      },
      initial,
      parameters);

    // transform solution vector back to x/y points
    const positions = solution.x;
    for (let i = 0; i < setids.length; ++i) {
      setid = setids[i];
      circles[setid].x = positions[2 * i];
      circles[setid].y = positions[2 * i + 1];
    }

    return circles;
  }

  const SMALL$1 = 1e-10;

  /** Returns the distance necessary for two circles of radius r1 + r2 to
    have the overlap area 'overlap' */
  function distanceFromIntersectArea(r1, r2, overlap) {
    // handle complete overlapped circles
    if (Math.min(r1, r2) * Math.min(r1, r2) * Math.PI <= overlap + SMALL$1) {
      return Math.abs(r1 - r2);
    }

    return bisect(distance => circleOverlap(r1, r2, distance) - overlap, 0, r1 + r2);
  }

  /** Missing pair-wise intersection area data can cause problems:
     treating as an unknown means that sets will be laid out overlapping,
     which isn't what people expect. To reflect that we want disjoint sets
     here, set the overlap to 0 for all missing pairwise set intersections */
  function addMissingAreas(areas) {
    areas = areas.slice();

    // two circle intersections that aren't defined
    const ids = []; const pairs = {}; let i; let j; let a; let b;
    for (i = 0; i < areas.length; ++i) {
      const area = areas[i];
      if (area.sets.length == 1) {
        ids.push(area.sets[0]);
      } else if (area.sets.length == 2) {
        a = area.sets[0];
        b = area.sets[1];
        pairs[[a, b]] = true;
        pairs[[b, a]] = true;
      }
    }
    ids.sort((a, b) => a > b);

    for (i = 0; i < ids.length; ++i) {
      a = ids[i];
      for (j = i + 1; j < ids.length; ++j) {
        b = ids[j];
        if (!([a, b] in pairs)) {
          areas.push({ 'sets': [a, b],
            'size': 0 });
        }
      }
    }

    return areas;
  }

  /// Returns two matrices, one of the euclidean distances between the sets
  /// and the other indicating if there are subset or disjoint set relationships
  function getDistanceMatrices(areas, sets, setids) {
    // initialize an empty distance matrix between all the points
    const distances = zerosM(sets.length, sets.length);
    const constraints = zerosM(sets.length, sets.length);

    // compute required distances between all the sets such that
    // the areas match
    areas.filter(x => x.sets.length == 2)
      .map((current) => {
        const left = setids[current.sets[0]];
        const right = setids[current.sets[1]];
        const r1 = Math.sqrt(sets[left].size / Math.PI);
        const r2 = Math.sqrt(sets[right].size / Math.PI);
        const distance = distanceFromIntersectArea(r1, r2, current.size);

        distances[left][right] = distances[right][left] = distance;

        // also update constraints to indicate if its a subset or disjoint
        // relationship
        let c = 0;
        if (current.size + 1e-10 >= Math.min(sets[left].size,
          sets[right].size)) {
          c = 1;
        } else if (current.size <= 1e-10) {
          c = -1;
        }
        constraints[left][right] = constraints[right][left] = c;
      });

    return { distances, constraints };
  }

  /// computes the gradient and loss simulatenously for our constrained MDS optimizer
  function constrainedMDSGradient(x, fxprime, distances, constraints) {
    let loss = 0; let i;
    for (i = 0; i < fxprime.length; ++i) {
      fxprime[i] = 0;
    }

    for (i = 0; i < distances.length; ++i) {
      const xi = x[2 * i]; const yi = x[2 * i + 1];
      for (let j = i + 1; j < distances.length; ++j) {
        const xj = x[2 * j]; const yj = x[2 * j + 1];
        const dij = distances[i][j];
        const constraint = constraints[i][j];

        const squaredDistance = (xj - xi) * (xj - xi) + (yj - yi) * (yj - yi);
        const distance = Math.sqrt(squaredDistance);
        const delta = squaredDistance - dij * dij;

        if (((constraint > 0) && (distance <= dij)) ||
                    ((constraint < 0) && (distance >= dij))) {
          continue;
        }

        loss += 2 * delta * delta;

        fxprime[2*i]     += 4 * delta * (xi - xj);
        fxprime[2*i + 1] += 4 * delta * (yi - yj);

        fxprime[2*j]     += 4 * delta * (xj - xi);
        fxprime[2*j + 1] += 4 * delta * (yj - yi);
      }
    }

    return loss;
  }

  /// takes the best working variant of either constrained MDS or greedy
  function bestInitialLayout(areas, params) {
    let initial = greedyLayout(areas, params);

    // greedylayout is sufficient for all 2/3 circle cases. try out
    // constrained MDS for higher order problems, take its output
    // if it outperforms. (greedy is aesthetically better on 2/3 circles
    // since it axis aligns)
    if (areas.length >= 8) {
      const constrained  = constrainedMDSLayout(areas, params);
      const constrainedLoss = lossFunction(constrained, areas);
      const greedyLoss = lossFunction(initial, areas);

      if (constrainedLoss + 1e-8 < greedyLoss) {
        initial = constrained;
      }
    }

    return initial;
  }

  /// use the constrained MDS variant to generate an initial layout
  function constrainedMDSLayout(areas, params) {
    params = params || {};
    const restarts = params.restarts || 10;

    // bidirectionally map sets to a rowid  (so we can create a matrix)
    const sets = []; const setids = {}; let i;
    for (i = 0; i < areas.length; ++i ) {
      const area = areas[i];
      if (area.sets.length == 1) {
        setids[area.sets[0]] = sets.length;
        sets.push(area);
      }
    }

    const matrices = getDistanceMatrices(areas, sets, setids);
    let distances = matrices.distances;
    const constraints = matrices.constraints;

    // keep distances bounded, things get messed up otherwise.
    // TODO: proper preconditioner?
    const norm = norm2(distances.map(norm2))/(distances.length);
    distances = distances.map(row => row.map(value => value / norm));

    const obj = function(x, fxprime) {
      return constrainedMDSGradient(x, fxprime, distances, constraints);
    };

    let best; let current;
    for (i = 0; i < restarts; ++i) {
      const initial = zeros(distances.length*2).map(Math.random);

      current = conjugateGradient(obj, initial, params);
      if (!best || (current.fx < best.fx)) {
        best = current;
      }
    }
    const positions = best.x;

    // translate rows back to (x,y,radius) coordinates
    const circles = {};
    for (i = 0; i < sets.length; ++i) {
      const set = sets[i];
      circles[set.sets[0]] = {
        x: positions[2*i] * norm,
        y: positions[2*i + 1] * norm,
        radius: Math.sqrt(set.size / Math.PI)
      };
    }

    if (params.history) {
      for (i = 0; i < params.history.length; ++i) {
        scale(params.history[i].x, norm);
      }
    }

    return circles;
  }

  /** Lays out a Venn diagram greedily, going from most overlapped sets to
    least overlapped, attempting to position each new set such that the
    overlapping areas to already positioned sets are basically right */
  function greedyLayout(areas) {
    // define a circle for each set
    const circles = {}; const setOverlaps = {}; let set;
    for (var i = 0; i < areas.length; ++i) {
      const area = areas[i];
      if (area.sets.length == 1) {
        set = area.sets[0];
        circles[set] = { x: 1e10, y: 1e10,
          rowid: circles.length,
          size: area.size,
          radius: Math.sqrt(area.size / Math.PI) };
        setOverlaps[set] = [];
      }
    }
    areas = areas.filter(a => a.sets.length == 2);

    // map each set to a list of all the other sets that overlap it
    for (i = 0; i < areas.length; ++i) {
      const current = areas[i];
      let weight = current.hasOwnProperty('weight') ? current.weight : 1.0;
      const left = current.sets[0]; const right = current.sets[1];

      // completely overlapped circles shouldn't be positioned early here
      if (current.size + SMALL$1 >= Math.min(circles[left].size,
        circles[right].size)) {
        weight = 0;
      }

      setOverlaps[left].push ({ set: right, size: current.size, weight });
      setOverlaps[right].push({ set: left,  size: current.size, weight });
    }

    // get list of most overlapped sets
    const mostOverlapped = [];
    for (set in setOverlaps) {
      if (setOverlaps.hasOwnProperty(set)) {
        let size = 0;
        for (i = 0; i < setOverlaps[set].length; ++i) {
          size += setOverlaps[set][i].size * setOverlaps[set][i].weight;
        }

        mostOverlapped.push({ set, size });
      }
    }

    // sort by size desc
    function sortOrder(a, b) {
      return b.size - a.size;
    }
    mostOverlapped.sort(sortOrder);

    // keep track of what sets have been laid out
    const positioned = {};
    function isPositioned(element) {
      return element.set in positioned;
    }

    // adds a point to the output
    function positionSet(point, index) {
      if (index && index in circles) {
        circles[index].x = point.x;
        circles[index].y = point.y;
        positioned[index] = true;
      }
    }

    // add most overlapped set at (0,0)
    positionSet({ x: 0, y: 0 }, mostOverlapped[0]?.set);

    // get distances between all points. TODO, necessary?
    // answer: probably not
    // var distances = venn.getDistanceMatrices(circles, areas).distances;
    for (i = 1; i < mostOverlapped.length; ++i) {
      const setIndex = mostOverlapped[i].set;
      const overlap = setOverlaps[setIndex].filter(isPositioned);
      set = circles[setIndex];
      overlap.sort(sortOrder);

      if (overlap.length === 0) {
        // this shouldn't happen anymore with addMissingAreas
        throw "ERROR: missing pairwise overlap information";
      }

      const points = [];
      for (var j = 0; j < overlap.length; ++j) {
        // get appropriate distance from most overlapped already added set
        const p1 = circles[overlap[j].set];
        const d1 = distanceFromIntersectArea(set.radius, p1.radius,
          overlap[j].size);

        // sample positions at 90 degrees for maximum aesthetics
        points.push({ x: p1.x + d1, y: p1.y });
        points.push({ x: p1.x - d1, y: p1.y });
        points.push({ y: p1.y + d1, x: p1.x });
        points.push({ y: p1.y - d1, x: p1.x });

        // if we have at least 2 overlaps, then figure out where the
        // set should be positioned analytically and try those too
        for (let k = j + 1; k < overlap.length; ++k) {
          const p2 = circles[overlap[k].set];
          const d2 = distanceFromIntersectArea(set.radius, p2.radius,
            overlap[k].size);

          const extraPoints = circleCircleIntersection(
            { x: p1.x, y: p1.y, radius: d1 },
            { x: p2.x, y: p2.y, radius: d2 });

          for (let l = 0; l < extraPoints.length; ++l) {
            points.push(extraPoints[l]);
          }
        }
      }

      // we have some candidate positions for the set, examine loss
      // at each position to figure out where to put it at
      let bestLoss = 1e50; let bestPoint = points[0];
      for (j = 0; j < points.length; ++j) {
        circles[setIndex].x = points[j].x;
        circles[setIndex].y = points[j].y;
        const loss = lossFunction(circles, areas);
        if (loss < bestLoss) {
          bestLoss = loss;
          bestPoint = points[j];
        }
      }

      positionSet(bestPoint, setIndex);
    }

    return circles;
  }

  /** Given a bunch of sets, and the desired overlaps between these sets - computes
    the distance from the actual overlaps to the desired overlaps. Note that
    this method ignores overlaps of more than 2 circles */
  function lossFunction(sets, overlaps) {
    let output = 0;

    function getCircles(indices) {
      return indices.map(i => sets[i]);
    }

    for (let i = 0; i < overlaps.length; ++i) {
      const area = overlaps[i]; var overlap;
      if (area.sets.length == 1) {
        continue;
      } else if (area.sets.length == 2) {
        const left = sets[area.sets[0]];
        const right = sets[area.sets[1]];
        overlap = circleOverlap(left.radius, right.radius,
          distance(left, right));
      } else {
        overlap = intersectionArea(getCircles(area.sets));
      }

      const weight = area.hasOwnProperty('weight') ? area.weight : 1.0;
      output += weight * (overlap - area.size) * (overlap - area.size);
    }

    return output;
  }

  // orientates a bunch of circles to point in orientation
  function orientateCircles(circles, orientation, orientationOrder) {
    if (orientationOrder === null) {
      circles.sort((a, b) => b.radius - a.radius);
    } else {
      circles.sort(orientationOrder);
    }

    let i;
    // shift circles so largest circle is at (0, 0)
    if (circles.length > 0) {
      const largestX = circles[0].x;
      const largestY = circles[0].y;

      for (i = 0; i < circles.length; ++i) {
        circles[i].x -= largestX;
        circles[i].y -= largestY;
      }
    }

    // rotate circles so that second largest is at an angle of 'orientation'
    // from largest
    if (circles.length > 1) {
      const rotation = Math.atan2(circles[1].x, circles[1].y) - orientation;
      const c = Math.cos(rotation);
      const s = Math.sin(rotation); let x; let y;

      for (i = 0; i < circles.length; ++i) {
        x = circles[i].x;
        y = circles[i].y;
        circles[i].x = c * x - s * y;
        circles[i].y = s * x + c * y;
      }
    }

    // mirror solution if third solution is above plane specified by
    // first two circles
    if (circles.length > 2) {
      let angle = Math.atan2(circles[2].x, circles[2].y) - orientation;
      while (angle < 0) {
        angle += 2* Math.PI;
      }
      while (angle > 2*Math.PI) {
        angle -= 2* Math.PI;
      }
      if (angle > Math.PI) {
        const slope = circles[1].y / (1e-10 + circles[1].x);
        for (i = 0; i < circles.length; ++i) {
          const d = (circles[i].x + slope * circles[i].y) / (1 + slope*slope);
          circles[i].x = 2 * d - circles[i].x;
          circles[i].y = 2 * d * slope - circles[i].y;
        }
      }
    }
  }

  function disjointCluster(circles) {
    // union-find clustering to get disjoint sets
    circles?.map((circle) => {
      circle.parent = circle;
    });

    // path compression step in union find
    function find(circle) {
      if (circle.parent !== circle) {
        circle.parent = find(circle.parent);
      }

      return circle.parent;
    }

    function union(x, y) {
      const xRoot = find(x); const yRoot = find(y);
      xRoot.parent = yRoot;
    }

    // get the union of all overlapping sets
    if (circles) {
      for (var i = 0; i < circles.length; ++i) {
        for (let j = i + 1; j < circles.length; ++j) {
          const maxDistance = circles[i].radius + circles[j].radius;
          if (distance(circles[i], circles[j]) + 1e-10 < maxDistance) {
            union(circles[j], circles[i]);
          }
        }
      }
    }

    // find all the disjoint clusters and group them together
    const disjointClusters = {}; let setid;
    for (i = 0; i < (circles?.length || 0); ++i) {
      setid = find(circles?.[i]).parent.setid;
      if (!(setid in disjointClusters)) {
        disjointClusters[setid] = [];
      }
      disjointClusters[setid].push(circles?.[i]);
    }

    // cleanup bookkeeping
    circles?.map((circle) => {
      delete circle.parent;
    });

    // return in more usable form
    const ret = [];
    for (setid in disjointClusters) {
      if (disjointClusters.hasOwnProperty(setid)) {
        ret.push(disjointClusters[setid]);
      }
    }

    return ret;
  }

  function getBoundingBox(circles) {
    const minMax = function(d) {
      const hi = Math.max.apply(null, circles?.map(
        c => c[d] + c.radius ));
      const lo = Math.min.apply(null, circles?.map(
        c => c[d] - c.radius ));

      return { max: hi, min: lo };
    };

    return { xRange: minMax('x'), yRange: minMax('y') };
  }

  function normalizeSolution(solution, orientation, orientationOrder) {
    if (orientation === null){
      orientation = Math.PI/2;
    }

    // work with a list instead of a dictionary, and take a copy so we
    // don't mutate input
    let circles = []; let i; let setid;
    for (setid in solution) {
      if (solution.hasOwnProperty(setid)) {
        const previous = solution[setid];
        circles.push({ x: previous.x,
          y: previous.y,
          radius: previous.radius,
          setid });
      }
    }

    // get all the disjoint clusters
    const clusters = disjointCluster(circles);

    // orientate all disjoint sets, get sizes
    for (i = 0; i < clusters.length; ++i) {
      orientateCircles(clusters[i], orientation, orientationOrder);
      const bounds = getBoundingBox(clusters[i]);
      clusters[i].size = (bounds.xRange.max - bounds.xRange.min) * (bounds.yRange.max - bounds.yRange.min);
      clusters[i].bounds = bounds;
    }
    clusters.sort((a, b) => b.size - a.size);

    // orientate the largest at 0,0, and get the bounds
    circles = clusters[0];
    let returnBounds = circles?.bounds;

    const spacing = ((returnBounds?.xRange.max || 0) - (returnBounds?.xRange.min || 0))/50;

    function addCluster(cluster, right, bottom) {
      if (!cluster) return;

      const bounds = cluster.bounds; let xOffset; let yOffset; let centreing;

      if (right) {
        xOffset = returnBounds.xRange.max  - bounds.xRange.min + spacing;
      } else {
        xOffset = returnBounds.xRange.max  - bounds.xRange.max;
        centreing = (bounds.xRange.max - bounds.xRange.min) / 2 -
                            (returnBounds.xRange.max - returnBounds.xRange.min) / 2;
        if (centreing < 0) xOffset += centreing;
      }

      if (bottom) {
        yOffset = returnBounds.yRange.max  - bounds.yRange.min + spacing;
      } else {
        yOffset = returnBounds.yRange.max  - bounds.yRange.max;
        centreing = (bounds.yRange.max - bounds.yRange.min) / 2 -
                            (returnBounds.yRange.max - returnBounds.yRange.min) / 2;
        if (centreing < 0) yOffset += centreing;
      }

      for (let j = 0; j < cluster.length; ++j) {
        cluster[j].x += xOffset;
        cluster[j].y += yOffset;
        circles.push(cluster[j]);
      }
    }

    let index = 1;
    while (index < clusters.length) {
      addCluster(clusters[index], true, false);
      addCluster(clusters[index+1], false, true);
      addCluster(clusters[index+2], true, true);
      index += 3;

      // have one cluster (in top left). lay out next three relative
      // to it in a grid
      returnBounds = getBoundingBox(circles);
    }

    // convert back to solution form
    const ret = {};
    for (i = 0; i < (circles?.length || 0); ++i) {
      ret[circles[i].setid] = circles?.[i];
    }

    return ret;
  }

  /** Scales a solution from venn.venn or venn.greedyLayout such that it fits in
    a rectangle of width/height - with padding around the borders. also
    centers the diagram in the available space at the same time */
  function scaleSolution(solution, width, height, padding, scaleToFit, minCircleRadius) {
    const circles = []; const setids = [];
    for (const setid in solution) {
      if (solution.hasOwnProperty(setid)) {
        setids.push(setid);
        circles.push(solution[setid]);
      }
    }

    width -= 2*padding;
    height -= 2*padding;

    const bounds = getBoundingBox(circles);
    const xRange = bounds.xRange;
    const yRange = bounds.yRange;
    let xScaling; let yScaling;
    if (scaleToFit) {
      const toScaleDiameter = Math.sqrt(scaleToFit / Math.PI)*2;
      xScaling = width  / toScaleDiameter;
      yScaling = height  / toScaleDiameter;
    } else {
      xScaling = width  / (xRange.max - xRange.min);
      yScaling = height / (yRange.max - yRange.min);
    }
    const scaling = Math.min(yScaling, xScaling);

    // while we're at it, center the diagram too
    const xOffset = (width -  (xRange.max - xRange.min) * scaling) / 2;
    const yOffset = (height - (yRange.max - yRange.min) * scaling) / 2;

    const scaled = {};
    for (let i = 0; i < circles.length; ++i) {
      const circle = circles[i];
      const radius = scaling * circle.radius;
      scaled[setids[i]] = {
        radius: radius < minCircleRadius ? minCircleRadius : radius, // minimum size
        x: padding + xOffset + (circle.x - xRange.min) * scaling,
        y: padding + yOffset + (circle.y - yRange.min) * scaling
      };
    }

    return scaled;
  }

  /* global console:true */

  function VennDiagram() {
    let width = 600;
    let height = 350;
    let padding = 15;
    let duration = 1000;
    let orientation = Math.PI / 2;
    let normalize = true;
    let scaleToFit = null;
    let minCircleRadius = 0;
    let wrap = true;
    let styled = true;
    let fontSize = null;
    let orientationOrder = null;

    // mimic the behaviour of d3.scale.category10 from the previous
    // version of d3
    const colourMap = {};

    // so this is the same as d3.schemeCategory10, which is only defined in d3 4.0
    // since we can support older versions of d3 as long as we don't force this,
    // I'm hackily redefining below. TODO: remove this and change to d3.schemeCategory10
    const colourScheme = ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22", "#17becf"];
    let colourIndex = 0;
    let colours = function(key) {
      if (key in colourMap) {
        return colourMap[key];
      }
      const ret = colourMap[key] = colourScheme[colourIndex];
      colourIndex += 1;
      if (colourIndex >= colourScheme.length) {
        colourIndex = 0;
      }

      return ret;
    };
    let layoutFunction = venn;

    function chart(selection) {
      const data = selection.datum();
      let solution = layoutFunction(data);
      if (normalize) {
        solution = normalizeSolution(solution,
          orientation,
          orientationOrder);
      }
      const circles = scaleSolution(solution, width, height, padding, undefined, minCircleRadius);
      const textCentres = computeTextCentres(circles, data);

      // create svg if not already existing
      selection.selectAll("svg").data([circles]).enter().append("svg");

      const svg = selection.select("svg")
        .attr("width", width)
        .attr("height", height);

      // to properly transition intersection areas, we need the
      // previous circles locations. load from elements
      const previous = {}; let hasPrevious = false;
      svg.selectAll(".venn-area path").each(function (d) {
        const path = d3Selection.select(this).attr("d");
        if ((d.sets.length == 1) && path) {
          hasPrevious = true;
          previous[d.sets[0]] = circleFromPath(path);
        }
      });

      // interpolate intersection area paths between previous and
      // current paths
      const pathTween = function(d) {
        return function(t) {
          const c = d.sets.map((set) => {
            let start = previous[set]; let end = circles[set];
            if (!start) {
              start = { x: width/2, y: height/2, radius: 1 };
            }
            if (!end) {
              end = { x: width/2, y: height/2, radius: 1 };
            }

            return { 'x': start.x * (1 - t) + end.x * t,
              'y': start.y * (1 - t) + end.y * t,
              'radius': start.radius * (1 - t) + end.radius * t };
          });

          return intersectionAreaPath(c);
        };
      };

      // update data, joining on the set ids
      const nodes = svg.selectAll(".venn-area")
        .data(data, d => d.sets);

      // create new nodes
      const enter = nodes.enter()
        .append('g')
        .attr("class", d => `venn-area venn-${
          d.sets.length == 1 ? "circle" : "intersection"}`)
        .attr("data-venn-sets", d => d.sets.join("_"));

      const enterPath = enter.append("path");
      const enterText = enter.append("text")
        .attr("class", "label")
        .text(d => label(d) )
        .attr("text-anchor", "middle")
        .attr("dy", ".35em")
        .attr("x", width/2)
        .attr("y", height/2);

      // apply minimal style if wanted
      if (styled) {
        enterPath.style("fill-opacity", "0")
          .filter(d => d.sets.length == 1 )
          .style("fill", d => colours(label(d)))
          .style("fill-opacity", ".25");

        enterText
          .style("fill", d => (d.sets.length == 1 ? colours(label(d)) : "#444"));
      }

      // update existing, using pathTween if necessary
      let update = selection;
      if (hasPrevious) {
        update = selection.transition("venn").duration(duration);
        update.selectAll("path")
          .attrTween("d", pathTween);
      } else {
        update.selectAll("path")
          .attr("d", d => intersectionAreaPath(d.sets.map(set => circles[set])));
      }

      const updateText = update.selectAll("text")
        .filter(d => d.sets in textCentres)
        .text(d => label(d) )
        .attr("x", d => Math.floor(textCentres[d.sets].x))
        .attr("y", d => Math.floor(textCentres[d.sets].y));

      if (wrap) {
        if (hasPrevious) {
          // d3 4.0 uses 'on' for events on transitions,
          // but d3 3.0 used 'each' instead. switch appropiately
          if ('on' in updateText) {
            updateText.on("end", wrapText(circles, label));
          } else {
            updateText.each("end", wrapText(circles, label));
          }
        } else {
          updateText.each(wrapText(circles, label));
        }
      }

      // remove old
      const exit = nodes.exit().transition('venn').duration(duration).remove();
      exit.selectAll("path")
        .attrTween("d", pathTween);

      const exitText = exit.selectAll("text")
        .attr("x", width/2)
        .attr("y", height/2);

      // if we've been passed a fontSize explicitly, use it to
      // transition
      if (fontSize !== null) {
        enterText.style("font-size", "0px");
        updateText.style("font-size", fontSize);
        exitText.style("font-size", "0px");
      }

      return { 'circles': circles,
        'textCentres': textCentres,
        'nodes': nodes,
        'enter': enter,
        'update': update,
        'exit': exit };
    }

    function label(d) {
      if (d.label) {
        return d.label;
      }
      if (d.sets.length == 1) {
        return `${  d.sets[0]}`;
      }
    }

    chart.wrap = function(_) {
      if (!arguments.length) return wrap;
      wrap = _;

      return chart;
    };

    chart.width = function(_) {
      if (!arguments.length) return width;
      width = _;

      return chart;
    };

    chart.height = function(_) {
      if (!arguments.length) return height;
      height = _;

      return chart;
    };

    chart.padding = function(_) {
      if (!arguments.length) return padding;
      padding = _;

      return chart;
    };

    chart.colours = function(_) {
      if (!arguments.length) return colours;
      colours = _;

      return chart;
    };

    chart.fontSize = function(_) {
      if (!arguments.length) return fontSize;
      fontSize = _;

      return chart;
    };

    chart.duration = function(_) {
      if (!arguments.length) return duration;
      duration = _;

      return chart;
    };

    chart.layoutFunction = function(_) {
      if (!arguments.length) return layoutFunction;
      layoutFunction = _;

      return chart;
    };

    chart.normalize = function(_) {
      if (!arguments.length) return normalize;
      normalize = _;

      return chart;
    };

    chart.scaleToFit = function(_) {
      if (!arguments.length) return scaleToFit;
      scaleToFit = _;

      return chart;
    };

    chart.minCircleRadius = function(_) {
      if (!arguments.length) return minCircleRadius;
      minCircleRadius = _;

      return chart;
    };

    chart.styled = function(_) {
      if (!arguments.length) return styled;
      styled = _;

      return chart;
    };

    chart.orientation = function(_) {
      if (!arguments.length) return orientation;
      orientation = _;

      return chart;
    };

    chart.orientationOrder = function(_) {
      if (!arguments.length) return orientationOrder;
      orientationOrder = _;

      return chart;
    };

    return chart;
  }
  // sometimes text doesn't fit inside the circle, if thats the case lets wrap
  // the text here such that it fits
  // todo: looks like this might be merged into d3 (
  // https://github.com/mbostock/d3/issues/1642),
  // also worth checking out is
  // http://engineering.findthebest.com/wrapping-axis-labels-in-d3-js/
  // this seems to be one of those things that should be easy but isn't
  function wrapText(circles, labeller) {
    return function() {
      const text = d3Selection.select(this);
      const data = text.datum();
      const width = circles[data.sets[0]].radius || 50;
      const label = labeller(data) || '';

      const words = label.split(/\s+/).reverse();
      const maxLines = 3;
      const minChars = (label.length + words.length) / maxLines;
      let word = words.pop();
      let line = [word];
      let joined;
      let lineNumber = 0;
      const lineHeight = 1.1; // ems
      let tspan = text.text(null).append("tspan").text(word);

      while (true) {
        word = words.pop();
        if (!word) break;
        line.push(word);
        joined = line.join(" ");
        tspan.text(joined);
        if (joined.length > minChars && tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text.append("tspan").text(word);
          lineNumber++;
        }
      }

      const initial = 0.35 - lineNumber * lineHeight / 2;
      const x = text.attr("x");
      const y = text.attr("y");

      text.selectAll("tspan")
        .attr("x", x)
        .attr("y", y)
        .attr("dy", (d, i) => `${initial + i * lineHeight  }em`);
    };
  }

  function circleMargin(current, interior, exterior) {
    let margin = interior[0].radius - distance(interior[0], current); let i; let m;
    for (i = 1; i < interior.length; ++i) {
      m = interior[i].radius - distance(interior[i], current);
      if (m <= margin) {
        margin = m;
      }
    }

    for (i = 0; i < exterior.length; ++i) {
      m = distance(exterior[i], current) - exterior[i].radius;
      if (m <= margin) {
        margin = m;
      }
    }

    return margin;
  }

  // compute the center of some circles by maximizing the margin of
  // the center point relative to the circles (interior) after subtracting
  // nearby circles (exterior)
  function computeTextCentre(interior, exterior) {
    // get an initial estimate by sampling around the interior circles
    // and taking the point with the biggest margin
    const points = []; let i;
    for (i = 0; i < interior.length; ++i) {
      const c = interior[i];
      points.push({ x: c.x, y: c.y });
      points.push({ x: c.x + c.radius/2, y: c.y });
      points.push({ x: c.x - c.radius/2, y: c.y });
      points.push({ x: c.x, y: c.y + c.radius/2 });
      points.push({ x: c.x, y: c.y - c.radius/2 });
    }
    let initial = points[0]; let margin = circleMargin(points[0], interior, exterior);
    for (i = 1; i < points.length; ++i) {
      const m = circleMargin(points[i], interior, exterior);
      if (m >= margin) {
        initial = points[i];
        margin = m;
      }
    }

    // maximize the margin numerically
    const solution = nelderMead(
      p => -1 * circleMargin({ x: p[0], y: p[1] }, interior, exterior),
      [initial.x, initial.y],
      { maxIterations: 500, minErrorDelta: 1e-10 }).x;
    let ret = { x: solution[0], y: solution[1] };

    // check solution, fallback as needed (happens if fully overlapped
    // etc)
    let valid = true;
    for (i = 0; i < interior.length; ++i) {
      if (distance(ret, interior[i]) > interior[i].radius) {
        valid = false;
        break;
      }
    }

    for (i = 0; i < exterior.length; ++i) {
      if (distance(ret, exterior[i]) < exterior[i].radius) {
        valid = false;
        break;
      }
    }

    if (!valid) {
      if (interior.length == 1) {
        ret = { x: interior[0].x, y: interior[0].y };
      } else {
        const areaStats = {};
        intersectionArea(interior, areaStats);

        if (areaStats.arcs.length === 0) {
          ret = { 'x': 0, 'y': -1000, disjoint: true };

        } else if (areaStats.arcs.length == 1) {
          ret = { 'x': areaStats.arcs[0].circle.x,
            'y': areaStats.arcs[0].circle.y };

        } else if (exterior.length) {
          // try again without other circles
          ret = computeTextCentre(interior, []);

        } else {
          // take average of all the points in the intersection
          // polygon. this should basically never happen
          // and has some issues:
          // https://github.com/benfred/venn.js/issues/48#issuecomment-146069777
          ret = getCenter(areaStats.arcs.map(a => a.p1));
        }
      }
    }

    return ret;
  }

  // given a dictionary of {setid : circle}, returns
  // a dictionary of setid to list of circles that completely overlap it
  function getOverlappingCircles(circles) {
    const ret = {}; const circleids = [];
    for (const circleid in circles) {
      circleids.push(circleid);
      ret[circleid] = [];
    }
    for (let i  = 0; i < circleids.length; i++) {
      const a = circles[circleids[i]];
      for (let j = i + 1; j < circleids.length; ++j) {
        const b = circles[circleids[j]];
        const d = distance(a, b);

        if (d + b.radius <= a.radius + 1e-10) {
          ret[circleids[j]].push(circleids[i]);

        } else if (d + a.radius <= b.radius + 1e-10) {
          ret[circleids[i]].push(circleids[j]);
        }
      }
    }

    return ret;
  }

  function computeTextCentres(circles, areas) {
    const ret = {}; const overlapped = getOverlappingCircles(circles);
    for (let i = 0; i < areas.length; ++i) {
      const area = areas[i].sets; const areaids = {}; const exclude = {};
      for (let j = 0; j < area.length; ++j) {
        areaids[area[j]] = true;
        const overlaps = overlapped[area[j]];
        // keep track of any circles that overlap this area,
        // and don't consider for purposes of computing the text
        // centre
        for (let k = 0; k < overlaps.length; ++k) {
          exclude[overlaps[k]] = true;
        }
      }

      const interior = []; const exterior = [];
      for (const setid in circles) {
        if (setid in areaids) {
          interior.push(circles[setid]);
        } else if (!(setid in exclude)) {
          exterior.push(circles[setid]);
        }
      }
      const centre = computeTextCentre(interior, exterior);
      ret[area] = centre;
      if (centre.disjoint && (areas[i].size > 0)) {
        areaError.push(area);
        console.log(`WARNING: ${area} not represented on screen`);
      }
    }

    return  ret;
  }

  // sorts all areas in the venn diagram, so that
  // a particular area is on top (relativeTo) - and
  // all other areas are so that the smallest areas are on top
  function sortAreas(div, relativeTo) {

    // figure out sets that are completly overlapped by relativeTo
    const overlaps = getOverlappingCircles(div.selectAll("svg").datum());
    const exclude = {};
    for (let i = 0; i < relativeTo.sets.length; ++i) {
      const check = relativeTo.sets[i];
      for (const setid in overlaps) {
        const overlap = overlaps[setid];
        for (let j = 0; j < overlap.length; ++j) {
          if (overlap[j] == check) {
            exclude[setid] = true;
            break;
          }
        }
      }
    }

    // checks that all sets are in exclude;
    function shouldExclude(sets) {
      for (let i = 0; i < sets.length; ++i) {
        if (!(sets[i] in exclude)) {
          return false;
        }
      }

      return true;
    }

    // need to sort div's so that Z order is correct
    div.selectAll("g").sort((a, b) => {
      // highest order set intersections first
      if (a.sets.length != b.sets.length) {
        return a.sets.length - b.sets.length;
      }

      if (a == relativeTo) {
        return shouldExclude(b.sets) ? -1 : 1;
      }
      if (b == relativeTo) {
        return shouldExclude(a.sets) ? 1 : -1;
      }

      // finally by size
      return b.size - a.size;
    });
  }

  function circlePath(x, y, r) {
    const ret = [];
    ret.push("\nM", x, y);
    ret.push("\nm", -r, 0);
    ret.push("\na", r, r, 0, 1, 0, r *2, 0);
    ret.push("\na", r, r, 0, 1, 0, -r *2, 0);

    return ret.join(" ");
  }

  // inverse of the circlePath function, returns a circle object from an svg path
  function circleFromPath(path) {
    const tokens = path.split(' ');

    return { 'x': parseFloat(tokens[1]),
      'y': parseFloat(tokens[2]),
      'radius': -parseFloat(tokens[4])
    };
  }

  /** returns a svg path of the intersection area of a bunch of circles */
  function intersectionAreaPath(circles) {
    const stats = {};
    intersectionArea(circles, stats);
    const arcs = stats.arcs;

    if (arcs.length === 0) {
      return "M 0 0";

    } if (arcs.length == 1) {
      const circle = arcs[0].circle;

      return circlePath(circle.x, circle.y, circle.radius);

    }
    // draw path around arcs
    const ret = ["\nM", arcs[0].p2.x, arcs[0].p2.y];
    for (let i = 0; i < arcs.length; ++i) {
      const arc = arcs[i]; const r = arc.circle.radius; const wide = arc.width > r;
      ret.push("\nA", r, r, 0, wide ? 1 : 0, 1,
        arc.p1.x, arc.p1.y);
    }

    return ret.join(" ");

  }

  exports.intersectionArea = intersectionArea;
  exports.circleCircleIntersection = circleCircleIntersection;
  exports.circleOverlap = circleOverlap;
  exports.circleArea = circleArea;
  exports.distance = distance;
  exports.circleIntegral = circleIntegral;
  exports.venn = venn;
  exports.greedyLayout = greedyLayout;
  exports.scaleSolution = scaleSolution;
  exports.normalizeSolution = normalizeSolution;
  exports.bestInitialLayout = bestInitialLayout;
  exports.lossFunction = lossFunction;
  exports.disjointCluster = disjointCluster;
  exports.distanceFromIntersectArea = distanceFromIntersectArea;
  exports.VennDiagram = VennDiagram;
  exports.wrapText = wrapText;
  exports.computeTextCentres = computeTextCentres;
  exports.computeTextCentre = computeTextCentre;
  exports.sortAreas = sortAreas;
  exports.circlePath = circlePath;
  exports.circleFromPath = circleFromPath;
  exports.intersectionAreaPath = intersectionAreaPath;
  exports.areaError = areaError;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
