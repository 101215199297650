import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { IconLayoutList } from '@tabler/icons-react';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Chip from '@mui/material/Chip';
import SmallEntityCards from '@/main/containers/entitiescards/entities';
import BaseWidget from '@/main/containers/widgets/base-widget';
import TitleComponent from '@/components/title';
import dnaConfig from '../../../config/dna.config.json';
import {
  WidgetWithPersonsAugmentedEntitiesProps
} from '@/main/containers/widgets/types';

function WidgetRelationsListMain(props: WidgetWithPersonsAugmentedEntitiesProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <BaseWidget
      {...props}
      title={
        <TitleComponent title={t('entities.relations.listOf')} icon={IconLayoutList} small/>
      }
    >
      <Stack spacing={2}>
        {props.data ?
          props.data
            .sort((a, b) => ((a.entity.dataViews?.data?.companies?.keyFiguresSums?.turnoverSum?.value || 0) > (b.entity.dataViews?.data?.companies?.keyFiguresSums?.turnoverSum?.value || 0) ? -1 : 1))
            .sort((a, b) => (a.details.find(detail => detail) && b.details.find(detail => detail) ?
              (a.details.find(detail => detail)?.positionTypes?.length || 0) > (b.details.find(detail => detail)?.positionTypes?.length || 0) ? 1 : -1 :
              a.details.find(detail => detail) ? -1 : 1))
            .sort((a, b) => ((a.details.find(detail => detail)?.prxmee?.score2 || 0) < (b.details.find(detail => detail)?.prxmee?.score2 || 0) ? 1 : -1))
            .slice(0, 3)
            .map(augmentedEntity => (
              <SmallEntityCards
                key={augmentedEntity.entity.entityId}
                entity={augmentedEntity.entity}
                sideColor={augmentedEntity.details.find(detail => detail)?.activeInNetwork ? theme.palette.success.light : augmentedEntity.details.find(detail => detail)?.activeInNetwork === undefined ? theme.palette.background.default : theme.palette.error.light}
                bgColor={theme.palette.background.paper}
                chips={[...augmentedEntity.details.find(detail => detail)?.positionTypes?.map((positionType: string) => (
                  <Chip
                    key={positionType}
                    label={t(`common.positionTypes.${positionType}`)}
                    size='small'
                  />
                )) || [],
                augmentedEntity.details.find(detail => detail)?.role?.text ?
                  <Chip
                    key='roleDesc'
                    label={augmentedEntity.details.find(detail => detail)?.role?.text}
                    size='small'
                  /> : <></>].filter(chip => chip.key)}
              />
            )) :
          <Stack spacing={2}>
            {[...Array(3)].map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Skeleton key={index} variant='rounded' sx={{ minWidth: 400 }} width='100%' height={76} />
            ))}
          </Stack>
        }
        <Link href={`../${dnaConfig.routes.persons.tabs.relations.baseUrl}`} textAlign='center'>
          {t('entities.relations.all')}
        </Link>
      </Stack>
    </BaseWidget>
  );
}

export default WidgetRelationsListMain;
