import React, { ReactElement } from 'react';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { uniqueId } from 'lodash';
import Box from '@mui/material/Box';
import { mainRadius } from '@/theme/theme';

function LoadingList(props: { rows?: number, rowHeight?: number }): ReactElement {

  return (
    <Stack spacing={4}>
      {!props.rows ?
        <Box
          pr={4}
          pl={4}
          mb={6}
          sx={{
            minWidth: 400
          }}
          height={50}
          width='100%'
        >
          <Skeleton variant='rounded' sx={{ borderRadius: mainRadius * 2, pl: 6, pr: 6 }} height='100%' width='100%' />
        </Box> : null
      }
      <Stack spacing={props.rows ? 2 : 1}>
        {!props.rows ?
          <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between' height={80} width='100%'>
            {[...Array(props.rows || 10)].map((_, index) => (
              <Box
                // eslint-disable-next-line react/no-array-index-key
                key={`loading-page-skeleton-head-${index}-${uniqueId()}`}
                height={18}
                width={76}
              >
                <Skeleton
                  variant='rounded'
                  width='100%'
                  height='100%'
                />
              </Box>
            ))}
          </Stack>: null
        }
        {[...Array(props.rows || 10)].map((_, index) => (
          <Box
          // eslint-disable-next-line react/no-array-index-key
            key={`loading-page-skeleton-${index}-${uniqueId()}`}
            sx={{
              minWidth: 400,
              opacity: 1 - index / (props.rows || 10)
            }}
            width='100%'
            height={props.rowHeight || 46}>
            <Skeleton
              variant='rounded'
              width='100%'
              height='100%'
            />
          </Box>
        ))}
      </Stack>
    </Stack>
  );
}

export default LoadingList;
