import routesAssistedSegmentations from './assisted/routes';
import routesCustomSegmentations from './custom/routes';

function routesSegmentations(id: string) {
  return [
    routesAssistedSegmentations(id),
    routesCustomSegmentations(id)
  ];
}

export default routesSegmentations;
