import React, { ReactElement, useEffect, useState } from 'react';
import { darken, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid2';
import { GroupAllValues } from '@deecision/dna-interfaces';
import { Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { IconArrowNarrowRight, IconCloudComputing, IconUsers } from '@tabler/icons-react';
import Box from '@mui/material/Box';
import IconBuildings from '@/assets/custom/IconBuildings';
import GroupsServices from '../../../modules/deetect/portfolios/services';
import { BasePortfolioDashboardProps, dashboardSpacing } from './types';
import { ReactComponent as DEECISION } from '../../../../assets/DEECISION.svg';

function PortfolioDashboardDataflow(props: BasePortfolioDashboardProps): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const groupsServices = new GroupsServices();
  const [values, setValues] = useState<GroupAllValues>();

  useEffect(() => {
    if (props.group.subGroupId) {
      groupsServices.getGroupValues(props.baseGroupId, props.group.subGroupId)
        .then(res => setValues(res.data));
    }
  }, []);

  return (
    <Stack direction='row' width='100%' spacing={dashboardSpacing} alignItems='center'>
      {props.group.augmented && [
        <Paper sx={{ minWidth: 'min-content', p: dashboardSpacing }}>
          <Stack direction='row' spacing={dashboardSpacing} alignItems='center'>
            <IconCloudComputing />
            <Stack alignItems='center'>
              <DEECISION width={128} height={24} />
              <Typography variant='body1' fontSize={18}>
                {t('segmentation.dashboard.sections.entry.augmentation')}
              </Typography>
            </Stack>
          </Stack>
        </Paper>,
        <Box>
          <IconArrowNarrowRight size='1.8rem'/>
        </Box>
      ]}
      {values ?
        <Paper
          sx={{
            minWidth: 'min-content',
            bgcolor: values.groupDesc.objectType === 'deecPerson' ? theme.palette.primary.light : theme.palette.secondary.light,
            borderColor: values.groupDesc.objectType === 'deecPerson' ? darken(theme.palette.primary.light, 0.2) : darken(theme.palette.secondary.light, 0.2),
            color: values.groupDesc.objectType === 'deecPerson' ? theme.palette.primary.dark : theme.palette.secondary.dark,
            p: dashboardSpacing,
            cursor: 'pointer',
            '&:hover': {
              bgcolor: values.groupDesc.objectType === 'deecPerson' ? darken(theme.palette.primary.light, 0.1) : darken(theme.palette.secondary.light, 0.1),
              borderColor: values.groupDesc.objectType === 'deecPerson' ? darken(theme.palette.primary.light, 0.1) : darken(theme.palette.secondary.light, 0.1)
            }
          }}
          onClick={() => props.callSegmentation?.(values.groupDesc.objectType, props.group.subGroupId)}
        >
          <Grid container spacing={dashboardSpacing} height='fit-content' width='min-content'>
            <Grid size={12}>
              <Stack direction='row' spacing={dashboardSpacing} alignItems='center'>
                {(props.group?.subGroupId || props.group.subGroupId.split('.').at(-1) || '').includes('persons') ?
                  <IconUsers size='1.2rem' /> : <IconBuildings size='1.2rem' color={theme.palette.secondary.dark} />
                }
                <Typography variant='h5' whiteSpace='nowrap' pr={2}>
                  {(props.group.subGroupId.split('.').at(-1) && ['sameFamily', 'personal', 'business', 'extended', 'client', 'notClient'].includes(props.group.subGroupId.split('.').at(-1) || 'unknown')) ? t(`portfolios.dashboard.groups.labels.${props.group.subGroupId.split('.').at(-1)}.label`) : t(`portfolios.dashboard.groups.labels.${props.group.subGroupId}.label`)}
                </Typography>
              </Stack>
            </Grid>
            <Grid size={12}>
              <Typography variant='h2' textAlign='center'>
                {values.values.totalCount}
              </Typography>
            </Grid>
          </Grid>
        </Paper> :
        <Skeleton variant='rounded' width={180} height={80}/>
      }
      {props.group.subGroups &&
        <Box>
          <IconArrowNarrowRight size='1.8rem'/>
        </Box>
      }
      {props.group.subGroups && props.group.subGroups.map(subGroup => (
        <PortfolioDashboardDataflow
          key={`sub-${subGroup.subGroupId}`}
          {...props}
          group={subGroup}
        />
      ))}
    </Stack>
  );
}

export default PortfolioDashboardDataflow;
