import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import { IDataBlock, BingNewsData, NewsApiOrgData, GoogleCseDataBlock } from '@deecision/dna-interfaces';
import { Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { uniq } from 'lodash';
import { IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import NewsCards from '@/main/containers/cards/news';
import Bias from '@/components/chips/bias';

export const newsDataPaths = ['newsApiOrg.news.articles', 'bing.news.articles', 'googleCse.search.results'];

interface BaseProps {
  title?: string,
  data: IDataBlock<BingNewsData | NewsApiOrgData | GoogleCseDataBlock>[],
  siteSelected?: string,
  setSiteSelected: (site?: string) => void
}

type Layers = 'official' | 'credible' | 'noise';

function NewsLayersList(props: { data: IDataBlock<BingNewsData | NewsApiOrgData | GoogleCseDataBlock>[], layers: { value: Layers, label: string }[], layer?: Layers, setLayer: (layer?: Layers) => void }): ReactElement {

  return (
    <Box sx={{ p: 4, height: 'min-content' }}>
      <Stack spacing={2}>
        {props.layers.map(layer => (
          <Stack
            direction='row'
            spacing={2}
            alignItems='center'
            onClick={() => props.setLayer(layer.value === props.layer ? undefined : layer.value)}
            sx={{ cursor: 'pointer' }}
          >
            <Typography
              key={layer.value}
              variant='h4'
              sx={{
                width: 'min-content',
                whiteSpace: 'nowrap',
                textDecoration: layer.value === props.layer && 'underline' || undefined,
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              {`${layer.label} (${props.data.filter(d => (d._source?.query?.trust.includes(layer.value) || (d.data as BingNewsData).query?.trust.includes(layer.value) || (layer.value === 'noise' && ((d._source?.query?.trust && d._source?.query?.trust.length === 0 || (d.data as BingNewsData).query?.trust && (d.data as BingNewsData).query?.trust.length === 0))))).length})`}
            </Typography>
            {layer.value === props.layer &&
              <Box
                sx={{
                  height: '14px',
                  opacity: 0.6,
                  '&:hover': {
                    opacity: 1
                  }
                }}
              >
                <IconX size='1rem' />
              </Box>
            }
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}

function NewsSiteList(props: BaseProps): ReactElement {
  const [siteList, setSiteList] = useState<{ site: string, nbElements: number }[]>([]);

  useEffect(() => {
    const siteListTmp = uniq(props.data.map(d => ((d.data as NewsApiOrgData).publisher?.name || (d.data as BingNewsData).provider?.[0].name || (d.data as GoogleCseDataBlock).displayLink)?.replace('.fr', '').replace('.com', '')));

    setSiteList(siteListTmp.map(site => ({
      site,
      nbElements: props.data.filter(news => (site ? ((news.data as NewsApiOrgData).publisher?.name || (news.data as BingNewsData).provider?.[0].name || (news.data as GoogleCseDataBlock).displayLink)?.replace('.fr', '').replace('.com', '') === site : true)).length
    })).sort((a, b) => b.nbElements - a.nbElements));
  }, [props.data]);

  return (
    <Box sx={{ p: 4, height: 'min-content' }}>
      <Stack spacing={2}>
        {siteList.map(site => (
          <Stack
            key={site.site}
            direction='row'
            spacing={2}
            alignItems='center'
            onClick={() => props.setSiteSelected(site.site === props.siteSelected ? undefined : site.site)}
            sx={{ cursor: 'pointer' }}
          >
            <Typography
              key={site.site}
              variant='body1'
              sx={{
                width: 'min-content',
                whiteSpace: 'nowrap',
                textDecoration: site.site === props.siteSelected && 'underline' || undefined,
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              {`${site.site?.replace('www.', '')?.replace('.fr', '').replace('.com', '')} (${site.nbElements})`}
            </Typography>
            {site.site === props.siteSelected &&
              <Box
                sx={{
                  height: '14px',
                  opacity: 0.6,
                  '&:hover': {
                    opacity: 1
                  }
                }}
              >
                <IconX size='1rem' />
              </Box>
            }
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}

function NewsList(props: Omit<BaseProps, 'siteSelected' | 'setSiteSelected'> & { bias?: string }): ReactElement {
  const { t } = useTranslation();
  const [bias, setBias] = useState<string | undefined>(undefined);

  useEffect(() => {
    setBias(props.bias);
  }, [props.bias]);

  const newsList = useMemo(() => (
    <React.Fragment key={props.data.map(news => (news.data as NewsApiOrgData).url || (news.data as GoogleCseDataBlock).link).join('|')}>
      {props.data.length > 0 ?
        props.data.filter(news => (bias ? (news._source?.query?.bias || (news.data as BingNewsData).query?.bias) === bias : true)).map((news, index) => (
          <React.Fragment key={(news.data as NewsApiOrgData).url || (news.data as GoogleCseDataBlock).link}>
            {index !== 0 &&
              <Divider sx={{ borderBottomWidth: 1.5, borderRadius: 1 }}/>
            }
            <NewsCards news={news} />
          </React.Fragment>
        )) :
        <Typography variant='h4' width='156px' textAlign='center' pt={4} pb={4}>
          {t('common.news.noNewsFound')}
        </Typography>
      }
    </React.Fragment>
  ), [props.data.length, bias]);

  return (
    <Paper elevation={0} sx={{ height: 'min-content' }}>
      <Stack spacing={2} key={bias || 'all'}>
        {props.title &&
          <>
            <Stack direction='row' spacing={2} alignItems='center' sx={{ p: 2, pb: 0 }}>
              <Typography variant='h3' mr={2}>{props.title}</Typography>
              <Bias data={props.data} path={['_source.query.bias', 'data.query.bias']} onClick={b => setBias(b === bias ? undefined : b)} selected={bias} />
            </Stack>
            <Divider sx={{ ml: 2, mr: 2 }} />
          </>
        }
        {newsList}
      </Stack>
    </Paper>
  );
}

function NewsTabsComponents(): ReactElement {
  const { t } = useTranslation();
  const data = useLoaderData() as { data: IDataBlock<BingNewsData | NewsApiOrgData | GoogleCseDataBlock>[] };
  const [searchParams] = useSearchParams();
  const layers: { value: Layers, label: string }[] = [{ value: 'official', label: t('common.trust.official') }, { value: 'credible', label: t('common.trust.credible') }, { value: 'noise', label: t('common.trust.noise') }];
  const [siteSelected, setSiteSelected] = useState<string | undefined>(undefined);
  const [layer, setLayer] = useState<Layers | undefined>(undefined);
  const [bias, setBias] = useState<string | undefined>(undefined);

  useEffect(() => {
    const layerTmp = searchParams.get('layer');
    const biasTmp = searchParams.get('bias');

    if (layerTmp) {
      setLayer(layerTmp as Layers);
    } else {
      setLayer(undefined);
    }
    if (biasTmp) {
      setBias(biasTmp);
    } else {
      setBias(undefined);
    }
  }, [searchParams]);

  return (
    <Stack direction='row' spacing={4} width='100%' justifyContent='space-between'>
      <Stack>
        <NewsLayersList data={data.data} layers={layers} layer={layer} setLayer={setLayer} />
        <Divider sx={{ marginLeft: '8px !important', width: 'calc(100% - 16px)' }} />
        <NewsSiteList
          data={data.data.filter(d => !layer || layer && (d._source?.query?.trust.includes(layer) || (d.data as BingNewsData).query?.trust.includes(layer)))}
          siteSelected={siteSelected}
          setSiteSelected={site => setSiteSelected(site)}
        />
      </Stack>
      {layers.map(layerToDisp => (!layer || layer === layerToDisp.value) && (
        <NewsList
          title={layerToDisp.label}
          data={
            data.data
              .filter(d => (d._source?.query?.trust.includes(layerToDisp.value) || (d.data as BingNewsData).query?.trust.includes(layerToDisp.value)) || layerToDisp.value === 'noise' && (d._source?.query?.trust && d._source?.query?.trust.length === 0 || (d.data as BingNewsData).query?.trust && (d.data as BingNewsData).query?.trust.length === 0))
              ?.filter(news => (siteSelected ? ((news.data as NewsApiOrgData).publisher?.name || (news.data as BingNewsData).provider?.[0].name || (news.data as GoogleCseDataBlock).displayLink)?.replace('.fr', '').replace('.com', '') === siteSelected : true))
          }
          bias={bias}
        />
      ))}
      <Box />
    </Stack>
  );
}

export default NewsTabsComponents;
