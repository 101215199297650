import React, { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { IconCirclesRelation, IconUsers, TablerIcon } from '@tabler/icons-react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconBuildings from '@/assets/custom/IconBuildings';
import { BasePortfolioDashboardProps, dashboardSpacing } from './types';
import BaseWidget from '@/main/containers/widgets/base-widget';
import TitleComponent from '@/components/title';

function ContainerGroupQuickDashboard(props: BasePortfolioDashboardProps & { titleAddon?: ReactNode, customIcon?: TablerIcon, children?: ReactNode }): ReactElement {
  const { t } = useTranslation();

  const iconMapper = (key: string): TablerIcon => {
    if (props.customIcon) return props.customIcon;

    switch (key) {
    case 'persons.companies':
      return IconBuildings;
    case 'persons.network':
      return IconCirclesRelation;
    case 'persons':
      return IconUsers;
    case 'companies':
      return IconBuildings;
    default:
      return IconUsers;
    }
  };

  return (
    <BaseWidget
      title={
        <TitleComponent
          title={props.group.img ?
            <Stack direction='row' spacing={2} alignItems='center'>
              <img src={props.group.img} alt={props.group.subGroupId} height={20} width={96} />
            </Stack> :
            (props.group.subGroupId.split('.').at(-1) && ['sameFamily', 'personal', 'business', 'extended', 'client', 'notClient'].includes(props.group.subGroupId.split('.').at(-1) || 'unknown')) ? t(`portfolios.dashboard.groups.labels.${props.group.subGroupId.split('.').at(-1)}.label`) : t(`portfolios.dashboard.groups.labels.${props.group.subGroupId}.label`)}
          icon={props.group.icon ? props.group.icon : iconMapper(props.group.subGroupId.split('.').at(0) || '')}
          size='small'
        />
      }
      link={props.group.link}
      action={props.titleAddon}
      fullWidth
    >
      <Grid container spacing={dashboardSpacing} height='fit-content' width='100%'>
        {props.group.description &&
          <Grid size={12}>
            <Typography variant='body2'>{t(`portfolios.dashboard.groups.labels.${props.group.subGroupId}.description`)}</Typography>
          </Grid>
        }
        {props.children}
      </Grid>
    </BaseWidget>
  );
}

export default ContainerGroupQuickDashboard;
