import React, { ReactElement, useEffect, useState } from 'react';
import { darken, useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import { useParams } from 'react-router-dom';
import { SegmentationData } from '@deecision/dna-interfaces';
import { Skeleton } from '@mui/material';
import { basePaperSx, BasePortfolioDashboardProps } from './types';
import SegmentationServices from '../../segmentations/services/segmentations.services';
import KeyValue from '@/components/keyvalue/keyvalue';
import ContainerGroupQuickDashboard from './container';

function PortfolioDashboardGroupSegmentation(props: BasePortfolioDashboardProps): ReactElement {
  const theme = useTheme();
  const segmentationServices = new SegmentationServices();
  const { id } = useParams();
  const [segmentationData, setSegmentationData] = useState<SegmentationData>();

  const possibleValues = [
    {
      id: 'green',
      color: theme.palette.success.dark,
      bgcolor: theme.palette.success.light,
      borderColor: darken(theme.palette.success.light, 0.2),
      hoverColor: darken(theme.palette.success.light, 0.1)
    },
    {
      id: 'orange',
      color: theme.palette.warning.dark,
      bgcolor: theme.palette.warning.light,
      borderColor: darken(theme.palette.warning.light, 0.2),
      hoverColor: darken(theme.palette.warning.light, 0.1)
    },
    {
      id: 'red',
      color: theme.palette.error.dark,
      bgcolor: theme.palette.error.light,
      borderColor: darken(theme.palette.error.light, 0.2),
      hoverColor: darken(theme.palette.error.light, 0.1)
    }
  ];

  useEffect(() => {
    segmentationServices.perform({
      dataSetId: 'deecPersons',
      globalFilteringItems: [],
      filters: [
        props.group.subGroupId.split('.').at(-1) === 'companies' ?
          {
            id: 'company_groupMember',
            filterId: 'company_groupMember',
            type: 'filter',
            on: 'company',
            values: [
              `${id}/persons.companies`
            ]
          } :
          {
            id: 'person_groupMember',
            filterId: 'person_groupMember',
            type: 'filter',
            on: 'person1',
            values: [
              `${id}/persons.withCompanies`
            ]
          }
      ],
      entitiesSettings: {
        includeEntities: true,
        findOptions: {}
      },
      outputEntities: props.group.subGroupId.split('.').at(-1) === 'companies' ? 'company' : 'person1',
      segmentationCriterias: props.group.segmentationCriterias || []
    })
      .then(res => setSegmentationData(res.data));
  }, [props.group.segmentationCriterias]);

  return (
    <ContainerGroupQuickDashboard {...props}>
      {props.group.segmentationCriterias?.map(criteria => (
        <Grid key={criteria.id} size={12} container>
          {segmentationData?.segmentationCriterias[
            criteria.filterId || criteria.id
          ].values
            ?.map(
              value =>
                possibleValues.find(
                  possibleValue => possibleValue.id === value.value
                ) && (
                  <Grid key={value.value} size='grow'>
                    <Paper
                      sx={{
                        ...basePaperSx,
                        color: possibleValues.find(
                          possibleValue => possibleValue.id === value.value
                        )?.color,
                        bgcolor: possibleValues.find(
                          possibleValue => possibleValue.id === value.value
                        )?.bgcolor,
                        borderColor: possibleValues.find(
                          possibleValue => possibleValue.id === value.value
                        )?.borderColor,
                        cursor: 'pointer',
                        '&:hover': {
                          bgcolor: possibleValues.find(
                            possibleValue => possibleValue.id === value.value
                          )?.hoverColor,
                          borderColor: possibleValues.find(
                            possibleValue => possibleValue.id === value.value
                          )?.hoverColor
                        }
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.callSegmentation(
                          props.group.subGroupId.split('.').at(-1) ===
                            'companies'
                            ? 'deecCompany'
                            : 'deecPerson',
                          props.group.subGroupId.split('.').at(-1) ===
                            'companies'
                            ? 'companies'
                            : 'persons.withCompanies',
                          props.group.subGroupId.split('.').at(-1) ===
                            'companies'
                            ? 'person1-companies_person2-companies'
                            : 'person1_link_person2',
                          [criteria.filterId || criteria.id],
                          [value.value]
                        );
                      }}
                    >
                      <KeyValue
                        value={value.count}
                        size='big'
                      />
                    </Paper>
                  </Grid>
                )
            )
            ?.sort(
              (a, b) =>
                possibleValues.findIndex(
                  possibleValue => possibleValue.id === a?.key
                ) -
                possibleValues.findIndex(
                  possibleValue => possibleValue.id === b?.key
                )
            ) || (
            <Grid size='grow'>
              <Skeleton variant='rounded' width='100%' height='38px' />
            </Grid>
          )}
        </Grid>
      ))}
      {props.group.subGroups?.map(subgroup => (
        <Grid size={12} key={subgroup.subGroupId}>
          <PortfolioDashboardGroupSegmentation {...props} group={subgroup} />
        </Grid>
      ))}
    </ContainerGroupQuickDashboard>
  );
}

export default PortfolioDashboardGroupSegmentation;
