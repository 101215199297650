import { DataElementDataStatus, IDataElement } from '@deecision/dna-interfaces';
import { newsDataPaths } from '../news';
import { weasseDataPaths } from '../../../persons/components/tabs/weasse';

type TabsFunctionsReturnType = ('visible' | 'hidden' | 'disabled');

function findDataBlockForTabDisplay(props: { dataElements: IDataElement[], dataBlocksRequired: string[] }): Promise<TabsFunctionsReturnType> {
  const dataBlocksStatuses = props.dataBlocksRequired.map(dataBlockRequired => ({
    dataBlockRequired,
    status: props.dataElements.find(dataElement => dataElement.provides.dataBlocks.some(dataBlock => dataBlockRequired.includes(dataBlock.path)))?.dataInfo.status || undefined
  }));

  return new Promise((res) => {
    if (dataBlocksStatuses.length > 0 && dataBlocksStatuses.every(dataBlockStatuses => dataBlockStatuses.status === DataElementDataStatus.AVAILABLE)) {
      res('visible' as const);
    } else {
      res('disabled' as const);
    }
  });
}

export function bodaccFunction(props: { id: string, data?: unknown, paths?: string[] }): Promise<TabsFunctionsReturnType> {
  return findDataBlockForTabDisplay({ dataElements: props.data as IDataElement[], dataBlocksRequired: props.paths || ['company.bodacc.publication'], ...props });
}

export function newsFunction(props: { id: string, data?: unknown, paths?: string[] }): Promise<TabsFunctionsReturnType> {
  return findDataBlockForTabDisplay({ dataElements: props.data as IDataElement[], dataBlocksRequired: props.paths ||  newsDataPaths, ...props });
}

export function weasseFunction(props: { id: string, data?: unknown, paths?: string[] }): Promise<TabsFunctionsReturnType> {
  return findDataBlockForTabDisplay({ dataElements: props.data as IDataElement[], dataBlocksRequired: props.paths || weasseDataPaths, ...props });
}

export function veegilenzFunction(props: { id: string, data?: unknown, paths?: string[] }): Promise<TabsFunctionsReturnType> {
  return findDataBlockForTabDisplay({ dataElements: props.data as IDataElement[], dataBlocksRequired: props.paths || [], ...props });
}

export function financialFunction(props: { id: string, data?: unknown, paths?: string[] }): Promise<TabsFunctionsReturnType> {
  return findDataBlockForTabDisplay({ dataElements: props.data as IDataElement[], dataBlocksRequired: props.paths || ['company.financialReportDetails'], ...props });
}
