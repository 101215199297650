import React, { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IconChartBar,
  IconChartDonut,
  IconChartPpf,
  IconDotsVertical
} from '@tabler/icons-react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { SegmentationCriteriaInfo } from '@deecision/dna-interfaces';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { lowerFirst } from 'lodash';
import Menu from '@mui/material/Menu';
import { useTheme } from '@mui/material/styles';
import TitleComponent from '@/components/title';
import BaseWidget, { BaseWidgetProp } from '@/main/containers/widgets/base-widget';
import BarCharts from '@/components/charts/bar';
import { BaseChartsProps } from '@/components/charts/types';
import PieCharts from '@/components/charts/pie';
import YesNoCharts from '@/components/charts/yesno';

function BaseWidgetChart(props: Omit<BaseWidgetProp, 'data'> & { chartProps: BaseChartsProps, baseChartType: SegmentationCriteriaInfo['displayInfo']['displayType'], halfPieChart?: boolean, actions?: [ReactNode] }): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const [chartType, setChartType] = useState(props.baseChartType);
  const [halfPieChart, setHalfPieChart] = useState<boolean>(!!props.halfPieChart);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Charts: Record<SegmentationCriteriaInfo['displayInfo']['displayType'], (props: BaseChartsProps) => ReactElement> = {
    pieChart: elementProps => PieCharts({ ...elementProps, halfPieChart }),
    yesNo: elementProps => YesNoCharts(elementProps),
    barChart: elementProps => BarCharts({ ...elementProps })
  };

  const ChartComponent = useMemo(() => {
    const Chart = Charts[chartType];

    return <Chart {...props.chartProps} loading={loading} />;
  }, [chartType, halfPieChart, loading, props.chartProps.datas]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [props.chartProps.datas]);

  return (
    <BaseWidget
      {...props}
      title={<TitleComponent title={t('segmentation.label')} icon={chartType === 'pieChart' ? halfPieChart ? IconChartPpf : IconChartDonut : IconChartBar} small />}
      action={
        <Stack direction='row' spacing={2} alignItems='center'>
          {props.actions}
          <IconButton
            size='small'
            id='chart-menu-button'
            onClick={handleClick}
            aria-controls={open ? 'chart-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            <IconDotsVertical size='1.2rem' />
          </IconButton><Menu
            id='chart-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'chart-menu-button'
            }}
          >
            {chartType !== 'barChart' &&
            <MenuItem
              onClick={() => {
                setChartType('barChart');
                handleClose();
              }}
            >
              <ListItemIcon>
                <IconChartBar color={theme.palette.text.primary} />
              </ListItemIcon>
              <ListItemText>
                {`${t('segmentation.builder.displayTypes.transformTo')} ${lowerFirst(t('segmentation.builder.displayTypes.barChart') || '')}`}
              </ListItemText>
            </MenuItem>
            }
            {(chartType !== 'pieChart' || halfPieChart) &&
            <MenuItem
              onClick={() => {
                setHalfPieChart(false);
                setChartType('pieChart');
                handleClose();
              }}
            >
              <ListItemIcon>
                <IconChartDonut color={theme.palette.text.primary} />
              </ListItemIcon>
              <ListItemText>
                {`${t('segmentation.builder.displayTypes.transformTo')} ${lowerFirst(t('segmentation.builder.displayTypes.pieChart') || '')}`}
              </ListItemText>
            </MenuItem>
            }
            {(chartType !== 'pieChart' || !halfPieChart) &&
            <MenuItem
              onClick={() => {
                setHalfPieChart(true);
                setChartType('pieChart');
                handleClose();
              }}
            >
              <ListItemIcon>
                <IconChartPpf color={theme.palette.text.primary} />
              </ListItemIcon>
              <ListItemText>
                {`${t('segmentation.builder.displayTypes.transformTo')} ${lowerFirst(t('segmentation.builder.displayTypes.halfPieChart') || '')}`}
              </ListItemText>
            </MenuItem>
            }
          </Menu>
        </Stack>
      }
      chart
    >
      <Box key={JSON.stringify(props.chartProps.datas)} height='100%' width='100%'>
        {props.chartProps.datas.length > 0 ? ChartComponent : <></>}
      </Box>
    </BaseWidget>
  );
}

export default BaseWidgetChart;
