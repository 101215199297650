import React, { ReactElement, useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { IconFolderPlus, IconUser } from '@tabler/icons-react';
import { PersonEntity } from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import TitleComponent from '@/components/title';
import dnaConfig from '../../../../../config/dna.config.json';
import PersonEntitiesChips from '@/main/containers/chips/person';
import { DataElementsQueryService } from '@/api/services/query';
import { tabsFunctions } from './tabs/utils/functions';
import PersonWidgetsFinancial from '@/main/containers/widgets/persons/financial';
import PersonWidgetsVeegilenz from '@/main/containers/widgets/persons/veegilenz';
import PersonWidgetsIdentity from '@/main/containers/widgets/persons/identity';
import PersonWidgetsCompaniesFinancial from '@/main/containers/widgets/persons/relations/companies/financial';
import PersonWidgetsRelationsFinancial from '@/main/containers/widgets/persons/relations/persons/financial';
import WidgetsNews from '@/main/containers/widgets/news';

function PersonComponent(): ReactElement {
  const data = useLoaderData() as { data: PersonEntity };
  const { t } = useTranslation();
  const [dynamicTabsLinkEnabled, setDynamicTabsLinkEnabled] = useState({
    [dnaConfig.routes.persons.tabs.veegilenz.baseUrl]: '',
    [dnaConfig.routes.persons.tabs.relations.baseUrl]: '',
    [dnaConfig.routes.persons.tabs.companies.baseUrl]: '',
    [dnaConfig.routes.persons.tabs.news.baseUrl]: ''
  });

  const getDisplayTabsInfo = () => {
    new DataElementsQueryService({ entityType: 'deecPerson' }).get(data.data.entityId)
      .then((res) => {
        const dynamicTabsLinkEnabledTemp = { ...dynamicTabsLinkEnabled };

        Object.keys(dynamicTabsLinkEnabledTemp).forEach((key) => {
          if (tabsFunctions?.[key]) {
            tabsFunctions?.[key]?.({ id: data.data.entityId, data: res.data })
              .then((r) => {
                if (r === 'visible') {
                  dynamicTabsLinkEnabledTemp[key] = key;
                }
              });
          } else {
            dynamicTabsLinkEnabledTemp[key] = key;
          }
        });

        setDynamicTabsLinkEnabled(dynamicTabsLinkEnabledTemp);
      });
  };

  useEffect(() => {
    getDisplayTabsInfo();
  }, [data.data, t]);

  return (
    <Grid container spacing={2} alignItems='center' justifyContent='space-between' width='100%'>
      <Grid size='grow'>
        <TitleComponent title={data.data.name} icon={IconUser} chips={PersonEntitiesChips({ entity: data.data })} />
      </Grid>
      <Grid justifySelf='flex-start' alignSelf='flex-start'>
        <Stack spacing={2} direction='row' alignItems='center'>
          <Button
            variant='contained'
            startIcon={<IconFolderPlus size='1.2rem' />}
            href={dnaConfig.routes.folders.create.steps.choices}
          >
            {t('folder.createFolder')}
          </Button>
        </Stack>
      </Grid>
      <Grid size={12} overflow='auto'>
        <Stack spacing={2} direction='row' alignItems='stretch' justifyContent='stretch' flexWrap='nowrap' width='max-content' pb={2}>
          <PersonWidgetsIdentity data={data.data} />
          <PersonWidgetsVeegilenz data={data.data} link={dynamicTabsLinkEnabled[dnaConfig.routes.persons.tabs.veegilenz.baseUrl]} />
          <PersonWidgetsFinancial data={data.data} />
          <PersonWidgetsCompaniesFinancial data={data.data} link={dynamicTabsLinkEnabled[dnaConfig.routes.persons.tabs.companies.baseUrl]} />
          <PersonWidgetsRelationsFinancial data={data.data} link={dynamicTabsLinkEnabled[dnaConfig.routes.persons.tabs.relations.baseUrl]} />
          <WidgetsNews data={data.data} link={dynamicTabsLinkEnabled[dnaConfig.routes.persons.tabs.news.baseUrl]} />
        </Stack>
      </Grid>
    </Grid>
  );
}

export default PersonComponent;
