import { FinancialReportDataBlock, IDataBlock } from '@deecision/dna-interfaces';
import { get } from 'lodash';

export function parseAndSortFinancialData(data: IDataBlock<FinancialReportDataBlock>[]): IDataBlock<FinancialReportDataBlock>[] {
  const dataTmp: IDataBlock<FinancialReportDataBlock>[] = [];

  data.forEach((d) => {
    const dataSameYear = dataTmp.find(dt => new Date(dt.data.closingDate).getFullYear() === new Date(d.data.closingDate).getFullYear());

    if (!dataSameYear) {
      dataTmp.push(d);
    } else if (d.data.balanceSheetType === 'full' || d.data.balanceSheetType === 'consolidated' && dataSameYear.data.balanceSheetType !== 'full' || d.data.balanceSheetType === 'simplified' && dataSameYear.data.balanceSheetType !== 'consolidated') {
      dataTmp.splice(dataTmp.findIndex(dt => new Date(dt.data.closingDate).getFullYear() === new Date(d.data.closingDate).getFullYear()), 1, d);
    }
  });

  return dataTmp.sort((a, b) => (new Date(a.data.closingDate).getFullYear() - new Date(b.data.closingDate).getFullYear()));
}

export function getFinancialYears(data: IDataBlock<FinancialReportDataBlock>[]): string[] {
  return data.map(d => new Date(d.data.closingDate).getFullYear().toString());
}

export const getPathList = (datas: IDataBlock<FinancialReportDataBlock>[]) => {
  let pathListTmp: Record<string, string[]> = {};

  datas.forEach((data) => {
    if (data.data.details) {
      Object.keys(data.data.details).forEach((key) => {
        if (!Object.keys(pathListTmp).find(k => k === key)) {
          pathListTmp = { ...pathListTmp, [key]: [] };
        }
        if (get(data.data.details, key)) {
          Object.keys(get(data.data.details, key)).forEach((subKey) => {
            if (!pathListTmp[key].find(sk => sk === `${key}.${subKey}`)) {
              pathListTmp[key] = [ ...pathListTmp[key], `${key}.${subKey}` ];
            }
          });
        }
      });
    }
  });

  return pathListTmp;
};
