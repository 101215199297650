import React, { ReactElement } from 'react';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import { IconMaximize, IconMaximizeOff } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useFullscreen } from '@/wrappers/fullscreen';

type FullscreenButtonProps = {
  noBorder?: boolean,
  action?: () => void
}

function FullscreenButton(props: FullscreenButtonProps): ReactElement {
  const theme = useTheme();
  const { isFullscreen, setIsFullscreen } = useFullscreen();
  const { t } = useTranslation();

  const onClickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setIsFullscreen(!isFullscreen);
    if (props.action) {
      props.action();
    }
  };

  return (props.noBorder ?
    <Tooltip title={t('common.utils.fullscreen')} arrow placement='bottom'>
      {isFullscreen ? <IconMaximizeOff /> : <IconMaximize />}
    </Tooltip> :
    <Box>
      <IconButton
        size='small'
        onClick={onClickHandler}
        sx={{
          border: props.noBorder ? 0 : 1,
          borderColor: '#C0C0C1',
          backgroundColor: !props.noBorder ? theme.palette.grey['50'] : undefined,
          '&:hover': {
            backgroundColor: !props.noBorder ? theme.palette.grey['200'] : undefined
          }
        }}
      >
        <Tooltip title={t('common.utils.fullscreen')} arrow placement='top'>
          {isFullscreen ? <IconMaximizeOff /> : <IconMaximize />}
        </Tooltip>
      </IconButton>
    </Box>
  );
}

export default FullscreenButton;
