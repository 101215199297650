import { InternalNode, Position } from '@xyflow/react';
import { TFunction } from 'i18next';
import type { Edge, Node } from '@xyflow/react/dist/esm/types';
import { Theme } from '@mui/material';
import { darken, lighten } from '@mui/material/styles';
import { AnyEntity, EntityType, ReechData } from '@deecision/dna-interfaces';
import { ReechClientType } from '@deecision/dna-interfaces/dist/reech/reech';

export type NodeData = Record<string, unknown> & {
  entity?: AnyEntity,
  entityType?: EntityType,
  label?: string,
  totalCount?: number,
  clientType?: ReechClientType
}

export type EdgeData = Record<string, unknown> & Pick<ReechData['targets'][0], 'metadata'>;

const nodesSpacing = 140;
const hideIfPlus = 4;

export const hideNode = (id: string, hide?: boolean) => (nodeOrEdge: Node<NodeData>) => {
  if (id !== nodeOrEdge.id && nodeOrEdge.id.includes(id)) {
    // eslint-disable-next-line no-param-reassign
    nodeOrEdge.hidden = hide;
  }

  return nodeOrEdge;
};

export const hideEdge = (id: string, hide?: boolean) => (nodeOrEdge: Edge<EdgeData>) => {
  if (id === nodeOrEdge.source || nodeOrEdge.source.includes(id)) {
    // eslint-disable-next-line no-param-reassign
    nodeOrEdge.hidden = hide;
  }

  return nodeOrEdge;
};

function getNodeIntersection(
  intersectionNode: InternalNode,
  targetNode: InternalNode
) {
  const { width: intersectionNodeWidth } = intersectionNode.measured;
  const intersectionNodePosition = intersectionNode.internals.positionAbsolute;
  const targetPosition = targetNode.internals.positionAbsolute;

  const r = (intersectionNodeWidth || 0) / 2;
  const cx = intersectionNodePosition.x + r;
  const cy = intersectionNodePosition.y + r;
  const tx = targetPosition.x + (targetNode.measured.width || 0) / 2;
  const ty = targetPosition.y + (targetNode.measured.height || 0) / 2;
  const dx = tx - cx;
  const dy = ty - cy;
  const distance = Math.sqrt(dx * dx + dy * dy);
  if (distance === 0) {
    return { x: cx, y: cy };
  }
  const scale = r / distance;
  const x = cx + dx * scale;
  const y = cy + dy * scale;

  return { x, y };
}

function getEdgePosition(
  node: InternalNode,
  intersectionPoint: { x: number, y: number }
) {
  const n = { ...node.internals.positionAbsolute, ...node };
  const nx = Math.round(n.x);
  const ny = Math.round(n.y);
  const px = Math.round(intersectionPoint.x);
  const py = Math.round(intersectionPoint.y);

  if (px <= nx + 1) {
    return Position.Left;
  }
  if (px >= nx + (n.measured.width || 0) - 1) {
    return Position.Right;
  }
  if (py <= ny + 1) {
    return Position.Top;
  }
  if (py >= n.y + (n.measured.height || 0) - 1) {
    return Position.Bottom;
  }

  return Position.Top;
}

export function getEdgeParams(source: InternalNode, target: InternalNode) {
  const sourceIntersectionPoint = getNodeIntersection(source, target);
  const targetIntersectionPoint = getNodeIntersection(target, source);

  const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
  const targetPos = getEdgePosition(target, targetIntersectionPoint);

  return {
    sx: sourceIntersectionPoint.x,
    sy: sourceIntersectionPoint.y,
    tx: targetIntersectionPoint.x,
    ty: targetIntersectionPoint.y,
    sourcePos,
    targetPos
  };
}

interface Datas {
  name: string,
  totalCount: number,
  nodes?: ReechData[]
}

const baseNode = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1rem',
  borderRadius: 50,
  width: 80,
  height: 80,
  border: '1px solid',
  cursor: 'pointer'
};

export function createNodesAndEdges(
  datas: Datas,
  t?: TFunction<'translation', undefined>,
  theme?: Theme
) {
  const nodes: Node<NodeData>[] = [];
  const edges: Edge<EdgeData>[] = [];
  const center = { x: window.innerWidth / 2, y: window.innerHeight / 3 };
  const possibleNodes = [
    {
      id: 'ppClient',
      entityType: 'deecPerson' as const,
      clientType: ReechClientType.CLIENT,
      node: datas.nodes?.find(node => node.entityType === 'deecPerson' && node.clientType === ReechClientType.CLIENT)
    },
    {
      id: 'ppProspect',
      entityType: 'deecPerson' as const,
      clientType: ReechClientType.PROSPECT,
      node: datas.nodes?.find(node => node.entityType === 'deecPerson' && node.clientType === ReechClientType.PROSPECT)
    },
    {
      id: 'pmProspect',
      entityType: 'deecCompany' as const,
      clientType: ReechClientType.PROSPECT,
      node: datas.nodes?.find(node => node.entityType === 'deecCompany' && node.clientType === ReechClientType.PROSPECT)
    },
    {
      id: 'pmClient',
      entityType: 'deecCompany' as const,
      clientType: ReechClientType.CLIENT,
      node: datas.nodes?.find(node => node.entityType === 'deecCompany' && node.clientType === ReechClientType.CLIENT)
    },
    {
      id: 'pmExClient',
      entityType: 'deecCompany' as const,
      clientType: ReechClientType.EX_CLIENT,
      node: datas.nodes?.find(node => node.entityType === 'deecCompany' && node.clientType === ReechClientType.EX_CLIENT)
    },
    {
      id: 'ppExClient',
      entityType: 'deecPerson' as const,
      clientType: ReechClientType.EX_CLIENT,
      node: datas.nodes?.find(node => node.entityType === 'deecPerson' && node.clientType === ReechClientType.EX_CLIENT)
    }
  ];

  nodes.push({
    id: datas.name,
    type: 'mainEntity',
    data: { label: datas.name, totalCount: datas.totalCount },
    position: center,
    style: {
      ...baseNode,
      backgroundColor: theme?.palette.background.paper,
      borderColor: darken(theme?.palette.primary.light || '', 0.4)
    }
  });

  possibleNodes.forEach((node, index) => {
    const degrees = (index * (360 / possibleNodes.length));
    const radians = degrees * (Math.PI / 180);
    const x = nodesSpacing * Math.cos(radians) + center.x;
    const y = nodesSpacing * Math.sin(radians) + center.y;

    nodes.push({
      id: `${datas.name}-${index}-${node.entityType}-${node.clientType}`,
      type: 'reechType',
      data: {
        label:
          t?.(`reech.${node.id}`) ||
          `${node.entityType}-${node.clientType}`,
        totalCount: node.node?.targets.length || 0,
        entityType: node.entityType,
        clientType: node.clientType
      },
      position: { x, y },
      style: {
        ...baseNode,
        backgroundColor: (node.node?.targets.length || 0) > 0
          ? node.entityType === 'deecPerson'
            ? theme?.palette.primary.light
            : theme?.palette.secondary.light
          : node.entityType === 'deecPerson'
            ? lighten(theme?.palette.primary.light || '', 0.5)
            : lighten(theme?.palette.secondary.light || '', 0.5),
        color: (node.node?.targets.length || 0) > 0
          ? node.entityType === 'deecPerson'
            ? theme?.palette.primary.dark
            : theme?.palette.secondary.dark
          : node.entityType === 'deecPerson'
            ? lighten(theme?.palette.primary.main || '', 0.3)
            : lighten(theme?.palette.secondary.main || '', 0.3),
        borderColor: (node.node?.targets.length || 0) > 0
          ? node.entityType === 'deecPerson'
            ? theme?.palette.primary.main
            : theme?.palette.secondary.main
          : node.entityType === 'deecPerson'
            ? lighten(theme?.palette.primary.main || '', 0.5)
            : lighten(theme?.palette.secondary.main || '', 0.5)
      }
    });

    edges.push({
      id: `edge-${datas.name}-${index}-${node.entityType}-${node.clientType}`,
      target: `${datas.name}-${index}-${node.entityType}-${node.clientType}`,
      source: datas.name,
      type: 'floating'
    });

    node.node?.targets.forEach((target, i) => {
      const targetDegrees =
          ((node.node?.targets.length || 0) > 1 ? i : 1) *
            ((360 / possibleNodes.length) / ((node.node?.targets.length || 0) > 1 ? (node.node?.targets.length || 0) - 1 : 2)) +
          (degrees - (360 / possibleNodes.length / 2));
      const targetRadians = targetDegrees * (Math.PI / 180);
      const targetX = (((node.node?.targets.length || 0) > 3 ? node.node?.targets.length || 4 : 2.5) * 78) * Math.cos(targetRadians) + x;
      const targetY = (((node.node?.targets.length || 0) > 3 ? node.node?.targets.length || 4 : 2.5) * 78) * Math.sin(targetRadians) + y;

      nodes.push({
        id: `${datas.name}-${index}-${node.node?.entityType}-${node.node?.clientType}-${i}`,
        type: 'reechTarget',
        data: {
          entity: target.entity,
          entityType: target.entity.entityType,
          clientType: node.clientType
        },
        position: { x: targetX, y: targetY },
        hidden: (node.node?.targets.length || 0) > hideIfPlus,
        style: target.entity?.entityType === 'deecPerson' ? {
          ...baseNode,
          backgroundColor: theme?.palette.primary.light,
          color: theme?.palette.primary.dark,
          borderColor: theme?.palette.primary.main
        } : {
          ...baseNode,
          backgroundColor: theme?.palette.secondary.light,
          color: theme?.palette.secondary.dark,
          borderColor: theme?.palette.secondary.main
        }
      });

      edges.push({
        id: `edge-${datas.name}-${index}-${node.node?.entityType}-${node.node?.clientType}-${i}`,
        target: `${datas.name}-${index}-${node.node?.entityType}-${node.node?.clientType}-${i}`,
        source: `${datas.name}-${index}-${node.node?.entityType}-${node.node?.clientType}`,
        type: 'floating',
        data: {
          metadata: target.metadata,
          entityType: node.entityType,
          clientType: node.clientType
        },
        hidden: (node.node?.targets.length || 0) > hideIfPlus
      });
    });
  });

  return { nodes, edges };
}
