import React, { ReactElement, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { BaseEntityLinkDetailsCompany, EntityLink, EntityRef, LinksAggregation } from '@deecision/dna-interfaces';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { sortedUniq, uniq } from 'lodash';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import EntitiesCards from '@/main/containers/cards/dispatch';
import dnaConfig from '../../../config/dna.config.json';
import { WEBAPP_NAME } from '../../../env/env';

function DifferentEntitiesTypesLink(props: { links: Partial<EntityLink>[] }): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const [activePositionTypes, setActivePositionTypes] = useState<string[]>();
  const [inactivePositionTypes, setInactivePositionTypes] = useState<string[]>();
  const [activeRoles, setActiveRoles] = useState<string[]>();
  const [inactiveRoles, setInactiveRoles] = useState<string[]>();
  const [sharesPercentage, setSharesPercentage] = useState<number>();
  const [hover, setHover] = useState(false);

  useEffect(() => {
    setActivePositionTypes(
      props.links.filter(link => link?.active).map(link => link?.details?.positionTypes || '').flat(1)
    );
    setInactivePositionTypes(
      props.links.filter(link => !link?.active).map(link => link?.details?.positionTypes || '').flat(1)
    );
    setActiveRoles(
      props.links.filter(link => link?.active).map(link => link?.details?.role?.text || '').flat(1).filter(role => role && role.length > 0)
    );
    setInactiveRoles(
      props.links.filter(link => !link?.active).map(link => link?.details?.role?.text || '').flat(1).filter(role => role && role.length > 0)
    );
    setSharesPercentage(
      props.links.find(link => link?.details?.sharesPercentage !== undefined)?.details?.sharesPercentage
    );
  }, [props.links[0].entity1?.entityId, props.links[0].entity2?.entityId]);

  return (
    <Grid size='grow' height='min-content'>
      <Stack
        direction='row'
        height='fit-content'
        width='100%'
        sx={{
          cursor: 'pointer'
        }}
        onClick={() => {
          window.open(`/${WEBAPP_NAME}/${dnaConfig.routes.relations.relativeUrl}/show?entity1=${props.links[0].entity1?.entityId}&entity1Type=${props.links[0].entity1?.entityType}&entity2=${props.links[0].entity2?.entityId}&entity2Type=${props.links[0].entity2?.entityType}`, '_blank');
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Stack alignItems='center' pt={2} pb={2} width='100%'>
          <Stack direction='row' spacing={2} divider={<Typography>|</Typography>}>
            {sortedUniq(uniq(activePositionTypes).map(position => (
              <Typography key={position} color={hover ? 'primary' : undefined} textAlign='center'>{position}</Typography>
            )))}
            {sortedUniq(uniq(inactivePositionTypes).map(position => (
              <Typography key={position} color={hover ? 'primary' : undefined} textAlign='center' sx={{ textDecoration: 'line-through' }}>{position}</Typography>
            )))}
          </Stack>
          <Box
            sx={{
              bgcolor: hover ? theme.palette.primary.main : theme.palette.grey['500'],
              width: '100%',
              height: hover ? 4 : 2,
              mt: hover ? '-1px !important' : undefined,
              mb: hover ? '-1px !important' : undefined
            }}
          />
          <Stack spacing={1} p={1} divider={<Divider orientation='horizontal' />}>
            {activeRoles && activeRoles.length > 0 && uniq(activeRoles).map(role => (
              <Typography key={role} color={hover ? 'primary' : undefined} textAlign='center' whiteSpace='pre-wrap' width={160}>{role}</Typography>
            ))}
            {inactiveRoles && inactiveRoles.length > 0 && uniq(inactiveRoles).map(role => (
              <Typography key={role} color={hover ? 'primary' : undefined} textAlign='center' whiteSpace='pre-wrap' width={160} sx={{ textDecoration: 'line-through' }}>{role}</Typography>
            ))}
          </Stack>
        </Stack>
        {sharesPercentage !== undefined &&
          <Stack direction='row' alignItems='center' height='min-content' mt='14px'>
            <Tooltip title={t('filters.shares')} arrow placement='top'>
              <Chip color='primary' label={`${sharesPercentage}%`} />
            </Tooltip>
            <Box sx={{ bgcolor: theme.palette.grey['500'], width: 8, height: 2 }} />
          </Stack>
        }
      </Stack>
    </Grid>
  );
}

function DeecPersonsLink(props: { links: LinksAggregation }): ReactElement {
  const theme = useTheme();
  const [companies, setCompanies] = useState<Array<BaseEntityLinkDetailsCompany | undefined>>();

  useEffect(() => {
    setCompanies(props.links?.map(link => link.details?.companies).flat(1));
  }, [props.links[0]?.entity1.entityId, props.links[0]?.entity2.entityId]);

  return (
    <Grid key={`link-${props.links[0].entity1.entityId}-${props.links[0].entity2.entityId}`} size='grow' width='100%' height='100%'>
      <Stack spacing={4} height='100%' justifyContent='space-evenly' width='100%'>
        {companies ?
          companies.map(company => (company &&
            <Grid container key={`${props.links[0].entity1.entityId}-${company.companyRef.entityId}`} wrap='nowrap'>
              <Grid size='grow' container>
                <DifferentEntitiesTypesLink
                  links={
                    company.person1.activeRoles.map(role => ({
                      active: true,
                      details: {
                        positionTypes: company.person1.activePositionTypes,
                        role
                      },
                      entity1: { ...props.links[0].entity1, name: null },
                      entity2: { ...company.companyRef, name: null }
                    }))
                  }
                />
              </Grid>
              <Grid>
                <EntitiesCards entityRef={company.companyRef} compact height='100%' />
              </Grid>
              <Grid size='grow' container>
                <DifferentEntitiesTypesLink
                  links={
                    company.person2.activeRoles.map(role => ({
                      active: true,
                      details: {
                        positionTypes: company.person2.activePositionTypes,
                        role
                      },
                      entity1: { ...company.companyRef, name: null },
                      entity2: { ...props.links[0].entity2, name: null }
                    }))
                  }
                />
              </Grid>
            </Grid>
          )) :
          <Grid size='grow'>
            <Box sx={{ bgcolor: theme.palette.grey['500'], width: 32, height: 2 }} />
          </Grid>
        }
      </Stack>
    </Grid>
  );
}

function DeecCompaniesLink(): ReactElement {
  const theme = useTheme();

  return (
    <Grid size='grow'>
      <Box sx={{ bgcolor: theme.palette.grey['500'], width: 32, height: 2 }} />
    </Grid>
  );
}

function DisplayTypesLinksRelations(props: { entity1: EntityRef, entity2: EntityRef, links: LinksAggregation }): ReactElement {

  if (props.links.length === 0) {
    return (
      <Grid size='grow' />
    );
  }

  switch (true) {
  case ((props.entity1?.entityType !== props.entity2?.entityType)):
    return <DifferentEntitiesTypesLink {...props} />;
  case (props.entity1?.entityType === props.entity2?.entityType && props.entity1?.entityType === 'deecPerson'):
    return <DeecPersonsLink {...props} />;
  case (props.entity1?.entityType === props.entity2?.entityType && props.entity1?.entityType === 'deecCompany'):
    return <DeecCompaniesLink />;
  default:
    return (<Grid size='grow' />);
  }
}

export default DisplayTypesLinksRelations;
