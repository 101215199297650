import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { startCase } from 'lodash';
import { PersonEntity } from '@deecision/dna-interfaces';
import { PersonGetter } from '@/main/providers/getter';
import Accuracy from '@/components/chips/accuracy';
import KeyValueList from '@/components/keyvalue/keyvaluelist';
import BaseWidget from '@/main/containers/widgets/base-widget';
import { WidgetProps } from '@/main/containers/widgets/types';

function PersonWidgetsIdentity(props: WidgetProps<PersonEntity> & { simple?: boolean, small?: boolean }): ReactElement {
  const { t } = useTranslation();
  const personGetter = new PersonGetter({
    data: props.data,
    errorMsg: t('common.utils.unknown')
  });
  const identityDatas = props.data.attributes ? [
    { key: t('common.identity.firstName'), value: startCase(personGetter.getFirstName().toLowerCase()) },
    { key: t('common.identity.lastName'), value: startCase(personGetter.getLastName().toLowerCase()) },
    { key: t('common.identity.age'), value: personGetter.getAge() },
    { key: t('common.identity.birthDate'), value: personGetter.getBirthDate() },
    { key: personGetter.getCountries().length < 2 ? t('common.identity.country') : t('common.identity.countries'), value: personGetter.getCountries().join(' / ') },
    { key: personGetter.getNationalities().length < 2 ? t('common.identity.nationality') : t('common.identity.nationalities'), value: personGetter.getNationalities().join(' / ') },
    ...(personGetter.getIsDead() ? [{
      key: t('common.utils.deceased'),
      value: (
        <Stack direction='row' spacing={2} pl={6}>
          <Typography fontWeight={500}>
            {personGetter.getDeathDate()}
          </Typography>
          <Accuracy accuracy={personGetter.getDeathMatchAccuracy()}/>
        </Stack>
      )
    }] : [])
  ] : [];

  return (props.simple ?
    <KeyValueList values={[identityDatas.slice(2)]} small={props.small} /> :
    <BaseWidget {...props} title={t('common.identity.identity')}>
      <KeyValueList values={[identityDatas]} small={props.small} />
    </BaseWidget>
  );
}

export default PersonWidgetsIdentity;
