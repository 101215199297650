import React, { useEffect, useState, ReactElement } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { IconAlertTriangleFilled } from '@tabler/icons-react';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import Grid from '@mui/material/Grid2';

export interface MaintenanceBannerWrapperProps {
  app: string,
  axiosInstance: AxiosInstance
}

const CustomBanner = styled(Grid)(({ theme }) => ({
  zIndex: 4000,
  backgroundColor: theme.palette.warning.light,
  color: theme.palette.warning.dark,
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '28px'
}));

function MaintenanceBanner(props: MaintenanceBannerWrapperProps & { children: ReactElement }): ReactElement {
  const [startDate, setStartDate] = useState<null | Date>(null);

  useEffect(() => {
    props.axiosInstance.interceptors.response.use((res: AxiosResponse) => {
      const maintenance = res.headers?.['x-deecision-maintenance'];

      if (maintenance) {
        if (new Date(maintenance).toISOString() !== startDate?.toISOString()) {
          setStartDate(new Date(maintenance));
        }
      } else {
        setStartDate(null);
      }

      return res;
    }, (error: AxiosError) => {
      if (error.response && error.response.status === 503) {
        if (window.location.pathname.split('/')[2] !== 'maintenance') {
          window.location.assign(`${window.location.origin}/${props.app}/maintenance`);
        }
      } else if (error.response && error.response.status > 500) {
        if (window.location.pathname.split('/')[2] !== 'maintenance') {
          window.location.assign(`${window.location.origin}/${props.app}/maintenance`);
        }
      }

      return Promise.reject(error);
    });
  }, []);

  const formatNumber = (num: number) => (
    num.toLocaleString('en-US',
      {
        minimumIntegerDigits: 2,
        useGrouping: false
      })
  );

  return (
    <>
      {startDate ?
        <Box sx={{ mt: '28px' }}>
          <CustomBanner container justifyContent='space-between'>
            <Grid sx={{ my: 1, mx: 4 }}>
              <IconAlertTriangleFilled size='1.2rem'/>
            </Grid>
            <Grid sx={{ alignItems: 'baseline', mx: 2, my: 1 }}>
              <Typography style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='body2'>Warning :</Typography>
                <Typography variant='h5' sx={{ ml: 2 }}>
                  {startDate ?
                    `Maintenance operation is scheduled the ${formatNumber(startDate.getDate())}/${formatNumber(startDate.getMonth())}/${startDate.getFullYear()} at ${formatNumber(startDate.getHours())}:${formatNumber(startDate.getMinutes())}` :
                    'Maintenance started'
                  }
                </Typography>
              </Typography>
            </Grid>
            <Grid>
              <Typography> </Typography>
            </Grid>
          </CustomBanner>
          {props.children}
        </Box> :
        props.children
      }
    </>
  );
}

export default MaintenanceBanner;
