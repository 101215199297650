import React, { ReactElement } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { BaseGroup } from '@deecision/dna-interfaces';
import Grid from '@mui/material/Grid2';
import { IconBuildings, IconUsers } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import VeegilenzPortfolioDashboard, {
  VeegilenzPortfolioDashboardConfig
} from '@/main/containers/dashboards/portfolio/veegilenz';
import dnaConfig from '@/config/dna.config.json';
import VeegilenzCountriesLists from '@/main/containers/veegilenz/countrieslists';
import DashboardSection from '@/main/containers/dashboards/section';

const veegilenzConfig: VeegilenzPortfolioDashboardConfig[] = [
  {
    id: 'persons',
    entityType: 'deecPerson',
    icon: IconUsers,
    groupId: 'persons'
  },
  {
    id: 'companies',
    entityType: 'deecCompany',
    icon: IconBuildings,
    groupId: 'companies'
  }
];

function VeegilenzTabsPortfolios(): ReactElement {
  const { t } = useTranslation();
  const data = useOutletContext() as { data: BaseGroup };
  const navigate = useNavigate();

  const callSegmentation = (entityType?: string, groupId?: string, workflowId?: string, filterIds?: string[], filterValues?: string[]) => {
    if (groupId && entityType) {
      navigate(`../${dnaConfig.routes.portfolios.childrens.augment.baseUrl}?entityType=${entityType}&groupId=${groupId}${workflowId ? `&workflowId=${workflowId}` : ''}${filterIds ? `&filterIds=${filterIds.join(',')}` : ''}${filterValues ? `&filterValues=${filterValues.join(',')}` : ''}`);
    }
  };

  return (
    <Grid container overflow='auto' spacing={4}>
      <Grid size='grow' minWidth='min-content' container>
        {veegilenzConfig.map(config => (
          <Grid key={config.id} minWidth='min-content' height='100%'>
            <DashboardSection
              title={config.entityType === 'deecPerson' ? t('entities.persons.label') : t('entities.companies.label')}
              icon={config.icon}
              direction='column'
            >
              <VeegilenzPortfolioDashboard
                baseGroupId={data.data.baseGroupId}
                config={config}
                callSegmentation={callSegmentation}
              />
            </DashboardSection>
          </Grid>
        ))}
      </Grid>
      <Grid size='auto' minWidth='min-content'>
        <VeegilenzCountriesLists />
      </Grid>
    </Grid>
  );
}

export default VeegilenzTabsPortfolios;
