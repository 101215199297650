import React, { ReactElement } from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import develop1 from '@/assets/images/develop1.svg';
import develop2 from '@/assets/images/develop2.svg';
import develop3 from '@/assets/images/develop3.svg';
import develop4 from '@/assets/images/develop4.svg';

function Construction(props: { title?: string, subtitle?: string }): ReactElement {
  let developImg;

  switch (Math.floor(Math.random() * (4 - 1 + 1) + 1)) {
  case 1:
    developImg = develop1;
    break;
  case 2:
    developImg = develop2;
    break;
  case 3:
    developImg = develop3;
    break;
  case 4:
    developImg = develop4;
    break;
  default:
    developImg = develop1;
    break;
  }

  return (
    <Grid container spacing={4} direction='column' justifyContent='center' sx={{ width: '100%', height: '100%', textAlign: 'center' }}>
      <Grid>
        <img alt='under development' src={developImg} width={400} style={{ margin: 'auto' }} />
      </Grid>
      <Grid>
        <Typography variant='h1'>
          {props.title || 'This page is under construction'}
        </Typography>
      </Grid>
      <Grid>
        <Typography variant='subtitle1'>
          {props.subtitle || "We're working on it !"}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Construction;
