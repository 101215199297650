import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export type CustomDialogProps = {
  open: boolean,
  handleClose: () => void,
  title: string,
  content: string,
  buttonConfirmationText?: string,
  buttonCancelText?: string,
  buttonConfirmationAction?: () => void,
  buttonCancelAction?: () => void,
  displayOnlyConfirmationButton?: boolean,
  size?: 'small' | 'medium' | 'large'
};

function CustomDialog(props: CustomDialogProps) {

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle id='alert-dialog-title'>
        {props.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {props.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!props.displayOnlyConfirmationButton &&
          <Button variant='text' onClick={props.buttonCancelAction || props.handleClose}>
            {props.buttonCancelText || 'No'}
          </Button>
        }
        <Button variant='outlined' onClick={props.buttonConfirmationAction || props.handleClose} autoFocus>
          {props.buttonConfirmationText || 'Yes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomDialog;