import React, { useEffect, useState } from 'react';
import { CompositeItemSpec, SegmentationFilter } from '@deecision/dna-interfaces';
import { IconAlertTriangle } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import { capitalizeFirstLetterOfEachWord } from '@/utils';
import * as venn from '../../../../venn/venn';
import { VennDiagramConfig, VennDiagramResult } from '../types.vennDiagram';
import SimpleDisplaySort, { SimpleSort } from '@/components/sort';

interface LegendProps {
  diagram: VennDiagramConfig,
  result: VennDiagramResult[],
  mapIdToLabel: (segment: string) => string,
  mapIdToColor: (segment: string) => string,
  filters: SegmentationFilter[],
  generateAndAddFilter: (selectedSegments: string[]) => void,
  removeFilter: () => void,
  initializeByChart: boolean
}

function Legend(props: LegendProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [sortedResults, setSortedResults] = useState<VennDiagramResult[]>([]);
  const [sortList, setSortList] = useState<SimpleSort[]>([
    {
      id: 'size',
      label: t('common.utils.size'),
      direction: 'dsc'
    },
    {
      id: 'criteriaSize',
      label: t('common.utils.criteriaSize'),
      direction: undefined
    }
  ]);
  const handleLegendClick = (segments: { segments: string[], count: number }) => {
    const mapIdToLabel = (segment: string) => `${props.diagram.segments.find(diagSegment => diagSegment.id === segment)?.label}`;
    const mapIdToGroupId = (segment: string) => `${props.diagram.segments.find(diagSegment => diagSegment.id === segment)?.groupIds}`;

    const id = segments.segments.map((segment: string) => mapIdToGroupId(segment)).join(',');
    const values = segments.segments.map((idSegment: string) => mapIdToLabel(idSegment));
    const isActive = props.filters.map(filter => (filter as CompositeItemSpec).subItemSpecs?.map(subItem => (subItem.values ? subItem.values : (subItem as CompositeItemSpec).subItemSpecs?.map(subItem2 => subItem2.values).flat(1)))).flat(2).join(',') === id;

    if (!isActive && !props.initializeByChart) {
      props.generateAndAddFilter(values);
    } else {
      props.removeFilter();
    }
  };

  const sortFunnel = (a: { segments: string[], count: number }, b: { segments: string[], count: number }, order: 'asc' | 'dsc') => {
    const sortValue = b.count - a.count;

    return order === 'asc' ? -sortValue : sortValue;
  };

  const sortUniqCriteria = (a: { segments: string[], count: number }, b: { segments: string[], count: number }, order: 'asc' | 'dsc') => {
    const lengthDifference = a.segments.length - b.segments.length;

    if (lengthDifference === 0) {
      const sortValue = b.count - a.count;

      return order === 'asc' ? -sortValue : sortValue;
    }

    return order === 'asc' ? -lengthDifference : lengthDifference;
  };

  useEffect(() => {
    setSortedResults([...props.result].sort((a, b) => {
      const sortCriteria = sortList.find(sort => sort.direction);
      const direction = sortCriteria?.direction || 'dsc';

      return sortCriteria?.id === 'size'
        ? sortFunnel(a, b, direction)
        : sortUniqCriteria(a, b, direction);
    }));
  }, [sortList]);

  return (
    <Grid container spacing={1} m={2}>
      <Stack display='flex' direction='row' spacing={2} marginLeft='auto !important' alignItems='center' pb={4} pr={2}>
        <SimpleDisplaySort sortList={sortList} setSortList={setSortList} defaultDirection='dsc' />
      </Stack>
      <Grid container spacing={1} m={2} height='700px' overflow='auto'>
        {sortedResults
          .map((segments: {segments: string[], count: number}) => {
            const isHovered = isEqual(segments.segments, props.filters[0]?.id.split(','));
            const translatedSegments = segments.segments.map(seg => props.mapIdToLabel(seg));
            const isAreaDisplayError = venn.areaError.some((errorArray: string[]) =>
              errorArray.length === translatedSegments.length &&
            errorArray.every((errorSeg: string) => translatedSegments.includes(errorSeg))
            );

            return (
              <Stack key={segments.segments.join(',')} display='flex' width='100%'>
                <Stack
                  flexBasis='60%'
                  flexGrow={1}
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  spacing={2}
                  onClick={() => {
                    handleLegendClick(segments);
                  }}
                  sx={{ cursor: 'pointer' }}
                  p={1}
                >
                  <Stack id='labelStack' direction='row' display='flex' flexWrap='wrap' rowGap={1}>
                    {segments.segments.length === 0 ?
                      <Stack direction='row'>
                        <Box
                          width='20px'
                          height='20px'
                          border={1}
                          borderRadius={1}
                          mr={1}
                        />
                        <Typography>
                        Criteria out of selection
                        </Typography>
                      </Stack>
                      : segments.segments.map((segmentSolo: string, indexSegment: number) => (
                        <Stack key={segmentSolo} direction='row' display='flex' alignItems='center' mr={1} mb={1}>
                          <Box
                            width='20px'
                            height='20px'
                            minWidth='20px'
                            bgcolor={props.mapIdToColor(segmentSolo)}
                            borderRadius={1}
                            mr={1}
                          />
                          <Typography
                            fontSize={14}
                            fontWeight={isHovered ? 600 : 400}
                          >
                            {capitalizeFirstLetterOfEachWord(props.mapIdToLabel(segmentSolo))}
                          </Typography>
                          {indexSegment < (segments.segments.length - 1) && (
                            <Typography
                              fontSize={14}
                              fontWeight={isHovered ? 600 : 400}
                              ml='3px'
                            >
                            &
                            </Typography>
                          )}
                        </Stack>
                      ))}
                    {isAreaDisplayError && (
                      <Tooltip title='Not represented on diagram' placement='top' arrow>
                        <IconAlertTriangle color={theme.palette.warning.main} />
                      </Tooltip>
                    )}
                  </Stack>

                  <Typography
                    fontSize={14}
                    fontWeight={isHovered ? 600 : 500}
                    justifySelf='flex-end'
                    sx={{
                      maxHeight: '35px',
                      lineHeight: '35px',
                      marginLeft: 'auto',
                      whiteSpace: 'nowrap',
                      paddingLeft: 6
                    }}
                  >
                    {`${segments.count} entities`}
                  </Typography>
                </Stack>
                <Divider flexItem orientation='horizontal' />
              </Stack>
            );
          })}
      </Grid>
    </Grid>
  );
}

export default Legend;
