import { IconFolders } from '@tabler/icons-react';
import React from 'react';
import { BaseGroup, GroupAllValues } from '@deecision/dna-interfaces';
import createElementRoutes from '@/router/create';
import routesCreate from './create/routes';
import dnaConfig from '../../../../config/dna.config.json';
import { CustomRouteObject } from '@/router/types';
import Construction from '@/components/construction';

const routesFolders: CustomRouteObject = createElementRoutes<BaseGroup, GroupAllValues>({
  i18nKey: dnaConfig.routes.folders.i18nKey,
  path: dnaConfig.routes.folders.baseUrl,
  icon: IconFolders,
  displayIndex: true,
  sectionTitle: 'deetect',
  component: <Construction />,
  idComponent: <Construction />,
  childrens: [
    routesCreate
  ]
});

export default routesFolders;
