import React, { ReactElement } from 'react';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { PaletteColor, styled, useTheme } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

interface TimelineProps<T> {
  idPath: string,
  datas: T[],
  datePath: string,
  years: string[],
  tooltipPath: string,
  select: (id: string) => void,
  criticityPath?: string
}

const StyledTimeline = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    zIndex: 99,
    left: 'calc(-50% + 12px)',
    right: 'calc(50% + 12px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      zIndex: 99,
      borderColor: theme.palette.grey[500]
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      zIndex: 99,
      borderColor: theme.palette.grey[500]
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    zIndex: 99,
    borderColor: theme.palette.grey[500],
    borderTopWidth: 3,
    borderRadius: 1
  }
}));

const TimelineStepIconRoot = styled('div')<{ ownerState: { color?: string, year?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    marginTop: ownerState.year ? '0.5px' : undefined,
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.color && {
      color: ownerState.color
    }),
    '& .StepIcon-circle': {
      width: 12,
      height: 12,
      borderRadius: '50%',
      backgroundColor: 'currentColor'
    },
    '& .Step-year': {
      width: '26px',
      height: 3,
      backgroundColor: theme.palette.grey[500]
    }
  })
);

function StepIcon(props: Pick<StepIconProps, 'className'> & { color?: string, year?: string, index?: number }) {
  const { className, color, year, index } = props;

  return (
    year && index && index !== 0 ?
      <TimelineStepIconRoot ownerState={{ color, year: true }} className={className}>
        <div className='Step-year' />
      </TimelineStepIconRoot> :
      <TimelineStepIconRoot ownerState={{ color }} className={className}>
        <div className='StepIcon-circle' />
      </TimelineStepIconRoot>
  );
}

function Timeline<T>(props: TimelineProps<T>): ReactElement {
  const { i18n } = useTranslation();
  const theme = useTheme();
  const criticity: Record<string, PaletteColor> = {
    neutral: theme.palette.primary,
    opportunity: theme.palette.success,
    alert: theme.palette.error,
    ambiguous: theme.palette.warning
  };

  return (
    <Paper variant='hoverElevation1' sx={{ p: 2, width: '100%', height: 'fit-content', overflowX: 'auto' }}>
      <Stepper activeStep={1} alternativeLabel connector={<StyledTimeline />} sx={{ mt: '24px', width: 'fit-content' }}>
        {props.years.map((year, index) => (
          props.datas.filter(data => (get(data, props.datePath) && year === new Date(get(data, props.datePath)).getFullYear().toString())).length > 0 &&
          <>
            <Step
              key={year}
              sx={{
                '& .MuiStepConnector-root': {
                  display: index === 0 ? 'none' : undefined,
                  left: 'calc(-18px)'
                }
              }}
            >
              <StepLabel
                StepIconComponent={prop => StepIcon({ ...prop, color: 'transparent', year, index })}
                sx={{ width: '80px' }}
              >
                <Typography
                  variant='h3'
                  sx={{ marginTop: '-45px', bgcolor: 'background.default', zIndex: 100, position: 'absolute', p: 2, ml: '12px' }}
                >
                  {year}
                </Typography>
              </StepLabel>
            </Step>
            {props.datas.filter(data => (get(data, props.datePath) && year === new Date(get(data, props.datePath)).getFullYear().toString()))
              .map(data => (get(data, props.datePath) &&
                <Step
                  key={get(data, props.idPath)}
                  onClick={() => props.select(get(data, props.idPath) || '')}
                >
                  <Tooltip title={get(data, props.tooltipPath) as string} arrow>
                    <StepLabel
                      StepIconComponent={prop => StepIcon({ ...prop, color: (props.criticityPath && criticity) ? (get(data, props.criticityPath) ? criticity[get(data, props.criticityPath)].main : undefined) : 'primary.main' })}
                      sx={{ cursor: 'pointer', width: '44px' }}
                    >
                      <Stack spacing={5} mt='-60px'>
                        <Typography variant='body1' noWrap>
                          {`${new Date(get(data, props.datePath)).getDate().toLocaleString(i18n.language, { minimumIntegerDigits: 2 })}/${(new Date(get(data, props.datePath)).getMonth() + 1).toLocaleString(i18n.language, { minimumIntegerDigits: 2 })}`}
                        </Typography>
                        {/*
                        <Typography variant='body1'>
                          {data.data['familleavis_lib' as keyof typeof data.data] as string}
                        </Typography>
                        */}
                      </Stack>
                    </StepLabel>
                  </Tooltip>
                </Step>
              ))
            }
          </>
        ))}
      </Stepper>
    </Paper>
  );
}

export default Timeline;
