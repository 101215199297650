import React, { ReactElement, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { DashboardBlockType, DashboardElement, DashboardWidgetType } from './types';

export interface DashboardProps {
  elements: (DashboardElement | undefined | null)[]
}

function Dashboard(props: DashboardProps): ReactElement {
  const [dashboardElements, setDashboardElements] = useState(props.elements);
  const theme = useTheme();

  useEffect(() => {
    setDashboardElements(props.elements);
  }, [props.elements]);

  return (
    <Stack direction='row' spacing={4}>
      {dashboardElements[0]?.orientation === 'vertical' && dashboardElements[0]?.type === 'block' &&
        <Box key={dashboardElements[0]?.id} p={2} pt={0}>
          <Box border={`solid 1px ${theme.palette.grey['500']}`} borderRadius={1} p={2} mt={4} height='min-content'>
            <Grid container spacing={4} p={2} pt={0} sx={{ width: 'min-content', overflow: 'visible' }}>
              <Grid size={12} pb={1} mt='-22px'>
                <Stack
                  spacing={2}
                  width='min-content'
                  direction='row'
                  alignItems='center'
                  bgcolor='grey.50'
                  ml='-4px'
                  pl={1}
                  pr={1}
                >
                  {(dashboardElements[0] as DashboardBlockType).icon}
                  <Typography variant='h3' mb='-4px !important' noWrap>
                    {(dashboardElements[0] as DashboardBlockType).label}
                  </Typography>
                </Stack>
              </Grid>
              {(dashboardElements[0] as DashboardBlockType).widgets.map(widget => widget && (
                <Grid key={widget.id}>
                  {widget.component}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      }
      <Grid container spacing={4}>
        {dashboardElements.filter(element => element && element?.orientation !== 'vertical').map((element, index) => element && (
          <React.Fragment key={element.id}>

            {element.type === 'block' &&
              <Grid key={(element as DashboardBlockType).label} size='grow' minWidth='60%' mt={2} mb={2}>
                <Box border={`solid 1px ${theme.palette.grey['500']}`} borderRadius={1} p={2}>
                  <Grid container direction='row' spacing={4} p={2} pt={0} sx={{ overflow: 'visible' }}>
                    <Grid size={12} pb={1} mt='-22px'>
                      <Stack
                        spacing={2}
                        width='min-content'
                        direction='row'
                        alignItems='center'
                        bgcolor='grey.50'
                        ml='-4px'
                        pl={1}
                        pr={1}
                      >
                        {(element as DashboardBlockType).icon}
                        <Typography variant='h3' mb='-4px !important' noWrap>
                          {(element as DashboardBlockType).label}
                        </Typography>
                      </Stack>
                    </Grid>
                    {(element as DashboardBlockType).actions &&
                      <Grid size={12}>
                        <Box ml='auto' width='fit-content'>
                          {(element as DashboardBlockType).actions}
                        </Box>
                      </Grid>
                    }
                    {(element as DashboardBlockType).widgets.map(widget => widget && (
                      <Grid key={widget.id} size={widget.largeWidget ? 'grow' : undefined} minWidth={widget.minWidth}>
                        {widget.component}
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            }

            {element.type === 'section' &&
              <Grid key={(element as DashboardBlockType).label} size='grow' minWidth='60%'>
                <Grid container direction='row' spacing={4} p={2} pt={0} sx={{ overflow: 'visible' }}>
                  {index !== 0 &&
                    <Grid size={12}>
                      <Divider sx={{ borderWidth: '1px' }} />
                    </Grid>
                  }
                  <Grid size={12} pb={1} pt={index === 0 ? 6 : undefined}>
                    <Stack
                      spacing={2}
                      width='min-content'
                      direction='row'
                      alignItems='center'
                      bgcolor='grey.50'
                      ml='-4px'
                      pl={1}
                      pr={1}
                    >
                      {(element as DashboardBlockType).icon}
                      <Typography variant='h3' mb='-4px !important' noWrap>
                        {(element as DashboardBlockType).label}
                      </Typography>
                      {(element as DashboardBlockType).actions}
                    </Stack>
                  </Grid>
                  {(element as DashboardBlockType).widgets.map(widget => widget && (
                    <Grid key={widget.id} size={widget.largeWidget ? 'grow' : undefined} minWidth={widget.minWidth}>
                      {widget.component}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            }

            {element.type === 'paper' &&
              <Grid key={(element as DashboardBlockType).label} size='grow' minWidth='60%'>
                <Paper variant='hoverElevation1' sx={{ pt: 1, pr: 0 }}>
                  <Stack direction='column' spacing={4} p={0}>
                    <Stack
                      spacing={3}
                      width='100%'
                      direction='row'
                      alignItems='center'
                      ml='-4px'
                      mr='-4px'
                      pl={2}
                      pr={2}
                    >
                      {(element as DashboardBlockType).icon}
                      <Typography variant='h3' mb='-4px !important' noWrap>
                        {(element as DashboardBlockType).label}
                      </Typography>
                      {(element as DashboardBlockType).actions &&
                        <Box ml='auto !important' mr='-4px'>
                          {(element as DashboardBlockType).actions}
                        </Box>
                      }
                    </Stack>
                    <Grid container spacing={4} direction='row' align-items='stretch' pr={2}>
                      {(element as DashboardBlockType).widgets.map(widget => widget && (
                        <Grid key={widget.id}>
                          {widget.component}
                        </Grid>
                      ))}
                    </Grid>
                  </Stack>
                </Paper>
              </Grid>
            }

            {element.type === 'widget' &&
              <>
                <Grid key={element.id} pt={4}>
                  {(element as DashboardWidgetType).component}
                </Grid>
              </>
            }

          </React.Fragment>
        ))}
      </Grid>
      {dashboardElements[dashboardElements.length - 1]?.orientation === 'vertical' && dashboardElements[dashboardElements.length - 1]?.type === 'block' &&
        <Box key={dashboardElements[dashboardElements.length - 1]?.id} border={`solid 1px ${theme.palette.grey['500']}`} borderRadius={1} p={2} mt={4} height='min-content'>
          <Grid container spacing={4} p={2} pt={0} sx={{ width: 'min-content', overflow: 'visible' }}>
            <Grid size={12} pb={1} mt='-22px'>
              <Stack
                spacing={2}
                width='min-content'
                direction='row'
                alignItems='center'
                bgcolor='grey.50'
                ml='-4px'
                pl={1}
                pr={1}
              >
                {(dashboardElements[dashboardElements.length - 1] as DashboardBlockType).icon}
                <Typography variant='h3' mb='-4px !important' noWrap>
                  {(dashboardElements[dashboardElements.length - 1] as DashboardBlockType).label}
                </Typography>
              </Stack>
            </Grid>
            {(dashboardElements[dashboardElements.length - 1] as DashboardBlockType).widgets.map(widget => widget && (
              <Grid key={widget.id}>
                {widget.component}
              </Grid>
            ))}
          </Grid>
        </Box>
      }
    </Stack>
  );
}

export default Dashboard;
