import React, { ReactElement, useEffect, useState } from 'react';
import { CompanyEntity, EntityLink, PersonEntity, SegmentationData } from '@deecision/dna-interfaces';
import PersonList from '../../../lists/person';
import CompanyList from '../../../lists/company';
import ExportIconButton from '../export';
import LinkList from '../../../lists/links';

interface ListSegmentationsProps<T> {
  data: {
    entities: T[],
    totalCount: number
  },
  entityType: SegmentationData['objectType'],
  enableSelect?: boolean,
  actions?: ReactElement, // This has been added to handle Export button, check ExportIconButton component for usage and where ExportIconButton is called for additionalFilters props handling logic
  // TODO: remove
  exportable?: {
    canExport: boolean,
    action: () => void
  }
}

function EntitiesListsSegmentations<T>(props: ListSegmentationsProps<T>): ReactElement {
  const [selected, setSelected] = useState<string[]>(props.data.entities.map(entity => entity['entityId' as keyof typeof entity] as string));

  useEffect(() => {
    setSelected(props.data.entities.map(entity => entity['entityId' as keyof typeof entity] as string));
  }, [props.data.entities]);

  return (
    <>
      {props.entityType === 'deecPerson' &&
        <PersonList
          {...props}
          data={props.data.entities as PersonEntity[]}
          totalCount={props.data.totalCount}
          basePageSize={20}
          selected={props.enableSelect ? selected : undefined}
          setSelected={props.enableSelect ? setSelected : undefined}
          actionsPadding='16px'
          actions={[
            <ExportIconButton />,
            props.actions || <></>
          ]}
        />
      }
      {props.entityType === 'deecCompany' &&
        <CompanyList
          {...props}
          data={props.data.entities as CompanyEntity[]}
          totalCount={props.data.totalCount}
          basePageSize={20}
          selected={props.enableSelect ? selected : undefined}
          setSelected={props.enableSelect ? setSelected : undefined}
          actionsPadding='16px'
          actions={[
            <ExportIconButton />,
            props.actions || <></>
          ]}
        />
      }
      {props.entityType === 'p2pNetworkLink' &&
        <LinkList
          {...props}
          data={props.data.entities as EntityLink[]}
          totalCount={props.data.totalCount}
          basePageSize={20}
          selected={props.enableSelect ? selected : undefined}
          setSelected={props.enableSelect ? setSelected : undefined}
          actionsPadding='16px'
          actions={[
            <ExportIconButton />,
            props.actions || <></>
          ]}
        />
      }
    </>
  );
}

export default EntitiesListsSegmentations;
