export function getAge(dateString: string) {
  const birthDate = new Date(dateString);
  const currentDate = new Date();

  // Calcul des années
  const ageYears = currentDate.getFullYear() - birthDate.getFullYear();

  // Vérification si l'anniversaire de l'année en cours est déjà passé
  const hasHadBirthdayThisYear = (
    currentDate.getMonth() > birthDate.getMonth() ||
    (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() >= birthDate.getDate())
  );

  // Si l'anniversaire n'est pas encore passé cette année, on soustrait 1 à l'âge
  return hasHadBirthdayThisYear ? ageYears : ageYears - 1;
}
