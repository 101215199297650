import React, { ReactElement } from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { IconX } from '@tabler/icons-react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { BaseItemFormProps } from './types.form';
import filterTypesMap from '@/main/containers/form/items/mapping';

interface ItemFormProps {
  index: number
}

function ItemForm(props: ItemFormProps & BaseItemFormProps): ReactElement {
  const theme = useTheme();
  const ItemFormComponent = filterTypesMap[props.filter.type as keyof typeof filterTypesMap];

  return (
    <>
      <Grid size={6}>
        <Stack direction='row' alignItems='center' spacing={2} height='100%'>
          <Typography variant='h4'>{`${props.index + 1}. ${props.filter.desc || props.filter.label || ''}`}</Typography>
        </Stack>
      </Grid>
      <Grid size={6}>
        <Stack direction='row' spacing={2} alignItems='center'>
          <ItemFormComponent {...props} />
          {props.filter.value &&
            <Box pr={1}>
              <Button
                variant='textIcon'
                sx={{
                  '&:hover': {
                    color: theme.palette.error.main
                  }
                }}
                onClick={() => props.onChange(undefined)}
              >
                <IconX />
              </Button>
            </Box>
          }
        </Stack>
      </Grid>
    </>
  );
}

export default ItemForm;
