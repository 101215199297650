import React, { ReactElement, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Step, StepButton, StepConnector, stepConnectorClasses, Stepper } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';
import Paper from '@mui/material/Paper';
import { lighten, styled, useTheme } from '@mui/material/styles';
import { BaseItemVennDiagramBuilderProps } from '../types.vennDiagram';

type StepperBuilderVennDiagramProps = (BaseItemVennDiagramBuilderProps) & {
  steps: ReactElement<BaseItemVennDiagramBuilderProps>[],
  isReady: boolean[]
}

const ColorLibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 18
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.success.main
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.grey['500'],
    borderRadius: 1,
    width: 'calc(100% - 16px)',
    marginLeft: '8px',
    marginRight: '8px'
  }
}));

function StepperBuilderVennDiagram(props: StepperBuilderVennDiagramProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(window.location.href.includes('create') ? 0 : 1);

  const totalSteps = () => props.steps.length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const handleNext = () => {
    const newActiveStep = isLastStep() ? activeStep : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  useEffect(() => {
    const activeStepTmp = props.isReady.findIndex(isReady => !isReady);

    setActiveStep(activeStepTmp < 0 ? 0 : activeStepTmp);
  }, []);

  useEffect(() => {
    setActiveStep(window.location.href.includes('create') ? 0 : 1);
  }, [window.location.href]);

  return (
    <Stack spacing={6} sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} connector={<ColorLibConnector />}>
        {props.steps.map((step, index) => (
          <Step
            key={step.key}
            completed={props.isReady?.[index]}
            active={props.isReady?.[index] ? false : undefined}
            sx={{
              '& .Mui-completed': {
                color: `${theme.palette.success.main} !important`
              },
              '& .Mui-active': {
                color: `${theme.palette.primary.main} !important`
              }
            }}
          >
            <StepButton
              color='inherit'
              onClick={handleStep(index)}
              sx={{
                borderRadius: 2,
                p: 2,
                ml: '-8px',
                cursor: 'pointer !important',
                border: 'solid 1.5px',
                borderColor: index === activeStep ? props.isReady?.[index] ? lighten(theme.palette.success.main, 0.3) : lighten(theme.palette.primary.main, 0.3) : 'transparent',
                bgcolor: index === activeStep ? props.isReady?.[index] ? theme.palette.success.light : theme.palette.primary.light : 'transparent'
              }}
              disabled={false}
            >
              <Typography
                variant='h3'
                sx={{
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                  color: props.isReady?.[index] ? `${theme.palette.success.main} !important` : index === activeStep ? `${theme.palette.primary.main} !important` : undefined
                }}
              >
                {step.key}
              </Typography>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Stack>
        <Paper
          sx={{
            p: 4,
            bgcolor: 'transparent',
            borderBottom: 'none',
            borderRadius: 0,
            width: '100%',
            '&:first-of-type': {
              borderTopLeftRadius: '12px !important',
              borderTopRightRadius: '12px !important'
            },
            '&:last-of-type': {
              borderBottom: 'solid 1px',
              borderColor: 'rgb(206, 212, 218)',
              borderTopLeftRadius: '12px !important',
              borderTopRightRadius: '12px !important'
            }
          }}
        >
          <Stack direction='row' spacing={4} alignItems='center'>
            <Button
              startIcon={<IconArrowLeft size='1.6rem' />}
              color='inherit'
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              {t('segmentation.builder.steps.back')}
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button
              endIcon={<IconArrowRight size='1.6rem' />}
              onClick={handleNext}
              sx={{ mr: 1 }}
              disabled={activeStep === props.steps.length - 1}
            >
              {t('segmentation.builder.steps.next')}
            </Button>
          </Stack>
        </Paper>
        {props.steps[activeStep]}
      </Stack>
    </Stack>
  );
}

export default StepperBuilderVennDiagram;
