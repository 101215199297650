import React from 'react';
import {
  EntityLinksAggregation,
  zEntityLinksAggregation
} from '@deecision/dna-interfaces';
import { IconCirclesRelation } from '@tabler/icons-react';
import createElementRoutes from '@/router/create';
import dnaConfig from '../../../../config/dna.config.json';
import DeveloperTabsComponents from '@/main/modules/entities/common/tabs/developer';
import RelationsComponent from './index';
import RelationComponent from './components';
import RelationsServices from './services/relations';
import DashboardTabsComponents from './components/tabs/dashboard';
import { CustomRouteObject } from '@/router/types';

const tabsRelations: CustomRouteObject[] = [
  {
    id: dnaConfig.routes.relations.tabs.dashboard.label,
    i18nKey: dnaConfig.routes.relations.tabs.dashboard.i18nKey,
    path: dnaConfig.routes.relations.tabs.dashboard.baseUrl,
    element: <DashboardTabsComponents />,
    handle: {
      keepParams: true
    }
  },
  {
    id: dnaConfig.routes.relations.tabs.developer.label,
    i18nKey: dnaConfig.routes.relations.tabs.developer.i18nKey,
    path: dnaConfig.routes.relations.tabs.developer.baseUrl,
    element: <DeveloperTabsComponents<EntityLinksAggregation[]> zodSchema={zEntityLinksAggregation.array()} />,
    handle: {
      keepParams: true
    }
  }
];

const routesRelations: CustomRouteObject = createElementRoutes<unknown>({
  i18nKey: dnaConfig.routes.relations.i18nKey,
  path: dnaConfig.routes.relations.baseUrl,
  icon: IconCirclesRelation,
  IdService: new RelationsServices<EntityLinksAggregation>({}),
  component: <RelationsComponent />,
  idComponent: <RelationComponent />,
  displayIndex: true,
  tabs: tabsRelations
});

export default routesRelations;
