import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { PersonEntity } from '@deecision/dna-interfaces';
import { PersonGetter } from '@/main/providers/getter';
import VeegilenzCard from '@/main/containers/veegilenz/card';
import BaseWidget from '@/main/containers/widgets/base-widget';
import VEEGILENZ from '@/assets/VEEGILENZ.svg';
import { WidgetProps } from '@/main/containers/widgets/types';

function PersonWidgetsVeegilenz(props: WidgetProps<PersonEntity>): ReactElement {
  const { t } = useTranslation();
  const personGetter = new PersonGetter({
    data: props.data,
    errorMsg: t('common.utils.unknown')
  });

  return (
    <BaseWidget
      {...props}
      title={<img src={VEEGILENZ} alt='Veegilenz' height={18} />}
    >
      <VeegilenzCard
        score={2}
        veegilenzDatas={{
          directIndex: personGetter.getVeegilenzLevel(true) as number,
          directIndexReason: personGetter.getVeegilenzReason()
        }}
      />
    </BaseWidget>
  );
}

export default PersonWidgetsVeegilenz;
