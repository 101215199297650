import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { CompanyEntity } from '@deecision/dna-interfaces';
import { CompanyGetter } from '@/main/providers/getter';
import KeyValueList from '@/components/keyvalue/keyvaluelist';
import BaseWidget from '@/main/containers/widgets/base-widget';
import { WidgetProps } from '@/main/containers/widgets/types';

function CompanyWidgetsIdentity(props: WidgetProps<CompanyEntity> & { simple?: boolean, small?: boolean }): ReactElement {
  const { t } = useTranslation();
  const companyGetter = new CompanyGetter({
    data: props.data,
    errorMsg: t('common.utils.unknown')
  });
  const identityDatas = props.data.dataViews?.data?.company?.identity ? [
    { key: t('common.codes.siren'), value: companyGetter.getSiren() },
    {
      key: t('common.utils.activitySector'),
      value: <Tooltip title={companyGetter.getActivitySector()} arrow placement='top'>
        <Typography variant='h5' maxWidth='100%' textOverflow='ellipsis' overflow='hidden' whiteSpace='nowrap'>
          {!props.simple && companyGetter.getActivitySector().length > 20 ? `${companyGetter.getActivitySector().substring(0, 20)}...` : companyGetter.getActivitySector()}
        </Typography>
      </Tooltip>
    },
    { key: t('common.utils.legalForm'),
      value: <Tooltip title={companyGetter.getLegalForm()} arrow placement='top'>
        <Typography variant='h5' maxWidth='100%' textOverflow='ellipsis' overflow='hidden' whiteSpace='nowrap'>
          {!props.simple && companyGetter.getLegalForm().length > 20 ? `${companyGetter.getLegalForm().substring(0, 20)}...` : companyGetter.getLegalForm()}
        </Typography>
      </Tooltip>
    },
    { key: t('common.localisation.country'), value: companyGetter.getCountry() },
    { key: t('common.dates.creationDate'), value: companyGetter.getCreationDate() }
  ] : [];

  return (props.simple ?
    <KeyValueList values={[identityDatas.slice(1)]} small={props.small} /> :
    <BaseWidget {...props} title={t('common.identity.identity')}>
      <KeyValueList values={[identityDatas]} small={props.small} />
    </BaseWidget>
  );
}

export default CompanyWidgetsIdentity;
