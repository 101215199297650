import React, { ReactElement } from 'react';
import { useLoaderData } from 'react-router-dom';
import { IDataBlock, FinancialReportDataBlock } from '@deecision/dna-interfaces';
import Financial from '@/main/containers/financial';

function FinancialTabsComponents(): ReactElement {
  const { data } = useLoaderData() as { data: IDataBlock<FinancialReportDataBlock>[] };

  return (
    <Financial data={data} />
  );
}

export default FinancialTabsComponents;
