import React from 'react';
import { getStraightPath, useInternalNode, EdgeLabelRenderer, type Edge } from '@xyflow/react';
import { EdgeProps } from '@xyflow/react/dist/esm/types/edges';
import Chip from '@mui/material/Chip';
import { BaseEdge } from 'reactflow';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { startCase } from 'lodash';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { EdgeData, getEdgeParams } from './utils';
import { mainRadius } from '@/theme/theme';

function FloatingEdge({ id, source, target, data }: EdgeProps<Edge<EdgeData>>) {
  const { t } = useTranslation();
  const theme = useTheme();
  const sourceNode = useInternalNode(source);
  const targetNode = useInternalNode(target);

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty } = getEdgeParams(sourceNode, targetNode);

  const [edgePath, lx, ly] = getStraightPath({
    sourceX: sx,
    sourceY: sy,
    targetX: tx,
    targetY: ty
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      {data && (data.metadata.role || (data.metadata.positionTypes?.length || 0) > 0 || data.metadata.proxemeeLevel || data.metadata.shareFamilyName || data.metadata.sharePercentage !== undefined && data.metadata.sharePercentage !== null) ?
        <EdgeLabelRenderer>
          <div
            style={{
              zIndex: 2,
              borderRadius: mainRadius,
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${lx}px,${ly}px)`,
              backgroundColor: theme.palette.background.default
            }}
          >
            <Chip
              label={
                <Stack spacing={1} alignItems='center'>
                  {data.metadata.proxemeeLevel ?
                    <Typography variant='body2'>
                      {startCase(t(`portfolios.dashboard.groups.labels.${data.metadata.proxemeeLevel}.label`))}
                      {data.metadata.shareFamilyName ? `(${t('portfolios.dashboard.groups.labels.sameFamily.label')})` : ''}
                    </Typography> :
                    [
                      data.metadata.role ?
                        <Typography variant='body2' fontSize='0.6rem' maxWidth={96} overflow='hidden' textOverflow='ellipsis'>
                          {data.metadata.role}
                        </Typography> : null,
                      data.metadata.positionTypes && data.metadata.positionTypes.length > 0 ?
                        <Typography variant='body2' fontSize='0.6rem' maxWidth={96} overflow='hidden' textOverflow='ellipsis'>
                          {data.metadata.positionTypes?.map(positionType => t(`common.positionTypes.${positionType}`)).join(', ') || t('common.utils.unknown')}
                        </Typography> : null,
                      data.metadata.sharePercentage !== undefined && data.metadata.sharePercentage !== null ?
                        <Typography variant='body2' fontSize='0.6rem'>
                          {data.metadata.sharePercentage}%
                        </Typography> : null
                    ]
                  }
                </Stack>
              }
              sx={{
                minHeight: 'min-content',
                pt: 1,
                pb: 1,
                bgcolor: data.metadata.proxemeeLevel ?
                  {
                    personal: theme?.palette.success.light,
                    business: theme?.palette.warning.light,
                    extended: theme?.palette.error.light
                  }[data.metadata.proxemeeLevel]
                  : undefined,
                '& .MuiChip-label': {
                  color: data.metadata.proxemeeLevel ?
                    {
                      personal: theme?.palette.success.dark,
                      business: theme?.palette.warning.dark,
                      extended: theme?.palette.error.dark
                    }[data.metadata.proxemeeLevel]
                    : undefined
                }
              }}
              size='small'
            />
          </div>
        </EdgeLabelRenderer> :
        null
      }
    </>
  );
}

export default FloatingEdge;
