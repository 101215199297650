import React from 'react';
import {
  useReactFlow,
  getNodesBounds,
  getViewportForBounds
} from '@xyflow/react';
import { toPng } from 'html-to-image';
import { IconScreenshot } from '@tabler/icons-react';
import { Tooltip } from '@mui/material';

function downloadImage(dataUrl: string, name: string) {
  const a = document.createElement('a');

  a.setAttribute('download', `reech-${name}.png`);
  a.setAttribute('href', dataUrl);
  a.click();
}

const imageWidth = 1920;
const imageHeight = 1080;

function DownloadButton(props: { name?: string }) {
  const { getNodes } = useReactFlow();
  const onClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    const nodesBounds = getNodesBounds(getNodes());
    const viewport = getViewportForBounds(
      nodesBounds,
      imageWidth,
      imageHeight,
      0.2,
      1,
      0
    );

    const doc = document.querySelector('.react-flow__viewport') as HTMLElement;

    if (doc) {
      toPng(doc, {
        backgroundColor: 'white',
        width: imageWidth,
        height: imageHeight,
        style: {
          width: imageWidth.toString(),
          height: imageHeight.toString(),
          transform: `translate(${viewport.x}px, ${viewport.y}px) scale(${viewport.zoom})`
        }
      }).then(data => downloadImage(data, props.name || 'entity'));
    }
  };

  return (
    <Tooltip title='Screenshot' arrow placement='right'>
      <IconScreenshot onClick={onClick} />
    </Tooltip>
  );
}

export default DownloadButton;
