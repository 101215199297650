import React, { useEffect } from 'react';
import { IconButton, Paper, Stack, Tooltip } from '@mui/material';
import { IconListNumbers, IconPencil, IconRefresh } from '@tabler/icons-react';

function ActionPanel() {

  useEffect(() => {
    // implement service to reload batch and dataElements
  }, []);

  return (
    <Paper elevation={1} sx={{ p: 1 }}>
      <Stack justifyContent='space-between' flexDirection='row' bgcolor='#E9ECEF'>
        <Tooltip title='Refresh'>
          <IconButton color='success'>
            <IconRefresh size='1.6rem' />
          </IconButton>
        </Tooltip>
        <Tooltip title='Edit' color='primary'>
          <IconButton>
            <IconPencil size='1.6rem' />
          </IconButton>
        </Tooltip>
        <Tooltip title='Priority' color='error'>
          <IconButton>
            <IconListNumbers size='1.6rem' />
          </IconButton>
        </Tooltip>
      </Stack>
    </Paper>
  );
}

export default ActionPanel;
