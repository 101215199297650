import React, { ReactElement, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { IconArrowLeft, IconChevronDown } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { i18nPossibleLanguages } from '@/i18n/config';
import { translate } from '../translate';
import { useLanguageStorageContext } from '../hooks/languagestorage';
import CountriesFlags from '../../components/countriesflags';

export function AppLanguages(props: { setOpenLanguages: React.Dispatch<React.SetStateAction<boolean>> }) {
  const { i18n } = useTranslation();
  const { languageStorage, setLanguageStorage } = useLanguageStorageContext();
  const [lng, setLanguage] = useState<string>(i18n.language);

  const handleChange = (language: string) => {
    i18n.changeLanguage(language)
      .then(() => {
        setLanguageStorage({
          lang: language
        });
        props.setOpenLanguages(false);
      })
      .catch(() => {
        props.setOpenLanguages(false);
      });
  };

  useEffect(() => {
    if (languageStorage) {
      setLanguage(languageStorage.lang);
    }
  }, [languageStorage]);

  return (
    <>
      <MenuItem
        sx={{
          mb: 4,
          '&.MuiButtonBase-root:hover': {
            bgcolor: 'transparent',
            cursor: 'default'
          }
        }}
        disableTouchRipple
        disableRipple
      >
        <IconButton
          sx={{
            ml: -2,
            mt: -1
          }}
          onClick={() => props.setOpenLanguages(false)}
          size='small'
        >
          <IconArrowLeft size='1.2rem' />
        </IconButton>
        <Typography sx={{ mt: -1, ml: 3 }}>
          {translate({ en: 'Languages', fr: 'Langues', language: lng })}
        </Typography>
      </MenuItem>
      {i18nPossibleLanguages.map(language => (
        <MenuItem
          key={language.version}
          sx={{
            '&.MuiButtonBase-root:hover': {
              bgcolor: 'transparent',
              cursor: 'default'
            }
          }}
          disableTouchRipple
          disableRipple
        >
          <Button
            onClick={() => handleChange(language.global)} sx={{ borderRadius: 1 }}
            variant='menu'
            size='large'
            startIcon={
              CountriesFlags({ countries: [(i18nPossibleLanguages.find(lang => lang.global === language.global)?.version || i18nPossibleLanguages[0].version).split('-')[1]], size: 'small' })

            }
            disabled={language.global === lng}
          >
            {language.full}
          </Button>
        </MenuItem>
      ))}
    </>
  );
}

function LanguageSelector(): ReactElement {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [disabled, setDisabled] = useState(false);
  const { languageStorage, setLanguageStorage } = useLanguageStorageContext();
  const [lng, setLanguage] = useState<string>(i18n.language);

  useEffect(() => {
    if (languageStorage) {
      setLanguage(languageStorage.lang);
    }
  }, [languageStorage]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (language: string) => {
    setDisabled(true);
    handleClose();
    i18n.changeLanguage(language)
      .then(() => {
        setLanguageStorage({
          lang: language
        });
        setDisabled(false);
      })
      .catch(() => {
        setDisabled(false);
      });
  };

  return (
    <>
      <Button
        id='lang-button'
        aria-controls={open ? 'lang-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant='text'
        sx={{
          textDecoration: 'none !important',
          '& .MuiButton-startIcon': {
            mr: '4px !important'
          },
          '& .MuiButton-endIcon': {
            ml: '4px !important'
          }
        }}
        startIcon={
          CountriesFlags({ countries: [(i18nPossibleLanguages.find(language => language.global === lng)?.version || i18nPossibleLanguages[0].version).split('-')[1].toUpperCase()], size: 'small' })
        }
        endIcon={
          <IconChevronDown
            size='1.2rem'
            style={{ transform: open ? 'rotate(180deg)' : undefined, transition: 'transform 0.2s ease' }}
          />
        }
        disabled={disabled}
      />
      <Menu
        id='lang-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        MenuListProps={{
          'aria-labelledby': 'lang-button'
        }}
      >
        {i18nPossibleLanguages.filter(language => language.global !== lng).map(language => (
          <MenuItem key={language.version} onClick={() => handleChange(language.global)} disabled={disabled}>
            <Stack direction='row' p={1} spacing={2} alignItems='center'>
              {CountriesFlags({ countries: [(i18nPossibleLanguages.find(lang => lang.global === language.global)?.version || i18nPossibleLanguages[0].version).split('-')[1].toUpperCase()], size: 'small' })}
              <Typography variant='subtitle1'>{language.full}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default LanguageSelector;
