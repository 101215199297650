import React, { ReactElement } from 'react';
import { z } from 'zod';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { IconChevronDown } from '@tabler/icons-react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import docco from 'react-syntax-highlighter/dist/esm/styles/hljs/docco';
import { isEqual, reduce } from 'lodash';
import { SafeParseError, SafeParseSuccess } from 'zod/lib/types';

SyntaxHighlighter.registerLanguage('json', json);

function reduceDiff<T = unknown, U = unknown>(dataA: T, dataB: U): Record<string, unknown>[] {
  if (!dataA) {
    return [];
  }

  return reduce(
    dataA,
    (result: Record<string, unknown>[], value, key) => (
      isEqual(value, dataB[key as keyof typeof dataB]) ?
        result : result.concat(
          (dataA[key as keyof typeof dataA] && dataB[key as keyof typeof dataB]) ?
            reduceDiff(dataA[key as keyof typeof dataA], dataB[key as keyof typeof dataB]) :
            { [key]: dataA[key as keyof typeof dataA] as Record<string, unknown> }
        )
    ),
    []
  );
}

function Displayjson<T = unknown>(props: { data: T, title?: string, zodSchema?: z.ZodTypeAny }): ReactElement {
  let dataParsed: SafeParseSuccess<T> | SafeParseError<T> | undefined;
  let lessOrEqual;
  let dataDiff;

  if (props.zodSchema) {
    dataParsed = props.zodSchema.safeParse(props.data);
    if (dataParsed.success) {
      if (isEqual(dataParsed.data, props.data)) {
        lessOrEqual = 'Equal';
      } else {
        lessOrEqual = 'Less';
        if (props.data) {
          dataDiff = reduceDiff(props.data, dataParsed.data);
        }
      }
    }
  }

  return (
    <Stack>
      <Accordion sx={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<IconChevronDown size='1.2rem' />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography>{props.title || 'Data'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SyntaxHighlighter language='json' style={docco} wrapLongLines >
            {JSON.stringify(props.data, null, 4)}
          </SyntaxHighlighter>
        </AccordionDetails>
      </Accordion>
      {props.zodSchema && dataParsed &&
        <Accordion sx={{ width: '100%' }}>
          <AccordionSummary
            expandIcon={<IconChevronDown size='1.2rem' />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Stack direction='row' spacing={2}>
              <Typography>Data Parsed (with Zod)</Typography>
              <Chip
                label={dataParsed.success ? 'Success' : 'Error'}
                color={dataParsed.success ? 'success' : 'error'}
                size='small'
              />
              {lessOrEqual &&
                <Chip
                  label={`${lessOrEqual} data`}
                  color={lessOrEqual === 'Equal' ? 'success' : 'error'}
                  size='small'
                />
              }
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <SyntaxHighlighter language='json' style={docco} wrapLongLines >
              {JSON.stringify(dataParsed.success ? dataParsed.data : dataParsed.error, null, 4)}
            </SyntaxHighlighter>
          </AccordionDetails>
        </Accordion>
      }
      {lessOrEqual === 'Less' && dataDiff &&
        <Accordion sx={{ width: '100%' }}>
          <AccordionSummary
            expandIcon={<IconChevronDown size='1.2rem' />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Stack direction='row' spacing={2}>
              <Typography>Missing Data (in Zod Schema)</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <SyntaxHighlighter language='json' style={docco} wrapLongLines >
              {JSON.stringify(dataDiff, null, 4)}
            </SyntaxHighlighter>
          </AccordionDetails>
        </Accordion>
      }
    </Stack>
  );
}

export default Displayjson;
