import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { CompanyEntity, EntityLinksAggregation } from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import { IconCertificate2, IconExternalLink, IconMoneybag } from '@tabler/icons-react';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid2';
import { upperFirst } from 'lodash';
import Box from '@mui/material/Box';
import BaseEntitiesCards, { BaseEntitiesCardsProps } from './base';
import { CompanyGetter } from '../../providers/getter';
import CompanyEntitiesChips from '@/main/containers/chips/company';
import KeyValueList from '@/components/keyvalue/keyvaluelist';
import { EntityLinksQueryAggregatorService } from '../../../api/services/query';
import KeyValueWithCard from '@/components/keyvalue/keyvaluewithcard';

function CompanyEntitiesCards(props: BaseEntitiesCardsProps & { commonRelationsWithMain? : string }): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const [entity, setEntity] = useState<CompanyEntity>(props.entity as CompanyEntity);
  const [shareHolders, setShareHolders] = useState<EntityLinksAggregation[]>();
  const linksServiceToPerson = new EntityLinksQueryAggregatorService<EntityLinksAggregation>({
    entityType: entity.entityType,
    toEntityType: 'deecPerson'
  }); // todo: remove asap
  const companyGetter = useMemo(() => new CompanyGetter({
    data: entity,
    errorMsg: t('common.utils.unknown')
  }), [entity]);

  const financial = entity.dataViews?.data?.company ? [
    {
      key: t('common.financial.turnover.label'),
      value: companyGetter.getTurnover(),
      completeness: companyGetter.getTurnoverCompleteness(),
      align: 'right' as const
    },
    {
      key: t('common.financial.capital.label'),
      value: companyGetter.getCapital(),
      completeness: companyGetter.getCapitalCompleteness(),
      align: 'right' as const
    },
    {
      key: t('common.financial.ebitda.label'),
      value: companyGetter.getEbitda(),
      completeness: companyGetter.getEbitdaCompleteness(),
      align: 'right' as const
    },
    {
      key: t('common.financial.valuation.label'),
      value: companyGetter.getValuation(),
      completeness: companyGetter.getValuationCompleteness(),
      accuracy: companyGetter.getValuationAccuracy(),
      align: 'right' as const
    }
  ] : [];

  const colorOfCriticity = [
    {
      label: 'opportunity',
      color: theme.palette.success.dark,
      bgColor: theme.palette.success.light
    },
    {
      label: 'neutral',
      color: theme.palette.primary.dark,
      bgColor: theme.palette.primary.light
    },
    {
      label: 'ambiguous',
      color: theme.palette.warning.dark,
      bgColor: theme.palette.warning.light
    },
    {
      label: 'alert',
      color: theme.palette.error.dark,
      bgColor: theme.palette.error.light
    }
  ];

  useEffect(() => {
    setEntity(props.entity as CompanyEntity);
    linksServiceToPerson.get(entity.entityId, 'all', ['has.shareholder'])
      .then((res) => {
        if (res.data) {
          setShareHolders(res.data);
        }
      })
      .catch(() => undefined);
  }, [props.entity]);

  return (
    <BaseEntitiesCards {...props}>
      <Grid container spacing={2}>
        {CompanyEntitiesChips({ entity, size: 'small' }).map(chip => (
          <Grid key={chip.key}>
            {chip}
          </Grid>
        ))}
        <Grid>
          <Tooltip
            title={`${t('common.utils.shareHolders')}`}
            arrow
            placement='top'
          >
            <Chip
              color='primary'
              label={
                <Stack spacing={1} direction='row' alignItems='center'>
                  <IconMoneybag
                    size='0.9rem'
                    color={theme.palette.primary.dark}
                  />
                  <Typography>
                    {shareHolders?.length || '0'}
                  </Typography>
                </Stack>
              }
              size='small'
            />
          </Tooltip>
        </Grid>
        {!props.compact && [
          <Grid key='company-compact-chip-legalForm'>
            <Chip label={companyGetter.getLegalForm()} size='small' />
          </Grid>,
          <Grid key='company-compact-chip-activitySector'>
            <Chip label={companyGetter.getActivitySector()} size='small' />
          </Grid>
        ]}
      </Grid>
      {!props.compact &&
        <Stack spacing={4} p={4} width='100%' height={props.full ? '100%' : props.commonRelationsWithMain && !props.commonRelationsWithMain.includes('undefined') ? 164 : 120} divider={<Divider />} overflow='auto'>
          {props.commonRelationsWithMain && !props.commonRelationsWithMain.includes('undefined') &&
            <KeyValueList
              key='commonRelationsCard'
              values={[[{
                key: t('common.utils.commonRelationEntity'),
                value: props.commonRelationsWithMain
              }]]
              }
            />
          }
          <KeyValueList
            key='financialCard'
            values={[financial]}
          />
          {props.full && [
            <Stack key='company-full-official-informations' spacing={4}>
              <Stack spacing={2} direction='row' alignItems='flex-end'>
                <IconCertificate2 size='1.6rem' />
                <Typography variant='h4'>{t('entities.companies.company.tabs.officialInformations')}</Typography>
              </Stack>
              <Box>
                <Grid key='criticity' container spacing={2} minWidth='256px'>
                  {colorOfCriticity.map(c => (
                    <Grid size={6} key={c.label}>
                      <KeyValueWithCard
                        keyValueCard={{
                          bgColor: c.bgColor,
                          color: c.color,
                          Key: upperFirst(c.label),
                          width: '100%',
                          height: '42px',
                          padding: '10px 8px',
                          size: 'small',
                          value: entity.dataViews?.data?.company?.bodacc?.stats?.countsByCriticity[c.label as keyof typeof entity.dataViews.data.company.bodacc.stats.countsByCriticity] || '0',
                          params: `criticity=${c.label}`
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Stack>,
            <Stack key='company-full-shareHolders' spacing={4}>
              <Tooltip title={t('common.utils.shareHolders')} placement='right' arrow>
                <Stack spacing={2} direction='row' alignItems='flex-end'>
                  <IconMoneybag size='1.6rem' color={theme.palette.primary.main} />
                  <Typography variant='h4' color={theme.palette.primary.main}>{t('common.utils.shareHolders')}</Typography>
                  <Typography variant='h4' color={theme.palette.primary.main} ml='auto !important'>{shareHolders?.length || 0}</Typography>
                </Stack>
              </Tooltip>
              <Stack spacing={2}>
                {shareHolders?.map(shareholder => (
                  <Link sx={{ textDecoration: 'none' }} key={shareholder.entity2.entityId} href={`/persons/${shareholder.entity2.entityId}`} target='_blank'>
                    <Stack direction='row' alignItems='center' spacing={2}>
                      <Typography sx={{ '&:hover': { textDecoration: 'underline' } }} variant='h5' noWrap>{shareholder.entity2.name}</Typography>
                      <IconExternalLink size='1rem' />
                      <Chip label={`${shareholder.links.find(l => l.type === 'has.shareholder')?.details?.sharesPercentage}%`} size='small' color='primary' sx={{ ml: 'auto !important' }} />
                    </Stack>
                  </Link>
                ))}
              </Stack>
            </Stack>
          ]}
        </Stack>
      }
    </BaseEntitiesCards>
  );
}

export default CompanyEntitiesCards;
