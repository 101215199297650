import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { Button, Stack } from '@mui/material';
import { IconDeviceFloppy, IconChartInfographic } from '@tabler/icons-react';
import { omit } from 'lodash';
import VennDiagramServices from './services/vennDiagram.services';
import { VennDiagramConfig } from './types.vennDiagram';
import TextBuilderVennDiagram from './builder/text.builder.vennDiagram';
import InitializeVennDiagram from './builder/steps/Initialize.vennDiagram';
import SegmentsVennDiagram from './builder/steps/segments.vennDiagram';
import StepperBuilderVennDiagram from './builder/step.vennDiagram';
import { capitalizeFirstLetterOfEachWord } from '@/utils';

function VennDiagramBuilder(): ReactElement {
  const { t } = useTranslation();
  const data = useLoaderData() as { data: VennDiagramConfig } | undefined;
  const navigate = useNavigate();
  const dataService = new VennDiagramServices();
  const [saveDisable, setSaveDisable] = useState<boolean>(true);
  const [queryBuild, setQueryBuild] = useState<VennDiagramConfig>({
    _id: '',
    label: 'New Venn Diagram',
    objectType: undefined,
    segments: []
  });

  const save = () => {
    if (!data) {
      let queryBuildWithoutId = omit(queryBuild, ['_id']);
      queryBuildWithoutId = { ...queryBuildWithoutId, label: capitalizeFirstLetterOfEachWord(queryBuild.label) };
      // Add a check to see if it already exists
      dataService.post?.(queryBuildWithoutId);
    } else {
      dataService.put?.(data.data._id, { ...queryBuild, label: capitalizeFirstLetterOfEachWord(queryBuild.label) });
    }

    // TODO: put absolute path with config.dna
    setTimeout(() => {
    // Had to setTimeout to retrieve correct value from back once navigate done, otherwise it will fetch the same data as before
      navigate('../../');
    }, 2);
  };

  useEffect(() => {
    let disabled = false;

    if (!queryBuild.label ||
      !queryBuild.objectType ||
      !queryBuild.segments ||
      queryBuild.segments.length === 0) {
      setSaveDisable(true);
      disabled = true;
    }
    queryBuild.segments.forEach((segment) => {
      if (!segment.behavior ||
        !segment.label ||
        !segment.color) {
        setSaveDisable(true);
        disabled = true;
      }
    });

    !disabled && setSaveDisable(false);
  }, [queryBuild]);

  useEffect(() => {
    if (data) {
      setQueryBuild(data.data);
    }
  }, [data]);

  return (
    <Stack spacing={6} pt={4}>
      <Stack spacing={4} direction='row' alignItems='center' justifyContent='space-between'>
        <Stack spacing={4} direction='row' alignItems='center'>
          <TextBuilderVennDiagram
            variant='h2'
            data={queryBuild}
            path='label'
            setData={setQueryBuild}
            icon={<IconChartInfographic />}
          />
        </Stack>
        <Button
          variant='contained'
          startIcon={<IconDeviceFloppy size='1.6rem' />}
          onClick={save}
          style= {{
            position: 'absolute',
            right: 35,
            bottom: 35,
            zIndex: 1
          }}
          disabled={saveDisable}
        >
          {t('common.utils.save')}
        </Button>
      </Stack>

      <StepperBuilderVennDiagram
        data={queryBuild}
        path=''
        isReady={[]}
        setData={setQueryBuild}
        ownerId='admin'
        steps={[
          <InitializeVennDiagram
            key={t('segmentation.builder.steps.initialize.label')}
            data={queryBuild}
            path='label'
            ownerId='admin'
            setData={setQueryBuild}
          />,
          <SegmentsVennDiagram
            key='Segment'
            data={queryBuild}
            path='segment'
            ownerId='admin'
            setData={setQueryBuild}
          />
        ]}
      />
    </Stack>
  );
}

export default VennDiagramBuilder;
