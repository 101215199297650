import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { EntityLinksAggregation } from '@deecision/dna-interfaces';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { IconCirclesRelation, IconLink, IconLinkOff } from '@tabler/icons-react';
import Tooltip from '@mui/material/Tooltip';
import IconBuildings from '@/assets/custom/IconBuildings';
import { getEntityPath } from '@/main/providers/getter';
import { TreeLinksLayerProps, TreeLinksProps } from './types';
// eslint-disable-next-line import/no-cycle
import TreeLinksLayer from './links';
import TreeEntityCard from './card';
import TreeLinksCompanies from './companies';

function TreeLink(props: Pick<TreeLinksLayerProps, 'entity' | 'filters' | 'sortList' | 'parentEntities' | 'id'> & { link: EntityLinksAggregation, subLayerOpen?: TreeLinksProps['entity'], setSubLayerOpen: (subLayer?: TreeLinksProps['entity']) => void, index: number, personsToDisplay?: 'all' | 'shareholders' | 'shareholdersFromCompany' }): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const scrollToRef = useRef<HTMLButtonElement>(null);
  const [sharesPercentage, setSharesPercentage] = useState<number | undefined>(undefined);
  const [roles, setRoles] = useState<string[]>([]);
  const [inactiveRoles, setInactiveRoles] = useState<string[]>([]);
  const [dispCompaniesInCommon, setDispCompaniesInCommon] = useState(false);
  const [dispShareHolders, setDispShareHolders] = useState(false);

  useEffect(() => {
    const shares = props.link.links.find(link => link.type === 'has.investedCompany' || link.type === 'has.shareholder')?.details?.sharesPercentage;
    const rolesTmp: typeof roles = [];
    const inactiveRolesTmp: typeof inactiveRoles = [];

    props.link.links.forEach((link) => {
      link.details?.positionTypes?.forEach((positionType) => {
        if (!rolesTmp.includes(positionType) && positionType !== '') {
          if (link.active) {
            rolesTmp.push(positionType);
          } else {
            inactiveRolesTmp.push(positionType);
          }
        }
      });
    });

    if (shares !== undefined) {
      setSharesPercentage(shares);
    }
    setRoles(rolesTmp);
    setInactiveRoles(inactiveRolesTmp);
  }, [props.link]);

  useEffect(() => {
    if (scrollToRef.current && props.index === 0) {
      scrollToRef.current.scrollIntoView({ block: 'center' });
    }
  }, [scrollToRef]);

  return (
    <Stack direction='row' alignItems='center' width='min-content'>
      {(props.personsToDisplay !== 'shareholders' && props.personsToDisplay !== 'shareholdersFromCompany') && <Box sx={{ bgcolor: theme.palette.grey['500'], width: 56, height: 2, borderRadius: 2 }}/>}
      {roles.length > 0 &&
        <Paper sx={{ bgcolor: 'primary.light', color: 'primary.dark', pt: 1, pb: 1, ml: (props.personsToDisplay === 'shareholdersFromCompany' || props.personsToDisplay === 'shareholders') ? '-52px' : '-36px', width: roles.length > 3 ? 64 : 36, zIndex: 50 }}>
          <Grid container direction='column' spacing={0} maxHeight='108px'>
            {roles.map(role => (
              <Grid key={role}>
                <Typography variant='body2'>
                  {role}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Paper>
      }
      {inactiveRoles.length > 0 &&
        <>
          {roles.length > 0 && <Box sx={{ bgcolor: theme.palette.grey['500'], width: 42, height: 2, borderRadius: 2 }}/>}
          <Paper sx={{ bgcolor: 'error.light', color: 'error.dark', pt: 1, pb: 1, ml: (props.personsToDisplay === 'shareholdersFromCompany' || props.personsToDisplay === 'shareholders') ? '-52px' : '-36px', width: inactiveRoles.length > 3 ? 64 : 36, zIndex: 50 }}>
            <Grid container direction='column' spacing={0} maxHeight='108px'>
              {inactiveRoles.map(role => (
                <Grid key={role}>
                  <Typography variant='body2' sx={{ textDecorationLine: 'line-through', textDecorationColor: theme.palette.error.dark }}>
                    {role}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </>
      }
      {!(roles.length > 0 && inactiveRoles.length > 0) && props.personsToDisplay !== 'shareholders' && props.personsToDisplay !== 'shareholdersFromCompany' &&
        <Box sx={{ bgcolor: theme.palette.grey['500'], width: 42, height: 2, borderRadius: 2 }}/>
      }
      {(props.personsToDisplay !== 'shareholders' && props.personsToDisplay !== 'shareholdersFromCompany') ?
        <Box sx={{ bgcolor: theme.palette.grey['500'], width: 64, height: 2, borderRadius: 2 }}/> :
        <Box sx={{ bgcolor: theme.palette.grey['500'], width: props.personsToDisplay === 'shareholdersFromCompany' ? 112 : (props.personsToDisplay === 'shareholders' && !props.link.links.find(link => link.type === 'has.networkPerson')?.details?.nbCompaniesInCommon) ? 188 : 172, height: 2, borderRadius: 2, ml: props.personsToDisplay === 'shareholdersFromCompany' ? roles.length > 0 || inactiveRoles.length > 0 ? '-96px' : '-112px' : '-172px' }}/>
      }
      <Stack height='108px' ml={props.personsToDisplay === 'shareholders' ? '-664px !important' : props.personsToDisplay === 'shareholdersFromCompany' ? '-612px !important' : undefined}>
        <Link href={getEntityPath(props.link.entity2)} sx={{ textDecoration: 'none !important' }}>
          <TreeEntityCard entity={props.link.entity2} roles={roles} />
        </Link>
        {(roles.includes('sha') || roles.includes('inv')) &&
          <Tooltip title={(props.personsToDisplay === 'shareholdersFromCompany' || props.personsToDisplay === 'shareholders') || sharesPercentage === 100 || props.parentEntities.length > 1 || props.entity.entityType === 'deecCompany' ? t('filters.shares') : `${t('relations.shareholders.display')}`} placement='top' arrow>
            <Paper
              variant='hoverElevation3'
              onClick={() => {
                if (props.personsToDisplay !== 'shareholdersFromCompany' && props.personsToDisplay !== 'shareholders' && sharesPercentage !== 100 && props.parentEntities.length < 2 && props.entity.entityType === 'deecPerson') {
                  if (!dispShareHolders) {
                    props.setSubLayerOpen(props.link.entity2);
                    setDispShareHolders(true);
                  } else {
                    props.setSubLayerOpen(undefined);
                    setDispShareHolders(false);
                  }
                }
              }}
              sx={{
                boxShadow: 2,
                cursor: props.personsToDisplay !== 'shareholdersFromCompany' && props.personsToDisplay !== 'shareholders' && sharesPercentage !== 100 && props.entity.entityType === 'deecPerson' ? 'pointer' : undefined,
                bgcolor: sharesPercentage && sharesPercentage > 0 ? 'primary.main' : 'grey.600',
                color: 'primary.contrastText',
                mt: '-66px',
                ml: (props.personsToDisplay === 'shareholdersFromCompany' || props.personsToDisplay === 'shareholders') ? '490px' : sharesPercentage === 100 ? '-36px' : sharesPercentage === undefined ? '-12px' :  '-28px',
                pt: 1,
                pb: 1
              }}
            >
              <Typography variant='body2' fontWeight={500}>
                {sharesPercentage === undefined ? t('common.utils.unknown') : `${sharesPercentage}%`}
              </Typography>
            </Paper>
          </Tooltip>
        }
        <Stack direction='row' alignItems='start' sx={{ ml: '480px', mt: (roles.includes('sha') || roles.includes('inv')) ? '-36px' : '-74px', zIndex: 99 }}>
          {props.personsToDisplay !== 'shareholdersFromCompany' &&
            <Tooltip title={t('relations.label')} placement='top' arrow>
              <Button
                ref={scrollToRef}
                sx={{
                  mt: '4px',
                  ml: (props.personsToDisplay === 'shareholders') ? '-494px' : '6px',
                  pt: '3px'
                }}
                variant='containedIcon'
                size='small'
                onClick={() => {
                  if (props.subLayerOpen?.entityId === props.link.entity2.entityId) {
                    if (dispCompaniesInCommon) {
                      setDispCompaniesInCommon(false);
                    } else if (dispShareHolders) {
                      setDispShareHolders(false);
                    } else {
                      props.setSubLayerOpen(undefined);
                    }
                  } else {
                    props.setSubLayerOpen(props.link.entity2);
                  }
                }}
              >
                {props.subLayerOpen?.entityId === props.link.entity2.entityId && !dispCompaniesInCommon && !dispShareHolders ? <IconLink size='1rem' /> : <IconLinkOff size='1rem' />}
              </Button>
            </Tooltip>
          }
          {props.personsToDisplay === 'shareholders' && props.link.links.find(link => link.type === 'has.networkPerson')?.details?.nbCompaniesInCommon &&
            <Tooltip title={`${props.link.links.find(link => link.type === 'has.networkPerson')?.details?.nbCompaniesInCommon || 0} ${t('relations.inCommon.companies')}`} placement='top' arrow>
              <Button
                sx={{
                  width: 'max-content',
                  mt: 1,
                  pr: 4,
                  pl: 3,
                  ml: '472px'
                }}
                variant='containedIcon'
                size='small'
                onClick={() => {
                  if (props.subLayerOpen?.entityId === props.link.entity2.entityId) {
                    if (dispCompaniesInCommon) {
                      props.setSubLayerOpen(undefined);
                      setDispCompaniesInCommon(false);
                    } else {
                      setDispCompaniesInCommon(true);
                    }
                  } else {
                    props.setSubLayerOpen(props.link.entity2);
                    setDispCompaniesInCommon(true);
                  }
                }}
              >
                <Stack direction='row' spacing={2}>
                  <IconCirclesRelation size='1.1rem' />
                  <IconBuildings size='1.1rem' />
                  <Typography>
                    {props.link.links.find(link => link.type === 'has.networkPerson')?.details?.nbCompaniesInCommon}
                  </Typography>
                </Stack>
              </Button>
            </Tooltip>
          }
          {props.subLayerOpen?.entityId === props.link.entity2.entityId && !dispCompaniesInCommon && !dispShareHolders &&
            <>
              {props.personsToDisplay === 'shareholders' &&
                <Box sx={{ bgcolor: theme.palette.grey['500'], width: 180 - 12, height: 2, ml: props.link.links.find(link => link.type === 'has.networkPerson')?.details?.nbCompaniesInCommon ? '-62px' : '648px', mt: props.link.links.find(link => link.type === 'has.networkPerson')?.details?.nbCompaniesInCommon ? '18px' : '22px' }} />
              }
              <Box sx={{ mt: props.personsToDisplay === 'shareholders' && !props.link.links.find(link => link.type === 'has.networkPerson')?.details?.nbCompaniesInCommon ? '4px' : undefined }}>
                <TreeLinksLayer
                  key={`${props.subLayerOpen.entityId}-${props.subLayerOpen.entityType}`}
                  entity={props.link.entity2}
                  id={props.link.entity2.entityId}
                  filters={props.filters}
                  sortList={props.sortList}
                  parentEntities={[...props.parentEntities, props.link.entity2.entityId]}
                  isSubLayer
                />
              </Box>
            </>
          }
          {props.subLayerOpen?.entityId === props.link.entity2.entityId && dispCompaniesInCommon && !dispShareHolders &&
            <>
              {(props.personsToDisplay === 'shareholders' || props.personsToDisplay === 'shareholdersFromCompany') &&
                <Box sx={{ bgcolor: theme.palette.grey['500'], width: 180 - 12, height: 2, ml: '-62px', mt: '19px' }} />
              }
              <Box ml='-6px' mt='1px'>
                <TreeLinksCompanies
                  key={`${props.subLayerOpen.entityId}-${props.subLayerOpen.entityType}`}
                  {...props}
                  companies={props.link.links.find(link => link.type === 'has.networkPerson')?.details?.companies || []}
                  parentEntities={[...props.parentEntities, props.link.entity2.entityId]}
                  entity2={props.link.entity2}
                />
              </Box>
            </>
          }
          {props.subLayerOpen?.entityId === props.link.entity2.entityId && !dispCompaniesInCommon && dispShareHolders &&
            <>
              <Box sx={{ bgcolor: theme.palette.grey['500'], width: 50, height: 2, ml: '-722px', mt: '22px !important', zIndex: 0 }} />
              <Box ml='-6px'>
                <TreeLinksLayer
                  key={`${props.subLayerOpen.entityId}-${props.subLayerOpen.entityType}`}
                  entity={props.link.entity2}
                  id={props.link.entity2.entityId}
                  filters={props.filters}
                  sortList={props.sortList}
                  parentEntities={[...props.parentEntities, props.link.entity2.entityId]}
                  isSubLayer
                  personsToDisplay='shareholdersFromCompany'
                />
              </Box>
            </>
          }
        </Stack>
      </Stack>
    </Stack>
  );
}

export default TreeLink;
