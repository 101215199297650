import React, { ReactElement, useEffect, useState } from 'react';
import { IDataElement } from '@deecision/dna-interfaces';
import { IconBrandDatabricks } from '@tabler/icons-react';
import { upperFirst } from 'lodash';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import TitleComponent from '@/components/title';
import KeyValueList, { KeyValue } from '@/components/keyvalue/keyvaluelist';
import HeaderComponents from '../../../../components/header';
import { DataElementsQueryService } from '@/api/services/query';
import DependenciesHeaderCard from './components/dataElement.components.DependenciesHeaderCard';
import { WEBAPP_NAME } from '@/env/env';
import ActionPanel from '../Dashboard/components/actionPanel';

type Dependencies = {
  type: string,
  entityRef: {
      entityType: string,
      entityId: string,
      name?: string | undefined
  },
  available: boolean,
  dataElementIds: string[],
  lastModified: string,
  featureId: string,
  needAvailableRecalc: boolean
}

type DataElementsWithId = IDataElement & { _id: string };

function DataElementDetailsHeader(): ReactElement {
  const dataElementId = window.location.pathname.split('/')[4];
  const localStorageParams = localStorage.getItem(`entityType${dataElementId}`);
  const entityType = (localStorageParams != null ?  localStorageParams : '');

  const dataElementServices = new DataElementsQueryService({ entityType });
  const [dataElement, setDataElement] = useState<IDataElement>();
  const [identity, setIdentity] = useState<KeyValue[]>([]);
  const [scheduling, setScheduling] = useState<KeyValue[]>([]);
  const [dataInfo, setDataInfo] = useState<KeyValue[]>([]);
  const [dependenciesId, setDependenciesId] = useState<Dependencies[]>([]);
  const [chips, setChips] = useState<ReactElement[]>([]);

  useEffect(() => {
    dataElementServices.findInAllEntities({ _id: dataElementId })
      .then((res) => {
        if (res.data) {
          const elementsWithId = res.data as DataElementsWithId[];

          setDataElement(res.data[0]);
          setIdentity([{ key: 'DataElement id', value: elementsWithId[0]._id },
            { key: 'Type', value: elementsWithId[0].type },
            { key: 'EntityName', value: elementsWithId[0].entityRef.name, redirectTo: `/${WEBAPP_NAME}/entities/${elementsWithId[0].entityRef.entityId as string}/summary` },
            { key: 'EntityType', value: elementsWithId[0].entityRef.entityType },
            { key: 'EntityId', value: elementsWithId[0].entityRef.entityId, redirectTo: `/${WEBAPP_NAME}/entities/${elementsWithId[0].entityRef.entityId as string}/summary` },
            { key: 'Provider', value: elementsWithId[0].elementInfo.provider.label }]);
          setScheduling([{ key: 'status', value: elementsWithId[0].scheduling.status },
            { key: 'nbDependents', value: elementsWithId[0].dependencies.length },
            { key: 'priority', value: elementsWithId[0].scheduling.priority },
            { key: 'effectivePriority', value: elementsWithId[0].scheduling.effectivePriority },
            { key: 'partialDependencies', value: elementsWithId[0].scheduling.partialDependencies.toString() },
            { key: 'partialSets', value: elementsWithId[0].scheduling.partialSets.toString() }]);
          setDataInfo([ { key: 'status', value: elementsWithId[0].dataInfo.status },
            { key: 'lastUpdate', value: elementsWithId[0].dataInfo.lastUpdate }]);
          setDependenciesId(elementsWithId[0].dependencies);
          setChips([
            <Chip key='state'
              label={upperFirst(elementsWithId[0].scheduling.status)}
              color={elementsWithId[0].scheduling.status === 'done' ? 'success' : 'error'}
            />
          ]);
        }
      });
  }, []);

  return (
    <Grid container spacing={2} alignItems='center'>
      <Stack flexDirection='row'>
        {/* See placement here where to place the action Panel */}
        <TitleComponent title={(dataElement?.entityRef.name ? `${dataElement?.entityRef.entityType} - ${dataElement?.entityRef.name}`  : 'title')} icon={IconBrandDatabricks} chips={chips} />
        <ActionPanel />
      </Stack>
      <Grid size={12}>
        <HeaderComponents
          height={180}
          selects={[]}
          keys={[
            'Identity',
            'Scheduling',
            'DataInfo',
            'Dependencie'
          ]}
          links={[
            '', '', '', ''
          ]}
        >
          {[
            <KeyValueList key='Identity' values={[identity]}  />,
            <KeyValueList key='Scheduling' values={[scheduling]} />,
            <KeyValueList key='DataInfo' values={[dataInfo]} />,
            <DependenciesHeaderCard dependencies={dependenciesId}/>
          ]}
        </HeaderComponents>
      </Grid>
    </Grid>
  );
}

export default DataElementDetailsHeader;
