import {
  AnyEntity,
  CompanyEntity,
  CompanyInfo, Completeness,
  ENTITY_LINK_TYPE_HAS_INVESTED_COMPANY,
  ENTITY_LINK_TYPE_HAS_NETWORK_PERSON,
  ENTITY_LINK_TYPE_HAS_REPRESENTED_COMPANY,
  ENTITY_LINK_TYPE_IS_RELATED_TO,
  EntityLink,
  EntityLinksAggregation,
  EntityRef,
  EntityTag,
  IDataBlock,
  PersonEntity, PoliticalExposureLevel, SegmentationCompaniesAggregationStats, SegmentationPersonsAggregationStats
} from '@deecision/dna-interfaces';
import { DefaultTReturn, TFunction } from 'i18next';
import { startCase, upperFirst } from 'lodash';
import {
  IconArrowBackUp,
  IconChartHistogram,
  IconChartLine,
  IconCoins,
  IconMoneybag,
  IconUsers
} from '@tabler/icons-react';
import { Theme } from '@mui/material';
import { TOptions } from 'i18next/typescript/options';
import { currencyFormatter, getAge, sirenParser } from '@/utils';
import ServiceError from '@/api/services/error';
import BaseDnaEntitiesService from '../modules/entities/services/entities';
import { getApeLabel, getRncsLabel } from '@/config/getter';
import { EntityLinksQueryAggregatorService } from '@/api/services/query';
import { KeyValue } from '@/components/keyvalue/keyvaluelist';

interface GetterProps<T> {
  data: T,
  errorMsg?: DefaultTReturn<TOptions>,
  t?: TFunction<'translation', undefined>
}

type AggregationGetterProps<T> = GetterProps<T> & Required<Pick<GetterProps<T>, 't'>> & {
  currency?: string,
  theme: Theme
}

export interface AugmentedEntity<T = AnyEntity> {
  entity: T,
  details: (EntityLink['details'])[],
  linkTypes: string[]
}

export function getEntity<T>(entityRef: Omit<EntityRef, 'name'>): Promise<T> {
  const entityService = new BaseDnaEntitiesService<T>({ entityType: entityRef.entityType });

  return new Promise<T>((resolve, reject) => {
    entityService.get(entityRef.entityId)
      .then(res => (res.data ? resolve(res.data) : reject(new ServiceError(204, 'no data'))))
      .catch(err => reject(new ServiceError(err.status, 'server error', err.message)));
  });
}

export function getEntityTypeI18nKey(entityType?: string): string {
  return {
    person: 'entities.person.label',
    company: 'entities.company.label',
    persons: 'entities.person.multiple',
    companies: 'entities.company.multiple',
    deecPersons: 'entities.person.multiple',
    deecCompanies: 'entities.company.multiple',
    deecPerson: 'entities.person.label',
    deecCompany: 'entities.company.label',
    link: 'entities.network.label',
    links: 'entities.network.multiple',
    relation: 'entities.network.label',
    relations: 'entities.network.multiple',
    p2pNetworkLink: 'entities.network.label',
    p2pNetworkLinks: 'entities.network.multiple',
    person2person: 'entities.network.multiple',
    company2company: 'entities.network.multiple'
  }[entityType || ''] || entityType as string;
}

export function getEntityTypeColor(entityType?: string): string {
  return ({
    person: 'primary' as const,
    company: 'secondary' as const,
    persons: 'primary' as const,
    companies: 'secondary' as const,
    deecPersons: 'primary' as const,
    deecCompanies: 'secondary' as const,
    deecPerson: 'primary' as const,
    deecCompany: 'secondary' as const,
    link: 'warning' as const,
    links: 'warning' as const,
    relation: 'warning' as const,
    relations: 'warning' as const,
    person2person: 'warning' as const,
    company2company: 'warning' as const
  }[entityType || ''] || 'default' as const);
}

export function getEntityTypeEquivalent(entityType?: string): string {
  return {
    person: 'deecPerson',
    company: 'deecCompany',
    persons: 'deecPerson',
    companies: 'deecCompany',
    deecPersons: 'deecPerson',
    deecCompanies: 'deecCompany',
    deecPerson: 'deecPersons',
    deecCompany: 'deecCompanies'
  }[entityType || ''] || entityType as string;
}

export function getRelations<T = AnyEntity>(entityRef: Omit<EntityRef, 'name'>, targetType: 'deecPerson' | 'deecCompany'): Promise<AugmentedEntity<T>[]> {
  const linksService = new EntityLinksQueryAggregatorService<EntityLinksAggregation>({
    entityType: entityRef.entityType,
    toEntityType: targetType
  });

  return new Promise((resolve) => {
    linksService.find(
      entityRef,
      entityRef.entityType === targetType && targetType === 'deecPerson' ?
        ENTITY_LINK_TYPE_HAS_NETWORK_PERSON :
        { '$in': [ENTITY_LINK_TYPE_HAS_INVESTED_COMPANY, ENTITY_LINK_TYPE_HAS_REPRESENTED_COMPANY, ENTITY_LINK_TYPE_IS_RELATED_TO] },
      false,
      true
    )
      .then((res) => {
        const augmentedEntities: AugmentedEntity<T>[] = [];

        if (res.data) {
          res.data.forEach((data) => {
            const entity = data.entity2Object?.[0] as T || data.entity1Object?.[0] as T;

            if (entity) {
              augmentedEntities.push({
                entity,
                details: data.links.map(link => link.details),
                linkTypes: data.links.map(link => link.type)
              });
            }
          });
        }
        resolve(augmentedEntities);
      });
  });
}

export function getEntityPath(entityRef: Pick<EntityRef, 'entityId' | 'entityType'>, withoutPrefix?: boolean): string {
  const routeMatcher = {
    deecPerson: 'dna/persons',
    deecCompany: 'dna/companies'
  };

  const routeMatcherWithoutPrefix = {
    deecPerson: 'persons',
    deecCompany: 'companies'
  };

  return `/${withoutPrefix ? routeMatcherWithoutPrefix[entityRef.entityType as keyof typeof routeMatcherWithoutPrefix] : routeMatcher[entityRef.entityType as keyof typeof routeMatcher]}/${entityRef.entityId}`;
}

export function makeRes(res?: string | number | boolean | undefined | null, errorMsg?: GetterProps<undefined>['errorMsg']): string {
  if (typeof res === 'number' && Number.isNaN(res)) {
    return makeRes();
  }

  return res?.toString() || errorMsg || '-';
}

class EntityGetter<T> {
  getterProps: GetterProps<T>;

  constructor(getterProps: GetterProps<T>) {
    this.getterProps = getterProps;
  }

  makeNumRes(res?: string | number): number | string {
    if (typeof res === 'number' && Number.isNaN(res)) {
      return this.makeRes();
    }

    return res !== undefined ? res : this.getterProps.errorMsg || '-';
  }

  makeRes(res?: string | number): string {
    return makeRes(res, this.getterProps.errorMsg);
  }
}

export class CompanyGetter extends EntityGetter<CompanyEntity> {
  getDataDate(): string {
    return this.makeRes(this.getterProps.data.dataViews?.lastUpdated ? new Date(this.getterProps.data.dataViews.lastUpdated).toLocaleString() : undefined);
  }

  getName(): string {
    return this.makeRes(this.getterProps.data.dataViews?.data?.company?.identity?.companyName);
  }

  getCreationDate(): string {
    return this.makeRes(this.getterProps.data.dataViews?.data?.company?.identity?.creationDate ? new Date(this.getterProps.data.dataViews.data.company.identity.creationDate).toLocaleDateString() : undefined);
  }

  getAddress(): string {
    return this.makeRes(this.getterProps.data.dataViews?.data?.company?.address?.address);
  }

  getCity(): string {
    return this.makeRes(this.getterProps.data.dataViews?.data?.company?.address?.city);
  }

  getCityZipCode(): string {
    return this.makeRes(this.getterProps.data.dataViews?.data?.company?.address?.zipCode);
  }

  getCountry(): string {
    return this.makeRes(this.getterProps.data.dataViews?.data?.company?.address?.country ? startCase(this.getterProps.data.dataViews.data.company.address.country.toLowerCase()) : undefined);
  }

  getCountryCode(raw?: true): string | undefined {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.company?.address?.countryCode;
    }

    return this.makeRes(this.getterProps.data.dataViews?.data?.company?.address?.countryCode);
  }

  getSiren(): string {
    return this.makeRes(sirenParser(this.getterProps.data.attributes?.knownIds?.find(id => id.includes('siren')))?.id);
  }

  getActivitySector(raw?: true): string {
    if (raw) {
      return this.makeRes(this.getterProps.data.dataViews?.data?.company?.identity?.codeApe);
    }

    return this.makeRes(`${getApeLabel(this.getterProps.data.dataViews?.data?.company?.identity?.codeApe)} (${this.getterProps.data.dataViews?.data?.company?.identity?.codeApe})`);
  }

  getLegalForm(raw?: true): string {
    if (raw) {
      return this.makeRes(this.getterProps.data.dataViews?.data?.company?.identity?.legalStatus.code);
    }

    return this.makeRes(getRncsLabel(this.getterProps.data.dataViews?.data?.company?.identity?.legalStatus.code));
  }

  getState(): string {
    return this.makeRes(this.getterProps.data.dataViews?.data?.company?.state?.state);
  }

  getVeegilenzLevel(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.company?.veegilenz?.summary?.levelAsInt ?? 0;
    }

    return this.makeRes(this.getterProps.data.dataViews?.data?.company?.veegilenz?.summary?.level);
  }

  getVeegilenzReason(): string {
    return this.makeRes(this.getterProps.data.dataViews?.data?.company?.veegilenz?.summary?.reasonCode);
  }

  getCapital(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.company?.keyFigures?.capital?.value || 0;
    }

    return this.makeRes(currencyFormatter(this.getterProps.data.dataViews?.data?.company?.keyFigures?.capital));
  }

  getCapitalCompleteness(raw?: true): string | number {
    if (raw) {
      return 0;
    }

    return this.makeNumRes(parseFloat(this.getterProps.data.dataViews?.data?.company?.keyFigures?.capital?.completeness?.toString() || 'undefined') || undefined);
  }

  getCapitalDate(raw?: true): Date | undefined {
    if (raw) {
      return new Date(this.getterProps.data.dataViews?.data?.company?.keyFigures?.capital?.date || '');
    }

    return this.getterProps.data.dataViews?.data?.company?.keyFigures?.capital?.date ? new Date(this.getterProps.data.dataViews?.data?.company?.keyFigures?.capital?.date?.toString() || '') : undefined;
  }

  getTurnover(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.company?.keyFigures?.turnover?.value || 0;
    }

    return this.makeRes(currencyFormatter(this.getterProps.data.dataViews?.data?.company?.keyFigures?.turnover));
  }

  getTurnoverCompleteness(raw?: true): string | number {
    if (raw) {
      return 0;
    }

    return this.makeNumRes(parseFloat(this.getterProps.data.dataViews?.data?.company?.keyFigures?.turnover?.completeness?.toString() || 'undefined') || undefined);
  }

  getTurnoverDate(raw?: true): Date | undefined {
    if (raw) {
      return new Date(this.getterProps.data.dataViews?.data?.company?.keyFigures?.turnover?.date || '');
    }

    return this.getterProps.data.dataViews?.data?.company?.keyFigures?.turnover?.date ? new Date(this.getterProps.data.dataViews?.data?.company?.keyFigures?.turnover?.date?.toString() || '') : undefined;
  }

  getEbitda(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.company?.keyFigures?.ebitda?.value || 0;
    }

    return this.makeRes(currencyFormatter(this.getterProps.data.dataViews?.data?.company?.keyFigures?.ebitda));
  }

  getEbitdaCompleteness(raw?: true): string | number {
    if (raw) {
      return 0;
    }

    return this.makeNumRes(parseFloat(this.getterProps.data.dataViews?.data?.company?.keyFigures?.ebitda?.completeness?.toString() || 'undefined') || undefined);
  }

  getEbitdaDate(raw?: true): Date | undefined {
    if (raw) {
      return new Date(this.getterProps.data.dataViews?.data?.company?.keyFigures?.ebitda?.date || '');
    }

    return this.getterProps.data.dataViews?.data?.company?.keyFigures?.ebitda?.date ? new Date(this.getterProps.data.dataViews?.data?.company?.keyFigures?.ebitda?.date?.toString() || '') : undefined;
  }

  getValuation(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.company?.valuation?.value || 0;
    }

    return this.makeRes(currencyFormatter(this.getterProps.data.dataViews?.data?.company?.valuation));
  }

  getValuationCompleteness(raw?: true): string | number {
    if (raw) {
      return 0;
    }

    return this.makeNumRes(parseFloat(this.getterProps.data.dataViews?.data?.company?.valuation?.completeness?.toString() || 'undefined') || undefined);
  }

  getValuationAccuracy(raw?: true): string | number {
    if (raw) {
      return 0;
    }

    return this.makeNumRes(parseFloat(this.getterProps.data.dataViews?.data?.company?.valuation?.accuracy?.toString() || 'undefined') || undefined);
  }

  getValuationDate(raw?: true): Date | undefined {
    if (raw) {
      return new Date(this.getterProps.data.dataViews?.data?.company?.valuation?.date || '');
    }

    return this.getterProps.data.dataViews?.data?.company?.valuation?.date ? new Date(this.getterProps.data.dataViews?.data?.company?.valuation?.date?.toString() || '') : undefined;
  }

  getIsDismemberment(): boolean | undefined {
    return this.getterProps.data.dataViews?.data?.assetComplexity?.summary?.dismemberment?.isDismemberment || false;
  }

  getNbMembers(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.assetComplexity?.summary?.dismemberment?.nbMembers || 0;
    }

    return this.makeRes(this.getterProps.data.dataViews?.data?.assetComplexity?.summary?.dismemberment?.nbMembers || 0);
  }

  getTags(family?: string): EntityTag[] {
    if (family) {
      return this.getterProps.data.tags?.filter(tag => tag.family === family) || [];
    }

    return this.getterProps.data.tags || [];
  }

  getAllCompaniesInfo(): CompanyInfo[] {
    return [this.getterProps.data].map(data => ({
      entityRef: {
        entityType: data.entityType,
        entityId: data.entityId,
        name: data.name
      },
      activeRoles: [],
      activePositionTypes: [],
      inactivePositionTypes: [],
      inactiveRoles: []
    }));
  }

  getSanctions(): string[] {
    return this.getterProps.data.dataViews?.data?.company?.sanctions?.summary?.sanctionTypes || [];
  }

  getPoliticalExposureLevels(): PoliticalExposureLevel[] {
    return this.getterProps.data.dataViews?.data?.company?.pep?.summary?.exposureLevels || [];
  }

  getHighestPoliticalExposureLevel(): PoliticalExposureLevel | undefined {
    const highest = this.getterProps.data.dataViews?.data?.company?.pep?.summary?.highestExposureLevel;

    return highest !== PoliticalExposureLevel.NONE ? highest : undefined;
  }

  getCompanyCompleteness(): number {
    return this.getterProps.data.dataViews?.data?.completeness?.value || 0;
  }
}

export class PersonGetter extends EntityGetter<PersonEntity> {

  getName(): string {
    return this.makeRes(upperFirst(this.getterProps.data.name));
  }

  getFirstName(): string {
    return this.makeRes(upperFirst(this.getterProps.data.attributes?.names.firstName));
  }

  getLastName(): string {
    return this.makeRes(upperFirst(this.getterProps.data.attributes?.names.lastName));
  }

  getFullName(): string {
    return this.makeRes(`${upperFirst(this.getterProps.data.attributes?.names.firstName)} ${upperFirst(this.getterProps.data.attributes?.names.lastName)}`);
  }

  getAge(raw?: true): string | number {
    if (raw) {
      this.getterProps.data.attributes?.birthInfo.birthDate ? getAge(this.getterProps.data.attributes.birthInfo.birthDate) : 0;
    }

    return this.makeRes(this.getterProps.data.attributes?.birthInfo.birthDate ? getAge(this.getterProps.data.attributes.birthInfo.birthDate).toString() : undefined);
  }

  getBirthDate(): string {
    return this.makeRes(this.getterProps.data.attributes?.birthInfo.birthDate ?
      this.getterProps.data.attributes?.birthInfo.birthDate.match('^\\d{4}\\-(0[1-9]|1[012])\\-(0[1-9]|[12][0-9]|3[01])$') ? new Date(this.getterProps.data.attributes.birthInfo.birthDate).toLocaleDateString() : this.getterProps.data.attributes.birthInfo.birthDate :
      undefined);
  }

  getCountries(): string[] {
    const strings: string[] = [];
    if (this.getterProps.data.dataViews?.data?.person?.allCountries) {
      this.getterProps.data.dataViews.data.person.allCountries
        .filter(country => country.text)
        .map(country => country.text)
        .forEach((country) => {
          if (!country || !strings.includes(country.toUpperCase())) {
            strings.push(country || '');
          }
        });
    }

    return strings.map(s => startCase(s.toLowerCase()));
  }

  getCountriesCodes(): string[] {
    const codes: string[] = [];
    if (this.getterProps.data.dataViews?.data?.person?.allCountries) {
      this.getterProps.data.dataViews.data.person.allCountries
        .filter(country => country.code && country.code.length > 1)
        .map(country => (country.code === 'PRT' ? 'PT' : country.code === 'DNK' ? 'DK' : country.code))
        .forEach((country) => {
          if (!country || !codes.includes(country.toUpperCase())) {
            codes.push(country || '');
          }
        });
    }

    return codes;
  }

  getNationalities(): string[] {
    const strings: string[] = [];

    if (this.getterProps.data.dataViews?.data?.person?.allNationalities) {
      this.getterProps.data.dataViews.data.person.allNationalities
        .filter(nationality => nationality.text)
        .map(nationality => nationality.text)
        .forEach((nationality) => {
          if (!nationality || !strings.includes(nationality.toUpperCase())) {
            strings.push(nationality || '');
          }
        });
    }

    return strings;
  }

  getNationalitiesCodes(): string[] {
    const codes: string[] = [];

    if (this.getterProps.data.dataViews?.data?.person?.allNationalities) {
      this.getterProps.data.dataViews.data.person.allNationalities
        .filter(nationality => nationality.code && nationality.code.length > 1)
        .map(nationality => (nationality.code === 'PRT' ? 'PT' : nationality.code === 'DNK' ? 'DK' : nationality.code))
        .forEach((nationality) => {
          if (!nationality || !codes.includes(nationality.toUpperCase())) {
            codes.push(nationality || '');
          }
        });
    }

    return codes;
  }

  getDeathDate(): string {
    return this.makeRes(this.getterProps.data.dataViews?.data?.person?.deathInfo?.deathDate);
  }

  getDeathPlace(): string {
    return this.makeRes(this.getterProps.data.dataViews?.data?.person?.deathInfo?.inseeCog);
  }

  getDeathFileId(): string {
    return this.makeRes(this.getterProps.data.dataViews?.data?.person?.deathInfo?.officialDeceasedFileId);
  }

  getDeathMatchAccuracy(): string | number {
    return this.makeNumRes(parseFloat(this.getterProps.data.dataViews?.data?.person?.deathInfo?.matchAccuracy.toString() || 'undefined') || undefined);
  }

  getIsDead(): boolean {
    return this.getterProps.data.dataViews?.data?.person?.deathInfo?.isDead || false;
  }

  getDataDate(): string {
    return this.makeRes(this.getterProps.data.dataViews?.lastUpdated ? new Date(this.getterProps.data.dataViews.lastUpdated).toLocaleString() : undefined);
  }

  getBank(): string {
    return this.makeRes(this.getterProps.data.dataViews?.data?.bankInfo?.banque || undefined);
  }

  getVeegilenzLevel(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.person?.veegilenz?.summary?.levelAsInt ?? 0;
    }

    return this.makeRes(this.getterProps.data.dataViews?.data?.person?.veegilenz?.summary?.level);
  }

  getVeegilenzReason(): string {
    return this.makeRes(this.getterProps.data.dataViews?.data?.person?.veegilenz?.summary?.reasonCode);
  }

  getFortune(raw?: true): string | number {
    if (raw) {
      return 0;
    }

    return this.makeRes(currencyFormatter(null));
  }

  getWeasse(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.person?.weasse?.weasse?.value || 0;
    }

    return this.makeRes(currencyFormatter(this.getterProps.data.dataViews?.data?.person?.weasse?.weasse));
  }

  getWeasseCompleteness(raw?: true): string | number {
    if (raw) {
      return 0;
    }

    return this.makeNumRes(parseFloat(this.getterProps.data.dataViews?.data?.person?.weasse?.weasse?.completeness?.toString() || 'undefined') || undefined);
  }

  getWeasseDate(raw?: true): Date | undefined {
    if (raw) {
      return new Date(this.getterProps.data.dataViews?.data?.person?.weasse?.weasse?.date || '');
    }

    return this.getterProps.data.dataViews?.data?.person?.weasse?.weasse?.date ? new Date(this.getterProps.data.dataViews?.data?.person?.weasse?.weasse?.date?.toString() || '') : undefined;
  }

  getWeasseMax(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.person?.weasse?.weasseMax?.value || 0;
    }

    return this.makeRes(currencyFormatter(this.getterProps.data.dataViews?.data?.person?.weasse?.weasseMax));
  }

  getWeasseMaxCompleteness(raw?: true): string | number {
    if (raw) {
      return 0;
    }

    return this.makeNumRes(parseFloat(this.getterProps.data.dataViews?.data?.person?.weasse?.weasseMax?.completeness?.toString() || 'undefined') || undefined);
  }

  getWeasseMaxDate(raw?: true): Date | undefined {
    if (raw) {
      return new Date(this.getterProps.data.dataViews?.data?.person?.weasse?.weasseMax?.date || '');
    }

    return this.getterProps.data.dataViews?.data?.person?.weasse?.weasseMax?.date ? new Date(this.getterProps.data.dataViews?.data?.person?.weasse?.weasseMax?.date?.toString() || '') : undefined;
  }

  getExtrapolatedWeasse(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.person?.extrapolatedWeasse?.extrapolatedWeasse?.value || 0;
    }

    return this.makeRes(currencyFormatter(this.getterProps.data.dataViews?.data?.person?.extrapolatedWeasse?.extrapolatedWeasse));
  }

  getExtrapolatedWeasseCompleteness(raw?: true): string | number {
    if (raw) {
      return (this.getterProps.data.dataViews?.data?.person?.extrapolatedWeasse?.extrapolatedWeasse?.completeness as Completeness)?.value || 0;
    }

    return this.makeNumRes(parseFloat((this.getterProps.data.dataViews?.data?.person?.extrapolatedWeasse?.extrapolatedWeasse?.completeness as Completeness)?.value?.toString() || 'undefined') || undefined);
  }

  getExtrapolatedWeasseAccuracy(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.person?.extrapolatedWeasse?.extrapolatedWeasse?.accuracy || 0;
    }

    return this.makeNumRes(parseFloat(this.getterProps.data.dataViews?.data?.person?.extrapolatedWeasse?.extrapolatedWeasse?.accuracy?.toString() || 'undefined') || undefined);
  }

  getTurnoverSum(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.companies?.keyFiguresSums?.turnoverSum?.value || 0;
    }

    return this.makeRes(currencyFormatter(this.getterProps.data.dataViews?.data?.companies?.keyFiguresSums?.turnoverSum));
  }

  getTurnoverSumCompleteness(raw?: true): string | number {
    let completeness;

    if (this.getterProps.data.dataViews?.data?.companies?.keyFiguresSums?.turnoverSum?.knownCount && this.getterProps.data.dataViews?.data?.companies?.keyFiguresSums.nbCompanies) {
      completeness = this.getterProps.data.dataViews.data.companies.keyFiguresSums.turnoverSum.knownCount / this.getterProps.data.dataViews.data.companies.keyFiguresSums.nbCompanies;
    }
    if (raw) {
      return 0;
    }

    return this.makeNumRes(completeness);
  }

  getTurnoverSumDate(raw?: true): Date | undefined {
    if (raw) {
      return new Date(this.getterProps.data.dataViews?.data?.companies?.keyFiguresSums?.turnoverSum?.date || '');
    }

    return this.getterProps.data.dataViews?.data?.companies?.keyFiguresSums?.turnoverSum?.date ? new Date(this.getterProps.data.dataViews?.data?.companies?.keyFiguresSums?.turnoverSum?.date?.toString() || '') : undefined;
  }

  getCapitalSum(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.companies?.keyFiguresSums?.capitalSum?.value || 0;
    }

    return this.makeRes(currencyFormatter(this.getterProps.data.dataViews?.data?.companies?.keyFiguresSums?.capitalSum));
  }

  getCapitalSumCompleteness(raw?: true): string | number {
    let completeness;

    if (this.getterProps.data.dataViews?.data?.companies?.keyFiguresSums?.capitalSum?.knownCount && this.getterProps.data.dataViews?.data?.companies?.keyFiguresSums.nbCompanies) {
      completeness = this.getterProps.data.dataViews.data.companies.keyFiguresSums.capitalSum.knownCount / this.getterProps.data.dataViews.data.companies.keyFiguresSums.nbCompanies;
    }
    if (raw) {
      return 0;
    }

    return this.makeNumRes(completeness);
  }

  getCapitalSumDate(raw?: true): Date | undefined {
    if (raw) {
      return new Date(this.getterProps.data.dataViews?.data?.companies?.keyFiguresSums?.capitalSum?.date || '');
    }

    return this.getterProps.data.dataViews?.data?.companies?.keyFiguresSums?.capitalSum?.date ? new Date(this.getterProps.data.dataViews?.data?.companies?.keyFiguresSums?.capitalSum?.date?.toString() || '') : undefined;
  }

  getEbitdaSum(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.companies?.keyFiguresSums?.ebitdaSum?.value || 0;
    }

    return this.makeRes(currencyFormatter(this.getterProps.data.dataViews?.data?.companies?.keyFiguresSums?.ebitdaSum));
  }

  getEbitdaSumCompleteness(raw?: true): string | number {
    let completeness;

    if (this.getterProps.data.dataViews?.data?.companies?.keyFiguresSums?.ebitdaSum?.knownCount && this.getterProps.data.dataViews?.data?.companies?.keyFiguresSums.nbCompanies) {
      completeness = this.getterProps.data.dataViews.data.companies.keyFiguresSums.ebitdaSum.knownCount / this.getterProps.data.dataViews.data.companies.keyFiguresSums.nbCompanies;
    }
    if (raw) {
      return 0;
    }

    return this.makeNumRes(completeness);
  }

  getEbitdaSumDate(raw?: true): Date | undefined {
    if (raw) {
      return new Date(this.getterProps.data.dataViews?.data?.companies?.keyFiguresSums?.ebitdaSum?.date || '');
    }

    return this.getterProps.data.dataViews?.data?.companies?.keyFiguresSums?.ebitdaSum?.date ? new Date(this.getterProps.data.dataViews?.data?.companies?.keyFiguresSums?.ebitdaSum?.date?.toString() || '') : undefined;
  }

  getValuationSum(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.person?.weasse?.valuationSum?.value || 0;
    }

    return this.makeRes(currencyFormatter(this.getterProps.data.dataViews?.data?.person?.weasse?.valuationSum));
  }

  getValuationSumCompleteness(raw?: true): string | number {
    if (raw) {
      return 0;
    }

    return this.makeNumRes(parseFloat(this.getterProps.data.dataViews?.data?.person?.weasse?.valuationSum?.completeness?.toString() || 'undefined') || undefined);
  }

  getValuationSumDate(raw?: true): Date | undefined {
    if (raw) {
      return new Date(this.getterProps.data.dataViews?.data?.person?.weasse?.valuationSum?.date || '');
    }

    return this.getterProps.data.dataViews?.data?.person?.weasse?.valuationSum?.date ? new Date(this.getterProps.data.dataViews?.data?.person?.weasse?.valuationSum?.date?.toString() || '') : undefined;
  }

  getRelationsTurnoverSum(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.network?.keyFigures?.turnoverSums?.sum?.value || 0;
    }

    return this.makeRes(currencyFormatter(this.getterProps.data.dataViews?.data?.network?.keyFigures?.turnoverSums?.sum));
  }

  getRelationsTurnoverSumCompleteness(raw?: true): string | number {
    if (raw) {
      return 0;
    }

    return this.makeNumRes(parseFloat(this.getterProps.data.dataViews?.data?.network?.keyFigures?.turnoverSums?.sum?.completeness?.toString() || 'undefined') || undefined);
  }

  getRelationsTurnoverSumDate(raw?: true): Date | undefined {
    if (raw) {
      return new Date(this.getterProps.data.dataViews?.data?.network?.keyFigures?.turnoverSums?.sum?.date || '');
    }

    return this.getterProps.data.dataViews?.data?.network?.keyFigures?.turnoverSums?.sum?.date ? new Date(this.getterProps.data.dataViews?.data?.network?.keyFigures?.turnoverSums?.sum?.date?.toString() || '') : undefined;
  }

  getRelationsCapitalSum(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.network?.keyFigures?.capitalSums?.sum?.value || 0;
    }

    return this.makeRes(currencyFormatter(this.getterProps.data.dataViews?.data?.network?.keyFigures?.capitalSums?.sum));
  }

  getRelationsCapitalSumCompleteness(raw?: true): string | number {
    if (raw) {
      return 0;
    }

    return this.makeNumRes(parseFloat(this.getterProps.data.dataViews?.data?.network?.keyFigures?.capitalSums?.sum?.completeness?.toString() || 'undefined') || undefined);
  }

  getRelationsCapitalSumDate(raw?: true): Date | undefined {
    if (raw) {
      return new Date(this.getterProps.data.dataViews?.data?.network?.keyFigures?.capitalSums?.sum?.date || '');
    }

    return this.getterProps.data.dataViews?.data?.network?.keyFigures?.capitalSums?.sum?.date ? new Date(this.getterProps.data.dataViews?.data?.network?.keyFigures?.capitalSums?.sum?.date?.toString() || '') : undefined;
  }

  getRelationsEbitdaSumOfSum(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.network?.keyFigures?.ebitdaSums?.sum?.value || 0;
    }

    return this.makeRes(currencyFormatter(this.getterProps.data.dataViews?.data?.network?.keyFigures?.ebitdaSums?.sum));
  }

  getRelationsEbitdaSumOfSumCompleteness(raw?: true): string | number {
    if (raw) {
      return 0;
    }

    return this.makeNumRes(parseFloat(this.getterProps.data.dataViews?.data?.network?.keyFigures?.ebitdaSums?.sum?.completeness?.toString() || 'undefined') || undefined);
  }

  getRelationsEbitdaSumOfSumDate(raw?: true): Date | undefined {
    if (raw) {
      return new Date(this.getterProps.data.dataViews?.data?.network?.keyFigures?.ebitdaSums?.sum?.date || '');
    }

    return this.getterProps.data.dataViews?.data?.network?.keyFigures?.ebitdaSums?.sum?.date ? new Date(this.getterProps.data.dataViews?.data?.network?.keyFigures?.ebitdaSums?.sum?.date?.toString() || '') : undefined;
  }

  getRelationsValuationSumOfSum(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.network?.keyFigures?.valuationSums?.sum?.value || 0;
    }

    return this.makeRes(currencyFormatter(this.getterProps.data.dataViews?.data?.network?.keyFigures?.valuationSums?.sum));
  }

  getRelationsValuationSumOfSumCompleteness(raw?: true): string | number {
    if (raw) {
      return 0;
    }

    return this.makeNumRes(parseFloat(this.getterProps.data.dataViews?.data?.network?.keyFigures?.valuationSums?.sum?.completeness?.toString() || 'undefined') || undefined);
  }

  getRelationsValuationSumOfSumDate(raw?: true): Date | undefined {
    if (raw) {
      return new Date(this.getterProps.data.dataViews?.data?.network?.keyFigures?.valuationSums?.sum?.date || '');
    }

    return this.getterProps.data.dataViews?.data?.network?.keyFigures?.valuationSums?.sum?.date ? new Date(this.getterProps.data.dataViews?.data?.network?.keyFigures?.valuationSums?.sum?.date?.toString() || '') : undefined;
  }

  getRelationsWeasseSum(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.network?.keyFigures?.weasse?.sum?.value || 0;
    }

    return this.makeRes(currencyFormatter(this.getterProps.data.dataViews?.data?.network?.keyFigures?.weasse?.sum));
  }

  getRelationsWeasseSumCompleteness(raw?: true): string | number {
    if (raw) {
      return 0;
    }

    return this.makeNumRes(parseFloat(this.getterProps.data.dataViews?.data?.network?.keyFigures?.weasse?.sum?.completeness?.toString() || 'undefined') || undefined);
  }

  getRelationsWeasseSumDate(raw?: true): Date | undefined {
    if (raw) {
      return new Date(this.getterProps.data.dataViews?.data?.network?.keyFigures?.weasse?.sum?.date || '');
    }

    return this.getterProps.data.dataViews?.data?.network?.keyFigures?.weasse?.sum?.date ? new Date(this.getterProps.data.dataViews?.data?.network?.keyFigures?.weasse?.sum?.date?.toString() || '') : undefined;
  }

  getRelationsWeasseMaxSum(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.network?.keyFigures?.weasseMax?.sum?.value || 0;
    }

    return this.makeRes(currencyFormatter(this.getterProps.data.dataViews?.data?.network?.keyFigures?.weasseMax?.sum));
  }

  getRelationsWeasseMaxSumCompleteness(raw?: true): string | number {
    if (raw) {
      return 0;
    }

    return this.makeNumRes(parseFloat(this.getterProps.data.dataViews?.data?.network?.keyFigures?.weasseMax?.sum?.completeness?.toString() || 'undefined') || undefined);
  }

  getRelationsWeasseMaxSumDate(raw?: true): Date | undefined {
    if (raw) {
      return new Date(this.getterProps.data.dataViews?.data?.network?.keyFigures?.weasseMax?.sum?.date || '');
    }

    return this.getterProps.data.dataViews?.data?.network?.keyFigures?.weasseMax?.sum?.date ? new Date(this.getterProps.data.dataViews?.data?.network?.keyFigures?.weasseMax?.sum?.date?.toString() || '') : undefined;
  }

  getNbCompanies(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.companies?.stats?.all?.count || 0;
    }

    return this.makeRes(this.getterProps.data.dataViews?.data?.companies?.stats?.all?.count);
  }

  getNbActiveCompanies(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.companies?.stats?.active?.count || 0;
    }

    return this.makeRes(this.getterProps.data.dataViews?.data?.companies?.stats?.active?.count);
  }

  getNbInactiveCompanies(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.companies?.stats?.inactive?.count || 0;
    }

    return this.makeRes(this.getterProps.data.dataViews?.data?.companies?.stats?.inactive?.count);
  }

  getNbRelations(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.network?.stats?.all.count || 0;
    }

    return this.makeRes(this.getterProps.data.dataViews?.data?.network?.stats?.all.count || 0);
  }

  getNbCurrentRelations(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.network?.stats?.active.count || 0;
    }

    return this.makeRes(this.getterProps.data.dataViews?.data?.network?.stats?.active.count || 0);
  }

  getNbOldRelations(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.network?.stats?.inactive.count || 0;
    }

    return this.makeRes(this.getterProps.data.dataViews?.data?.network?.stats?.inactive.count || 0);
  }

  getAllCompaniesInfo(): CompanyInfo[] {
    return [...(this.getterProps.data.dataViews?.data?.companies?.stats?.active?.companiesInfo || []), ...(this.getterProps.data.dataViews?.data?.companies?.stats?.inactive?.companiesInfo || [])];
  }

  getActiveCompaniesInfo(): CompanyInfo[] {
    return this.getterProps.data.dataViews?.data?.companies?.stats?.active?.companiesInfo || [];
  }

  getInactiveCompaniesInfo(): CompanyInfo[] {
    return this.getterProps.data.dataViews?.data?.companies?.stats?.inactive?.companiesInfo || [];
  }

  getCompaniesActivitySector(): (string | undefined)[] {
    return Object.keys(this.getterProps.data.dataViews?.data?.companies?.stats?.all?.groupByApeCode || {}).map(key => getApeLabel(key)) || [this.makeRes()];
  }

  getCompaniesLegalForm(): (string | undefined)[] {
    return Object.keys(this.getterProps.data.dataViews?.data?.companies?.stats?.all?.groupByLegalFormCode || {}).map(key => getRncsLabel(key)) || [this.makeRes()];
  }

  getIsMemberOfDismemberment(): boolean | undefined {
    return this.getterProps.data.dataViews?.data?.assetComplexity?.summary?.dismemberment?.isMemberOfDismemberment || false;
  }

  getNbDismemberment(raw?: true): string | number {
    if (raw) {
      return this.getterProps.data.dataViews?.data?.assetComplexity?.summary?.dismemberment?.nbDismemberment || 0;
    }

    return this.makeRes(this.getterProps.data.dataViews?.data?.assetComplexity?.summary?.dismemberment?.nbDismemberment || 0);
  }

  getTags(family?: string): EntityTag[] {
    if (family) {
      return this.getterProps.data.tags?.filter(tag => tag.family === family) || [];
    }

    return this.getterProps.data.tags || [];
  }

  getSanctions(): string[] {
    return this.getterProps.data.dataViews?.data?.person?.sanctions?.summary?.sanctionTypes || [];
  }

  getPoliticalExposureLevels(): PoliticalExposureLevel[] {
    return this.getterProps.data.dataViews?.data?.person?.pep?.summary?.exposureLevels || [];
  }

  getHighestPoliticalExposureLevel(): PoliticalExposureLevel | undefined {
    const highest = this.getterProps.data.dataViews?.data?.person?.pep?.summary?.highestExposureLevel;

    return highest !== PoliticalExposureLevel.NONE ? highest: undefined;
  }

  getPersonCompleteness(): number {
    return this.getterProps.data.dataViews?.data?.completeness?.value || 0;
  }
}

export class DataBlocksGetter extends EntityGetter<IDataBlock> {

  getDataPath(): string {
    // loop to match dataElement ID
    return this.getterProps.data.dataInfo.path;
  }

  getDataType(): string {
    return this.getterProps.data.dataInfo.dataType;
  }

}

abstract class LegacyAggregationGetter<T = SegmentationPersonsAggregationStats | SegmentationCompaniesAggregationStats> {
  aggregationData: T;

  t: TFunction<'translation', undefined>;

  currency: string;

  theme: Theme;

  errorMsg?: string | null;

  protected constructor(props: AggregationGetterProps<T>) {
    this.aggregationData = props.data;
    this.t = props.t;
    this.currency = props.currency || 'EUR';
    this.theme = props.theme;
    this.errorMsg = props.errorMsg;
  }
}

export class LegacyPersonAggregationGetter extends LegacyAggregationGetter<SegmentationPersonsAggregationStats> {
  constructor(props: AggregationGetterProps<SegmentationPersonsAggregationStats>) {
    super(props);
  }

  getTurnoverSumOfSum(): KeyValue {
    return (
      {
        key: this.t('common.financial.turnover.sumOfSum'),
        value: currencyFormatter({ value: this.aggregationData?.agg_keyFigures?.turnoverSum_sum, currency: this.currency }),
        icon: IconArrowBackUp,
        completeness: this.aggregationData?.agg_keyFigures.turnoverSum_completeness
      }
    );
  }

  getEbitdaSumOfSum(): KeyValue {
    return (
      {
        key: this.t('common.financial.ebitda.sumOfSum'),
        value: currencyFormatter({ value: this.aggregationData?.agg_keyFigures?.ebitdaSum_sum, currency: this.currency }),
        icon: IconChartHistogram,
        completeness: this.aggregationData?.agg_keyFigures.ebitdaSum_completeness
      }
    );
  }

  getCapitalSumOfSum(): KeyValue {
    return (
      {
        key: this.t('common.financial.capital.sumOfSum'),
        value: currencyFormatter({ value: this.aggregationData?.agg_keyFigures?.capitalSum_sum, currency: this.currency }),
        icon: IconCoins,
        completeness: this.aggregationData?.agg_keyFigures.capitalSum_completeness
      }
    );
  }

  getValuationSumOfSum(): KeyValue {
    return (
      {
        key: this.t('common.financial.valuation.sumOfSum'),
        value: currencyFormatter({ value: this.aggregationData?.agg_keyFigures?.valuationSum_sum, currency: this.currency }),
        icon: IconChartLine,
        completeness: this.aggregationData?.agg_keyFigures.valuationSum_completeness
      }
    );
  }

  getValuationSum(): KeyValue {
    return (
      {
        key: this.t('common.financial.valuation.sumOfSum'),
        value: currencyFormatter({ value: this.aggregationData?.agg_keyFigures?.valuation_sum, currency: this.currency }),
        icon: IconChartLine,
        completeness: this.aggregationData?.agg_keyFigures.totalNb ? 1 / (this.aggregationData?.agg_keyFigures.totalNb || 0) * (this.aggregationData?.agg_keyFigures.valuation_count || 0) : 0,
        accuracy: this.aggregationData?.agg_keyFigures.valuation_accuracy_average
      }
    );
  }

  getWeasseSum(): KeyValue {
    return (
      {
        key: this.t('common.financial.weasse.sum'),
        value: currencyFormatter({ value: this.aggregationData?.agg_keyFigures?.weasse_sum, currency: this.currency }),
        completeness: this.aggregationData?.agg_keyFigures.weasse_completeness,
        icon: IconMoneybag
      }
    );
  }

  getWeasseSumOfSum(): KeyValue {
    return (
      {
        key: this.t('common.financial.weasse.sumOfSum'),
        value: this.errorMsg || '-',
        completeness: this.errorMsg || '-',
        icon: IconMoneybag
      }
    );
  }

  getWeasseMaxSum(): KeyValue {
    return (
      {
        key: this.t('common.financial.weasseMax.sum'),
        value: currencyFormatter({ value: this.aggregationData?.agg_keyFigures?.weasseMax_sum, currency: this.currency }),
        completeness: this.aggregationData?.agg_keyFigures.weasseMax_completeness
      }
    );
  }

  getWidthHoldings(): KeyValue {
    return (
      {
        key: `${this.t('common.utils.with')} ${this.t('common.financial.holdings')}`,
        value: this.aggregationData?.agg_personHasHolding?.find(v => v.value)?.count.toString() || this.errorMsg || '-',
        icon: IconUsers
      }
    );
  }

  getWidthScis(): KeyValue {
    return (
      {
        key: `${this.t('')} ${this.t('common.utils.with')} ${this.t('common.financial.scis')}`,
        value: this.aggregationData?.agg_personHasSci?.find(v => v.value)?.count.toString() || this.errorMsg || '-',
        icon: IconUsers
      }
    );
  }

  getPersonsNetworkSum(): KeyValue {
    return (
      {
        key: this.t('common.utils.total'),
        value: this.aggregationData?.agg_keyFigures.network_size_all_sum?.toString() || this.errorMsg || '-'
      }
    );
  }

  getPersonsNetworkActiveSum(): KeyValue {
    return (
      {
        key: this.t('common.utils.actives'),
        value: this.aggregationData?.agg_keyFigures.network_size_active_sum?.toString() || this.errorMsg || '-'
      }
    );
  }

  getPersonsNetworkInactiveSum(): KeyValue {
    return (
      {
        key: this.t('common.utils.inactives'),
        value: this.aggregationData?.agg_keyFigures.network_size_inactive_sum?.toString() || this.errorMsg || '-'
      }
    );
  }

  getCompaniesNetworkSum(): KeyValue {
    return (
      {
        key: this.t('common.utils.total'),
        value: ((this.aggregationData?.agg_companiesStats?.active_count || 0) + (this.aggregationData?.agg_companiesStats?.inactive_count || 0))?.toString() || this.errorMsg || '-'
      }
    );
  }

  getCompaniesNetworkActiveSum(): KeyValue {
    return (
      {
        key: this.t('common.utils.actives'),
        value: this.aggregationData?.agg_companiesStats?.active_count?.toString() || this.errorMsg || '-',
        color: this.theme.palette.success.main
      }
    );
  }

  getCompaniesNetworkInactiveSum(): KeyValue {
    return (
      {
        key: this.t('common.utils.inactives'),
        value: this.aggregationData?.agg_companiesStats?.inactive_count?.toString() || this.errorMsg || '-',
        color: this.theme.palette.error.main
      }
    );
  }
}

export class LegacyCompanyAggregationGetter extends LegacyAggregationGetter<SegmentationCompaniesAggregationStats> {
  constructor(props: AggregationGetterProps<SegmentationCompaniesAggregationStats>) {
    super(props);
  }

  getTurnoverSumOfSum(): KeyValue {
    return (
      {
        key: this.t('common.financial.turnover.sumOfSum'),
        value: currencyFormatter({ value: this.aggregationData?.agg_keyFigures?.turnover_sum, currency: this.currency }),
        icon: IconArrowBackUp,
        completeness: this.aggregationData?.agg_keyFigures.totalNb ? 1 / (this.aggregationData?.agg_keyFigures.totalNb || 0) * (this.aggregationData?.agg_keyFigures.turnover_count || 0) : 0
      }
    );
  }

  getEbitdaSum(): KeyValue {
    return (
      {
        key: this.t('common.financial.ebitda.sum'),
        value: currencyFormatter({ value: this.aggregationData?.agg_keyFigures?.ebitdaSum_sum, currency: this.currency }),
        icon: IconChartHistogram,
        completeness: this.aggregationData?.agg_keyFigures.totalNb ? 1 / (this.aggregationData?.agg_keyFigures.totalNb || 0) * (this.aggregationData?.agg_keyFigures.ebitda_count || 0) : 0
      }
    );
  }

  getCapitalSum(): KeyValue {
    return (
      {
        key: this.t('common.financial.capital.sum'),
        value: currencyFormatter({ value: this.aggregationData?.agg_keyFigures?.capital_sum, currency: this.currency }),
        icon: IconCoins,
        completeness: this.aggregationData?.agg_keyFigures.totalNb ? 1 / (this.aggregationData?.agg_keyFigures.totalNb || 0) * (this.aggregationData?.agg_keyFigures.capital_count || 0) : 0
      }
    );
  }
}

export interface ProxemeeLevel {
  codeAsInt: number,
  code: string,
  label: string,
  color?: string,
  bgcolor?: string
}

export function computeProxemeeScore(codeAsInt?: number, score?: number): number {
  return (codeAsInt || 0) + ((score || 0.01) / 100000);
}

export function getProxemeeLevel(value: string | number, t?: TFunction<'translation', undefined>, theme?: Theme): ProxemeeLevel | undefined {
  const proxemeeLevels: ProxemeeLevel[] = [
    {
      codeAsInt: 3,
      code: 'personal',
      label: t?.('proxemee.personal.label') || 'personal',
      color: theme?.palette.success.dark,
      bgcolor: theme?.palette.success.light
    },
    {
      codeAsInt: 2,
      code: 'business',
      label: t?.('proxemee.business.label') || 'business',
      color: theme?.palette.warning.dark,
      bgcolor: theme?.palette.warning.light
    },
    {
      codeAsInt: 1,
      code: 'extended',
      label: t?.('proxemee.extended.label') || 'extended',
      color: theme?.palette.error.dark,
      bgcolor: theme?.palette.error.light
    },
    {
      codeAsInt: 0,
      code: 'undefined',
      label: t?.('proxemee.notCalculated.label') || '-'
    }
  ];

  if (typeof value === 'string') {
    return proxemeeLevels.find(proxemeeLevel => proxemeeLevel.code === value);
  }

  return proxemeeLevels.find(proxemeeLevel => (proxemeeLevel.codeAsInt === Math.round(value as number)));
}
