import { EntityRef, ReechData } from '@deecision/dna-interfaces';
import axiosInstance, { BaseService } from '@/api';
import dnaConfig from '@/config/dna.config.json';

class ReechService extends BaseService<ReechData> {
  constructor() {
    super({
      axiosInstance,
      elementPrefixUrl: dnaConfig.api.url.reechPrefix
    });
  }

  getAll(props: { entityRef: EntityRef }) {
    return this.axiosInstance.get(this.constructUrl(`${props.entityRef.entityType}/${props.entityRef.entityId}`))
      .then(res => this.handleArrayResponse<ReechData>(res));
  }

  get(id: string) {
    return this.axiosInstance.get(this.constructUrl(id))
      .then(res => this.handleResponse<ReechData>(res));
  }
}

export default ReechService;
