import React, { ReactElement, useEffect } from 'react';
import { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { useLocation } from 'react-router-dom';

const createRequestKey = (config: InternalAxiosRequestConfig) => {
  const { url, method = 'GET', params, data } = config;

  return JSON.stringify({
    url,
    method,
    params,
    data
  });
};

export function AxiosCancelWrapper(props: { axiosInstance: AxiosInstance, children?: ReactElement }): ReactElement {
  const { pathname } = useLocation();

  const getRequestInterceptor = (abortControllers: Map<string, AbortController>) => props.axiosInstance.interceptors.request.use((config) => {
    const configTmp = { ...config };
    const requestKey = createRequestKey(config);

    if (configTmp.url) {
      const existingController = abortControllers.get(requestKey);
      if (existingController) {
        existingController.abort();
      }

      const controller = new AbortController();
      configTmp.signal = controller.signal;
      abortControllers.set(requestKey, controller);
    }

    return configTmp;
  });

  const getResponseInterceptor = (abortControllers: Map<string, AbortController>) =>
    props.axiosInstance.interceptors.response.use((response) => {
      const requestKey = createRequestKey(response.config);

      if (requestKey) {
        abortControllers.delete(requestKey);
      }

      return response;
    }, (error) => {
      if (error.name === 'CanceledError' || error.name === 'AbortError') {
        return new Promise(() => {});
      }

      if (error.config) {
        const requestKey = createRequestKey(error.config);

        abortControllers.delete(requestKey);
      }

      return Promise.reject(error);
    }
    );

  const cancelAllRequests = (abortControllers: Map<string, AbortController>) => {
    abortControllers.forEach((controller) => {
      controller.abort();
    });
    abortControllers.clear();
  };

  useEffect(() => {
    const abortControllers = new Map();
    const requestInterceptor = getRequestInterceptor(abortControllers);
    const responseInterceptor = getResponseInterceptor(abortControllers);

    return () => {
      props.axiosInstance.interceptors.request.eject(requestInterceptor);
      props.axiosInstance.interceptors.response.eject(responseInterceptor);
      cancelAllRequests(abortControllers);
    };
  }, [pathname]);

  return props.children || <></>;
}
