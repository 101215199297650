import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { CompanyEntity, EntityLinksAggregation, PersonEntity } from '@deecision/dna-interfaces';
import {
  IconAlertCircle,
  IconBinaryTree, IconBinaryTree2,
  IconChartArcs,
  IconSitemap,
  IconUserDown,
  IconUserUp
} from '@tabler/icons-react';
import Paper from '@mui/material/Paper';
import { ReactFlowProvider } from 'reactflow';
import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Chip, Stack, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import LinksTrees from '@/main/containers/network/trees/simple';
import CirclesNetwork from '../../../../containers/network/circles';
import RelationsTree from '../../../../containers/network/trees/vectorial';
import { DataElementChipType } from '@/main/containers/network/types';
import FullscreenProvider from '@/wrappers/fullscreen';
import { EntityLinksQueryAggregatorService } from '@/api/services/query';
import WidgetsReech from '@/main/containers/widgets/reech';

type NetworkType = {
  id: number,
  icon: JSX.Element,
  label: string,
  value: string
}

function DataElementChipComponent(props: { dataElementChips: DataElementChipType }) {
  return (
    <Stack direction='row' width='88px' ml='8px'>
      <Tooltip arrow title={`Parent DataElement: ${props.dataElementChips?.parent.dataInfo ?
        props.dataElementChips?.parent.dataInfo === 'missing' ? 'data can be missing and will be updated' :
          props.dataElementChips?.parent.dataInfo === 'incomplete' ? 'Ran into issues when identifying represented/representatives' :
            props.dataElementChips?.parent.dataInfo : ' Due to nature of entity, parents are not scheduled to be fetched'}
              ${props.dataElementChips?.parent.scheduling || ''}`}
      >
        <Chip
          sx={{
            width: 45,
            height: 24
          }}
          color={
            props.dataElementChips?.parent.dataInfo ?
              props.dataElementChips?.parent.dataInfo === 'available' ?
                'success' :
                props.dataElementChips?.parent.dataInfo === 'incomplete' ? 'error' : 'warning' :
              'warning'
          }
          label={
            <Stack style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
              <IconUserUp size='1.2rem' />
            </Stack>
          }
        />
      </Tooltip>
      <Tooltip arrow title={`Subsidiary DataElement: ${props.dataElementChips?.children.dataInfo ?
        props.dataElementChips?.children.dataInfo === 'missing' ? 'data can be missing and will be updated' :
          props.dataElementChips?.children.dataInfo === 'incomplete' ? 'Ran into issues when identifying represented/representatives' :
            props.dataElementChips?.children.dataInfo : ' Due to nature of entity, subsidiary are not scheduled to be fetched'}
              ${props.dataElementChips?.children.scheduling || ''}`}
      >
        <Chip
          color={
            props.dataElementChips?.children.dataInfo ?
              props.dataElementChips?.children.dataInfo === 'available' ?
                'success' :
                props.dataElementChips?.children.dataInfo === 'incomplete' ? 'error' : 'warning' :
              'warning'
          }
          label={
            <Stack style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
              <IconUserDown size='1.2rem' />
            </Stack>
          }
          style={{
            marginLeft: '8px',
            width: 45,
            height: 24
          }}
        />
      </Tooltip>
    </Stack>
  );
}

function NetworkTabsComponents<T extends CompanyEntity | PersonEntity>(): ReactElement {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const entity = useOutletContext() as { data: T };
  const { id } = useParams();
  const networkTypes = [
    {
      id: 0,
      icon: <IconSitemap size='1.1rem' style={{ transform: 'rotate(-90deg)' }}/>,
      label: t('network.tree.label'),
      value: 'Tree View'
    },
    {
      id: 1,
      icon: <IconBinaryTree size='1.1rem' style={{ transform: 'rotate(-90deg)' }}/>,
      label: t('network.tree.extended.label'),
      value: 'Tree Chart'
    },
    {
      id: 2,
      icon: <IconChartArcs size='1.1rem'/>,
      label: t('network.circles.label'),
      value: 'Circles Chart'
    },
    {
      id: 3,
      icon: <IconBinaryTree2 size='1.1rem' />,
      label: t('reech.label'),
      value: 'reech'
    }
  ];
  const selected = useMemo<NetworkType>(() => networkTypes.find(networkType => networkType.value === searchParams.get('networkType')) || networkTypes[0],
    [searchParams.get('networkType')]);
  const [dataElementChips, setDataElementChips] = useState<DataElementChipType>();
  const [nbRelations, setNbRelations] = useState<number>(0);
  const linksServiceToCompany = new EntityLinksQueryAggregatorService<EntityLinksAggregation>({
    entityType: window.location.href.includes('persons') ? 'deecPerson' : 'deecCompany',
    toEntityType: 'deecCompany'
  });
  const linksServiceToPerson = new EntityLinksQueryAggregatorService<EntityLinksAggregation>({
    entityType: window.location.href.includes('persons') ? 'deecPerson' : 'deecCompany',
    toEntityType: 'deecPerson'
  });

  const getLinks = () => {
    linksServiceToCompany.get(id)
      .then((res) => {
        if (res.data) {
          setNbRelations(prevState => prevState + (res.data?.length || 0));
        }
      });
    linksServiceToPerson.get(id)
      .then((res) => {
        if (res.data) {
          setNbRelations(prevState => prevState + (res.data?.length || 0));
        }
      });
  };

  useEffect(() => {
    getLinks();
  }, [id]);

  return (
    <Grid container spacing={2}>
      <Grid size={12} container display='flex' alignItems='center' pb={2}>
        <Grid>
          <Paper
            sx={{ p: 1 }}
          >
            <Stack direction='row' spacing={2} alignItems='center'>
              {networkTypes
                .filter(networkType => entity.data.entityType === 'deecPerson' || networkType.value !== 'reech')
                .map(networkType => (
                  <Button
                    key={networkType.id}
                    variant='contained'
                    startIcon={networkType.icon}
                    onClick={() => setSearchParams({ networkType: networkType.value })}
                    sx={{
                      borderRadius: '8px',
                      border: 'none',
                      bgcolor: selected.id === networkType.id ? theme.palette.background.paper : 'transparent',
                      color: theme.palette.text.primary,
                      '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: theme.palette.background.paper
                      }
                    }}
                    size='small'
                  >
                    {networkType.label}
                  </Button>
                ))}
            </Stack>
          </Paper>
        </Grid>
        <Grid>
          {dataElementChips && selected.value === 'Tree Chart' &&
            <Grid size='grow' display='flex' alignItems='center'>
              <DataElementChipComponent dataElementChips={dataElementChips} />
            </Grid>
          }
          {!entity.data.entityType.includes('Person') && selected.value === 'Circles Chart' &&
            <Grid size='grow' display='flex' height='49px' alignItems='center'>
              <Stack direction='row' width='88px' ml='8px'>
                <Tooltip arrow title={t('charts.chip.prxmeeNotAvailable.label')}>
                  <Chip
                    sx={{
                      width: 45,
                      height: 24
                    }}
                    color='warning'
                    label={
                      <Stack style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                        <IconAlertCircle size='1.2rem' />
                      </Stack>
                    }
                  />
                </Tooltip>
              </Stack>
            </Grid>
          }
        </Grid>
      </Grid>
      <Grid size={12}>
        {id && selected.value === 'Tree View' &&
          <FullscreenProvider>
            <LinksTrees entity={entity.data} id={id} />
          </FullscreenProvider>
        }
        {id && selected.value === 'Tree Chart' &&
          <ReactFlowProvider>
            <FullscreenProvider>
              <RelationsTree entity={entity.data} id={id} setDataElementsChips={setDataElementChips} nbRelations={nbRelations}/>
            </FullscreenProvider>
          </ReactFlowProvider>
        }
        {id && selected.value === 'Circles Chart' &&
          <FullscreenProvider>
            <CirclesNetwork entity={entity.data} id={id} />
          </FullscreenProvider>
        }
        {id && selected.value === 'reech' && entity.data.entityType === 'deecPerson' &&
          <FullscreenProvider>
            <WidgetsReech data={entity.data} fullHeight fullWidth simple />
          </FullscreenProvider>
        }
      </Grid>
    </Grid>
  );
}

export default NetworkTabsComponents;
