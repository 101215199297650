import { createReactComponent } from '@tabler/icons-react';

const IconBuildings = createReactComponent('outline', 'buildings', 'IconBuildings', [
  ['path', { d: 'M3.1875 21H22.3125' }],
  ['path', { d: 'M14.875 21V7C14.875 6.20435 14.5392 5.44129 13.9414 4.87868C13.3436 4.31607 12.5329 4 11.6875 4H7.4375C6.59212 4 5.78137 4.31607 5.1836 4.87868C4.58583 5.44129 4.25 6.20435 4.25 7V21' }],
  ['path', { d: 'M21.25 21L21.25 10.6667C21.25 9.87104 20.9142 9.10798 20.3164 8.54537C19.7186 7.98276 18.9079 7.66669 18.0625 7.66669H16' }],
  ['path', { d: 'M9.5625 17V21' }],
  ['path', { d: 'M8.5 13H10.625' }],
  ['path', { d: 'M16 13H18.125' }],
  ['path', { d: 'M8.5 9H10.625' }]
]);

export default IconBuildings;
