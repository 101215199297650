import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WidgetWithCompaniesAugmentedEntitiesProps } from '@/main/containers/widgets/types';
import BaseWidgetCompaniesApeRncs from '@/main/containers/widgets/base-ape-rncs';

function CompanyWidgetCompaniesApeRncs(props: WidgetWithCompaniesAugmentedEntitiesProps): ReactElement {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<'codeApe' | 'legalStatus'>('codeApe');

  const chartData = useMemo(() => {
    const dataTmp: { value: string, count: number }[] = [];

    props.data?.forEach((data) => {
      if (selected === 'codeApe') {
        const index = dataTmp.findIndex(d => d.value === data.entity.dataViews?.data?.company?.identity?.codeApe);

        if (index !== undefined && index >= 0) {
          dataTmp[index].count += 1;
        } else {
          dataTmp.push({
            value: data.entity.dataViews?.data?.company?.identity?.codeApe || 'undefined',
            count: 1
          });
        }
      } else if (selected === 'legalStatus') {
        const index = dataTmp.findIndex(d => d.value === data.entity.dataViews?.data?.company?.identity?.legalStatus.text);

        if (index !== undefined && index >= 0) {
          dataTmp[index].count += 1;
        } else {
          dataTmp.push({
            value: data.entity.dataViews?.data?.company?.identity?.legalStatus.text || 'undefined',
            count: 1
          });
        }
      }
    });

    return dataTmp;
  }, [props.data, selected]);

  return (
    <BaseWidgetCompaniesApeRncs<typeof selected>
      {...props}
      chartData={chartData}
      options={[
        { value: 'codeApe', label: t('common.utils.activitySector') },
        { value: 'legalStatus', label: t('common.utils.legalForm') }
      ]}
      selected={selected}
      setSelected={setSelected}
    />
  );
}

export default CompanyWidgetCompaniesApeRncs;
