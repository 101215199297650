import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import BaseWidgetChart from '@/main/containers/widgets/base-chart';
import { BaseWidgetProp } from '@/main/containers/widgets/base-widget';

function BaseWidgetRelationsPositionsRoles(props: BaseWidgetProp & { data?: unknown[], chartData: Record<'roles' | 'positionTypes', Record<string, number>> }): ReactElement {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<'roles' | 'positionTypes'>('roles');

  const data = useMemo(() => Object.keys(props.chartData[selected]).map(code => ({
    value: code,
    count: props.chartData[selected][code] || 0
  })), [props.chartData, selected]);

  return (
    <BaseWidgetChart
      {...props}
      actions={[
        <FormControl key='form-position-roles' fullWidth sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <Select
            native
            variant='outlined'
            value={selected}
            onChange={e => setSelected(e.target.value as typeof selected)}
            size='small'
            sx={{ width: 'max-content' }}
          >
            <option value='roles'>{t('common.utils.roles')}</option>
            <option value='positionTypes'>{t('common.utils.positionTypes')}</option>
          </Select>
        </FormControl>
      ]}
      baseChartType='barChart'
      chartProps={{
        datas: [data.sort((a, b) => b.count - a.count)],
        totalCount: props.data?.length || data.length,
        title: t('common.utils.activitySector'),
        dataType: selected === 'positionTypes' ? 'positionTypes' : 'string'
      }}
      fullWidth
    />
  );
}

export default BaseWidgetRelationsPositionsRoles;
