import { BodaccDataBlock, IDataBlock } from '@deecision/dna-interfaces';
import { DataBlocksQueryService } from '@/api/services/query';

function PrioreezProvider(companiesId: string[], criticities: string[], durationInMonth?: number): Promise<IDataBlock<BodaccDataBlock>[]> {
  const queryService = new DataBlocksQueryService<BodaccDataBlock>({
    entityType: 'deecCompany'
  });

  return queryService.find({
    entityId: { '$in': companiesId },
    dataPath: 'company.bodacc.publication',
    others: {
      'data._deecision.criticity': criticities ? { '$in': criticities } : undefined,
      'dataInfo.dataTimestamp': durationInMonth ? { '$gte': new Date().setMonth(new Date().getMonth() - durationInMonth) } : undefined
    }
  })
    .then(res => res?.data as IDataBlock<BodaccDataBlock>[] || [])
    .catch(() => []);
}

export default PrioreezProvider;
