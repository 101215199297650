import React from 'react';
import { IconSearch } from '@tabler/icons-react';
import createElementRoutes from '@/router/create';
import dnaConfig from '../../../../config/dna.config.json';
import { CustomRouteObject } from '@/router/types';
import SearchEngine from './components';

const routesSearch: CustomRouteObject = createElementRoutes<unknown>({
  i18nKey: dnaConfig.routes.search.i18nKey,
  path: dnaConfig.routes.search.baseUrl,
  icon: IconSearch,
  sectionTitle: 'entities.label',
  component: <SearchEngine />,
  displayIndex: true
});

export default routesSearch;
