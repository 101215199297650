import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import NewsSmallCards from '@/main/containers/entitiescards/news';
import BaseWidget from '@/main/containers/widgets/base-widget';
import { WidgetProps } from '@/main/containers/widgets/types';

function WidgetsNews(props: WidgetProps): ReactElement {
  const { t } = useTranslation();

  return (
    <BaseWidget {...props} title={t('entities.companies.company.tabs.news')}>
      <NewsSmallCards key='news' entityType='deecPerson' />
    </BaseWidget>
  );
}

export default WidgetsNews;
