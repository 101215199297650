import { CustomRouteObject } from '@/router/types';
import routesPersons from '@/main/modules/entities/persons/routes';
import routesCompanies from '@/main/modules/entities/companies/routes';
import routesRelations from '@/main/modules/entities/relations/routes';
import routesSearch from '@/main/modules/entities/search/routes';

const routesEntities: CustomRouteObject[] = [
  routesSearch,
  routesRelations,
  routesPersons,
  routesCompanies
];

export default routesEntities;
