import React from 'react';
import { IconDashboard } from '@tabler/icons-react';
import createElementRoutes from '@/router/create';
import AdminDashboard from './adminDashboard';
import { CustomRouteObject } from '@/router/types';
import dnaConfig from '../../../../config/dna.config.json';

const routeDashboard: CustomRouteObject = createElementRoutes({
  id: dnaConfig.routes.admin.dashboard.label,
  i18nKey: dnaConfig.routes.admin.dashboard.i18nKey,
  path: dnaConfig.routes.admin.dashboard.baseUrl,
  icon: IconDashboard,
  component: <AdminDashboard />,
  idComponent: <AdminDashboard />,
  displayIndex: true
});

export default routeDashboard;
