import React, { ReactElement, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import { useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import dnaConfig from '../../../../../../../config/dna.config.json';

function PreparationStepsComponentsCreateFolders(): ReactElement {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [activeStep, setActiveStep] = useState(0);
  const [connectionLevel, setConnectionLevel] = useState<string>();

  // const selectChevron = (params: Record<string, unknown>) => <IconChevronDown size='1rem' style={{ marginRight: '8px' }} {...params} />;

  useEffect(() => {
    const activeStepTmp = searchParams.get('step');

    if (activeStepTmp) {
      setActiveStep(parseInt(activeStepTmp, 10) - 1);
    }
  }, [searchParams]);

  return (
    <Grid
      container
      spacing={6}
      maxWidth='800px'
      justifyContent='center'
      alignItems='top'
      mt={8}
      ml='auto !important'
      mr='auto !important'
    >
      <Grid size={6} mt='10px'>
        <Typography variant='h4'>{`1. ${t('folder.steps.choices.selectConnectionLevelNb')}`}</Typography>
      </Grid>
      <Grid size={6}>
        <FormControl fullWidth>
          <RadioGroup
            row
            name='row-radio-buttons-group'
            value={connectionLevel}
            onChange={e => setConnectionLevel(e.target.value)}
          >
            <FormControlLabel value='1' control={<Radio />} label={`1 ${t('folder.steps.choices.level')}`} />
            <FormControlLabel value='2' control={<Radio />} label={`2 ${t('folder.steps.choices.level')}`} />
            <FormControlLabel value='3' control={<Radio />} label={`3 ${t('folder.steps.choices.level')}`} />
            <FormControlLabel value='4' control={<Radio />} label={`4 ${t('folder.steps.choices.level')}`} />
          </RadioGroup>
        </FormControl>
      </Grid>

      {/*
      <Grid size={6}>
        <Typography variant='h4'>{`1. ${t('folder.steps.choices.selectFolderType')}`}</Typography>
      </Grid>
      <Grid size={6}>
        <FormControl fullWidth>
          <InputLabel id='folder-type-select-label' size='small' >{t('folder.steps.choices.folderType')}</InputLabel>
          <Select
            labelId='folder-type-select-label'
            id='folder-type-select'
            value={folderType}
            onChange={e => setFolderType(e.target.value)}
            sx={{
              borderRadius: 2
            }}
            IconComponent={selectChevron}
            size='small'
            fullWidth
            label={t('folder.steps.choices.folderType')}
          >
            <MenuItem value={10}>Prêt</MenuItem>
            <MenuItem value={21}>Prêt longue durée</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      */}

      <Grid size={12} textAlign='center' mt={4}>
        <Stack direction='row' spacing={2} justifyContent='center'>
          <Button variant='textIcon' href={`${dnaConfig.routes.folders.create.relativeUrl}?step=${activeStep}`}>
            <IconArrowLeft size='1.6rem' />
          </Button>
          <Button
            variant='contained'
            sx={{
              width: '50%',
              '& .MuiButton-startIcon': {
                marginRight: 'auto !important'
              },
              '& .MuiButton-endIcon': {
                marginLeft: 'auto !important'
              }
            }}
            href={`${dnaConfig.routes.folders.create.relativeUrl}?step=${activeStep + 2}`}
            startIcon={<Box width={24} height={24} />}
            endIcon={<IconArrowRight size='1.6rem' />}
            disabled={!connectionLevel}
          >
            {t('folder.steps.nextStep')}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default PreparationStepsComponentsCreateFolders;
