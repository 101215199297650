import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { FinancialReportDataBlock, IDataBlock } from '@deecision/dna-interfaces';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { cloneDeep, get, upperFirst } from 'lodash';
import Dashboard from '@/main/containers/dashboards/entity';
import KeyValueCardComponent from '@/components/cards/keyvalue.cards';
import { currencyFormatter } from '@/utils';
import FinancialActions from '@/main/containers/financial/actions';
import { getFinancialYears } from '@/main/containers/financial/utils';

function FinancialYearList(props: { datas: IDataBlock<FinancialReportDataBlock>[], selectedKey: string[], setSelectedKey: (keyPath: string[]) => void }): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const years = useMemo(() => getFinancialYears(props.datas), [props.datas]);

  const getSelected = useMemo(() => {
    const selectedTmp: Array<{ year: string, elements: string[] }> = [];

    years.forEach((year) => {
      const data = props.datas.find(d => year === new Date(d.data.closingDate).getFullYear().toString());

      if (data?.data.details) {
        selectedTmp.push({ year, elements: Object.keys(data.data.details) });
      }
    });

    return selectedTmp;
  }, [years, props.datas]);

  const [selected, setSelected] = useState(getSelected);

  useEffect(() => {
    setSelected(getSelected);
  }, [getSelected]);

  return (
    <Dashboard
      elements={
        years.map((year) => {
          const data = props.datas.find(d => year === new Date(d.data.closingDate).getFullYear().toString());
          const month = new Date(data?.data.closingDate || '').getMonth().toString().toLowerCase();

          return (data?.data?.details &&
            {
              id: year,
              type: 'block',
              label: `${t(`common.month.${month}`)} ${year} - ${upperFirst(data.data.balanceSheetType)}`,
              actions: <FinancialActions />,
              widgets: Object.keys(data.data.details).map(key => (Object.keys(get(data.data.details, key)).length > 0 &&
                <KeyValueCardComponent
                  id={key}
                  title={key.replace(/([A-Z])/g, ' $1').charAt(0).toUpperCase() + key.replace(/([A-Z])/g, ' $1').slice(1)}
                  bgColor={theme.palette.grey['200']}
                  border={theme.palette.grey['500']}
                  select={() => setSelected((prevState) => {
                    const stateTmp: typeof prevState = cloneDeep(prevState.filter(y => y.year !== year));
                    let elementsTmp: string[] = cloneDeep(prevState.find(y => y.year === year)?.elements || []);

                    if (elementsTmp.find(element => element === key)) {
                      elementsTmp = elementsTmp.filter(element => element !== key);

                      return [...stateTmp, { year, elements: elementsTmp }];
                    }
                    elementsTmp.push(key);

                    return [...stateTmp, { year, elements: elementsTmp }];
                  })}
                  selected={selected.find(y => y.year === year)?.elements.find(element => element === key)}
                  cardParts={{
                    mainValue: {
                      key: '',
                      value: ''
                    },
                    values: Object.keys(get(data.data.details, key)).map(detailsKey => ({
                      id: detailsKey,
                      key: detailsKey.replace(/([A-Z])/g, ' $1').charAt(0).toUpperCase() + detailsKey.replace(/([A-Z])/g, ' $1').slice(1),
                      value: currencyFormatter({ value: get(data.data.details, `${key}.${detailsKey}`), currency: data.data.currencyCode }) || '',
                      align: 'right',
                      indicator: (
                        get(data.data.details, `${key}.${detailsKey}`) && get(props.datas.find(d => (parseInt(year, 10) - 1).toString() === new Date(d.data.closingDate).getFullYear().toString())?.data.details, `${key}.${detailsKey}`) ?
                          parseFloat(get(data.data.details, `${key}.${detailsKey}`) || '0') - parseFloat(get(props.datas.find(d => (parseInt(year, 10) - 1).toString() === new Date(d.data.closingDate).getFullYear().toString())?.data.details, `${key}.${detailsKey}`) || '0')
                          : t('common.utils.unknown') || ''
                      ),
                      percentage: (
                        get(data.data.details, `${key}.${detailsKey}`) && get(props.datas.find(d => (parseInt(year, 10) - 1).toString() === new Date(d.data.closingDate).getFullYear().toString())?.data.details, `${key}.${detailsKey}`) ?
                          (100 / parseFloat(get(props.datas.find(d => (parseInt(year, 10) - 1).toString() === new Date(d.data.closingDate).getFullYear().toString())?.data.details, `${key}.${detailsKey}`) || '0') * parseFloat(get(data.data.details, `${key}.${detailsKey}`) || '0') - 100)
                          : undefined
                      ),
                      selected: !!props.selectedKey.find(k => k === `${key}.${detailsKey}`) || undefined
                    })),
                    selectKey: (myKey: string) => props.setSelectedKey(props.selectedKey.find(k => k === `${key}.${myKey}`) ? props.selectedKey.filter(k => k !== `${key}.${myKey}`) : [...props.selectedKey, `${key}.${myKey}`])
                  }}
                />
              )).map(widget => (
                widget ? {
                  id: widget.props.id,
                  type: 'widget',
                  component: widget
                } : false
              ))
            }
          );
        })
      }
    />
  );
}

export default FinancialYearList;
