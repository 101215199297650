import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { IconBuildingFactory, IconPlus } from '@tabler/icons-react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import dnaConfig from '../../../../config/dna.config.json';
import { CustomUserData } from '@/main/containers/segmentations/types.segmentations';
import ListSegmentations from '../../../containers/segmentations/list/list.segmentations';
import CustomAccordion from '../../../../components/accordion';
import { VennDiagramConfig } from '@/main/containers/vennDiagram/types.vennDiagram';
import VennDiagramServices from '../../../containers/vennDiagram/services/vennDiagram.services';
import ListVennDiagram from '../../../containers/vennDiagram/services/list.vennDiagram';
import getSegmentations from '../../../containers/segmentations/getters/segmentation.getters.segmentations';

function ComponentFactory(): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const keycloak = useKeycloak();
  const vennDataFactoryService = new VennDiagramServices();
  const [userData, setUserData] = useState<CustomUserData[]>();
  const [groupData, setGroupData] = useState<CustomUserData[]>();
  const [vennList, setVennList] = useState<VennDiagramConfig[]>();

  const updateList = useCallback(() => {
    setGroupData(undefined);
    getSegmentations(keycloak.keycloak)
      .then((segmentation) => {
        setUserData(segmentation.filter(seg => seg.ownerType === 'user') as CustomUserData[]);
        setGroupData(segmentation.filter(seg => seg.ownerType === 'group') as CustomUserData[]);
      });
  }, [keycloak.keycloak]);

  useEffect(() => {
    if (keycloak.keycloak.tokenParsed) {
      updateList();
    }
    vennDataFactoryService.getAll({}).then((res) => {
      setVennList(res.data as VennDiagramConfig[]);
    });
  }, [keycloak.keycloak.tokenParsed?.preferred_username]);

  return (
    <Stack spacing={4} pt={4}>
      <Stack spacing={4} direction='row'>
        <IconBuildingFactory size='1.6rem' />
        <Typography variant='h2'>{t('admin.factory.label')}</Typography>
      </Stack>
      <Stack>
        <CustomAccordion title={t('admin.factory.dashboard.multiple')} disabled />

        <CustomAccordion title={t('segmentation.multiple')} defaultOpen>
          <Stack>
            <ListSegmentations
              data={[...(userData || []), ...(groupData || [])]}
              totalCount={[...(userData || []), ...(groupData || [])]?.length || 0}
              updateList={updateList}
              actions={[
                <Button variant='contained' endIcon={<IconPlus size='1.6rem' />} onClick={() => navigate({ pathname: dnaConfig.routes.admin.factory.segmentation.create.relativeUrl })}>
                  {t('segmentation.create')}
                </Button>
              ]}
              actionsPadding='16px'
            />
          </Stack>
        </CustomAccordion>
        <CustomAccordion title={t('Venn Diagram')} defaultOpen>
          <ListVennDiagram
            data={vennList || []}
            totalCount={vennList?.length || 0}
            actions={[
              <Button variant='contained' endIcon={<IconPlus size='1.6rem' />} onClick={() => navigate({ pathname: '/admin/factory/venn-diagram/create' })}>
                New venn diagram
              </Button>
            ]}
            actionsPadding='16px'
            service={vennDataFactoryService}
          />
        </CustomAccordion>
      </Stack>
    </Stack>
  );
}

export default ComponentFactory;
