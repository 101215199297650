import React, { ReactElement, useRef, useState } from 'react';
import Keycloak from 'keycloak-js';
import { AxiosInstance } from 'axios';
import { IconArrowsExchange, IconLogout, IconSettings, IconUserCog } from '@tabler/icons-react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Slide from '@mui/material/Slide';
import MenuList from '@mui/material/MenuList';
import Typography from '@mui/material/Typography';
import { useKeycloak } from '@react-keycloak/web';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import { Stack } from '@mui/material';
import { AxiosInstanceModifier } from '../../api';
import AppSettings from './appsettings';
import { translate } from '../translate';
import { i18nPossibleLanguages } from '@/i18n/config';
import { AppLanguages } from './language';
import CountriesFlags from '../../components/countriesflags';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    menuUser: true,
    menuUserActive: true
  }
}

export interface UserMenuProps {
  axiosInstance: AxiosInstance,
  urlLogoutRedirect?: string
}

const accountConsole = (keycloak: Keycloak.KeycloakInstance): void => {
  keycloak.accountManagement();
};

const logout = (keycloak: Keycloak.KeycloakInstance, axiosInstance: AxiosInstance, urlLogoutRedirect?: string): void => {
  const axiosInstanceModifier = new AxiosInstanceModifier(axiosInstance);

  axiosInstanceModifier.deleteHeader('Authorization');

  keycloak.logout({ redirectUri: urlLogoutRedirect });
};

function UserMenu(props: UserMenuProps): ReactElement {
  const { keycloak } = useKeycloak();
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openSettings, setOpenSettings] = useState(false);
  const [openLanguages, setOpenLanguages] = useState(false);
  const menuRef = useRef(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpenSettings(false);
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant={open ? 'menuUserActive' : 'menuUser'}
        size='large'
        color='primary'
        onClick={handleClick}
        endIcon={
          <IconSettings size='1.6rem' style={{ marginRight: '6px', marginLeft: '15px' }} />
        }
      >
        <Avatar
          sx={{
            height: '2.1rem',
            width: '2.1rem',
            ml: '-3px',
            fontSize: '0.8rem',
            bgcolor: 'primary.main'
          }}
        >
          {`${keycloak.tokenParsed?.given_name?.charAt(0)}${keycloak.tokenParsed?.family_name?.charAt(0)}`}
        </Avatar>
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          p: 2
        }}
      >
        <Collapse in={!openSettings && !openLanguages} collapsedSize={openSettings && 184 || openLanguages && 228 || 144}>
          <MenuList ref={menuRef}>
            <Paper sx={{ bgcolor: 'grey.200', m: 2, mt: 0, p: 2 }}>
              <Typography variant='h4' sx={{ pt: 2, pb: 0, pl: 2, pr: 2, width: '260px' }}>
                {`${keycloak.tokenParsed?.given_name} ${keycloak.tokenParsed?.family_name}`}
              </Typography>
              <Typography variant='caption' sx={{ p: 0, pl: 2, pr: 2 }}>
                {`${keycloak.tokenParsed?.email}`}
              </Typography>
              <Typography variant='body2'>
              &nbsp;
              </Typography>
              <MenuItem
                sx={{
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: 'transparent',
                    cursor: 'default'
                  }
                }}
                disableTouchRipple
                disableRipple
              >
                <Button
                  variant='menuContrasted'
                  size='large'
                  onClick={() => accountConsole(keycloak)}
                  startIcon={
                    <IconUserCog size='1.2rem' style={{ marginRight: '6px' }} />
                  }
                >
                  <Typography variant='subtitle2' sx={{ mr: '16px' }}>
                    {translate({ en: 'Account settings', fr: 'Paramètres du compte', language: i18n.language })}
                  </Typography>
                </Button>
              </MenuItem>
              <MenuItem
                sx={{
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: 'transparent',
                    cursor: 'default'
                  }
                }}
                disableTouchRipple
                disableRipple
              >
                <Button
                  variant='menuContrasted'
                  size='large'
                  onClick={() => logout(keycloak, props.axiosInstance, props.urlLogoutRedirect)}
                  startIcon={<IconLogout size='1.2rem' style={{ marginRight: '6px' }} />}
                >
                  <Typography variant='subtitle2' sx={{ mr: '16px' }}>
                    {translate({ en: 'Logout', fr: 'Déconnexion', language: i18n.language })}
                  </Typography>
                </Button>
              </MenuItem>
            </Paper>
            <MenuItem
              sx={{
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent',
                  cursor: 'default'
                }
              }}
              disableTouchRipple
              disableRipple
            >
              <Button
                variant='menu'
                size='large'
                startIcon={<IconArrowsExchange size='1.2rem' style={{ marginRight: '6px' }} />}
                onClick={() => setOpenLanguages(true)}
              >
                <Stack direction='row' spacing={2} alignItems='center'>
                  {CountriesFlags({ countries: [(i18nPossibleLanguages.find(language => language.global === i18n.language)?.version || i18nPossibleLanguages[0].version).split('-')[1].toUpperCase()], size: 'small' })}
                  <Typography variant='subtitle2' sx={{ mr: '16px' }}>
                    {i18nPossibleLanguages.find(language => language.global === i18n.language)?.full || i18nPossibleLanguages[0].full}
                  </Typography>
                </Stack>
              </Button>
            </MenuItem>
            <MenuItem
              sx={{
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent',
                  cursor: 'default'
                }
              }}
              disableTouchRipple
              disableRipple
            >
              <Button
                variant='menu'
                size='large'
                startIcon={
                  <IconSettings size='1.2rem' style={{ marginRight: '6px' }} />
                }
                onClick={() => setOpenSettings(true)}
              >
                <Typography variant='subtitle2' sx={{ mr: '16px' }}>
                  {translate({ en: 'Application Settings', fr: 'Paramètres de l\'application', language: i18n.language })}
                </Typography>
              </Button>
            </MenuItem>
            <Slide direction='left' in={openLanguages} container={menuRef.current}>
              <Paper
                elevation={0}
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  pt: 2,
                  bgcolor: 'grey.50',
                  border: 'none'
                }}
              >
                <AppLanguages setOpenLanguages={setOpenLanguages} />
              </Paper>
            </Slide>
            <Slide direction='left' in={openSettings} container={menuRef.current}>
              <Paper
                elevation={0}
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  pt: 2,
                  bgcolor: 'grey.50',
                  border: 'none'
                }}
              >
                <AppSettings language={i18n.language} setOpenSettings={setOpenSettings} />
              </Paper>
            </Slide>
          </MenuList>
        </Collapse>
      </Menu>
    </>
  );
}

export default UserMenu;
