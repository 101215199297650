import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IDataElement } from '@deecision/dna-interfaces';
import { IconChevronRight } from '@tabler/icons-react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import Link from '@mui/material/Link';
import { CurrencyFormatterProps } from '@/utils';
import EvenOddTable from '@/components/tables/evenodd/table';
import Filter from '../Dashboard/components/filter';
import computeString from '../components/computeString';
import { DataElementsQueryService } from '@/api/services/query';
import { Column } from '@/components/tables/types';

type DataElementsWithId = IDataElement & { _id: string };

function DataElementsScreen(): ReactElement {
  const dataElementService = new DataElementsQueryService({ entityType: 'deecCompany' });
  const [dataElements, setDataElements] = useState<DataElementsWithId[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const [filterEntityType, setFilterEntityType] = useState<string[]>([]);
  const [filterType, setFilterType] = useState<string[]>(['deecCompany:googleCse.search.results']);
  const [filterSchedulingStatus, setFilterSchedulingStatus] = useState<string[]>(['failed']);
  const [filterEntityTypeName, setFilterEntityTypeName] = useState<string[]>([]);
  const [filterTypeName, setFilterTypeName] = useState<string[]>(['deecCompany:googleCse.search.results']);
  const [filterSchedulingStatusName, setFilterSchedulingStatusName] = useState<string[]>(['failed']);
  const [filterParams, setFilterParams] = useSearchParams();
  const [resetting, setResetting] = useState(false);
  const navigate = useNavigate();

  const setUrlFilter = (valueType: string, valueEntityType: string, valueSchedulingStatus: string) => {
    filterTypeName.length !== 0 ? filterParams.set('type', valueType) : filterParams.delete('type');
    filterEntityTypeName.length !== 0 ? filterParams.set('entityType', valueEntityType) : filterParams.delete('entityType');
    filterSchedulingStatusName.length !== 0 ? filterParams.set('status', valueSchedulingStatus) : filterParams.delete('status');
    setFilterParams(filterParams);
  };

  const handleSetters = (setter: string, value: string[]) => {
    console.log(value);
    switch (setter) {
    case 'filterEntityType':
      setFilterEntityType(value);
      setFilterEntityTypeName(value);
      break;
    case 'filterType':
      setFilterType(value);
      setFilterTypeName(value);
      break;
    case 'filterSchedulingStatus':
      console.log('update filterSchedulingStatusName states');
      setFilterSchedulingStatus(value);
      setFilterSchedulingStatusName(value);
      break;
    default:
      break;
    }
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const type = urlParams.get('type');
    const status = urlParams.get('status');

    if (type) setFilterType([type as string]);
    if (status) setFilterSchedulingStatus([status as string]);
  }, []);

  useEffect(() => {
    dataElementService.findInAllEntities({ 'type': filterType, 'schedulingStatus': filterSchedulingStatus })
      .then((res) => {
        if (res.data) {
          const elementsWithId = res.data as (IDataElement & { _id: string })[];

          setDataElements(elementsWithId.map(item => ({
            ...item,
            _id: item._id
          })));
        }
        setTotalCount(res.totalCount);
        setUrlFilter(computeString(filterType), computeString(filterEntityType), computeString(filterSchedulingStatus));
      });
  }, [filterEntityType, filterType, filterSchedulingStatus]);

  useEffect(() => {
    if (resetting) {
      filterParams.delete('status');
      filterParams.delete('type');
      filterParams.delete('entityType');
      setFilterEntityType([]);
      setFilterType([]);
      setFilterSchedulingStatus([]);
      setFilterEntityTypeName([]);
      setFilterTypeName([]);
      setFilterSchedulingStatusName([]);
      setResetting(false);
    }
  }, [resetting]);

  const columns: Column<CurrencyFormatterProps>[] = [
    {
      id: 'entityType',
      label: 'Entity Type'
    },
    {
      id: 'name',
      label: 'Name'
    },
    {
      id: 'type',
      label: 'Type'
    },
    {
      id: 'status',
      label: 'Status'
    },
    {
      id: 'dataInfoStatus',
      label: 'Data Info Status'
    },
    {
      id: 'priority',
      label: 'Priority'
    },
    {
      id: 'effectivePriority',
      label: 'effective Priority'
    },
    {
      id: 'nbDependencies',
      label: 'Nb Dependencies'
    },
    {
      id: 'dependenciesDone',
      label: 'Dependencies done'
    },
    {
      id: 'voirPlus',
      label: 'Voir Plus'
    }
  ];

  const rows = dataElements?.map(dataElement => ({
    entityType: dataElement.entityRef.entityType,
    id: dataElement._id,
    name: <Link href={`../entities/${dataElement.entityRef.entityId as string}`}
      onClick={() => {
        localStorage.setItem(`entityType${dataElement.entityRef.entityId}`, dataElement.entityRef.entityType);
      }}>
      {dataElement.entityRef.name}<br/><Typography fontSize={12} fontWeight={300}>({dataElement.entityRef.entityId})</Typography>
    </Link>,
    type: dataElement.type,
    status: dataElement.scheduling.status,
    dataInfoStatus: dataElement.dataInfo.status,
    priority: dataElement.scheduling.priority,
    effectivePriority: dataElement.scheduling.effectivePriority,
    nbDependencies: dataElement.dependencies.length,
    // dependenciesDone: computeDependenciesPercentage(dataElement.dependencies),
    voirPlus:
    <Tooltip title={dataElement._id} >
      <IconButton onClick={() => {
        localStorage.setItem(`entityType${dataElement._id}`, dataElement.entityRef.entityType);
        navigate(`${dataElement._id}`);
      }}>
        <IconChevronRight size='1.6rem' />
      </IconButton>
    </Tooltip>
  }));

  return (
    <Grid container spacing={2} mt='1%' sx={{ flexGrow: 1 }}>
      <Filter
        filterEntityTypeName={filterEntityTypeName}
        filterTypeName={filterTypeName}
        filterSchedulingStatusName={filterSchedulingStatusName}
        handleSetters={handleSetters}
        setResetting={setResetting} />
      <Grid size={12}>
        <EvenOddTable<CurrencyFormatterProps> label='dataElements' columns={columns} rows={rows || []} totalCount={totalCount}/>
      </Grid>
    </Grid>
  );
}

export default DataElementsScreen;
