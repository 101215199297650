import React from 'react';
import { Box, Chip, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Stack, Typography, useTheme } from '@mui/material';
import { IconCheck, IconX, IconUser, IconBuilding, IconAlertTriangle } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import CustomAccordion from '../../../../../components/accordion';
import { BaseItemVennDiagramBuilderProps } from '../../types.vennDiagram';

function InitializeVennDiagram(props: BaseItemVennDiagramBuilderProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const objectType = [{ type: 'deecPerson', icon: <IconUser />, label: t('entities.persons.label') }, { type: 'deecCompany', icon: <IconBuilding />,  label: t('entities.companies.label') }];

  return (
    <CustomAccordion title={
      <Stack spacing={2} direction='row' alignItems='center'>
        <Typography variant='h4'>
          {t('segmentation.builder.steps.initialize.label')}
        </Typography>
        {props.data.objectType ?
          <Chip
            sx={{ '& > *': { p: 2 } }}
            label={<Box display='flex' justifyContent='center'><IconCheck size='1.1rem' /></Box>}
            color='success'
            size='small'
          /> :
          <Chip
            sx={{ '& > *': { p: 2 } }}
            label={<Box display='flex' justifyContent='center'><IconX size='1.1rem' /></Box>}
            color='error'
            size='small'
          />
        }
      </Stack>
    } defaultOpen >
      <Stack spacing={6} p={6} minWidth='50%' width='fit-content'>
        <FormControl required>
          <FormLabel id='demo-controlled-radio-buttons-group' sx={{ fontSize: '1rem', color: theme.palette.text.primary }}>
            {t('segmentation.builder.steps.initialize.desc')}
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby='demo-controlled-radio-buttons-group'
            name='controlled-radio-buttons-group'
            value={props.data.objectType}
            onChange={(value) => {
              props.setData({ ...props.data, objectType: value.target.value as 'deecPerson' | 'deecCompany' });
            }}
          >
            {objectType.map(type => (
              <FormControlLabel
                key={type.type}
                value={type.type}
                control={<Radio checked={props.data.objectType === type.type}/>}
                label={
                  <Stack direction='row' spacing={2} alignItems='center'>
                    {type.icon}
                    <Typography variant='h5'>{t(`${type.label}`)}</Typography>
                  </Stack>
                }
                disabled={props.data.segments.length > 0}
              />
            ))}
          </RadioGroup>
          {props.data.segments.length > 0 &&
            <Stack display='flex' direction='row' justifyContent='center' alignItems='center' spacing={2}>
              <IconAlertTriangle color={theme.palette.warning.main} size='1.1rem' />
              <Typography fontSize={16}>{t('segmentation.vennDiagram.builder.initialize.warningDisabled')}</Typography>
            </Stack>
          }
          {!props.data.objectType && <FormHelperText>{t('common.utils.required')}</FormHelperText>}
        </FormControl>
      </Stack>
    </CustomAccordion>
  );
}

export default InitializeVennDiagram;
