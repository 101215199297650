import React from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { IconFilterPlus } from '@tabler/icons-react';
import { useTheme } from '@mui/material';
import { CustomSegmentationData } from '../../types.segmentations';
import CustomAccordion from '../../../../../components/accordion';

function PreFilteringBuilderSegmentation(props: { customSegmentationData: CustomSegmentationData, setCustomSegmentationData: (data: CustomSegmentationData) => void }) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <CustomAccordion
      title={t('segmentation.builder.prefilters.label')}
      bgcolor={theme.palette.background.default}
      defaultOpen
    >
      <Stack spacing={4} p={4} alignItems='flex-start'>
        {props.customSegmentationData.preFilters.length > 0 ?
          <Stack /> :
          <Button variant='outlined' sx={{ width: 'fit-content' }} startIcon={<IconFilterPlus size='1.2rem' />}>
            {t('segmentation.builder.prefilters.add')}
          </Button>
        }
      </Stack>
    </CustomAccordion>
  );
}

export default PreFilteringBuilderSegmentation;
