import { LinkProps, ThemeOptions } from '@mui/material';
import { merge } from 'lodash';
import CustomLinkBehavior from './link';
import customThemeComponents from './theme';

const customThemeComponentsLinks: ThemeOptions = {
  components: {
    MuiLink: {
      defaultProps: {
        component: CustomLinkBehavior
      } as LinkProps
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: CustomLinkBehavior
      }
    }
  }
};

const customThemeComponentsWithRouter: ThemeOptions = merge(customThemeComponents, customThemeComponentsLinks);

export default customThemeComponentsWithRouter;
