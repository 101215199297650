import React, { ReactElement, useEffect, useState } from 'react';
import { IconArrowsSplit2, IconCirclesRelation, IconUsers } from '@tabler/icons-react';
import { SegmentationData } from '@deecision/dna-interfaces';
import { SegmentationCriteriaSpec } from '@deecision/dna-interfaces/dist/segmentation/segmentationFilters';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { darken } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import IconBuildings from '@/assets/custom/IconBuildings';
import KeyValue from '@/components/keyvalue/keyvalue';
import SegmentationsServices from '../../segmentations/services/segmentations.services';
import ContainerGroupQuickDashboard from './container';
import { basePaperSx, BasePortfolioDashboardProps, dashboardSpacing } from './types';

const companiesPatrimonialComplexitySegmentationCriterias: SegmentationCriteriaSpec[] = [
  {
    'id': 'company_has_asset_complexity',
    'type': 'segmentationCriteria',
    'on': 'company',
    'filterId': 'company_has_asset_complexity'
  },
  {
    'id': 'company_is_dismemberment',
    'type': 'segmentationCriteria',
    'on': 'company',
    'filterId': 'company_is_dismemberment'
  },
  {
    'id': 'company_has_usufructuaries',
    'type': 'segmentationCriteria',
    'on': 'company',
    'filterId': 'company_has_usufructuaries'
  },
  {
    'id': 'company_has_bared_owners',
    'type': 'segmentationCriteria',
    'on': 'company',
    'filterId': 'company_has_bared_owners'
  },
  {
    'id': 'company_asset_complexity_reasons',
    'type': 'segmentationCriteria',
    'on': 'company',
    'filterId': 'company_asset_complexity_reasons'
  },
  {
    'id': 'company_asset_complexity_is_SCI',
    'type': 'segmentationCriteria',
    'on': 'company',
    'filterId': 'company_asset_complexity_is_SCI'
  },
  {
    'id': 'company_asset_complexity_is_holding',
    'type': 'segmentationCriteria',
    'on': 'company',
    'filterId': 'company_asset_complexity_is_holding'
  }
];

const personsPatrimonialComplexitySegmentationCriterias: SegmentationCriteriaSpec[] = [
  {
    'id': 'person_has_asset_complexity',
    'type': 'segmentationCriteria',
    'on': 'person1',
    'filterId': 'person_has_asset_complexity'
  },
  {
    'id': 'person_is_member_of_dismemberment',
    'type': 'segmentationCriteria',
    'on': 'person1',
    'filterId': 'person_is_member_of_dismemberment'
  },
  {
    'id': 'person_is_usufructuary',
    'type': 'segmentationCriteria',
    'on': 'person1',
    'filterId': 'person_is_usufructuary'
  },
  {
    'id': 'person_is_bared_owner',
    'type': 'segmentationCriteria',
    'on': 'person1',
    'filterId': 'person_is_bared_owner'
  },
  {
    'id': 'person_asset_complexity_reasons',
    'type': 'segmentationCriteria',
    'on': 'person1',
    'filterId': 'person_asset_complexity_reasons'
  },
  {
    'id': 'person_asset_complexity_has_SCI',
    'type': 'segmentationCriteria',
    'on': 'person1',
    'filterId': 'person_asset_complexity_has_SCI'
  },
  {
    'id': 'person_asset_complexity_has_holding',
    'type': 'segmentationCriteria',
    'on': 'person1',
    'filterId': 'person_asset_complexity_has_holding'
  }
];

function PatrimonialComplexityPortfolioDashboardContainers(props: BasePortfolioDashboardProps): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const segmentationService = new SegmentationsServices();
  const [personsSegmentationData, setPersonsSegmentationData] = useState<SegmentationData>();
  const [companiesSegmentationData, setCompaniesSegmentationData] = useState<SegmentationData>();
  const personsBasePaperSx = {
    ...basePaperSx,
    bgcolor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    borderColor: darken(theme.palette.primary.light, 0.2),
    minWidth: 'fit-content',
    alignItems: 'center !important',
    cursor: 'pointer',
    '&:hover': {
      bgcolor: darken(theme.palette.primary.light, 0.2)
    }
  };
  const companiesBasePaperSx = {
    ...basePaperSx,
    bgcolor: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    borderColor: darken(theme.palette.secondary.light, 0.2),
    minWidth: 'fit-content',
    alignItems: 'center !important',
    cursor: 'pointer',
    '&:hover': {
      bgcolor: darken(theme.palette.secondary.light, 0.2)
    }
  };

  const performSegmentations = () => {
    segmentationService.perform({
      dataSetId: 'deecPersons',
      outputEntities: 'person1',
      workflowId: 'person1_link_person2',
      filters: [
        {
          id: 'person_groupMember',
          filterId: 'person_groupMember',
          type: 'filter',
          on: 'person1',
          values: [
            `${props.baseGroupId}/persons.withCompanies`
          ]
        }
      ],
      globalFilteringItems: [
      ],
      segmentationCriterias: personsPatrimonialComplexitySegmentationCriterias,
      entitiesSettings: {
        includeEntities: false,
        findOptions: {
          pagingParams: {},
          queryFilters: [],
          sortOptions: []
        }
      }
    })
      .then(res => setPersonsSegmentationData(res.data));

    segmentationService.perform({
      dataSetId: 'deecCompanies',
      outputEntities: 'company',
      workflowId: 'simple_company',
      filters: [
        {
          id: 'company_groupMember',
          filterId: 'company_groupMember',
          type: 'filter',
          on: 'company',
          values: [
            `${props.baseGroupId}/companies`
          ]
        }
      ],
      globalFilteringItems: [
      ],
      segmentationCriterias: companiesPatrimonialComplexitySegmentationCriterias,
      entitiesSettings: {
        includeEntities: false,
        findOptions: {
          pagingParams: {},
          queryFilters: [],
          sortOptions: []
        }
      }
    })
      .then(res => setCompaniesSegmentationData(res.data));
  };

  useEffect(() => {
    performSegmentations();
  }, [props.baseGroupId]);

  return (
    <ContainerGroupQuickDashboard {...props} customIcon={IconCirclesRelation}>
      <Grid container spacing={dashboardSpacing}>
        <Grid size='grow' container spacing={dashboardSpacing} minWidth='fit-content'>
          <Grid size='grow' minWidth='fit-content'>
            <Paper
              sx={personsBasePaperSx}
              onClick={() => props.callSegmentation('deecPerson', 'persons.withCompanies', 'person1_link_person2', ['person_asset_complexity_has_SCI'], ['true'])}
            >
              <KeyValue
                icon={IconUsers}
                value={personsSegmentationData?.segmentationCriterias.person_asset_complexity_has_SCI.values?.find(value => value.value)?.count}
                Key={t('portfolios.dashboard.groups.labels.patrimonialComplexity.personsWithSci.label')}
                size='big'
              />
            </Paper>
          </Grid>
          <Grid size='grow' minWidth='fit-content'>
            <Paper
              sx={companiesBasePaperSx}
              onClick={() => props.callSegmentation('deecCompany', 'companies', 'simple_company', ['company_asset_complexity_is_SCI'], ['true'])}
            >
              <KeyValue
                icon={IconBuildings}
                value={companiesSegmentationData?.segmentationCriterias.company_asset_complexity_is_SCI.values?.find(value => value.value)?.count}
                Key={t('portfolios.dashboard.groups.labels.patrimonialComplexity.sciCompanies.label')}
                size='big'
                color={theme.palette.secondary.dark}
              />
            </Paper>
          </Grid>
        </Grid>
        <Grid size='grow' container spacing={dashboardSpacing} minWidth='fit-content'>
          <Grid size='grow' minWidth='fit-content'>
            <Paper
              sx={personsBasePaperSx}
              onClick={() => props.callSegmentation('deecPerson', 'persons.withCompanies', 'person1_link_person2', ['person_asset_complexity_has_holding'], ['true'])}
            >
              <KeyValue
                icon={IconUsers}
                value={personsSegmentationData?.segmentationCriterias.person_asset_complexity_has_holding.values?.find(value => value.value)?.count}
                Key={t('portfolios.dashboard.groups.labels.patrimonialComplexity.personsWithHolding.label')}
                size='big'
              />
            </Paper>
          </Grid>
          <Grid size='grow' minWidth='fit-content'>
            <Paper
              sx={companiesBasePaperSx}
              onClick={() => props.callSegmentation('deecCompany', 'companies', 'simple_company', ['company_asset_complexity_is_holding'], ['true'])}
            >
              <KeyValue
                icon={IconBuildings}
                value={companiesSegmentationData?.segmentationCriterias.company_asset_complexity_is_holding.values?.find(value => value.value)?.count}
                Key={t('portfolios.dashboard.groups.labels.patrimonialComplexity.holdingCompanies.label')}
                size='big'
                color={theme.palette.secondary.dark}
              />
            </Paper>
          </Grid>
        </Grid>
        <Grid size={12}>
          <Paper
            sx={{
              ...basePaperSx,
              bgcolor: theme.palette.grey['300'],
              height: 'fit-content',
              width: '100%',
              minWidth: 'fit-content',
              alignItems: 'center !important'
            }}
          >
            <Grid container spacing={dashboardSpacing} width='100%'>
              <Grid size={12}>
                <Stack direction='row' spacing={dashboardSpacing} alignItems='center'>
                  <IconArrowsSplit2 />
                  <Typography variant='h4' whiteSpace='nowrap'>
                    {t('portfolios.dashboard.groups.labels.patrimonialComplexity.dismemberment.label')}
                  </Typography>
                </Stack>
              </Grid>
              <Grid size='grow' minWidth='fit-content'>
                <Paper
                  sx={personsBasePaperSx}
                  onClick={() => props.callSegmentation('deecPerson', 'persons.withCompanies', 'person1_link_person2', ['person_is_bared_owner'], ['true'])}
                >
                  <KeyValue
                    icon={IconUsers}
                    value={personsSegmentationData?.segmentationCriterias.person_is_bared_owner.values?.find(value => value.value)?.count}
                    Key={t('portfolios.dashboard.groups.labels.patrimonialComplexity.dismemberment.personsAreBareOwners.label')}
                    size='big'
                  />
                </Paper>
              </Grid>
              <Grid size='grow' minWidth='fit-content'>
                <Paper
                  sx={companiesBasePaperSx}
                  onClick={() => props.callSegmentation('deecCompany', 'companies', 'simple_company', ['company_is_dismemberment'], ['true'])}
                >
                  <KeyValue
                    icon={IconBuildings}
                    value={companiesSegmentationData?.segmentationCriterias.company_is_dismemberment.values?.find(value => value.value)?.count}
                    Key={t('portfolios.dashboard.groups.labels.patrimonialComplexity.dismemberment.companies.label')}
                    size='big'
                    color={theme.palette.secondary.dark}
                  />
                </Paper>
              </Grid>
              <Grid size='grow' minWidth='fit-content'>
                <Paper
                  sx={personsBasePaperSx}
                  onClick={() => props.callSegmentation('deecPerson', 'persons.withCompanies', 'person1_link_person2', ['person_is_usufructuary'], ['true'])}
                >
                  <KeyValue
                    icon={IconUsers}
                    value={personsSegmentationData?.segmentationCriterias.person_is_usufructuary.values?.find(value => value.value)?.count}
                    Key={t('portfolios.dashboard.groups.labels.patrimonialComplexity.dismemberment.personsAreUsufructuary.label')}
                    size='big'
                  />
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </ContainerGroupQuickDashboard>
  );
}

export default PatrimonialComplexityPortfolioDashboardContainers;
