import React, { ReactElement, useEffect, useState } from 'react';
import { GroupAllValues, GroupBaseValues } from '@deecision/dna-interfaces';
import { CircularProgress } from '@mui/material';
import { darken, lighten, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import { IconCornerDownRight, IconTopologyRing2, IconUsers } from '@tabler/icons-react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { currencyFormatter } from '@/utils';
import GroupsServices from '../../../modules/deetect/portfolios/services';
import KeyValue, { KeyValueProps } from '@/components/keyvalue/keyvalue';
import PROXEMEE from '../../../../assets/PRXMEE.svg';
import {
  basePaperSx,
  BasePortfolioDashboardProps,
  CustomGroupValues,
  dashboardSpacing
} from './types';
import KeyValueList from '@/components/keyvalue/keyvaluelist';
import BaseWidget from '@/main/containers/widgets/base-widget';
import TitleComponent from '@/components/title';

export function getProxemeeSubGroups(group: CustomGroupValues): CustomGroupValues[] {
  return group.subGroups?.filter(subGroup => (subGroup.subGroupId?.includes('personal') || subGroup.subGroupId?.includes('business') || subGroup.subGroupId?.includes('extended'))) || [];
}

function PortfolioDashboardProxemeeSubGroup(props: { label?: string, full?: boolean, color?: string, baseGroupId: string, groupId?: string, size: KeyValueProps['size'] } & Pick<BasePortfolioDashboardProps, 'callSegmentation'>): ReactElement {
  const { t } = useTranslation();
  const groupsServices = new GroupsServices();
  const [values, setValues] = useState<GroupAllValues>();

  useEffect(() => {
    if (props.groupId) {
      groupsServices.getGroupValues(props.baseGroupId, props.groupId)
        .then(res => setValues(res.data));
    }
  }, []);

  return values ? (
    <Box
      onClick={(e) => {
        e.stopPropagation();
        props.callSegmentation?.(values.groupDesc.objectType, props.groupId);
      }}
      sx={{
        pt: props.full ? 1 : 0,
        pb: props.full ? 1 : 0,
        cursor: 'pointer',
        '&:hover': {
          color: 'black'
        }
      }}
    >
      {props.full ? (
        <Stack spacing={dashboardSpacing}>
          <KeyValue
            Key={props.label}
            value={values?.values?.main.count || 0}
            size='big'
            keySize='big'
          />
          <KeyValueList
            values={[
              [
                {
                  key: t('common.financial.weasse.sum'),
                  keyColor: props.color,
                  value: currencyFormatter({
                    value: values?.values?.main.weasse_sum,
                    currency: 'EUR'
                  })
                },
                {
                  key: t('common.financial.weasseMax.sum'),
                  keyColor: props.color,
                  value: currencyFormatter({
                    value: values?.values?.main.weasseMax_sum,
                    currency: 'EUR'
                  })
                }
              ]
            ]}
          />
          <Paper
            sx={{
              width: '100%',
              bgcolor: 'transparent',
              borderColor: props.color ? lighten(props.color, 0.2) : undefined,
              color: props.color,
              fontWeight: 500
            }}
          >
            <KeyValueList
              values={[
                (values?.values &&
                  Object.keys(values?.values).filter(
                    value => value !== 'main' && value !== 'totalCount'
                  ).length > 0 &&
                  Object.keys(values?.values)
                    .filter(
                      value => value !== 'main' && value !== 'totalCount'
                    )
                    .map(value => [
                      {
                        key: t(
                          `portfolios.dashboard.groups.labels.${value}.label`
                        ),
                        keyColor: props.color,
                        value:
                          (
                            values.values[
                              value as keyof typeof values.values
                            ] as Record<string, number>
                          ).count || 0
                      },
                      {
                        key: t('common.financial.valuation.sum'),
                        keyColor: props.color,
                        value: currencyFormatter({
                          value: (
                            values.values[
                              value as keyof typeof values.values
                            ] as GroupBaseValues
                          )?.valuationSum,
                          currency: 'EUR'
                        })
                      },
                      {
                        key: t('common.financial.valuation.sumOfSum'),
                        keyColor: props.color,
                        value: currencyFormatter({
                          value: (
                            values.values[
                              value as keyof typeof values.values
                            ] as GroupBaseValues
                          )?.valuationSum_sum,
                          currency: 'EUR'
                        })
                      },
                      {
                        key: t('common.financial.ebitda.sum'),
                        keyColor: props.color,
                        value: currencyFormatter({
                          value: (
                            values.values[
                              value as keyof typeof values.values
                            ] as GroupBaseValues
                          )?.ebitdaSum,
                          currency: 'EUR'
                        })
                      },
                      {
                        key: t('common.financial.capital.sum'),
                        keyColor: props.color,
                        value: currencyFormatter({
                          value: (
                            values.values[
                              value as keyof typeof values.values
                            ] as GroupBaseValues
                          )?.capitalSum,
                          currency: 'EUR'
                        })
                      },
                      {
                        key: t('common.financial.turnover.sum'),
                        keyColor: props.color,
                        value: currencyFormatter({
                          value: (
                            values.values[
                              value as keyof typeof values.values
                            ] as GroupBaseValues
                          )?.turnoverSum,
                          currency: 'EUR'
                        })
                      }
                    ])
                    .flat(1)) ||
                  []
              ]}
            />
          </Paper>
        </Stack>
      ) : (
        <KeyValue value={values.values.totalCount || 0} size={props.size} />
      )}
    </Box>
  ) : (
    <CircularProgress
      size={props.size === 'big' ? 28 : props.size === 'normal' ? 20 : 16}
      color='inherit'
    />
  );
}

function PortfolioDashboardProxemee(props: { full?: boolean } & BasePortfolioDashboardProps): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const [possibleGroups, setPossibleGroups] = useState<(string | undefined)[]>([]);
  const [possibleSubGroups, setPossibleSubGroups] = useState<{ id?: string, subIds: (string | undefined)[]}[]>([]);

  const colors = [
    {
      color: theme.palette.success.dark,
      bgColor: theme.palette.success.light
    },
    {
      color: theme.palette.warning.dark,
      bgColor: theme.palette.warning.light
    },
    {
      color: theme.palette.error.dark,
      bgColor: theme.palette.error.light
    }
  ];

  useEffect(() => {
    setPossibleGroups(uniq(getProxemeeSubGroups(props.group).map(group => group.subGroups?.map(subGroup => subGroup.subGroupId.split('.').at(-1))).flat(1)));
  }, [props.group]);

  useEffect(() => {
    setPossibleSubGroups(possibleGroups.map(possibleGroup => ({
      id: possibleGroup,
      subIds: uniq(getProxemeeSubGroups(props.group)
        .filter(group => (group.subGroups?.find(subGroup => subGroup.subGroupId.split('.').at(-1) === possibleGroup)?.subGroups?.length || 0) > 0)
        .map(group => (group.subGroups?.find(subGroup => subGroup.subGroupId.split('.').at(-1) === possibleGroup)?.subGroups?.map(subGroup => subGroup.subGroupId.split('.').at(-1))))
        .flat(1))
    })));
  }, [possibleGroups]);

  return (
    <BaseWidget
      title={
        <TitleComponent
          title={
            <Stack direction='row' spacing={2} alignItems='center' height={20}>
              <IconTopologyRing2 />
              <img src={PROXEMEE} alt='PROXEMEE' height={20} width={80} />
              <Typography variant='h5'>{t('proxemee.desc')}</Typography>
            </Stack>
          }
          icon={IconUsers}
          size='small'
        />
      }
      link={!props.full ? '../proxemee' : undefined}
      fullWidth
    >
      <Grid container spacing={2} minWidth='fit-content' minHeight='100%'>
        <Grid
          container
          spacing={dashboardSpacing}
          size={12}
          minWidth='max-content'
        >
          {!props.full && (
            <Grid
              container
              minWidth={120}
              width='min-content'
              alignContent='flex-start'
            >
              <Grid size={12} height={30} />
              <Grid size={12}>
                <Paper
                  sx={{
                    ...basePaperSx,
                    bgcolor: 'transparent',
                    borderColor: 'transparent'
                  }}
                >
                  <Box width='100%'>
                    <Grid container spacing={dashboardSpacing}>
                      <Grid size={12}>
                        <Box height={23} />
                      </Grid>
                      {possibleGroups.map(possibleGroup => (
                        <Grid key={`label-${possibleGroup}`} size={12}>
                          <Paper
                            sx={{
                              ...basePaperSx,
                              bgcolor: 'transparent',
                              borderColor: 'transparent'
                            }}
                          >
                            <Stack
                              spacing={dashboardSpacing / 2}
                              height={
                                (possibleSubGroups.find(
                                  subGroup => subGroup.id === possibleGroup
                                )?.subIds.length || 0) > 0
                                  ? 60
                                  : 33
                              }
                              width='100%'
                              alignItems='center'
                              justifyContent='center'
                            >
                              <Typography width='100%' variant='h5'>
                                {t(
                                  `portfolios.dashboard.groups.labels.${possibleGroup}.label`
                                )}
                              </Typography>
                              {possibleSubGroups
                                .find(
                                  subGroup => subGroup.id === possibleGroup
                                )
                                ?.subIds.map(subId => (
                                  <Stack
                                    key={subId}
                                    spacing={dashboardSpacing}
                                    direction='row'
                                    alignItems='center'
                                  >
                                    <IconCornerDownRight size='1.2rem' />
                                    <Typography noWrap mt='4px !important'>
                                      {t(
                                        `portfolios.dashboard.groups.labels.${subId}.label`
                                      )}
                                    </Typography>
                                  </Stack>
                                ))}
                            </Stack>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          )}
          {getProxemeeSubGroups(props.group).map((group, index) => (
            <Grid
              key={group.subGroupId}
              container
              spacing={dashboardSpacing}
              width='min-content'
            >
              {!props.full && (
                <Grid size={12}>
                  <Typography
                    textAlign='center'
                    variant={props.full ? 'h4' : 'h5'}
                  >
                    {t(
                      `portfolios.dashboard.groups.labels.${group.subGroupId.split('.').at(-1)}.label`
                    )}
                  </Typography>
                </Grid>
              )}
              <Grid size={12}>
                <Paper
                  sx={{ ...basePaperSx, bgcolor: theme.palette.grey['300'] }}
                >
                  <Box width='100%' height='100%'>
                    <Grid container spacing={dashboardSpacing} height='100%'>
                      <Grid size={12} display='flex' justifyContent='center'>
                        <PortfolioDashboardProxemeeSubGroup
                          {...props}
                          label={
                            props.full
                              ? t(
                                `portfolios.dashboard.groups.labels.${group.subGroupId.split('.').at(-1)}.label`
                              )
                              : undefined
                          }
                          groupId={group.subGroupId}
                          size='normal'
                        />
                      </Grid>
                      {possibleGroups.map(possibleGroup => (
                        <Grid
                          key={`${group.subGroupId}-${possibleGroup}`}
                          size={12}
                        >
                          <Paper
                            sx={{
                              ...basePaperSx,
                              bgcolor: colors[index].bgColor,
                              borderColor: darken(colors[index].bgColor, 0.2),
                              pl: 2,
                              pr: 2
                            }}
                          >
                            <Stack
                              spacing={dashboardSpacing / 2}
                              justifyContent='center'
                              width='100%'
                            >
                              <Box
                                sx={{ color: colors[index].color }}
                                display='flex'
                                justifyContent='center'
                              >
                                <PortfolioDashboardProxemeeSubGroup
                                  {...props}
                                  label={t(
                                    `portfolios.dashboard.groups.labels.${possibleGroup}.label`
                                  )}
                                  color={colors[index].color}
                                  groupId={
                                    group.subGroups?.find(
                                      subGroup =>
                                        subGroup.subGroupId
                                          .split('.')
                                          .at(-1) === possibleGroup
                                    )?.subGroupId
                                  }
                                  size='normal'
                                />
                              </Box>
                              {group.subGroups
                                ?.find(
                                  subGroup =>
                                    subGroup.subGroupId.split('.').at(-1) ===
                                      possibleGroup
                                )
                                ?.subGroups?.map(subGrp => (
                                  <Stack
                                    key={subGrp.subGroupId}
                                    spacing={dashboardSpacing / 2}
                                    direction='row'
                                    alignItems={
                                      props.full ? 'flex-start' : 'center'
                                    }
                                    justifyContent='center'
                                    width='100%'
                                    sx={{ color: colors[index].color }}
                                  >
                                    <IconCornerDownRight size='1.2rem' />
                                    <Typography noWrap mt='4px !important'>
                                      <PortfolioDashboardProxemeeSubGroup
                                        {...props}
                                        label={t(
                                          `portfolios.dashboard.groups.labels.${subGrp.subGroupId.split('.').at(-1)}.label`
                                        )}
                                        color={colors[index].color}
                                        groupId={subGrp.subGroupId}
                                        size='small'
                                      />
                                    </Typography>
                                  </Stack>
                                ))}
                            </Stack>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </BaseWidget>
  );
}

export default PortfolioDashboardProxemee;
