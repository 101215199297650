import React, { ReactElement, useEffect, useState } from 'react';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import { BodaccDataBlock, IDataBlock } from '@deecision/dna-interfaces';
import Stack from '@mui/material/Stack';
import { uniqWith } from 'lodash';
import Timeline from '@/components/steppers/timeline';
import BodaccHeader from '../../../../../containers/bodacc/header';
import { FilterGroupChoices } from '@/main/containers/bodacc/types';
import { bodaccDataSort, getParsedData, getYearsBodacc } from '@/main/containers/bodacc/utils';
import BodaccFilters from '../../../../../containers/bodacc/filters';
import BodaccAccordions from '../../../../../containers/bodacc/accordions';

function BodaccTabsComponents(): ReactElement {
  const data = useLoaderData() as { data: IDataBlock<BodaccDataBlock>[] };
  const [sortedData, setSortedData] = useState<IDataBlock<BodaccDataBlock>[]>([]);
  const [parsedData, setParsedData] = useState<IDataBlock<BodaccDataBlock>[]>([]);
  const [filters, setFilters] = useState<FilterGroupChoices<BodaccDataBlock>[]>([]);
  const [selectedId, setSelectedId] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCriticity, setSelectedCriticity] = useState(searchParams.get('criticity') || '');

  useEffect(() => {
    setSortedData(uniqWith(data.data, (a, b) => (a.data.id === b.data.id)).sort(bodaccDataSort));
  }, [data]);

  useEffect(() => {
    setParsedData(getParsedData(sortedData, filters));
  }, [filters, sortedData]);

  useEffect(() => {
    if (selectedCriticity === '') {
      searchParams.delete('criticity');
    } else {
      searchParams.set('criticity', selectedCriticity);
    }
    setSearchParams(searchParams);
  }, [selectedCriticity]);

  useEffect(() => {
    if (searchParams.get('criticity') !== selectedCriticity) {
      setSelectedCriticity(searchParams.get('criticity') || '');
    }
  }, [searchParams]);

  return (
    <Stack spacing={4}>
      {sortedData.length > 0 &&
        <>
          <BodaccHeader
            datas={sortedData}
            parsedDatas={parsedData}
            selectedCriticity={selectedCriticity}
            selectCriticity={
              (c: string) => setSelectedCriticity(prevState => (
                c === prevState ? '' : c
              ))
            }
          />
          <BodaccFilters
            datas={selectedCriticity !== '' ? sortedData.filter(d => d.data._deecision?.criticity === selectedCriticity) : sortedData}
            parsedData={selectedCriticity !== '' ? parsedData.filter(d => d.data._deecision?.criticity === selectedCriticity) : parsedData}
            setFilters={(f: FilterGroupChoices<BodaccDataBlock>[]) => setFilters(f)}
          />
        </>
      }
      {(selectedCriticity !== '' ? parsedData.filter(d => d.data._deecision?.criticity === selectedCriticity) : parsedData).length > 0 &&
        <>
          <Timeline
            idPath='data.id'
            datas={selectedCriticity !== '' ? parsedData.filter(d => d.data._deecision?.criticity === selectedCriticity) : parsedData}
            datePath='dataInfo.dataTimestamp'
            tooltipPath='data.familleavis_lib'
            years={getYearsBodacc(selectedCriticity !== '' ? parsedData.filter(d => d.data._deecision?.criticity === selectedCriticity) : parsedData)}
            select={(id: string) => setSelectedId(id)}
            criticityPath='data._deecision.criticity'
          />
          <BodaccAccordions
            datas={selectedCriticity !== '' ? parsedData.filter(d => d.data._deecision?.criticity === selectedCriticity) : parsedData}
            selectedId={selectedId}
          />
        </>
      }
    </Stack>
  );
}

export default BodaccTabsComponents;
