import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import SimpleDisplaySort, { SimpleSort } from '@/components/sort';
import { TreeLinksProps } from '@/main/containers/network/trees/simple/types';
import TreeLinksLayer from '@/main/containers/network/trees/simple/links';
import SimpleDisplayFilters, { SimpleFilter } from '@/components/filters/simple';
import { ElementBaseStyle, ElementOnFullscreenStyle, filterBaseStyle, filterOnFullscreenStyle, useFullscreen } from '@/wrappers/fullscreen';
import FullscreenButton from '@/components/charts/utils/fullscreenButton';

function LinksTrees(props: Omit<TreeLinksProps, 'service'>): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isFullscreen } = useFullscreen();
  const [filters, setFilters] = useState<SimpleFilter[]>([
    {
      label: t('filters.showActive'),
      id: 'showActive',
      active: false
    },
    {
      label: t('filters.showInactive'),
      id: 'showInactive',
      active: false
    }
  ]);
  const [sortList, setSortList] = useState<SimpleSort[]>([
    {
      id: 'name',
      label: t('filters.name'),
      direction: 'asc'
    },
    {
      id: 'shares',
      label: t('filters.shares')
    }
  ]);

  return (
    <Stack spacing={isFullscreen ? 0 : 4} width='100%' height='max-content'>
      <Stack className='filters' direction='row' spacing={2} justifyContent='space-between' alignItems='center'>
        <Stack direction='row' spacing={4} style={ isFullscreen ? { ...filterOnFullscreenStyle, ...{ left: 0 } } : filterBaseStyle }>
          <FullscreenButton />
          <SimpleDisplaySort sortList={sortList} setSortList={setSortList} />
          <SimpleDisplayFilters filters={filters} setFilters={setFilters} />
        </Stack>
      </Stack>
      <Stack className='chart' style={ isFullscreen ? ElementOnFullscreenStyle : ElementBaseStyle }>
        <TreeLinksLayer
          {...props}
          filters={filters}
          sortList={sortList}
          parentEntities={[props.entity.entityId]}
        >
          <Tooltip title={props.entity.name} placement='top' arrow>
            <Paper variant='hoverElevation3' sx={{ boxShadow: 2, bgcolor: theme.palette.primary.main, pr: 4, height: 'min-content', width: 500 }}>
              <Typography variant='h5' color={theme.palette.primary.contrastText} sx={{ textAlign: 'center' }} noWrap>
                {props.entity.name}
              </Typography>
            </Paper>
          </Tooltip>
        </TreeLinksLayer>
      </Stack>
    </Stack>
  );
}

export default LinksTrees;
