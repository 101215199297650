import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PersonEntity, ReechData } from '@deecision/dna-interfaces';
import { IconBinaryTree2 } from '@tabler/icons-react';
import { ReactFlow, Controls, ControlButton } from '@xyflow/react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import type { Edge, Node } from '@xyflow/react/dist/esm/types';
import { ReechClientType } from '@deecision/dna-interfaces/dist/reech/reech';
import { WidgetProps } from '@/main/containers/widgets/types';
import BaseWidget from '@/main/containers/widgets/base-widget';
import TitleComponent from '@/components/title';
import '@xyflow/react/dist/style.css';
import FloatingEdge from './edge';
import { createNodesAndEdges, EdgeData, hideEdge, hideNode, NodeData } from './utils';
import './index.css';
import ReechService from '@/main/containers/widgets/reech/service';
import { MainEntityNode, ReechTargetNode, ReechTypeNode } from '@/main/containers/widgets/reech/node';
import { getEntityPath } from '@/main/providers/getter';
import { baseChartHeight } from '@/components/charts/utils';
import FullscreenButton from '@/components/charts/utils/fullscreenButton';
import { useFullscreen } from '@/wrappers/fullscreen';
import dnaConfig from '@/config/dna.config.json';
import DownloadButton from '@/main/containers/widgets/reech/download';

function WidgetsReech(props: WidgetProps<PersonEntity> & { simple?: boolean }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const reechService = new ReechService();
  const { isFullscreen, setIsFullscreen } = useFullscreen();
  const [nodes, setNodes] = useState<Node<NodeData>[]>([]);
  const [edges, setEdges] = useState<Edge<EdgeData>[]>([]);

  const nodeTypes = {
    mainEntity: MainEntityNode,
    reechType: ReechTypeNode,
    reechTarget: ReechTargetNode
  };

  const getNodesAndEdges = (reechData?: ReechData[]) => {
    const datas = {
      name: `${props.data.attributes?.names.firstName[0]?.toUpperCase()}${props.data.attributes?.names.lastName[0]?.toUpperCase()}`,
      totalCount: reechData?.reduce((acc, curr) => acc + curr.targets.length, 0) || 0,
      nodes: reechData
    };

    const nodesAndEdges = createNodesAndEdges(datas, t, theme);

    setNodes(nodesAndEdges.nodes);
    setEdges(nodesAndEdges.edges);
  };

  const getReechData = () => {
    reechService.getAll({ entityRef: { ...props.data } })
      .then(res => getNodesAndEdges(res.data));
  };

  const handleNodeClick = useCallback((event: React.MouseEvent<Element, MouseEvent>, node: Node<NodeData>) => {
    if (node.data.entity) {
      navigate(getEntityPath(node.data.entity, true));
    } else if (nodes.filter(nd => nd.id !== node.id && nd.id.includes(node.id)).every(nd => nd.hidden)) {
      setNodes(prev => prev.map(hideNode(node.id, false)));
      setEdges(prev => prev.map(hideEdge(node.id, false)));
    } else {
      setNodes(prev => prev.map(hideNode(node.id, true)));
      setEdges(prev => prev.map(hideEdge(node.id, true)));
    }
  }, [nodes]);

  useEffect(() => {
    getReechData();
  }, [props.data]);

  const ReechComponent = useMemo(() => (
    <Stack
      spacing={2}
      alignContent='flex-start'
      sx={isFullscreen ? {
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 9998,
        backgroundColor: '#F8F9FA',
        pt: 1,
        paddingLeft: '5px',
        paddingRight: '5px'
      } : undefined}
    >
      <Box
        sx={{
          '& .react-flow__node': {
            '&:hover': {
              zIndex: '1 !important',
              boxShadow: 2
            },
            transition: 'box-shadow 0.2s ease-in-out'
          }
        }}
        onClick={e => e.stopPropagation()}
      >
        <div
          className='floatingedges'
          style={isFullscreen
            ? {
              height: 'calc(100vh - 48px)'
            }
            : {
              height: nodes.filter(node => node.data.clientType === ReechClientType.EX_CLIENT && !node.hidden).length > 2 && nodes.filter(node => node.data.clientType === ReechClientType.PROSPECT && !node.hidden).length > 2
                ? baseChartHeight * 3
                : (nodes.filter(node => node.data.clientType === ReechClientType.EX_CLIENT && !node.hidden).length > 2 || nodes.filter(node => node.data.clientType === ReechClientType.PROSPECT && !node.hidden).length > 2 || nodes.filter(node => node.data.clientType === ReechClientType.CLIENT && node.data.entityType === 'deecPerson' && !node.hidden).length > 10 || nodes.filter(node => node.data.clientType === ReechClientType.CLIENT && node.data.entityType === 'deecCompany' && !node.hidden).length > 10)
                  ? baseChartHeight * 2
                  : baseChartHeight,
              width: '100%'
            }}
        >
          <ReactFlow
            key={`${JSON.stringify(nodes)}-${isFullscreen}`}
            nodes={nodes}
            nodeTypes={nodeTypes}
            edges={edges}
            edgeTypes={{
              floating: FloatingEdge
            }}
            fitView
            onlyRenderVisibleElements
            elementsSelectable={false}
            maxZoom={1}
            preventScrolling={false}
            proOptions={{ hideAttribution: true }}
            onNodeClick={handleNodeClick}
            zoomOnScroll={false}
            zoomActivationKeyCode='ControlLeft'
          >
            <Controls showFitView={false} showInteractive={false} position='top-left'>
              <ControlButton>
                <DownloadButton name={props.data.name} />
              </ControlButton>
              <ControlButton onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.stopPropagation();
                setIsFullscreen(!isFullscreen);
              }}>
                <FullscreenButton noBorder />
              </ControlButton>
            </Controls>
          </ReactFlow>
        </div>
      </Box>
    </Stack>
  ), [nodes, edges, isFullscreen]);

  return (props.simple ?
    ReechComponent :
    <BaseWidget
      {...props}
      title={<TitleComponent title='Reech' icon={IconBinaryTree2} disabled={nodes.length < 8} small />}
      link={`${getEntityPath(props.data, true)}/${dnaConfig.routes.persons.tabs.network.baseUrl}?networkType=reech`}
      size='small'
      disabled={nodes.length < 8}
    >
      {ReechComponent}
    </BaseWidget>
  );
}

export default WidgetsReech;
