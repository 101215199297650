import React, { ReactElement, useMemo, useState } from 'react';
import { IDataBlock, FinancialReportDataBlock } from '@deecision/dna-interfaces';
import Grid from '@mui/material/Grid2';
import FinancialGrid from '@/main/containers/financial/grid';
import FinancialYearList from '@/main/containers/financial/list';
import Selector from '@/components/selector';
import { getPathList, parseAndSortFinancialData } from '@/main/containers/financial/utils';
import FinancialChart, { baseSelectedKeys } from '@/main/containers/financial/chart';

function Financial(props: { data: IDataBlock<FinancialReportDataBlock>[] }): ReactElement {
  const [selectedKey, setSelectedKey] = useState(baseSelectedKeys);
  const [view, setView] = useState('grid');
  const dataSortedAndParsed = useMemo(() => parseAndSortFinancialData(props.data), [props.data]);
  const pathList = useMemo(() => getPathList(dataSortedAndParsed), [dataSortedAndParsed]);

  return (
    <Grid container spacing={4}>
      <Grid size='grow'>
        <FinancialChart data={dataSortedAndParsed} selectedKey={selectedKey} setSelectedKey={setSelectedKey} pathList={pathList} />
      </Grid>
      <Grid size={12}>
        <Selector
          options={[{ label: 'Grid', value: 'grid' }, { label: 'Per Year', value: 'perYear' }]}
          option={view}
          setOption={option => setView(option)}
        />
      </Grid>
      <Grid size={12}>
        {{
          perYear: <FinancialYearList datas={dataSortedAndParsed} selectedKey={selectedKey} setSelectedKey={setSelectedKey}/>,
          grid: <FinancialGrid datas={dataSortedAndParsed} selectedKey={selectedKey} setSelectedKey={setSelectedKey} pathList={pathList} />
        }[view]}
      </Grid>
    </Grid>
  );
}

export default Financial;
