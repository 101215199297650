import React from 'react';
import {  Paper, Stack, Typography, useTheme, lighten } from '@mui/material';
import { Handle, Position, useStore } from 'reactflow';
import { IconAlertTriangle } from '@tabler/icons-react';
import { round } from 'lodash';

export function WarningShaParentNode(data: { data: {shareholderTotalPercentage: number | undefined} }) {
  const theme = useTheme();
  const zoomLevel = useStore((state: { transform: number[] }) => state.transform[2]);

  return (
    <Paper variant='hoverElevation2' sx={{
      boxShadow: 1,
      border: 'dashed',
      borderColor: theme.palette.warning.main,
      backgroundColor: lighten(theme.palette.warning.light, 0.23),
      width: '500px',
      height: '108px',
      p: 1,
      pl: 4,
      pr: 4
    }}>
      <Handle id='top' type='source' position={Position.Right} isConnectable style={{ visibility: 'hidden' }} />
      <Stack height='100%' spacing={1} alignItems='center' justifyContent='center' sx={{ ml: '-8px !important' }}>
        <IconAlertTriangle size='1.9rem' color={theme.palette.warning.main} />
        <Typography variant='h5' align='center' fontSize={zoomLevel < 0.5 ? '2rem' : '1.2rem'} pl={2} mr={2} noWrap>
          Missing {`${data.data.shareholderTotalPercentage ? round(100 - data.data.shareholderTotalPercentage, 2) : 100 }%`} of Shareholders
        </Typography>
      </Stack>
      <Handle id='left' type='target' position={Position.Left} isConnectable style={{ visibility: 'hidden' }} />
    </Paper>
  );
}
