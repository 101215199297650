import React, { memo } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Handle, Position } from '@xyflow/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { NodeBase, NodeProps } from '@xyflow/system/dist/esm/types/nodes';
import { startCase } from 'lodash';
import { Tooltip } from '@mui/material';
import { IconBuildings, IconUsers } from '@tabler/icons-react';
import Box from '@mui/material/Box';
import { NodeData } from '@/main/containers/widgets/reech/utils';
import Status from '@/components/status';
import { CompanyGetter, PersonGetter } from '@/main/providers/getter';

export const MainEntityNode = memo((props: NodeProps<NodeBase<NodeData>>) => (
  <>
    <Handle
      type='source'
      position={Position.Right}
      id={props.id}
      isConnectable={props.isConnectable}
    />
    <Stack spacing={1} alignItems='center'>
      <Typography variant='h5' textAlign='center'>{props.data.label}</Typography>
      <Typography variant='body2' textAlign='center'>{props.data.totalCount || 0}</Typography>
    </Stack>
  </>
));

export const ReechTypeNode = memo((props: NodeProps<NodeBase<NodeData>>) => (
  <>
    <Handle
      type='source'
      position={Position.Right}
      id={props.id}
      isConnectable={props.isConnectable}
    />
    <Stack spacing={1} alignItems='center' p={2}>
      {props.data.entityType === 'deecPerson' ?
        <IconUsers size='1.2rem' /> :
        <IconBuildings size='1.2rem' />
      }
      <Typography textAlign='center' variant='body2'>{props.data.label}</Typography>
      <Typography textAlign='center' variant='body2'>{props.data.totalCount || 0}</Typography>
    </Stack>
    <Handle
      type='target'
      position={Position.Left}
      id={props.id}
      isConnectable={props.isConnectable}
    />
  </>
));

export const ReechTargetNode = memo((props: NodeProps<NodeBase<NodeData>>) => {

  const getter = props.data.entity?.entityType === 'deecPerson' ? new PersonGetter({
    data: props.data.entity,
    errorMsg: '-'
  }) : props.data.entity?.entityType === 'deecCompany' ?new CompanyGetter({
    data: props.data.entity,
    errorMsg: '-'
  }) : undefined;

  return (
    <>
      <Tooltip title={props.data.entity?.name} arrow placement='top'>
        <Stack
          p={2}
          height='100%'
          width='100%'
          alignItems='center'
          justifyContent='center'
        >
          <Typography
            sx={{
              textDecoration: 'none',
              color: 'inherit',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            maxWidth='100%'
            maxHeight='90%'
            variant='h6'
            textAlign='center'
            overflow='hidden'
            textOverflow='ellipsis'
          >
            {props.data.entity?.entityType === 'deecPerson' ?
              `${startCase(props.data.entity.attributes?.names.firstName)} ${startCase(props.data.entity.attributes?.names.lastName)}` :
              props.data.entity?.name
            }
          </Typography>
        </Stack>
      </Tooltip>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0
        }}
      >
        <Status
          statusAsInt={
            getter?.getVeegilenzReason() === 'atLeastOneBlackFact' && 3 ||
            getter?.getVeegilenzReason() === 'atLeastOneRedFact' && 2 ||
            getter?.getVeegilenzReason() === 'atLeastOneOrangeFact' && 1 ||
            getter?.getVeegilenzReason() === 'moreThanTreeOrangeFacts' && 1 ||
            getter?.getVeegilenzReason() === 'onlyGreenFacts' && 0 ||
            0
          }
          size='1.2rem'
        />
      </Box>
      <Handle
        type='target'
        position={Position.Left}
        id={props.id}
        isConnectable={props.isConnectable}
      />
    </>
  );
});
