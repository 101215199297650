import React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Tooltip } from '@mui/material';
import { mainRadius } from '@/theme/theme';

interface Option {
  label: string,
  value: string,
  tooltip?: string
}

interface SelectorLevelsRenderSegmentationsProps {
  options: Option[],
  option: string | undefined,
  setOption: (option: string) => void,
  inverted?: boolean
}

function Selector(props: SelectorLevelsRenderSegmentationsProps) {
  return (
    <Paper
      sx={{
        p: !props.inverted ? 0 : 2,
        border: !props.inverted ? 'none' : undefined,
        borderRadius: 0,
        borderBottom: 'none',
        bgcolor: !props.inverted ? 'transparent' : 'background.default',
        width: '100%',
        height: '100%',
        overflow: 'auto'
      }}
    >
      <Paper
        sx={{
          p: 1,
          borderRadius: 1,
          bgcolor: !props.inverted ? 'background.default' : 'background.paper'
        }}
      >
        <Stack
          direction='row'
          spacing={2}
          alignItems='center'
          width='100%'
        >
          {props.options.map(option => (
            <Tooltip key={option.value} title={option.tooltip} arrow placement='top'>
              <Button
                variant='contained'
                onClick={() => props.setOption(option.value)}
                sx={{
                  bgcolor: props.option === option.value ? !props.inverted ? 'background.paper' : 'background.default' : !props.inverted ? 'background.default' : 'background.paper',
                  color: 'text.primary',
                  border: 'none',
                  borderRadius: `${mainRadius - 4}px`,
                  boxShadow: 'none',
                  '&:hover': {
                    bgcolor: !props.inverted ? 'background.paper' : 'background.default',
                    boxShadow: 'none'
                  }
                }}
                size='small'
              >
                <Typography variant='h4'>
                  {option.label}
                </Typography>
              </Button>
            </Tooltip>

          ))}
        </Stack>
      </Paper>
    </Paper>
  );
}

export default Selector;
