import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import SimpleDisplayFilters, { SimpleFilter } from '@/components/filters/simple';
import RelationsChart, { RelationsChartProps } from './chart';
import { ElementBaseStyle, ElementOnFullscreenStyle, filterBaseStyle, filterOnFullscreenStyle, useFullscreen } from '@/wrappers/fullscreen';
import FullscreenButton from '@/components/charts/utils/fullscreenButton';

function CirclesNetwork(props: Omit<RelationsChartProps, 'filters'>): ReactElement {
  const { t } = useTranslation();
  const { isFullscreen } = useFullscreen();
  const [filters, setFilters] = useState<SimpleFilter[]>([
    {
      label: t('filters.showActive'),
      id: 'showActive',
      active: false
    },
    {
      label: t('filters.showInactive'),
      id: 'showInactive',
      active: false
    }
  ]);

  return (
    <Stack spacing={ isFullscreen ? 0 : 4} width='100%' height='max-content'>
      <Stack direction='row' spacing={4} style={ isFullscreen ? { ...filterOnFullscreenStyle, ...{ left: 0 } } : filterBaseStyle }>
        <FullscreenButton />
        <SimpleDisplayFilters filters={filters} setFilters={setFilters} buttonLabel={t('filters.addFilter')} />
      </Stack>
      <Stack style={ isFullscreen ? ElementOnFullscreenStyle : ElementBaseStyle }>
        <RelationsChart filters={filters} {...props} />
      </Stack>
    </Stack>
  );
}

export default CirclesNetwork;
