import { getCountryISO2 } from '@/utils/getCountryISO2';

export function getFlagEmoji(countryCode: string | undefined | null) {
  if (countryCode === undefined || countryCode === null || countryCode === '') return '';

  const iso2Code = getCountryISO2(countryCode.toUpperCase());

  if (iso2Code?.length !== 2) return countryCode;

  return [...iso2Code].map(char =>
    String.fromCodePoint(127397 + char.charCodeAt(0))
  ).reduce((a, b) => `${a}${b}`) || '';
}
