import React, { ReactElement, useMemo, useState } from 'react';
import { darken, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import ReactECharts from 'echarts-for-react';
import { EChartOption } from 'echarts';
import { EChartsInstance } from 'echarts-for-react/src/types';
import { BaseChartsProps } from './types';
import { colorTableForVeegilenz, interpolateColors, sortVeegilenzColor, wrapEmoji } from './utils';
import './style.css';
import valueParser from '@/utils/value-parser';
import getPercentage from '@/utils/getPercentage';
import { mainRadius } from '@/theme/theme';

const rotateTypes = ['amount', 'string', 'positionTypes', 'asset_complexity_reasons', 'tag'];

interface BarChartsProps extends BaseChartsProps {
  yAxisID?: string,
  rotateXAxisLabels?: boolean,
  types?: ('bar' | 'line')[],
  line?: boolean
}

function BarCharts(props: BarChartsProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const [selected, setSelected] = useState(props.selected);

  const onDataClick = (params: EChartOption.Tooltip.Format) => {
    const indexSelected = params.dataIndex || 0;
    setSelected(indexSelected === selected ? undefined : indexSelected);
    props.setSelected?.(indexSelected === selected ? undefined : indexSelected);

  };

  const onEvents = {
    click: onDataClick
  };

  const option: EChartOption = useMemo(() => {
    const colors = interpolateColors('#6C93CF', '#977599', props.datas.length || 1);
    const marginLeft = 24 + (props.datas[0]?.map(d => valueParser({ value: d.count, valueType: props.countType, currency: props.currency, t })).sort((a, b) => (b?.toString().length || 0) - (a?.toString().length || 0))[0]?.length || 10) * 7;
    const marginBottom = 24 + (props.datas[0]?.map(d => valueParser({ value: d.value, valueType: props.dataType, currency: props.currency, t })).sort((a, b) => (b?.toString().length || 0) - (a?.toString().length || 0))[0]?.length || 10) * 9;
    const xAxisLabelsRotate = (props.datas[0]?.length || 0) > 1 && (props.dataType && rotateTypes.includes(props.dataType) && props.datas[0]?.some(d => (valueParser({ value: d.value, valueType: props.dataType, currency: props.currency, t })?.length || 0) > 10)) ? 45 : 0;

    return {
      color: colors,
      grid: {
        top: props.legend ? 38 : 24,
        right: 24,
        bottom: (xAxisLabelsRotate || props.rotateXAxisLabels) ? marginBottom > 128 ? 128 : marginBottom : 24,
        left: marginLeft
      },
      legend: props.legends ? {
        data: props.legends ? Array.isArray(props.legends) ? props.legends : [props.legends] : []
      } : undefined,
      tooltip: {
        confine: true,
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        },
        extraCssText: `border-radius: ${mainRadius}px; box-shadow: ${theme.shadows[3]}`,
        backgroundColor: theme.palette.background.paper,
        rich: {
          flagEmojiStyle: {
            fontFamily: 'NotoColorEmojiLimited'
          }
        },
        formatter: params => (Array.isArray(params) ?
          params.map((param, index) => `<div style="word-break: break-all;white-space: pre-wrap;">${wrapEmoji(valueParser({ value: param.data.dataValue, range: param.data.range, valueType: props.dataType, currency: props.currency, t }), props.dataType, true)}</div><br /><div style="word-break: break-all;white-space: pre-wrap;">${param.marker} ${props.countType && props.countType !== 'number' ? Array.isArray(props.legends) ? `${props.legends[index]} : ` : `${props.legends} : ` : ''}<b>${wrapEmoji(valueParser({ value: param.value?.toString() || '', valueType: props.countType, currency: props.currency, t }), props.countType, true)} ${(!props.countType || props.countType === 'number') ? `(${param.data.percentage}%)` : ''}</b></div>`).join('<br />') :
          `<div style="word-break: break-all;white-space: pre-wrap;">${wrapEmoji(valueParser({ value: params.data.dataValue, range: params.data.range, valueType: props.dataType, currency: props.currency, t }), props.dataType, true)}</div><br /><div style="word-break: break-all;white-space: pre-wrap;">${params.marker} ${props.countType && props.countType !== 'number' ? Array.isArray(props.legends) ? `${props.legends[0]} : ` : `${props.legends} : ` : ''}<b>${wrapEmoji(valueParser({ value: params.value?.toString() || '', valueType: props.countType, currency: props.currency, t }), props.countType, true)} ${(!props.countType || props.countType === 'number') ? `(${params.data.percentage}%)` : ''}</b></div>`),
        valueFormatter: (value: number) => wrapEmoji(valueParser({ value, valueType: props.countType, currency: props.currency, t }), props.countType)
      },
      xAxis: [
        {
          type: 'category',
          triggerEvent: true,
          axisLabel: {
            rotate: xAxisLabelsRotate,
            rich: {
              flagEmojiStyle: {
                fontFamily: 'NotoColorEmojiLimited'
              }
            },
            width: xAxisLabelsRotate ? 160 : undefined,
            overflow: 'truncate',
            formatter: (value: string) => wrapEmoji(value, props.dataType)
          },
          data: props.datas[0]?.sort(sortVeegilenzColor).map(d => `${valueParser({ value: d.value, range: d.range, valueType: props.dataType, currency: props.currency, t })}`) || []
        }
      ],
      yAxis: [
        {
          name: Array.isArray(props.legends) ? props.legends?.[0] : props.legends,
          axisLabel: {
            rich: {
              flagEmojiStyle: {
                fontFamily: 'NotoColorEmojiLimited'
              }
            },
            width: 64,
            overflow: 'truncate',
            ellipsis: '...',
            formatter: (value: number) => wrapEmoji(valueParser({ value, valueType: props.countType, currency: props.currency, t }), props.countType)
          }
        }
      ],
      series: props.datas.map((data, index) => ({
        type: props.line ? 'line' : props.types?.[index] || 'bar',
        name: Array.isArray(props.legends) ? props.legends?.[index] : props.legends,
        barCategoryGap: '10%',
        emphasis: {
          label: {
            show: true,
            fontSize: 20 - ((data?.length || 0) > 6 ? 6 : (data?.length || 0)),
            fontWeight: 'normal'
          }
        },
        label: {
          show: (props.datas[0]?.length || 0) <= 30,
          position: 'top',
          fontSize: 16 - ((data?.length || 0) > 5 ? 5 : (data?.length || 0)),
          rich: {
            flagEmojiStyle: {
              fontFamily: 'NotoColorEmojiLimited'
            }
          },
          formatter: (params: EChartOption.Tooltip.Format) => ((!props.countType || props.countType === 'number') && (props.datas[0]?.length || 0) <= 10 ?
            `${wrapEmoji(valueParser({ value: params.value, valueType: props.countType, currency: props.currency, t }), props.countType)} (${params.data.percentage}%)` :
            wrapEmoji(valueParser({ value: params.value, valueType: props.countType, currency: props.currency, t }), props.countType))
        },
        data: data ? data.map((d, subIndex) => {
          const total = props.totalCount ?? data.reduce((acc, curr) => acc + (curr.count || 0), 0);
          const color = interpolateColors('#6C93CF', '#977599', data.length || 1);
          const colorToDisplay = (props.line ? 'line' : props.types?.[index]) !== 'line' ? colorTableForVeegilenz[d.value] ?? color[subIndex] : colors[index];
          const slctd = selected === subIndex;

          return {
            value: d.count,
            range: d.range,
            dataValue: d.value,
            percentage: getPercentage(d.count, total),
            borderRadius: '8px',
            itemStyle: (props.line ? 'line' : props.types?.[index]) !== 'line' ? {
              borderColor: darken(colorToDisplay, slctd ? 0.5 : 0.2),
              borderWidth: slctd ? 2 : 1,
              borderRadius: mainRadius / 2,
              color: darken(colorToDisplay, slctd ? 0.2 : 0)
            } : undefined,
            label: {
              fontWeight: slctd ? 900 : 'normal',
              color: (props.line ? 'line' : props.types?.[index]) !== 'line' ? darken(colorToDisplay, slctd ? 0.6 : 0.3) : darken(colors[index], slctd ? 0.6 : 0.3)
            }
          };
        }) : []
      }))
    };
  }, [props.datas, selected]);

  const onChartReady = (echarts: EChartsInstance) => {
    echarts.setOption({
      ...option,
      animation: false
    });

    setTimeout(() => {
      echarts.setOption({
        ...option,
        animation: true
      });
    }, 50);
  };

  return (
    <ReactECharts
      option={option}
      onEvents={onEvents}
      onChartReady={props.loading === false ? onChartReady : undefined}
      lazyUpdate={props.loading || props.loading === undefined}
      style={{ height: '100%', width: '100%' }}
    />
  );
}

export default BarCharts;
