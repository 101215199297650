import React, { ReactElement, useEffect, useState } from 'react';
import { IDataBlock } from '@deecision/dna-interfaces';
import DisplayJson from '@/components/displayjson';
import { DataBlocksQueryService } from '../../../../../api/services/query';

function JsonDataBlocksBrutTabs(): ReactElement {
  const dataBlockId = window.location.pathname.split('/')[4];

  const dataElementServices = new DataBlocksQueryService({ entityType: 'deecCompany' });
  const [dataElement, setDataElement] = useState<IDataBlock[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await dataElementServices.findInAllEntities({ _id: dataBlockId });
      const res = Array.isArray(data.data) ? data.data : [];
      setDataElement(res);
    };

    fetchData();
  }, []);

  return (
    <DisplayJson data={dataElement} />
  );
}

export default JsonDataBlocksBrutTabs;
