import React, { ReactElement, useContext } from 'react';
import { IconUsers } from '@tabler/icons-react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useSearchParams } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import IconBuildings from '@/assets/custom/IconBuildings';
import { dashboardSpacing } from '@/main/containers/dashboards/portfolio/types';
import AbstractSegmentation, { SegmentationDataContext } from '../abstract';

function NumberCard(): ReactElement {
  const segmentationData = useContext(SegmentationDataContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get('groupId');

  return (
    segmentationData ?
      <Paper
        sx={{
          minWidth: 'min-content',
          bgcolor: segmentationData.objectType === 'deecPerson' ? theme.palette.primary.light : theme.palette.secondary.light,
          color: segmentationData.objectType === 'deecPerson' ? theme.palette.primary.dark : theme.palette.secondary.dark,
          p: dashboardSpacing
        }}
      >
        <Grid container spacing={dashboardSpacing} height='fit-content' width='min-content'>
          <Grid size={12}>
            <Stack direction='row' spacing={dashboardSpacing} alignItems='center'>
              {(groupId || groupId?.split('.').at(-1) || '').includes('persons') ?
                <IconUsers size='1.6rem'/> : <IconBuildings size='1.6rem' color={theme.palette.secondary.dark}/>
              }
              <Typography variant='h4' whiteSpace='nowrap' pr={2}>
                {groupId ?
                  (groupId?.split('.').at(-1) && ['sameFamily', 'personal', 'business', 'extended', 'client', 'notClient'].includes(groupId?.split('.').at(-1) || 'unknown')) ? t(`portfolios.dashboard.groups.labels.${groupId?.split('.').at(-1)}.label`) : t(`portfolios.dashboard.groups.labels.${groupId}.label`) :
                  segmentationData.objectType === 'deecPerson' ? t('portfolios.dashboard.groups.labels.persons.label') : t('portfolios.dashboard.groups.labels.companies.label')
                }
              </Typography>
            </Stack>
          </Grid>
          <Grid size={12}>
            <Typography variant='h2' textAlign='center'>
              {segmentationData.totalCount}
            </Typography>
          </Grid>
        </Grid>
      </Paper> :
      <></>
  );
}

function NumberCardsSegmentations(): ReactElement {
  return (
    <AbstractSegmentation
      loadingPage={
        <Skeleton variant='rounded' height={78} width={211} />
      }
    >
      <NumberCard />
    </AbstractSegmentation>
  );
}

export default NumberCardsSegmentations;
