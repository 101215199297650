import React, { ReactElement } from 'react';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { IconBuildingEstate, IconSearch, IconUser, IconWallet } from '@tabler/icons-react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import dnaConfig from '../../../../../../../config/dna.config.json';

function TargetStepsComponentsCreateFolders(): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid container spacing={6} width='100%' justifyContent='center' alignItems='center' mt={8}>
      <Grid sx={{ cursor: 'pointer' }} onClick={() => navigate(dnaConfig.routes.portfolios.relativeUrl)}>
        <Paper
          variant='hoverElevation3'
          sx={{
            bgcolor: theme.palette.grey['400'],
            width: 240,
            height: 200,
            boxShadow: 1
          }}
        >
          <Stack spacing={4} width='100%' height='100%' alignItems='center' justifyContent='center'>
            <IconWallet size='4em' />
            <Typography variant='h3'>
              {t('folder.steps.target.selectAPortfolio')}
            </Typography>
          </Stack>
        </Paper>
      </Grid>
      <Grid>
        <Typography variant='h4'>{t('common.utils.or')}</Typography>
      </Grid>
      <Grid sx={{ border: 'dashed 1.5px', borderRadius: 2, borderColor: theme.palette.grey['600'] }}>
        <Stack spacing={4}>
          <Stack direction='row' spacing={2} alignItems='center'>
            <IconSearch size='1.2rem' />
            <Typography variant='h3'>{t('common.utils.search')}</Typography>
          </Stack>
          <Paper
            variant='hoverElevation3'
            onClick={() => navigate(dnaConfig.routes.persons.baseUrl)}
            sx={{
              bgcolor: theme.palette.grey['400'],
              width: 240,
              height: 140,
              boxShadow: 1,
              cursor: 'pointer'
            }}
          >
            <Stack spacing={4} width='100%' height='100%' alignItems='center' justifyContent='center'>
              <IconUser size='4em' />
              <Typography variant='h3'>
                {t('entities.persons.person.label')}
              </Typography>
            </Stack>
          </Paper>
          <Paper
            variant='hoverElevation3'
            onClick={() => navigate(dnaConfig.routes.companies.baseUrl)}
            sx={{
              bgcolor: theme.palette.grey['400'],
              width: 240,
              height: 140,
              boxShadow: 1,
              cursor: 'pointer'
            }}
          >
            <Stack spacing={4} width='100%' height='100%' alignItems='center' justifyContent='center'>
              <IconBuildingEstate size='4em' />
              <Typography variant='h3'>
                {t('entities.companies.company.label')}
              </Typography>
            </Stack>
          </Paper>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default TargetStepsComponentsCreateFolders;
