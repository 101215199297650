import { AxiosInstance, AxiosResponse } from 'axios';
import { ApiArrayResponse, ApiResponse } from '@deecision/deecision-interfaces';
import { Params } from 'react-router-dom';
import ServiceError from '@/api/services/error';

export interface BaseServiceInterface {
  handleResponse<T>(res: AxiosResponse<ApiResponse<T> | ApiArrayResponse<T>>): Promise<ApiResponse<T> | ApiArrayResponse<T>>
}

export interface BaseServiceProps {
  axiosInstance: AxiosInstance,
  elementPrefixUrl: string
}
export abstract class BaseService<T = unknown> implements BaseServiceInterface {
  axiosInstance: AxiosInstance;

  elementPrefixUrl: string;

  protected constructor(props: BaseServiceProps) {
    this.axiosInstance = props.axiosInstance;
    this.elementPrefixUrl =
      props.elementPrefixUrl.charAt(0) === '/' ? props.elementPrefixUrl : `/${props.elementPrefixUrl}`;
  }

  constructUrl(call?: string): string {
    return (
      call ?
        `${this.elementPrefixUrl}${call.charAt(0) === '/' ? call : `/${call}`}` :
        this.elementPrefixUrl
    );
  }

  abstract get(id?: string): Promise<ApiResponse<T> | ApiArrayResponse<T>>
  abstract getAll(props?: unknown): Promise<ApiResponse<T> | ApiArrayResponse<T>>
  post?(data: T): Promise<ApiResponse<T>>
  put?(id: string, data: T): Promise<ApiResponse<T>>
  delete?(id: string): Promise<ApiResponse<T>>

  handleResponse<U>(res: AxiosResponse<ApiResponse<U>>): Promise<ApiResponse<U>> {
    return this._handleResponse<ApiResponse<U>>(res);
  }

  handleArrayResponse<U = T>(res: AxiosResponse<ApiArrayResponse<U>>): Promise<ApiArrayResponse<U>> {
    return this._handleResponse<ApiArrayResponse<U>>(res);
  }

  private _handleResponse<U = ApiResponse<unknown> | ApiArrayResponse<unknown>>(res: AxiosResponse<U>): Promise<U> {
    return new Promise<U>((resolve, reject) => {
      // First, check the response status
      if (res.status < 200 || res.status >= 300) {
        reject(new ServiceError(res.status, 'call failed'));
      }
      if (!res.data || !(res as AxiosResponse<ApiResponse<unknown>>).data.data || (res as AxiosResponse<ApiArrayResponse<unknown>>).data.data?.length === 0) {
        if (Array.isArray((res as AxiosResponse<ApiArrayResponse<unknown>>).data.data) && (res as AxiosResponse<ApiArrayResponse<unknown>>).data.data?.length === 0) {
          resolve(res.data);
        }
        reject(new ServiceError(res.status === 200 ? 204 : res.status, 'no data'));
      }
      if ((res.data as ApiResponse<U>).error) {
        reject(new ServiceError(res.status, 'Server returned an error', (res.data as ApiResponse<U>).error));
      }

      resolve(res.data);
    });
  }
}

export class CustomBaseService<T> extends BaseService<T> {
  constructor(props: BaseServiceProps) {
    super({ ...props });
  }

  get(id?: string, props?: unknown, params?: Params<string>): Promise<ApiResponse<T> | ApiArrayResponse<T>> {
    console.log(id, props, params);

    return new Promise(() => {});
  }

  getAll(props?: unknown): Promise<ApiResponse<T> | ApiArrayResponse<T>> {
    console.log(props);

    return new Promise(() => {});
  }

  post?(data: T): Promise<ApiResponse<T>> {
    console.log(data);

    return new Promise(() => {});
  }

  put?(id: string, data: T): Promise<ApiResponse<T>> {
    console.log(id, data);

    return new Promise(() => {});
  }

  delete?(id: string): Promise<ApiResponse<T>> {
    console.log(id);

    return new Promise(() => {});
  }
}
