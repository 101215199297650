import { BodaccDataBlock, IDataBlock } from '@deecision/dna-interfaces';
import { cloneDeep, get, sortBy, upperFirst } from 'lodash';
import { FilterGroupChoices } from './types';

export function bodaccDataSort( a: IDataBlock, b: IDataBlock ) {
  if (!a.dataInfo.dataTimestamp) {
    return -1;
  }
  if (!b.dataInfo.dataTimestamp) {
    return 1;
  }

  const d1 = new Date(a.dataInfo.dataTimestamp).getTime();
  const d2 = new Date(b.dataInfo.dataTimestamp).getTime();

  if (d1 > d2) {
    return -1;
  }
  if (d1 < d2) {
    return 1;
  }

  return 0;
}

export function getParsedData(data: IDataBlock<BodaccDataBlock>[], filters: FilterGroupChoices<BodaccDataBlock>[], index?: number, jIndex?: number): IDataBlock<BodaccDataBlock>[] {
  let dataTmp: IDataBlock<BodaccDataBlock>[] = cloneDeep(data);
  const filtersTmp: FilterGroupChoices<BodaccDataBlock>[] = cloneDeep(filters);

  if (filtersTmp.length > 0) {
    if (index !== undefined && jIndex === undefined) {
      if (filtersTmp[index].subFilters.every(subFilter => subFilter.status)) {
        filtersTmp[index].subFilters.forEach((tmpSubFilter, j) => {
          filtersTmp[index].subFilters[j].status = false;
        });
      } else {
        filtersTmp[index].subFilters.forEach((tmpSubFilter, j) => {
          filtersTmp[index].subFilters[j].status = true;
        });
      }
    } else if (index !== undefined && jIndex !== undefined) {
      filtersTmp[index].subFilters[jIndex].status = !filtersTmp[index].subFilters[jIndex].status;
    }

    filtersTmp.forEach((filter) => {
      if (filter.value === 'years') {
        filter.subFilters.forEach((subFilter) => {
          if (!subFilter.status) {
            dataTmp = dataTmp.filter(dTmps => !(new Date(get(dTmps, subFilter.key)).getFullYear().toString() === subFilter.value));
          }
        });
      } else if (filter.value === '') {
        filter.subFilters.forEach((subFilter) => {
          if (!subFilter.status) {
            dataTmp = dataTmp.filter(dTmps => !(upperFirst(get(dTmps.data, subFilter.key)) === subFilter.value));
          }
        });
      } else {
        filter.subFilters.forEach((subFilter) => {
          if (!subFilter.status) {
            dataTmp = dataTmp.filter(dTmps => !(upperFirst(get(dTmps.data, subFilter.key)).replaceAll('.', '') === subFilter.value));
          }
        });
      }
    });
  }

  return dataTmp;
}

export function getYearsBodacc(datas: IDataBlock<BodaccDataBlock>[], sort?: 'asc' | 'dsc') {

  const yearsTmp: string[] = [];

  datas.forEach((d) => {
    if (d.dataInfo.dataTimestamp && !yearsTmp.find(year => d.dataInfo.dataTimestamp && year === new Date(d.dataInfo.dataTimestamp).getFullYear().toString())) {
      yearsTmp.push(new Date(d.dataInfo.dataTimestamp).getFullYear().toString());
    }
  });

  return (!sort || sort === 'asc') ? sortBy(yearsTmp).reverse() : sortBy(yearsTmp);
}
