import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { PersonEntity, SegmentationCriteria } from '@deecision/dna-interfaces';
import { WidgetProps } from '@/main/containers/widgets/types';
import BaseWidgetChart from '@/main/containers/widgets/base-chart';

function BaseWidgetCompaniesApeRncs<T = string>(props: Omit<WidgetProps<PersonEntity>, 'data'> & { chartData: Required<SegmentationCriteria>['values'], options: { value: T, label: string }[], selected: T, setSelected: (value: T) => void }): ReactElement {
  const { t } = useTranslation();

  return (
    <BaseWidgetChart
      {...props}
      actions={[
        <FormControl fullWidth sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <Select
            native
            variant='outlined'
            value={props.selected}
            onChange={e => props.setSelected(e.target.value as typeof props.selected)}
            size='small'
            sx={{ width: 'max-content' }}
          >
            {props.options.map(option => <option key={option.value?.toString()} value={option.value?.toString()}>{option.label}</option>)}
          </Select>
        </FormControl>
      ]}
      baseChartType='pieChart'
      chartProps={{
        datas: [props.chartData?.sort((a, b) => (b.count || 0) - (a.count || 0))],
        totalCount: props.chartData?.length || 0,
        title: t('common.utils.activitySector'),
        dataType: 'string'
      }}
      fullWidth
    />
  );
}

export default BaseWidgetCompaniesApeRncs;
