import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { BaseEntityLinkDetailsCompany, BaseEntityLinkDetailsCompanyPerson, EntityRef } from '@deecision/dna-interfaces';
import Button from '@mui/material/Button';
import { IconCirclesRelation, IconLink, IconLinkOff } from '@tabler/icons-react';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import IconBuildings from '@/assets/custom/IconBuildings';
import TreeEntityCard from './card';
import { TreeLinksLayerProps } from './types';
// eslint-disable-next-line import/no-cycle
import TreeLinksLayer from './links';
import { getEntityPath } from '@/main/providers/getter';
import ScrollToDiv from '../../../../../components/scrolltodiv';

function TreeLinksCompanies(props: TreeLinksLayerProps & { companies: BaseEntityLinkDetailsCompany[], entity2: Omit<EntityRef, 'name'> & { name?: string | null } }): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const scrollToRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [subLayerOpen, setSubLayerOpen] = useState<EntityRef>();

  useEffect(() => {
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ block: 'center' });
    }
  }, [scrollToRef]);

  return (
    <Stack alignItems='start'>
      <Stack direction='row' alignItems='start' mt='18px'>
        <Box sx={{ bgcolor: theme.palette.grey['500'], width: 150, height: 2 }} />
        <Box sx={{ bgcolor: theme.palette.grey['500'], width: 2, height: 56, borderRadius: 2 }} />
      </Stack>
      {open ?
        <Stack alignItems='start' ml='151px'>
          <Stack spacing={1} alignItems='center' ml='-30px'>
            <Box sx={{ bgcolor: theme.palette.grey['500'], width: 40, height: 2, borderRadius: 2, transform: 'rotate(-16deg)' }} />
            <Button variant='textIcon' size='small' onClick={() => setOpen(!open)} sx={{ width: '60px', '&:hover': { bgcolor: theme.palette.grey['400'] } }}>
              <Stack spacing={2} direction='row' color={theme.palette.grey['700']}>
                <IconCirclesRelation size='1.1rem' />
                <IconBuildings size='1.1rem' />
              </Stack>
            </Button>
            <Box sx={{ bgcolor: theme.palette.grey['500'], width: 40, height: 2, borderRadius: 2, transform: 'rotate(-16deg)' }} />
          </Stack>
          <Stack direction='row'>
            <Box sx={{ bgcolor: theme.palette.grey['500'], width: 2, height: (props.companies.length - 1) * (124 + 80) + 96, borderRadius: 2, mt: '-2px !important' }} />
            <Stack spacing={4} ml='-1px'>
              {props.companies.map((company, index) => (
                <Stack key={company.companyRef.entityId} direction='row' alignItems='center'>
                  <Box sx={{ bgcolor: theme.palette.grey['500'], width: 172, height: 2, borderRadius: 2 }}/>
                  <Stack height='188px'>
                    <Link href={getEntityPath(company.companyRef)} sx={{ textDecoration: 'none !important' }}>
                      <TreeEntityCard
                        entity={company.companyRef}
                      >
                        <Stack direction='row' spacing={2} alignItems='center'>
                          {Object.keys(company).filter(key => key.includes('person')).map(key => (
                            <Box key={key} sx={{ p: 2, pt: 1, pb: 1, borderRadius: 1, bgcolor: theme.palette.grey['200'], height: '80px', width: '230px' }}>
                              <Stack spacing={1}>
                                <Stack direction='row' alignItems='center'>
                                  <Typography variant='subtitle2' noWrap>{key === 'person1' ? props.entity.name : props.entity2.name || props.entity2.entityId}</Typography>
                                </Stack>
                                <Stack direction='row' alignItems='center'>
                                  {(company[key as keyof typeof company] as BaseEntityLinkDetailsCompanyPerson).activePositionTypes.map(position => (
                                    <Chip
                                      key={`active-${position}`}
                                      sx={{
                                        transform: 'scale(0.8)',
                                        ml: '-6px !important'
                                      }}
                                      color='primary'
                                      label={position}
                                      size='small'
                                    />
                                  ))}
                                  {(company[key as keyof typeof company] as BaseEntityLinkDetailsCompanyPerson).inactivePositionTypes.map(position => (
                                    <Chip
                                      key={`inactive-${position}`}
                                      sx={{
                                        transform: 'scale(0.8)',
                                        ml: '-6px !important'
                                      }}
                                      color='error'
                                      label={position}
                                      size='small'
                                    />
                                  ))}
                                </Stack>
                              </Stack>
                            </Box>
                          ))}
                        </Stack>
                      </TreeEntityCard>
                    </Link>

                    <Stack direction='row' alignItems='start' sx={{ ml: '500px', mt: '-114px', zIndex: 99 }}>
                      <Tooltip title={t('relations.label')} placement='top' arrow>
                        <Button
                          sx={{
                            mt: '4px',
                            pt: '3px',
                            ml: '-14px'
                          }}
                          variant='containedIcon'
                          size='small'
                          onClick={() => {
                            if (subLayerOpen?.entityId === company.companyRef.entityId) {
                              setSubLayerOpen(undefined);
                            } else {
                              setSubLayerOpen(company.companyRef);
                            }
                          }}
                        >
                          {subLayerOpen?.entityId === company.companyRef.entityId ? <IconLink size='1rem' /> : <IconLinkOff size='1rem' />}
                        </Button>
                      </Tooltip>
                      {index === 0 && <ScrollToDiv />}
                      {subLayerOpen?.entityId === company.companyRef.entityId &&
                        <Box>
                          { /* eslint-disable-next-line @typescript-eslint/no-use-before-define */ }
                          <TreeLinksLayer
                            key={`${subLayerOpen.entityId}-${subLayerOpen.entityType}`}
                            entity={company.companyRef}
                            id={company.companyRef.entityId}
                            filters={props.filters}
                            sortList={props.sortList}
                            parentEntities={[...props.parentEntities, company.companyRef.entityId]}
                            isSubLayer
                          />
                        </Box>
                      }
                    </Stack>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack> :
        <Paper
          ref={scrollToRef}
          sx={{
            width: 160,
            ml: '70px',
            cursor: props.companies.length > 0 ? 'pointer' : undefined,
            '&:hover': {
              boxShadow: props.companies.length > 0 ? 2 : 1
            }
          }}
          onClick={props.companies.length > 0 ? () => setOpen(!open) : undefined}
        >
          <Stack direction='row' spacing={3} alignItems='center'>
            <Stack direction='row' spacing={1} alignItems='center' color={theme.palette.grey['700']}>
              <IconCirclesRelation size='1.1rem' />
              <IconBuildings size='1.1rem' />
            </Stack>
            <Typography sx={{ textAlign: 'center' }}>
              {`${props.companies.length} ${t('entities.companies.label')}`}
            </Typography>
          </Stack>
        </Paper>
      }
    </Stack>
  );
}

export default TreeLinksCompanies;
