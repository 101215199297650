import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { SegmentationPossibleFilter, SegmentationPossibleOutputEntityTypes } from '@deecision/dna-interfaces';
import { uniqueId } from 'lodash';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { CustomSegmentationData, PotentialSegmentationCriteria } from '../../types.segmentations';
import CustomAccordion from '../../../../../components/accordion';
import AddCriteriasBuilderSegmentation from './add.criterias.builder.segmentation';
import ListCriteriasBuilderSegmentation from './list.criterias.builder.segmentation';
import { CustomSegmentationFiltersService } from '../../services/segmentations.services';
import { possibleCriteriaTypes } from '../workflows';
import InheritedCriterias from '../../render/criterias/inherited';
import { SegmentationContext } from '../../wrappers/wrapper.segmentations';

function CriteriasBuilderSegmentation(props: { customSegmentationData: CustomSegmentationData, setCustomSegmentationData: (data: CustomSegmentationData) => void, outputEntities?: SegmentationPossibleOutputEntityTypes, onlyAddBtn?: boolean, noAccordion?: true }): ReactElement {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const segmentationContext = useContext(SegmentationContext);
  const [possibleCriterias, setPossibleCriterias] = useState<SegmentationPossibleFilter[]>([]);
  const possibleCriteriasService = new CustomSegmentationFiltersService<SegmentationPossibleFilter>();

  const getPossibleCriterias = (outputEntities?: SegmentationPossibleOutputEntityTypes) => {
    setPossibleCriterias([]);

    possibleCriteriaTypes(props.customSegmentationData.possibleDataSetIds, outputEntities ? [outputEntities] : undefined)?.forEach(criteriaType => possibleCriteriasService.getAll({ filters: [{ scope: 'on', id: 'on', value: criteriaType }] })
      .then((res) => {
        setPossibleCriterias(prevState => [
          ...prevState.filter(possibleCriteria => !res.data?.find(dataFilter => dataFilter.id === possibleCriteria.id)),
          ...res.data ? [
            ...res.data.filter(possibleCriteria => !possibleCriteria.categories.find(category => category === 'hidden') && possibleCriteria.canUseForSegmentation)
          ]
            .filter(filter => !props.customSegmentationData.potentialSegmentationCriterias?.find(f => f.filterId === filter.id))
            .filter(filter => (outputEntities ? possibleCriteriaTypes(undefined, [outputEntities]).includes(filter.on) : filter)) :
            []
        ]);
      }));
  };

  const buildSegmentationPotentialCriteria = (possibleCriteria: SegmentationPossibleFilter) => ({
    type: 'segmentationCriteria' as const,
    ...possibleCriteria,
    id: `${Date.now()}${uniqueId()}`,
    filterId: possibleCriteria.id,
    filterLabel: possibleCriteria.label || possibleCriteria.id,
    displayInfo: {
      displayType: possibleCriteria.possibleDisplayTypes[0],
      valueType: possibleCriteria.valueType
    }
  });

  const possibleCriteriasFiltered = useMemo(() => (
    possibleCriterias.filter(possibleCriteria => !props.customSegmentationData.potentialSegmentationCriterias?.find(f => f.filterId === possibleCriteria.id))
  ), [possibleCriterias, props.customSegmentationData.potentialSegmentationCriterias]);

  const addSegmentationCriterias = (possibleCriteriasTmp: SegmentationPossibleFilter[]) => {
    props.setCustomSegmentationData({
      ...props.customSegmentationData,
      potentialSegmentationCriterias: [...props.customSegmentationData.potentialSegmentationCriterias, ...possibleCriteriasTmp.map(possibleCriteria => buildSegmentationPotentialCriteria(possibleCriteria))]
    });
  };

  const addSegmentationCriteriaCategory = (category?: string) => {
    addSegmentationCriterias(possibleCriteriasFiltered.filter(possibleCriteria => (category ? possibleCriteria.categories.includes(category) : true)));
  };

  const updateSegmentationPotentialCriteria = (potentialCriterias: PotentialSegmentationCriteria[]) => {
    props.setCustomSegmentationData({
      ...props.customSegmentationData,
      potentialSegmentationCriterias: [...potentialCriterias]
    });
  };

  const replaceSegmentationPotentialCriteria = (possibleCriteriaTmp: SegmentationPossibleFilter, oldId: string) => {
    props.setCustomSegmentationData({
      ...props.customSegmentationData,
      potentialSegmentationCriterias: props.customSegmentationData.potentialSegmentationCriterias?.map(potentialCriteria => (potentialCriteria.filterId === oldId ? buildSegmentationPotentialCriteria(possibleCriteriaTmp) : potentialCriteria))
    });
  };

  const deleteSegmentationPotentialCriteria = (potentialCriteriaIds: string[]) => {
    props.setCustomSegmentationData({
      ...props.customSegmentationData,
      potentialSegmentationCriterias: props.customSegmentationData.potentialSegmentationCriterias?.filter(potentialCriteria => !potentialCriteriaIds.includes(potentialCriteria.id))
    });
  };

  useEffect(() => {
    getPossibleCriterias(props.outputEntities);
  }, [props.customSegmentationData.possibleCriteriaTypes, i18n.language, props.outputEntities, props.customSegmentationData.potentialSegmentationCriterias]);

  return (
    props.onlyAddBtn ?
      <Box width='100%'>
        <AddCriteriasBuilderSegmentation
          possibleCriterias={possibleCriteriasFiltered}
          addSegmentationCriterias={addSegmentationCriterias}
          addSegmentationCriteriaCategory={addSegmentationCriteriaCategory}
          fullWidth
        />
      </Box> :
      props.noAccordion ?
        <Stack spacing={2} p={4} alignItems='flex-start' width='100%' id='list-criterias-builder-segmentation'>
          <ListCriteriasBuilderSegmentation
            possibleCriterias={possibleCriteriasFiltered}
            potentialSegmentationCriterias={props.customSegmentationData.potentialSegmentationCriterias}
            updateSegmentationPotentialCriteria={updateSegmentationPotentialCriteria}
            replaceSegmentationPotentialCriteria={replaceSegmentationPotentialCriteria}
            deleteSegmentationPotentialCriteria={deleteSegmentationPotentialCriteria}
          />
          <Stack spacing={2} direction='row' alignItems='center'>
            <AddCriteriasBuilderSegmentation
              possibleCriterias={possibleCriteriasFiltered}
              addSegmentationCriterias={addSegmentationCriterias}
              addSegmentationCriteriaCategory={addSegmentationCriteriaCategory}
              contained
            />
            <Typography textAlign='center'>{t('common.utils.or')}</Typography>
            <InheritedCriterias
              setCriterias={newCriterias => (segmentationContext?.segmentation ?
                props.setCustomSegmentationData({ ...(segmentationContext.segmentation.data), potentialSegmentationCriterias: newCriterias }) :
                undefined)
              }
            />
          </Stack>
        </Stack> :
        <CustomAccordion
          title={
            <Stack direction='row' spacing={2} alignItems='center'>
              <Typography variant='h4'>{t('segmentation.builder.segmentationCriterias.label')}</Typography>
              <Chip
                label={`${props.customSegmentationData.potentialSegmentationCriterias?.length || 0} ${(props.customSegmentationData.potentialSegmentationCriterias?.length || 0) > 1 ? t('segmentation.builder.segmentationCriterias.addeds') : t('segmentation.builder.segmentationCriterias.added')}`}
                color='primary'
                size='small'
              />
              <Chip
                label={`${possibleCriteriasFiltered?.length || 0} ${(possibleCriteriasFiltered?.length || 0) > 1 ? t('segmentation.builder.segmentationCriterias.availables') : t('segmentation.builder.segmentationCriterias.available')}`}
                color='success'
                size='small'
              />
            </Stack>
          }
          bgcolor={theme.palette.background.default}
          defaultOpen
        >
          <Stack spacing={2} p={4} alignItems='flex-start' id='list-criterias-builder-segmentation'>
            <ListCriteriasBuilderSegmentation
              possibleCriterias={possibleCriteriasFiltered}
              potentialSegmentationCriterias={props.customSegmentationData.potentialSegmentationCriterias}
              updateSegmentationPotentialCriteria={updateSegmentationPotentialCriteria}
              replaceSegmentationPotentialCriteria={replaceSegmentationPotentialCriteria}
              deleteSegmentationPotentialCriteria={deleteSegmentationPotentialCriteria}
            />
            <Stack spacing={2} direction='row' alignItems='center'>
              <AddCriteriasBuilderSegmentation
                possibleCriterias={possibleCriteriasFiltered}
                addSegmentationCriterias={addSegmentationCriterias}
                addSegmentationCriteriaCategory={addSegmentationCriteriaCategory}
                contained
              />
              <Typography textAlign='center'>{t('common.utils.or')}</Typography>
              <InheritedCriterias
                setCriterias={newCriterias => (segmentationContext?.segmentation ?
                  props.setCustomSegmentationData({ ...(segmentationContext.segmentation.data), potentialSegmentationCriterias: newCriterias }) :
                  undefined)
                }
              />
            </Stack>
          </Stack>
        </CustomAccordion>
  );
}

export default CriteriasBuilderSegmentation;
