import React, { ReactElement } from 'react';
import { Outlet, useLoaderData, useLocation, useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import CustomTabs, { TabsComponentsProps } from '../layouts/tabs';
import { CustomRouteObject } from '@/router/types';

export interface DataOutletProps extends Omit<TabsComponentsProps, 'tabs' | 'params'> {
  layout?: ReactElement,
  tabs?: CustomRouteObject[],
  childrens?: CustomRouteObject[],
  idChildrens?: CustomRouteObject[],
  wrapper?: (props: { children: ReactElement }) => React.JSX.Element
}

function DataOutlet(props: DataOutletProps): ReactElement {
  const data = useLoaderData();
  const params = useParams();
  const location = useLocation();

  if (!props.layout && !props.tabs) {
    return <Outlet context={data} />;
  }

  return (
    <Stack spacing={2}>
      {props.wrapper ?
        <props.wrapper>
          <>
            {props.layout}
            {props.tabs && !props.idChildrens?.find(idChildren => (idChildren.path && location.pathname.includes(idChildren.path.replaceAll(/\/:[a-zA-Z]+\?/g, '')))) && !props.childrens?.find(children => (children.path && location.pathname.includes(children.path.replaceAll(/\/:[a-zA-Z]+\?/g, '')))) &&
              <CustomTabs tabs={props.tabs} params={params} {...props} />
            }
            <Outlet context={data} />
          </>
        </props.wrapper> :
        <>
          {props.layout}
          {props.tabs && !props.idChildrens?.find(idChildren => (idChildren.path && location.pathname.includes(idChildren.path.replaceAll(/\/:[a-zA-Z]+\?/g, '')))) && !props.childrens?.find(children => (children.path && location.pathname.includes(children.path.replaceAll(/\/:[a-zA-Z]+\?/g, '')))) &&
            <CustomTabs tabs={props.tabs} params={params} {...props} />
          }
          <Outlet context={data} />
        </>
      }
    </Stack>
  );
}

export default DataOutlet;
