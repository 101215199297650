import { ApiResponse } from '@deecision/deecision-interfaces';
import { CustomBaseService } from '@/api';
import axiosInstance from '../../../../../api';

export type StatusDataType =  { // TODO: export in dna-interfaces
  entityType: string,
  type: string,
  count: number,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  scheduling_status: string
}

class AdminServices extends CustomBaseService<{ description: string }> {
  constructor() {
    super({ axiosInstance, elementPrefixUrl: 'data-elements2' });
  }

  getStats( filterSchedulingStatusName?: string[], filterSchedulingStatus?: string[], filterType?: string[], filterEntityType? : string[]): Promise<ApiResponse<StatusDataType[]>> {
    if (filterSchedulingStatus || filterType || filterEntityType || filterSchedulingStatusName) {
      let valueEntityType = '';
      let valueType = '';
      let valueSchedulingStatus = '';

      if (filterSchedulingStatusName?.length === 0) valueSchedulingStatus = `"done", "failed", "requested", "waiting_for_dependencies"`;

      // Refacto with computeString()
      filterEntityType?.forEach((element, index) => {
        valueEntityType += `"${element}"${(index === filterEntityType.length - 1 ? '' : ',')}`;
      });
      filterType?.forEach((element, index) => {
        valueType += `"${element}"${(index === filterType.length - 1 ? '' : ',')}`;
      });
      filterSchedulingStatus?.forEach((element, index) => {
        valueSchedulingStatus += `"${element}"${(index === filterSchedulingStatus.length - 1 ? '' : ',')}`;
      });
      const entityTypeQuery = valueEntityType !== '' ? `"entityRef.entityType": {"$in": [${valueEntityType}]}` : undefined;
      const typeQuery = valueType !== '' ? `"type": {"$in": [${valueType}]}` : undefined;
      const schedulingStatusQuery = valueSchedulingStatus !== '' ? `"scheduling.status": {"$in": [${valueSchedulingStatus}]}` : undefined;

      return axiosInstance.get(`${this.constructUrl()}/actions/stats?query={${(schedulingStatusQuery || entityTypeQuery) && `${schedulingStatusQuery} ${filterEntityType && filterEntityType.length !== 0 ? `,${entityTypeQuery}` : '' } ${filterType && filterType.length !== 0 ? `,${typeQuery}` : '' }` || ''}}`)
        .then(res => this.handleArrayResponse<StatusDataType>(res));
    }

    return axiosInstance.get(`${this.constructUrl()}/actions/stats?query={}`)
      .then(res => this.handleArrayResponse<StatusDataType>(res));
  }
}

export default AdminServices;
