import React, { ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import { upperFirst } from 'lodash';
import { useTranslation } from 'react-i18next';
import { CompanyEntity } from '@deecision/dna-interfaces';
import BaseWidget from '@/main/containers/widgets/base-widget';
import KeyValueWithCard from '@/components/keyvalue/keyvaluewithcard';
import { WidgetProps } from '@/main/containers/widgets/types';

function CompanyWidgetsBodacc(props: WidgetProps<CompanyEntity>): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();

  const colorOfCriticity = [
    {
      label: 'opportunity',
      color: theme.palette.success.dark,
      bgColor: theme.palette.success.light
    },
    {
      label: 'neutral',
      color: theme.palette.primary.dark,
      bgColor: theme.palette.primary.light
    },
    {
      label: 'ambiguous',
      color: theme.palette.warning.dark,
      bgColor: theme.palette.warning.light
    },
    {
      label: 'alert',
      color: theme.palette.error.dark,
      bgColor: theme.palette.error.light
    }
  ];

  return (
    <BaseWidget
      {...props}
      title={t('entities.companies.company.tabs.officialInformations')}
    >
      <Box>
        <Grid key='criticity' container spacing={2} minWidth='256px' width='256px'>
          {colorOfCriticity.map(c => (
            <Grid size={6} key={c.label}>
              <KeyValueWithCard
                keyValueCard={{
                  bgColor: c.bgColor,
                  color: c.color,
                  Key: upperFirst(c.label),
                  width: '100%',
                  height: '42px',
                  padding: '10px 8px',
                  size: 'small',
                  value: props.data.dataViews?.data?.company?.bodacc?.stats?.countsByCriticity[c.label as keyof typeof props.data.dataViews.data.company.bodacc.stats.countsByCriticity] || '0',
                  link: 'official_informations',
                  params: `criticity=${c.label}`
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </BaseWidget>
  );
}

export default CompanyWidgetsBodacc;
