import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { PersonEntity } from '@deecision/dna-interfaces';
import KeyValueWithCard from '@/components/keyvalue/keyvaluewithcard';
import { PersonGetter } from '@/main/providers/getter';
import BaseWidget from '@/main/containers/widgets/base-widget';
import { WidgetProps } from '@/main/containers/widgets/types';

function PersonWidgetsRelationsFinancial(props: WidgetProps<PersonEntity>): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const personGetter = new PersonGetter({
    data: props.data,
    errorMsg: t('common.utils.unknown')
  });

  const relationsDatas = props.data.dataViews?.data?.companies ? [
    {
      key: t('common.financial.turnover.sumShort'),
      fullKey: t('common.financial.turnover.sum'),
      value: personGetter.getRelationsTurnoverSum(),
      completeness: personGetter.getRelationsTurnoverSumCompleteness(),
      date: personGetter.getRelationsTurnoverSumDate(),
      align: 'right' as const
    },
    {
      key: t('common.financial.capital.sum'),
      value: personGetter.getRelationsCapitalSum(),
      completeness: personGetter.getRelationsCapitalSumCompleteness(),
      date: personGetter.getRelationsCapitalSumDate(),
      align: 'right' as const
    },
    {
      key: t('common.financial.ebitda.sumOfSum'),
      value: personGetter.getRelationsEbitdaSumOfSum(),
      completeness: personGetter.getRelationsEbitdaSumOfSumCompleteness(),
      date: personGetter.getRelationsEbitdaSumOfSumDate(),
      align: 'right' as const
    },
    {
      key: t('common.financial.valuation.sumOfSumShort'),
      fullKey: t('common.financial.valuation.sumOfSum'),
      value: personGetter.getRelationsValuationSumOfSum(),
      completeness: personGetter.getRelationsValuationSumOfSumCompleteness(),
      date: personGetter.getRelationsValuationSumOfSumDate(),
      align: 'right' as const
    },
    {
      key: t('common.financial.weasse.sumShort'),
      fullKey: t('common.financial.weasse.sum'),
      value: personGetter.getRelationsWeasseSum(),
      completeness: personGetter.getRelationsWeasseSumCompleteness(),
      date: personGetter.getRelationsWeasseSumDate(),
      align: 'right' as const
    },
    {
      key: t('common.financial.weasseMax.sumShort'),
      fullKey: t('common.financial.weasseMax.sum'),
      value: personGetter.getRelationsWeasseMaxSum(),
      completeness: personGetter.getRelationsWeasseMaxSumCompleteness(),
      date: personGetter.getRelationsWeasseMaxSumDate(),
      align: 'right' as const
    }
  ] : [];

  return (
    <BaseWidget {...props} title={t('entities.persons.alterLabels.relations')}>
      <KeyValueWithCard
        values={[relationsDatas]}
        keyValueCard={
          {
            Key: `${t('common.utils.current')} / ${t('common.utils.total')}`,
            value: `${personGetter.getNbCurrentRelations()}/${personGetter.getNbRelations()}`,
            size: 'big',
            bgColor: ((personGetter.getNbRelations() as number)
              - (personGetter.getNbCurrentRelations() as number))
            < (personGetter.getNbCurrentRelations() as number) ?
              theme.palette.success.light : theme.palette.error.light,
            color: ((personGetter.getNbRelations() as number)
              - (personGetter.getNbCurrentRelations() as number))
            < (personGetter.getNbCurrentRelations() as number) ?
              theme.palette.success.dark : theme.palette.error.dark
          }
        }
      />
    </BaseWidget>
  );
}

export default PersonWidgetsRelationsFinancial;
