import React, { ReactElement, useState } from 'react';
import { PersonEntity } from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import { WidgetProps } from '@/main/containers/widgets/types';
import BaseWidgetCompaniesApeRncs from '@/main/containers/widgets/base-ape-rncs';

function PersonWidgetCompaniesApeRncs(props: WidgetProps<PersonEntity>): ReactElement {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<'groupByApeCode' | 'groupByLegalFormCode'>('groupByApeCode');

  const data = Object.keys(props.data.dataViews?.data?.companies?.stats?.active?.[selected] || {}).map(code => ({
    value: code,
    count: props.data.dataViews?.data?.companies?.stats?.active?.[selected]?.[code]?.count || 0
  }));

  return (
    <BaseWidgetCompaniesApeRncs<typeof selected>
      {...props}
      chartData={data}
      options={[
        { value: 'groupByApeCode', label: t('common.utils.activitySector') },
        { value: 'groupByLegalFormCode', label: t('common.utils.legalForm') }
      ]}
      selected={selected}
      setSelected={setSelected}
    />
  );
}

export default PersonWidgetCompaniesApeRncs;
