import React, { ReactElement } from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { IconChevronDown } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

interface SelectProps {
  setSelected: (s: string) => void,
  choices: string[],
  selected: string
}

interface Action {
  label: string,
  action: () => void
}

interface HeaderComponentsProps {
  actions?: Action[],
  selects?: (SelectProps | undefined | SelectProps[])[],
  keys?: (string | string[])[],
  links?: (string | string[])[],
  children: (ReactElement | ReactElement[])[],
  height?: number
}

function HeaderComponents(props: HeaderComponentsProps): ReactElement {
  const selectChevron = (params: Record<string, unknown>) => <IconChevronDown size='0.9rem' {...params} />;
  const headerSize = props.height || 140;
  const navigate = useNavigate();

  return (
    <Box width='100%' sx={{ overflowX: 'auto', pb: 2, overflowY: 'hidden', mb: '-8px' }}>
      <Stack spacing={4} direction='row' width='max-content' p={2}>
        {props.children.map((child, index) => (
          Array.isArray(child) ?
            <React.Fragment key={`parent - ${child[0].key}`}>
              <Paper elevation={1} sx={{ p: 4, height: `${headerSize}px`, overflowX: 'auto', overflowY: 'hidden' }}>
                <Grid container spacing={4} direction='row' p={0} flexWrap='nowrap' width='max-content'>
                  {child.map((subChild, i) => (
                    <React.Fragment key={subChild.key}>
                      {i !== 0 &&
                        <Grid pl={3} pr={3} pt={2} pb={2} height={`calc(${headerSize}px - 16px)`}>
                          <Divider orientation='vertical' />
                        </Grid>
                      }
                      <Grid p={1}>
                        <Grid container height={`calc(${headerSize}px - 8px)`} p={0} width='min-content'>
                          {props.keys && props.keys[index] && props.keys[index][i] &&
                            <Grid p={2} pb={1} pt={1} height='24px'>
                              <Typography variant='h6' sx={{ fontWeight: '600' }}>
                                {props.keys[index][i]}
                              </Typography>
                            </Grid>
                          }
                          {props.selects && props.selects[index] && Array.isArray(props.selects[index]) && (props.selects[index] as SelectProps[])[i] &&
                            <Grid p={2} pb={1} pt={1} ml='auto' height='24px'>
                              <FormControl size='small'>
                                <Select
                                  labelId='demo-multiple-name-label'
                                  id='demo-multiple-name'
                                  IconComponent={selectChevron}
                                  value={(props.selects[index] as SelectProps[])[i].selected}
                                  onChange={event => (props.selects && props.selects[index] ? (props.selects[index] as SelectProps[])[i].setSelected(event.target.value) : undefined)}
                                  input={
                                    <OutlinedInput
                                      sx={{
                                        height: '16px',
                                        fontSize: '0.75rem',
                                        color: 'grey.600',
                                        borderColor: 'grey.500',
                                        '& .MuiSelect-select': {
                                          p: 0,
                                          pl: 4
                                        }
                                      }}
                                    />
                                  }
                                >
                                  {(props.selects[index] as SelectProps[])[i].choices.map(choice => (
                                    <MenuItem
                                      key={choice}
                                      value={choice}
                                    >
                                      {choice}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          }
                          <Grid size={12} p={2} pt={1} height={`calc(${headerSize}px - 32px)`}>
                            {subChild}
                          </Grid>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </Paper>
            </React.Fragment> :
            <React.Fragment key={child.key}>
              <Box onClick={() => (props.links && props.links[index] && props.links[index] !== '' && navigate(props.links[index].toString()))}>
                <Paper
                  elevation={1}
                  sx={{
                    height: `${headerSize}px`,
                    width: 'min-content',
                    cursor: props.links && props.links[index] && props.links[index] !== '' && 'pointer' || undefined,
                    '&:hover': {
                      boxShadow: props.links && props.links[index] && props.links[index] !== '' ? 2 : 1
                    }
                  }}
                >
                  {props.keys && props.keys[index] !== '' ?
                    <Grid container spacing={2} overflow='auto' height='100%'>
                      <Grid p={2} pb={0} pt={1} height='24px'>
                        <Typography variant='h6' sx={{ fontWeight: '600' }} noWrap>
                          {props.keys[index]}
                        </Typography>
                      </Grid>
                      <Grid>
                        {child}
                      </Grid>
                    </Grid> :
                    <Box p={2} height='100%' overflow='auto'>{child}</Box>
                  }
                </Paper>
              </Box>
            </React.Fragment>
        ))}
      </Stack>
    </Box>
  );
}

export default HeaderComponents;
