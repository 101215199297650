import React, { Dispatch, ReactElement, SetStateAction, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SegmentationData } from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid2';
import {
  IconClipboardData,
  IconDeviceFloppy,
  IconDotsVertical,
  IconEdit,
  IconEditOff,
  IconFilterSearch,
  IconPencil,
  IconRefresh,
  IconRotate,
  IconTimelineEventPlus,
  IconTrash
} from '@tabler/icons-react';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import TitleComponent from '@/components/title';
import { SegmentationContext } from '../wrappers/wrapper.segmentations';
import DeleteModalsRenderSegmentations from './modals/delete.modals.render.segmentations';

interface SegmentationTitleProps {
  segmentationData?: SegmentationData,
  isDraft: boolean,
  setIsDraft: Dispatch<SetStateAction<boolean>>,
  isEditMode: boolean,
  setIsEditMode: (isEditMode: boolean) => void,
  setSegmentationData: Dispatch<SetStateAction<SegmentationData | undefined>>
}

function SegmentationTitle(props: SegmentationTitleProps): ReactElement {
  const segmentationContext = useContext(SegmentationContext);
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenConfirmDelete = () => {
    setOpenConfirmDelete(true);
  };
  const handleCloseOpenConfirmDelete = () => {
    setOpenConfirmDelete(false);
    handleClose();
  };
  const handleDeleteSegmentation = () => {
    segmentationContext?.deleteSegmentation();
  };

  return (
    <Grid size={12} container spacing={2} alignItems='flex-start' sx={{ position: 'sticky', top: 0, bgcolor: theme.palette.grey['50'], zIndex: 3, pt: 2, pb: 2 }}>
      <Grid size='grow' container height='100%' alignItems='center'>
        <Grid>
          <TitleComponent title={segmentationContext?.segmentation?.title || ''} icon={IconClipboardData} />
        </Grid>
        {props.isEditMode &&
          <Grid>
            <IconPencil size='1.4rem' />
          </Grid>
        }
        <Grid>
          <Chip
            label={
              <Stack direction='row' spacing={1} alignItems='center'>
                <IconFilterSearch size='1rem' />
                <Typography variant='body2'>
                  {`${segmentationContext?.segmentation?.data?.potentialSegmentationCriterias?.length} ${t('segmentation.render.segmentationCriterias.label')}`}
                </Typography>
              </Stack>
            }
            color='primary'
            size='small'
          />
        </Grid>
        <Grid>
          <Tooltip title={t('common.dates.creationDate')} arrow placement='top'>
            <Chip
              label={
                <Stack direction='row' spacing={1} alignItems='center'>
                  <IconTimelineEventPlus size='1rem' />
                  <Typography variant='body2'>
                    {segmentationContext?.segmentation?.date ? `${new Date(segmentationContext.segmentation.date || '').toLocaleDateString()} ${new Date(segmentationContext.segmentation.date || '').toLocaleTimeString()}` : ''}
                  </Typography>
                </Stack>
              }
              color='success'
              size='small'
            />
          </Tooltip>
        </Grid>
        <Grid>
          <Tooltip title={t('common.dates.lastUpdate')} arrow placement='top'>
            <Chip
              label={
                <Stack direction='row' spacing={1} alignItems='center'>
                  <IconRefresh size='1rem' />
                  <Typography variant='body2'>
                    {segmentationContext?.segmentation?.lastUpdate ? `${new Date(segmentationContext.segmentation.lastUpdate).toLocaleDateString()} ${new Date(segmentationContext.segmentation.lastUpdate).toLocaleTimeString()}` : ''}
                  </Typography>
                </Stack>
              }
              sx={{ bgcolor: theme.palette.secondary.light, color: theme.palette.secondary.dark }}
              size='small'
            />
          </Tooltip>
        </Grid>
      </Grid>
      {props.isDraft &&
        <Grid ml='auto'>
          <Tooltip title={t('common.utils.reset')} placement='top' arrow>
            <IconButton onClick={() => navigate(0)}>
              <IconRotate size='1.2rem' style={{ transform: 'rotate(180deg)' }} />
            </IconButton>
          </Tooltip>
        </Grid>
      }
      {(location.pathname.includes('custom') || props.isDraft) &&
        <Grid ml='auto'>
          <Button
            variant='contained'
            startIcon={<IconDeviceFloppy size='1.6rem' />}
            sx={{ minWidth: 'max-content' }}
            onClick={() => {
              segmentationContext?.save(segmentationContext?.segmentation?.groups?.[0] || 'me', undefined, !location.pathname.includes('custom') ? true : undefined);
              props.setSegmentationData(undefined);
              props.setIsDraft(false);
              props.setIsEditMode(false);
            }}
            disabled={!props.isDraft}
          >
            {location.pathname.includes('custom') ? t('segmentation.render.settings.save.label') : t('segmentation.render.settings.save.new')}
          </Button>
        </Grid>
      }
      {location.pathname.includes('custom') &&
        <Grid>
          <IconButton
            id='segmentation-settings-button'
            onClick={handleClick}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            aria-controls={open ? 'segmentation-settings' : undefined}
          >
            <IconDotsVertical size='1.6rem' />
          </IconButton>
          <Menu
            id='segmentation-settings'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'segmentation-settings-button'
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                props.setIsEditMode(!props.isEditMode);
              }}
            >
              <Stack direction='row' spacing={2} alignItems='center' p={2} pr={4}>
                {props.isEditMode ? <IconEditOff size='1.6rem' /> : <IconEdit size='1.6rem' />}
                <Typography variant='h5'>{t('segmentation.render.settings.edit')}</Typography>
              </Stack>
            </MenuItem>
            {!location.pathname.includes('/create') &&
              <MenuItem
                onClick={() => {
                  handleOpenConfirmDelete();
                }}
              >
                <Stack direction='row' spacing={2} alignItems='center' p={2} pr={4}>
                  <IconTrash size='1.6rem' color={theme.palette.error.main}/>
                  <Typography color='error' variant='h5'>{t('segmentation.render.settings.delete')}</Typography>
                </Stack>
              </MenuItem>
            }
          </Menu>
          <DeleteModalsRenderSegmentations openConfirmDelete={openConfirmDelete} handleCloseOpenConfirmDelete={handleCloseOpenConfirmDelete} handleDeleteSegmentation={handleDeleteSegmentation} />
        </Grid>
      }
    </Grid>
  );
}

export default SegmentationTitle;
