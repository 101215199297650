import React, { ReactElement } from 'react';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme
} from '@mui/material';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { merge } from 'lodash';
import customThemeComponents from '@/theme/custom/theme';
import themeMui from '../theme/theme';
import customThemeComponentsWithRouter from '@/theme/custom/themewithoutrouter';

export type ThemeWrapperProps = boolean;

function MuiWrapper(props : { withoutRouter?: boolean, children: React.ReactNode }): ReactElement {
  const appliedTheme = createTheme(merge(themeMui, props.withoutRouter ? customThemeComponents : customThemeComponentsWithRouter));

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appliedTheme}>
        <CssBaseline />
        <Container maxWidth={false} disableGutters>
          {props.children}
        </Container>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default MuiWrapper;
