import React from 'react';
import { IconChartCircles } from '@tabler/icons-react';
import createElementRoutes from '@/router/create';
import { VennDiagramConfig } from '@/main/containers/vennDiagram/types.vennDiagram';
import dnaConfig from '../../../../config/dna.config.json';
import RenderVennDiagram from '../../../containers/vennDiagram/render.vennDiagram';
import SegmentationWrapper from '../../../containers/segmentations/wrappers/wrapper.segmentations';
import { CustomRouteObject } from '@/router/types';

function routesVennDiagram(id: string): CustomRouteObject {
  return createElementRoutes<VennDiagramConfig[]>({
    id: `${id}-${dnaConfig.childRoutes.vennDiagrams.diagram.baseUrl}`,
    path: `${dnaConfig.childRoutes.vennDiagrams.baseUrl}/${dnaConfig.childRoutes.vennDiagrams.diagram.baseUrl}`,
    slug: 'segmentationId',
    slugSuffix: true,
    component: <SegmentationWrapper>
      <RenderVennDiagram />
    </SegmentationWrapper>,
    // Service: new VennDiagramServices(),
    icon: IconChartCircles
  });
}

export default routesVennDiagram;
