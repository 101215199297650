import { TabFunction } from '@/layouts/tabs';
import dnaConfig from '../../../../../../../config/dna.config.json';
import {
  bodaccFunction,
  financialFunction,
  newsFunction,
  veegilenzFunction
} from '@/main/modules/entities/common/tabs/utils/functions';
import { veegilenzCompanyPaths } from '@/main/containers/veegilenz';

export const tabsFunctions: Record<string, TabFunction> = {
  [dnaConfig.routes.companies.tabs.officialInformations.baseUrl]: bodaccFunction,
  [dnaConfig.routes.companies.tabs.financial.baseUrl]: financialFunction,
  [dnaConfig.routes.companies.tabs.news.baseUrl]: newsFunction,
  [dnaConfig.routes.persons.tabs.veegilenz.baseUrl]: (props: { id: string, data?: unknown }) => veegilenzFunction({ ...props, paths: veegilenzCompanyPaths })
};
