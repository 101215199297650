import React, { ReactElement, ReactNode } from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { darken, lighten, styled, useTheme } from '@mui/material/styles';
import { Box, Divider, tooltipClasses, TooltipProps } from '@mui/material';
import {
  Icon,
  IconExternalLink,
  IconProps
} from '@tabler/icons-react';
import Tooltip from '@mui/material/Tooltip';
import { DefaultTReturn } from 'i18next';
import type { TOptions } from 'i18next/typescript/options';
import CountriesFlags from '@/components/countriesflags';
import Indicator from '../indicator';
import Status, { StatusProps } from '../status';
import Completeness, { CompletenessProps } from '../chips/completeness';
import Accuracy, { AccuracyProps } from '../chips/accuracy';
import KeyValueDate from '../chips/date';

export type KeyValue = StatusProps & {
  id?: string,
  key: string | ReactNode,
  fullKey?: string | DefaultTReturn<TOptions>,
  value?: string | string[] | number | ReactElement,
  flags?: boolean,
  tooltip?: string | ReactNode,
  tooltipWidth?: number | string,
  link?: string,
  redirectTo?: string,
  wrap?: true,
  width?: string,
  icon?: React.ForwardRefExoticComponent<Omit<IconProps, 'ref'> & React.RefAttributes<Icon>>,
  align?: 'left' | 'center' | 'right',
  completeness?: CompletenessProps['completeness'],
  accuracy?: AccuracyProps['accuracy'],
  date?: Date,
  indicator?: number | string,
  percentage?: number,
  selected?: true,
  color?: string,
  keyColor?: string,
  bgcolor?: string
}

export interface KeyValueListProps {
  values: KeyValue[][],
  card?: true,
  alignItems?: string,
  small?: boolean
}

export interface KeyValueListRowProps {
  values: KeyValue[],
  compact?: true,
  selectKey?: (key: string) => void,
  small?: boolean
}

const CustomTooltip = styled(
  ({ className, ...props }: TooltipProps & { tooltipMaxWidth?: number | string }) => (
    <Tooltip {...props} classes={{ popper: className }}>
      {props.children}
    </Tooltip>
  ))(props => ({
  [`& .${tooltipClasses.tooltip}`]: {
    width: 'fit-content',
    maxWidth: props.tooltipMaxWidth,
    maxHeight: '100vh',
    overflow: 'auto'
  }
}));

export function KeyValueListRow(props: KeyValueListRowProps) {
  const theme = useTheme();

  return (
    <Grid
      container
      direction='row'
      spacing={0}
      minWidth={!props.compact ? '200px' : undefined}
      width='100%'
      maxWidth='100%'
      justifyContent='space-between'
      flexWrap='nowrap'
    >
      <Grid size='grow'>
        <Stack>
          {props.values.map((value, index) => (value.value !== '' &&
            <React.Fragment key={`keys-${value.key}-${value.value?.toString()}`}>
              {!props.compact && index !== 0 &&
                <Box pb={2}><Divider /></Box>
              }
              <Box
                height={props.small ? '18px': props.compact ? '22px' : '28px'}
                pr={1}
                onClick={() => (props.selectKey ? props.selectKey(value.id || (typeof value.key === 'string' ? value.key : '')) : undefined)}
                sx={{ cursor: props.selectKey ? 'pointer' : undefined }}
              >
                {value.icon ?
                  <Stack direction='row' spacing={2} alignItems='center' height={19}>
                    <value.icon size='1rem' />
                    <Tooltip title={value.fullKey} arrow placement='left'>
                      <Typography
                        variant={props.small ? 'body2' : 'body1'}
                        fontWeight={400}
                        color={value.keyColor || (value.selected ? theme.palette.primary.main : theme.palette.text.secondary)}
                        sx={{
                          textDecoration: props.selectKey ? 'underline' : undefined,
                          textDecorationColor: props.selectKey ? lighten(theme.palette.primary.main, 0.5) : undefined,
                          color: props.selectKey ? theme.palette.primary.main : undefined,
                          '&:hover': {
                            color: props.selectKey ? darken(theme.palette.primary.main, 0.1) : undefined,
                            textDecorationColor: props.selectKey ? darken(theme.palette.primary.main, 0.1) : undefined
                          }
                        }}
                        noWrap
                      >
                        {value.key}
                      </Typography>
                    </Tooltip>
                  </Stack>
                  :
                  <Tooltip title={value.fullKey} arrow placement='left'>
                    <Typography
                      variant={props.small ? 'body2' : 'body1'}
                      fontWeight={400}
                      color={value.keyColor || (value.selected ? theme.palette.primary.main : theme.palette.text.secondary)}
                      sx={{
                        textDecoration: props.selectKey ? 'underline' : undefined,
                        textDecorationColor: props.selectKey ? lighten(theme.palette.primary.main, 0.5) : undefined,
                        color: props.selectKey ? theme.palette.primary.main : undefined,
                        '&:hover': {
                          color: props.selectKey ? darken(theme.palette.primary.main, 0.1) : undefined,
                          textDecorationColor: props.selectKey ? darken(theme.palette.primary.main, 0.1) : undefined
                        }
                      }}
                      noWrap
                    >
                      {value.key}
                    </Typography>
                  </Tooltip>
                }
              </Box>
            </React.Fragment>
          ))}
        </Stack>
      </Grid>
      <Grid>
        <Stack>
          {props.values.map((value, index) => (value.value !== '' &&
            <React.Fragment key={`values-${value.key}-${value.value?.toString()}`}>
              {!props.compact && index !== 0 &&
                <Grid pb={2}><Divider /></Grid>
              }
              <Grid
                height={props.small ? '18px': props.compact ? '22px' : typeof value.value === 'string' ? '28px' : undefined}
                maxHeight='28px'
                pl={1}
                onClick={() => (props.selectKey ? props.selectKey(typeof value.key === 'string' ? value.key : '') : undefined)}
                sx={{ cursor: props.selectKey ? 'pointer' : undefined }}
              >
                <CustomTooltip
                  title={value.tooltip}
                  arrow
                  placement='right'
                  tooltipMaxWidth={value.tooltipWidth}
                >
                  <Stack direction='row' spacing={2} justifyContent='flex-end' alignItems='center'>
                    {
                      value.flags ?
                        <Stack direction='row' spacing={2} pt={1} pb={1} alignItems='center' height={28}>
                          {CountriesFlags({ countries: (value.value as string[]), size: 'small' })}
                        </Stack> :
                        typeof value.value === 'string' ?
                          value.redirectTo ?
                            <Stack
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                window.open(value.redirectTo, '_blank');
                              }}>
                              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Typography
                                  variant={props.small ? 'h6' : 'h5'}
                                  fontWeight={props.compact ? 500 : 600}
                                  color={value.selected ? theme.palette.primary.main : value.status ? theme.palette[value.status].main : value.color ||
                                    value.date ? Math.abs(new Date(Date.now() - (value.date?.getTime() || 0)).getUTCFullYear() - 1970) < 1 ? theme.palette.success.dark : Math.abs(new Date(Date.now() - (value.date?.getTime() || 0)).getUTCFullYear() - 1970) < 2 ? theme.palette.warning.dark : theme.palette.error.dark : undefined
                                  }
                                  noWrap={value.wrap ? undefined : true}
                                  width={value.width || 'min-content'}
                                  ml={value.align === 'right' ? 'auto' : undefined}
                                >
                                  {value.value || ''}
                                </Typography>
                                <IconExternalLink size='0.6rem' style={{ marginBottom: '1vh' }}/>
                              </Box>
                            </Stack> :
                            <Typography
                              variant={props.small ? 'h6' : 'h5'}
                              fontWeight={props.compact ? 500 : 600}
                              color={value.selected ? theme.palette.primary.main : value.status ? theme.palette[value.status].main : value.color ||
                                value.date ? Math.abs(new Date(Date.now() - (value.date?.getTime() || 0)).getUTCFullYear() - 1970) < 1 ? theme.palette.success.main : Math.abs(new Date(Date.now() - (value.date?.getTime() || 0)).getUTCFullYear() - 1970) < 2 ? theme.palette.warning.main : theme.palette.error.main : undefined
                              }
                              noWrap={value.wrap ? undefined : true}
                              width={value.width || 'min-content'}
                              ml={value.align === 'right' ? 'auto' : undefined}
                              pt='2px'
                              height={19}
                            >
                              {value.value || ''}
                            </Typography> :
                          value.value || ''
                    }
                    <KeyValueDate { ...value } size={undefined} key={`date-${value.key}`} />
                    <Status { ...value } size={undefined} key={`status-${value.key}`} />
                    <Indicator { ...value } key={`indicator-${value.key}`}  />
                    <Completeness { ...value } size={undefined} key={`completeness-${value.key}`}  />
                    <Accuracy { ...value } size={undefined} key={`accuracy-${value.key}`}  />
                  </Stack>
                </CustomTooltip>

                {/* <Stack direction='row' spacing={2} justifyContent='flex-end' alignItems='center'>
                  {typeof value.value === 'string' ?
                    value.redirectTo ?
                    <Stack
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        window.open(value.redirectTo, '_blank');
                      }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography
                          variant='h5'
                          fontWeight={props.compact ? 500 : 600}
                          color={value.selected ? theme.palette.primary.main : value.status ? theme.palette[value.status].main : undefined}
                          noWrap={value.wrap ? undefined : true}
                          width={value.width || 'min-content'}
                          ml={value.align === 'right' ? 'auto' : undefined}
                        >
                          {value.value || ''}
                        </Typography>
                        <IconExternalLink size={10} style={{marginBottom: '1vh'}}/>
                      </Box>
                    </Stack> :
                    <Typography
                      variant='h5'
                      fontWeight={props.compact ? 500 : 600}
                      color={value.selected ? theme.palette.primary.main : value.status ? theme.palette[value.status].main : undefined}
                      noWrap={value.wrap ? undefined : true}
                      width={value.width || 'min-content'}
                      ml={value.align === 'right' ? 'auto' : undefined}
                    >
                      {value.value || ''}
                    </Typography> :
                    value.value || ''
                  }
                  <Status { ...value } key={`status-${value.key}`} />
                  <Indicator { ...value } key={`indicator-${value.key}`}  />
                  <Completeness { ...value } key={`completeness-${value.key}`}  />
                  <Accuracy { ...value } key={`accuracy-${value.key}`}  />
                </Stack> */}
              </Grid>
            </React.Fragment>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
}

function KeyValueList(props: KeyValueListProps): ReactElement {

  return (
    <Stack direction='row' spacing={2} width='100%' maxWidth='100%' alignItems={props.alignItems}>
      {props.values.map(values => (
        <KeyValueListRow key={`keyValueList-${values.map(value => value.key).join('.')}`} values={values} small={props.small} compact />
      ))}
    </Stack>
  );
}

export default KeyValueList;
