import React, { ReactElement, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import {
  IDataBlock,
  NewsApiOrgData,
  BingNewsData,
  GoogleCseDataBlock
} from '@deecision/dna-interfaces';
import { useNavigate, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { DataBlocksQueryService } from '@/api/services/query';
import Bias from '../../../components/chips/bias';
import { newsDataPaths } from '@/main/modules/entities/common/tabs/news';

function NewsSmallCards(props: { entityType: string }): ReactElement {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const newsService = new DataBlocksQueryService<BingNewsData | NewsApiOrgData>({ dataPath: newsDataPaths, entityType: props.entityType });
  const [data, setData] = useState<IDataBlock<BingNewsData | NewsApiOrgData>[] | undefined>(undefined);

  const onRedirect = (bias: string, layer: string) => {
    navigate(`news?layer=${layer}&bias=${bias}`);
  };

  useEffect(() => {
    if (id) {
      newsService.get(id)
        .then((res) => {
          setData(res.data);
        })
        .catch(() => setData(undefined));
    }
  }, []);

  return (
    <Stack spacing={2}>
      <Stack spacing={1} direction='row' alignItems='center'>
        <Typography variant='body1' width='60px'>{t('common.trust.official')}</Typography>
        {data && data.length > 0 ?
          <Bias<IDataBlock<BingNewsData | NewsApiOrgData | GoogleCseDataBlock>>
            data={data.filter(d => (d._source?.query?.trust.includes('official') || (d.data as BingNewsData).query?.trust.includes('official')))}
            path={['_source.query.bias', 'data.query.bias']}
            onClick={b => onRedirect(b, 'official')}
          /> :
          <Typography variant='h4'>{t('common.utils.unknown')}</Typography>
        }
      </Stack>
      <Stack spacing={1} direction='row' alignItems='center'>
        <Typography variant='body1' width='60px'>{t('common.trust.credible')}</Typography>
        {data && data.length > 0 ?
          <Bias<IDataBlock<BingNewsData | NewsApiOrgData | GoogleCseDataBlock>>
            data={data.filter(d => (d._source?.query?.trust.includes('credible') || (d.data as BingNewsData).query?.trust.includes('credible')))}
            path={['_source.query.bias', 'data.query.bias']}
            onClick={b => onRedirect(b, 'credible')}
          /> :
          <Typography variant='h4'>{t('common.utils.unknown')}</Typography>
        }
      </Stack>
      <Stack spacing={1} direction='row' alignItems='center'>
        <Typography variant='body1' width='60px'>{t('common.trust.noise')}</Typography>
        {data && data.length > 0 ?
          <Bias<IDataBlock<BingNewsData | NewsApiOrgData | GoogleCseDataBlock>>
            data={data.filter(d => (d._source?.query?.trust.includes('noise') || (d.data as BingNewsData).query?.trust.includes('noise') || ((d._source?.query?.trust && d._source?.query?.trust.length === 0 || (d.data as BingNewsData).query?.trust && (d.data as BingNewsData).query?.trust.length === 0))))}
            path={['_source.query.bias', 'data.query.bias']}
            onClick={b => onRedirect(b, 'noise')}
          /> :
          <Typography variant='h4'>{t('common.utils.unknown')}</Typography>
        }
      </Stack>
    </Stack>
  );
}

export default NewsSmallCards;
