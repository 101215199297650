import routesPortfolios from './portfolios/routes';
import routesFolders from './folders/routes';
import { CustomRouteObject } from '@/router/types';

const routesDeetect: CustomRouteObject[] = [
  routesPortfolios,
  routesFolders
];

export default routesDeetect;
