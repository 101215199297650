export interface CurrencyFormatterProps {
  currency?: string | null,
  value?: number | null,
  lang?: string,
  errorMsg?: string
}

export function currencyFormatter(obj?: CurrencyFormatterProps | null): string | undefined {
  const prefix = obj?.value && obj.value < 0 ? '-' : '';
  const value = obj?.value && obj.value < 0 ? obj.value * -1 : obj?.value || 0;

  return `${prefix}${new Intl.NumberFormat(obj?.lang || ((!obj?.currency || obj?.currency === 'EUR') ? 'fr-FR' : 'en-US'), {
    notation: value >= 1000000 ? 'compact' : undefined,
    style: 'currency',
    maximumFractionDigits: value >= 1000000 ? 2 : 0,
    currency: obj?.currency || 'EUR'
  }).format(value || 0)}`;
}
