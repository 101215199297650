import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';
import { IconArrowRight, IconCornerDownRight } from '@tabler/icons-react';
import {
  IDataBlock,
  VeegilenzCompanyFact,
  VeegilenzCompanyFactAllDataBlockContent,
  VeegilenzPersonFactAllDataBlockContent,
  VeegilenzPersonFact,
  VeegilenzSummaryAllDataBlockContent,
  VeegilenzCompanyIndirectLink, EntityRef,
  VeegilenzPersonFactMandatesV1DataBlockContent,
  VeegilenzPersonFactNationalityV1DataBlockContent,
  VeegilenzPersonFactResidenceV1DataBlockContent,
  VeegilenzFactSanctionsV1DataBlockContent,
  VeegilenzPersonFactPEPV1DataBlockContent,
  VeegilenzPersonFactRelatedPEPV1DataBlockContent,
  VeegilenzCompanyFactBodaccV1DataBlockContent,
  VeegilenzCompanyFactAddressV1DataBlockContent,
  VeegilenzCompanyFactFinancialBilanV1DataBlockContent,
  VeegilenzCompanyFactFinancialStabilityV1DataBlockContent,
  VeegilenzCompanyFactLinksV1DataBlockContent,
  VeegilenzCompanyFactRiskV1DataBlockContent,
  VeegilenzCompanyFactStateV1DataBlockContent,
  VeegilenzCompanyFactRelatedPEPV1DataBlockContent
} from '@deecision/dna-interfaces';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { startCase } from 'lodash';
import {
  VeegilenzCompanyFactReasonCode,
  VeegilenzPersonFactReasonCode,
  VeegilenzSummaryReasonCode
} from '@deecision/dna-interfaces/dist/constants';
import TitleComponent from '@/components/title';
import { CardPart } from '@/components/cards/keyvalue.cards';
import Status from '../../../components/status';
import { KeyValue } from '@/components/keyvalue/keyvaluelist';
import { VeegilenzProps } from './types';
import { veegilenzSummaryPaths } from '@/main/containers/veegilenz';
import { getEntityPath } from '@/main/providers/getter';

function GetIndirectLinks(props: { indirectLinks?: VeegilenzCompanyIndirectLink[] }): ReactElement {
  const { t } = useTranslation();

  const getEntityName = (entityRef: EntityRef, coma?: boolean) => <Typography variant='body2' noWrap>
    {entityRef.name || entityRef.entityId}{coma ? ',' : ''}
  </Typography>;

  return props.indirectLinks ? (
    <Stack spacing={2}>
      {props.indirectLinks.map(indirectLink => (
        <Grid
          container
          key={indirectLink.companyRef.entityId}
          spacing={1}
          alignItems='center'
        >
          <Grid container>
            {indirectLink.level1PersonRefs.map((entity, index) => (
              <Grid key={entity.entityId}>
                {getEntityName(entity, indirectLink.level1PersonRefs.length > 1 && index !== indirectLink.level1PersonRefs.length - 1)}
              </Grid>
            ))}
          </Grid>
          {indirectLink.level1PersonRefs.length > 0 && (
            <Grid>
              <IconArrowRight size='1rem' />
            </Grid>
          )}
          <Grid container>
            {indirectLink.level2PersonRefs.map((entity, index) => (
              <Grid key={entity.entityId}>
                {getEntityName(entity, indirectLink.level2PersonRefs.length > 1 && index !== indirectLink.level2PersonRefs.length - 1)}
              </Grid>
            ))}
          </Grid>
          {indirectLink.level2PersonRefs.length > 0 && (
            <Grid>
              <IconArrowRight size='1rem' />
            </Grid>
          )}
          <Grid>
            <Typography variant='body2' noWrap>
              {indirectLink.companyRef.name || indirectLink.companyRef.entityId}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Stack>
  ) : (
    <>{t('common.utils.unknown')}</>
  );
}

function getGlobalVeegilenzLevel(summaryReason: VeegilenzSummaryReasonCode | 'noReason') {
  switch (summaryReason) {
  case 'atLeastOneBlackFact':
    return 3;
  case 'atLeastOneRedFact':
    return 2;
  case 'atLeastOneOrangeFact':
  case 'moreThanTreeOrangeFacts':
    return 1;
  case 'onlyGreenFacts':
    return 0;
  default:
    return 0;
  }
};

function DirectIndexVeegilenzPepTooltip(props: {
  dataBlock: VeegilenzPersonFactRelatedPEPV1DataBlockContent | VeegilenzCompanyFactRelatedPEPV1DataBlockContent
}): ReactElement {
  const { t } = useTranslation();

  return <Stack spacing={1}>
    {props.dataBlock.metadata?.sources.map((source) => {
      const key = source.origins.map(o => o.entityRef.entityId).join('___');
      const ultimateOrigin = source.origins[source.origins.length - 1];
      const intermediaries = source.origins.slice(0, -1);
      const exposureTypes = source.exposures
        .map(exposure => `${t(`veegilenz.politicalExposures.types.${exposure.type}.${exposure.subType}`)} ${exposure.description}`)
        .join(', ');

      return (
        <Typography key={key}>
          {ultimateOrigin ? (
            <a
              href={getEntityPath(ultimateOrigin.entityRef)}
              style={{ color: 'inherit' }}
            >
              {ultimateOrigin.entityRef.name ??
                ultimateOrigin.entityRef.entityId}
            </a>
          ) : (
            ''
          )}
          {` ${exposureTypes} `}
          {intermediaries.length > 0 ?
            <span>
              {'( Via: '}
              {intermediaries.map(origin => (
                <a
                  key={origin.entityRef.entityId}
                  href={getEntityPath(origin.entityRef)}
                  style={{ color: 'inherit' }}
                >
                  {origin.entityRef.name ?? origin.entityRef.entityId}
                </a>
              ))}
              {' )'}
            </span>
            : (
              ''
            )}
        </Typography>
      );
    })
    }
  </Stack>;
}

function DirectIndexVeegilenz(props: VeegilenzProps): ReactElement {
  const { t } = useTranslation();

  // filter on datablock path to dispatch summary / person / companies
  const personFactDataBlocks = (useLoaderData() as { data: IDataBlock<unknown>[] }).data.filter((block: IDataBlock<unknown>) => block.dataInfo.path === 'person.veegilenz.facts') as IDataBlock<VeegilenzPersonFactAllDataBlockContent>[];
  const companyFactDataBlocks = (useLoaderData() as { data: IDataBlock<unknown>[] }).data.filter((block: IDataBlock<unknown>) => block.dataInfo.path === 'company.veegilenz.facts') as IDataBlock<VeegilenzCompanyFactAllDataBlockContent>[];
  const summaryFactDataBlock = (useLoaderData() as { data: IDataBlock<unknown>[] }).data.filter((block: IDataBlock<unknown>) => veegilenzSummaryPaths.includes(block.dataInfo.path))[0] as IDataBlock<VeegilenzSummaryAllDataBlockContent>;

  const getTooltipReason = (reasonCode: VeegilenzPersonFactReasonCode | VeegilenzCompanyFactReasonCode) => (reasonCode !== 'noReason'
    ? t(`veegilenz.reason.${reasonCode}`)
    : undefined);

  const getValuesForDeecPerson = () => {
    const items: KeyValue[] = [];
    Object.values(VeegilenzPersonFact).forEach((fact) => {
      const relatedDataBlocks = personFactDataBlocks.filter(dataBlock => dataBlock.data?.fact === fact);

      // if the processing has not already be done we can have no datablock for a fact
      if (!relatedDataBlocks.length) {
        items.push({
          key: t(`veegilenz.keys.${fact}`),
          value: t('veegilenz.errorResponse.notSearched'),
          align: 'right' as const
        });
      }

      relatedDataBlocks.forEach((dataBlock) => {
        const dataBlockData = dataBlock.data;

        const item: KeyValue = {
          key: t(`veegilenz.keys.${fact}`),
          value: t('common.utils.unknown'),
          tooltip: undefined,
          align: 'right' as const,
          statusAsInt: dataBlockData.levelAsInt,
          flags: undefined
        };

        switch (fact) {
        case VeegilenzPersonFact.MANDATES_CURRENT:
        case VeegilenzPersonFact.MANDATES_CURRENT_AS_BO:
        case VeegilenzPersonFact.MANDATES_CURRENT_HOLDINGS:
        case VeegilenzPersonFact.MANDATES_CURRENT_WITH_AML_RISKS:
        case VeegilenzPersonFact.MANDATES_CURRENT_WITH_LISTED_COMPANIES:
        case VeegilenzPersonFact.MANDATES_PAST:
        case VeegilenzPersonFact.MANDATES_PAST_AS_BO:
        case VeegilenzPersonFact.MANDATES_PAST_HOLDINGS:
        case VeegilenzPersonFact.MANDATES_PAST_WITH_AML_RISKS:
        case VeegilenzPersonFact.MANDATES_PAST_WITH_LISTED_COMPANIES:
          // eslint-disable-next-line no-case-declarations
          const castedAsMandate = dataBlockData as VeegilenzPersonFactMandatesV1DataBlockContent;
          item.value = castedAsMandate.metadata?.entityRefs.length.toString();
          item.tooltip = (castedAsMandate.metadata?.entityRefs.length || 0) > 0 ?
            <Stack spacing={1}>
              {castedAsMandate.metadata?.entityRefs.map(entityRef =>
                <a href={getEntityPath(entityRef)} style={{ color: 'inherit' }}>{startCase(entityRef.name?.toLowerCase())}</a>
              )}
            </Stack>
            : undefined;
          break;
        case VeegilenzPersonFact.NATIONALITY:
        case VeegilenzPersonFact.RESIDENCE:
          // eslint-disable-next-line no-case-declarations
          const castedAsNationalityOrResidence = dataBlockData as VeegilenzPersonFactResidenceV1DataBlockContent | VeegilenzPersonFactNationalityV1DataBlockContent;
          item.value = castedAsNationalityOrResidence.metadata?.countryCodes;
          item.tooltip = getTooltipReason(castedAsNationalityOrResidence.reasonCode);
          item.flags = true;
          break;
        case VeegilenzPersonFact.SANCTIONS:
          // eslint-disable-next-line no-case-declarations
          const castedAsSanction = dataBlockData as VeegilenzFactSanctionsV1DataBlockContent;
          item.value = castedAsSanction.metadata?.sanctions?.length.toString();
          item.tooltip = castedAsSanction.metadata?.sanctions?.map(sanction => t(`veegilenz.sanctionType.${sanction.type}`)).join(', ');
          break;
        case VeegilenzPersonFact.PEP_DIRECT:
          // eslint-disable-next-line no-case-declarations
          const castedAsPepDirect = dataBlockData as VeegilenzPersonFactPEPV1DataBlockContent;
          item.value = castedAsPepDirect.metadata?.nbExposures?.toString();
          item.tooltip = castedAsPepDirect.metadata?.exposures
            ?.map(exposure => `${t(`veegilenz.politicalExposures.types.${exposure.type}.${exposure.subType}`)} ${exposure.description}`)
            .join(', ');
          break;
        case VeegilenzPersonFact.PEP_FAMILY_MEMBER:
        case VeegilenzPersonFact.PEP_INDIRECT:
          const castedAsPepRelated = dataBlockData as VeegilenzPersonFactRelatedPEPV1DataBlockContent;
          item.value = castedAsPepRelated.metadata?.nbSources?.toString();
          item.tooltip = (castedAsPepRelated.metadata?.sources.length || 0) > 0
            ? <DirectIndexVeegilenzPepTooltip dataBlock={castedAsPepRelated} />
            : undefined;
          item.tooltipWidth = 'max-content';
          break;
        default:
          throw new Error(`Unsupported person fact ${fact}`);
        }
        items.push(item);
      });
    });

    return items;
  };

  const getValuesForDeecCompany = () => {
    const items: KeyValue[] = [];

    Object.values(VeegilenzCompanyFact).forEach((fact) => {
      const relatedDataBlocks = companyFactDataBlocks.filter(dataBlock => dataBlock.data?.fact === fact);

      // if the processing has not already be done we can have no datablock for a fact
      if (!relatedDataBlocks.length) {
        items.push({
          key: t(`veegilenz.keys.${fact}`),
          value: t('veegilenz.errorResponse.notSearched'),
          align: 'right' as const
        });
      }

      relatedDataBlocks.forEach((dataBlock) => {
        const dataBlockData = dataBlock.data;

        const item: KeyValue = {
          key: t(`veegilenz.keys.${fact}`),
          value: t('common.utils.unknown'),
          tooltip: undefined,
          align: 'right' as const,
          statusAsInt: dataBlockData.levelAsInt,
          flags: undefined
        };

        switch (fact) {
        case VeegilenzCompanyFact.ADDRESS:
          // eslint-disable-next-line no-case-declarations
          const castedAsAddress = dataBlockData as VeegilenzCompanyFactAddressV1DataBlockContent;
          item.value = castedAsAddress.metadata?.countryCodes;
          item.tooltip = getTooltipReason(castedAsAddress.reasonCode);
          item.flags = true;
          break;
        case VeegilenzCompanyFact.BODACC_COLLECTIVE_PROCEDURES:
        case VeegilenzCompanyFact.BODACC_CONCILIATIONS:
        case VeegilenzCompanyFact.BODACC_MISC_MODIFICATIONS:
        case VeegilenzCompanyFact.BODACC_RADIATIONS:
        case VeegilenzCompanyFact.BODACC_TRANSACTIONS:
          // eslint-disable-next-line no-case-declarations
          const castedAsBodacc = dataBlockData as VeegilenzCompanyFactBodaccV1DataBlockContent;
          item.value = castedAsBodacc.metadata?.publications.length.toString();
          item.tooltip = castedAsBodacc.metadata?.publications.map(publication => new Date(publication.dateparution).toLocaleDateString()).join(', ');
          break;
        case VeegilenzCompanyFact.FINANCIAL_BILANS:
          // eslint-disable-next-line no-case-declarations
          const castedAsFinancialBilan = dataBlockData as VeegilenzCompanyFactFinancialBilanV1DataBlockContent;
          item.value = castedAsFinancialBilan.metadata?.lastBilanDate
            ? `${castedAsFinancialBilan.metadata.lastBilanDate.replaceAll('-', '/')} (${t(`veegilenz.bilanType.${castedAsFinancialBilan.metadata.lastBilanType || 'unknown'}`)})`
            : t(`veegilenz.reason.${castedAsFinancialBilan.reasonCode}`);
          item.tooltip = getTooltipReason(castedAsFinancialBilan.reasonCode);
          break;
        case VeegilenzCompanyFact.FINANCIAL_STABILITY:
          // eslint-disable-next-line no-case-declarations
          const castedAsFinancialStability = dataBlockData as VeegilenzCompanyFactFinancialStabilityV1DataBlockContent;
          // eslint-disable-next-line no-case-declarations
          const parts: string[] = [];
          if (castedAsFinancialStability.metadata?.ebitdaDiffPercent !== undefined) {
            parts.push(`${t('veegilenz.ebitdaDiffPercent')} ${castedAsFinancialStability.metadata?.ebitdaDiffPercent || t('common.utils.unknown')}% < ${castedAsFinancialStability.metadata?.ebitdaDiffTreshold || t('common.utils.unknown')}%`);
          }
          if (castedAsFinancialStability.metadata?.turnoverDiffPercent !== undefined) {
            parts.push(`${t('veegilenz.turnoverDiffPercent')} ${castedAsFinancialStability.metadata?.turnoverDiffPercent || t('common.utils.unknown')}% < ${castedAsFinancialStability.metadata?.turnoverDiffTreshold || t('common.utils.unknown')}%`);
          }

          if (parts.length > 0) {
            item.value = parts.join('. ');
            item.tooltip = getTooltipReason(dataBlockData.reasonCode);
          } else {
            item.value = '--';
          }
          break;
        case VeegilenzCompanyFact.LINKED_TO_FINANCIAL_INSTITUTION:
        case VeegilenzCompanyFact.LINKED_TO_HOLDING:
        case VeegilenzCompanyFact.LINKED_TO_LISTED_COMPANY:
          // eslint-disable-next-line no-case-declarations
          const castedAsLink = dataBlockData as VeegilenzCompanyFactLinksV1DataBlockContent;
          item.value = castedAsLink.metadata?.directLinkEntityRefs ? `${castedAsLink.metadata?.directLinkEntityRefs?.length || 0} direct` : castedAsLink.metadata?.indirectLinks ? `${castedAsLink.metadata?.indirectLinks?.length || 0} indirect` : t('common.utils.unknown');
          item.tooltip = castedAsLink.metadata?.directLinkEntityRefs
            ? castedAsLink.metadata?.directLinkEntityRefs.map(entityRef => startCase(entityRef.name?.toLowerCase()) || entityRef.entityId).join(', ')
            : <GetIndirectLinks indirectLinks={castedAsLink.metadata?.indirectLinks} />;
          break;
        case VeegilenzCompanyFact.RISKS_AML:
        case VeegilenzCompanyFact.RISKS_ESG:
        case VeegilenzCompanyFact.RISKS_HOLDING:
        case VeegilenzCompanyFact.RISKS_LISTED_COMPANY:
          // eslint-disable-next-line no-case-declarations
          const castedAsRisk = dataBlockData as VeegilenzCompanyFactRiskV1DataBlockContent;
          item.value = castedAsRisk.metadata?.isRisky ? t('common.utils.yes') : t('common.utils.no');
          item.tooltip = getTooltipReason(castedAsRisk.reasonCode);
          break;
        case VeegilenzCompanyFact.STATE:
          // eslint-disable-next-line no-case-declarations
          const castedAsState = dataBlockData as VeegilenzCompanyFactStateV1DataBlockContent;
          item.value = castedAsState.metadata?.state ? t(`common.utils.${castedAsState.metadata?.state}`) : t('common.utils.unknown');
          item.tooltip = getTooltipReason(castedAsState.reasonCode);
          break;
        case VeegilenzCompanyFact.SANCTIONS:
          // eslint-disable-next-line no-case-declarations
          const castedAsSanction = dataBlockData as VeegilenzFactSanctionsV1DataBlockContent;
          item.value = castedAsSanction.metadata?.sanctions?.length.toString();
          item.tooltip = castedAsSanction.metadata?.sanctions?.map(sanction => t(`veegilenz.sanctionType.${sanction.type}`)).join(', ');
          break;
        case VeegilenzCompanyFact.PEP_DIRECT:
        case VeegilenzCompanyFact.PEP_INDIRECT:
          // eslint-disable-next-line no-case-declarations
          const castedAsPepRelated = dataBlockData as VeegilenzCompanyFactRelatedPEPV1DataBlockContent;
          item.value = castedAsPepRelated.metadata?.nbSources?.toString();
          // for Each source we take its type of exposure and the ultimate origin as person
          item.tooltip = (castedAsPepRelated.metadata?.sources.length || 0) > 0
            ? <DirectIndexVeegilenzPepTooltip dataBlock={castedAsPepRelated} />
            : undefined;
          item.tooltipWidth = 'max-content';

          break;
        default:
          throw new Error(`Unsupported company fact ${fact}`);
        }
        items.push(item);
      });
    });

    return items;
  };

  const values = useMemo(() => (props.entityType === 'deecPerson'
    ? getValuesForDeecPerson()
    : getValuesForDeecCompany()),
  [personFactDataBlocks, companyFactDataBlocks, props.entityType]
  );

  return (
    <Paper variant='hoverElevation1'>
      <Grid container spacing={4} width='min-content' alignItems='center'>
        <Grid size='grow'>
          <TitleComponent title={t('veegilenz.directIndex')} />
        </Grid>
        <Grid>
          <Tooltip
            title={
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography variant='body2'>
                  {t(
                    `veegilenz.reason.${summaryFactDataBlock?.data?.reasonCode || 'noReason'}`
                  )}
                </Typography>
                <Status statusAsInt={getGlobalVeegilenzLevel(summaryFactDataBlock.data.reasonCode)} size='1rem' />
              </Stack>
            }
            arrow
            placement='right'
            hidden={
              summaryFactDataBlock?.data?.levelAsInt === undefined
            }
          >
            <Box height='1.6rem' width='1.6rem' mr={2}>
              <Status
                statusAsInt={
                  summaryFactDataBlock?.data?.levelAsInt || 0
                }
                size='1.6rem'
              />
            </Box>
          </Tooltip>
        </Grid>
        <Grid size={12} p={2} pr={4} pl={4}>
          <CardPart
            values={values.map((value, index) => ({
              ...value,
              key:
                index !== 0 && value?.key === values?.[index - 1]?.key ? (
                  <IconCornerDownRight
                    size='1rem'
                    style={{ marginLeft: '8px' }}
                  />
                ) : (
                  value?.key
                )
            }))}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default DirectIndexVeegilenz;
