import { getCountryISO2 } from '@/utils/getCountryISO2';

export function getCountryName(countryCode: string, lang?: string) {
  const regionNames = new Intl.DisplayNames([lang ?? 'en'], { type: 'region' });
  const countryISO2 = getCountryISO2(countryCode);

  if (countryISO2 && countryISO2.length === 2) {
    return regionNames.of(countryISO2);
  }

  return countryCode;
}
