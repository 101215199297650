import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CompanyEntity, PersonEntity } from '@deecision/dna-interfaces';
import Stack from '@mui/material/Stack';
import { IconArrowRight, IconChevronLeft, IconCirclesRelation, IconExchange } from '@tabler/icons-react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { getEntity } from '@/main/providers/getter';
import dnaConfig from '../../../../../../config/dna.config.json';
import EntitiesCards from '@/main/containers/cards/dispatch';

function Step3Relations(): ReactElement {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [entity1, setEntity1] = useState<PersonEntity | CompanyEntity>();
  const [entity2, setEntity2] = useState<PersonEntity | CompanyEntity>();

  useEffect(() => {
    const entity1Id = searchParams.get('entity1');
    const entity1Type = searchParams.get('entity1Type');
    const entity2Id = searchParams.get('entity2');
    const entity2Type = searchParams.get('entity2Type');

    if (entity1Id && entity1Type) {
      getEntity<PersonEntity | CompanyEntity>({
        entityId: entity1Id,
        entityType: entity1Type
      })
        .then((res) => {
          setEntity1(res);
        });
    }

    if (entity2Id && entity2Type) {
      getEntity<PersonEntity | CompanyEntity>({
        entityId: entity2Id,
        entityType: entity2Type
      })
        .then((res) => {
          setEntity2(res);
        });
    }
  }, [searchParams]);

  return (
    <Stack spacing={4} width='100%' alignItems='center'>
      <Stack direction='row' spacing={2} mr='48px !important'>
        <Tooltip title={t('entities.relations.stepper.step2.changeEntity')} placement='top' arrow>
          <Button variant='textIcon' onClick={() => navigate({ pathname: dnaConfig.routes.relations.relativeUrl, search: `?step=2&entity1=${entity2?.entityId}&entity1Type=${entity2?.entityType}` })}>
            <IconExchange size='1.4rem' />
          </Button>
        </Tooltip>
        {entity1 && <EntitiesCards entity={entity1} />}
      </Stack>
      <Stack direction='row' spacing={4} width='100%' justifyContent='center' alignItems='center'>
        <Divider orientation='horizontal' sx={{ width: 280 }} />
        <IconCirclesRelation size='1.8rem' color={theme.palette.grey['600']} />
        <Divider orientation='horizontal' sx={{ width: 280 }} />
      </Stack>
      <Stack direction='row' spacing={2} mr='48px !important'>
        <Tooltip title={t('entities.relations.stepper.step2.changeEntity')} placement='top' arrow>
          <Button variant='textIcon' onClick={() => navigate({ pathname: dnaConfig.routes.relations.relativeUrl, search: `?step=2&entity1=${entity1?.entityId}&entity1Type=${entity1?.entityType}` })}>
            <IconExchange size='1.4rem' />
          </Button>
        </Tooltip>
        {entity2 && <EntitiesCards entity={entity2} />}
      </Stack>
      <Stack direction='row' spacing={4} justifyContent='center' mt='56px !important'>
        <IconButton onClick={() => navigate(-1)}>
          <IconChevronLeft size='1.6rem' />
        </IconButton>
        <Button variant='contained' sx={{ width: 200 }} endIcon={<IconArrowRight size='1.6rem' />} onClick={() => navigate({ pathname: `${dnaConfig.routes.relations.relativeUrl}/show`, search: `?entity1=${entity1?.entityId}&entity1Type=${entity1?.entityType}&entity2=${entity2?.entityId}&entity2Type=${entity2?.entityType}` })}>
          {t('entities.relations.stepper.step3.validationBtn')}
        </Button>
      </Stack>
    </Stack>
  );
}

export default Step3Relations;
