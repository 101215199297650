import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { PersonEntity } from '@deecision/dna-interfaces';
import KeyValueWithCard from '@/components/keyvalue/keyvaluewithcard';
import { PersonGetter } from '@/main/providers/getter';
import BaseWidget from '@/main/containers/widgets/base-widget';
import { WidgetProps } from '@/main/containers/widgets/types';

function PersonWidgetsCompaniesFinancial(props: WidgetProps<PersonEntity>): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const personGetter = new PersonGetter({
    data: props.data,
    errorMsg: t('common.utils.unknown')
  });

  const companiesDatas = props.data.dataViews?.data?.companies ? [
    {
      key: t('common.financial.turnover.sumShort'),
      fullKey: t('common.financial.turnover.sum'),
      value: personGetter.getTurnoverSum(),
      completeness: personGetter.getTurnoverSumCompleteness(),
      date: personGetter.getTurnoverSumDate(),
      align: 'right' as const
    },
    {
      key: t('common.financial.capital.sum'),
      value: personGetter.getCapitalSum(),
      completeness: personGetter.getCapitalSumCompleteness(),
      date: personGetter.getCapitalSumDate(),
      align: 'right' as const
    },
    {
      key: t('common.financial.ebitda.sum'),
      value: personGetter.getEbitdaSum(),
      completeness: personGetter.getEbitdaSumCompleteness(),
      date: personGetter.getEbitdaSumDate(),
      align: 'right' as const
    },
    {
      key: t('common.financial.valuation.sumShort'),
      fullKey: t('common.financial.valuation.sum'),
      value: personGetter.getValuationSum(),
      completeness: personGetter.getValuationSumCompleteness(),
      date: personGetter.getValuationSumDate(),
      align: 'right' as const
    }
  ] : [];

  return (
    <BaseWidget {...props} title={t('entities.companies.label')}>
      <KeyValueWithCard
        values={[companiesDatas]}
        keyValueCard={
          {
            Key: `${t('common.utils.active')} / ${t('common.utils.total')}`,
            value: `${personGetter.getNbActiveCompanies(true)}/${personGetter.getNbCompanies(true)}`,
            size: 'big',
            bgColor: ((personGetter.getNbCompanies(true) as number)
              - (personGetter.getNbActiveCompanies(true) as number))
            < (personGetter.getNbActiveCompanies(true) as number) ?
              theme.palette.success.light : theme.palette.error.light,
            color: ((personGetter.getNbCompanies(true) as number)
              - (personGetter.getNbActiveCompanies(true) as number))
            < (personGetter.getNbActiveCompanies(true) as number) ?
              theme.palette.success.dark : theme.palette.error.dark
          }
        }
      />
    </BaseWidget>
  );
}

export default PersonWidgetsCompaniesFinancial;
