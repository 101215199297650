import React, { useEffect, useState } from 'react';
import { darken, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { GroupAllValues, GroupBaseValues } from '@deecision/dna-interfaces';
import {
  IconArrowBackUp, IconArrowNarrowRight,
  IconChartHistogram,
  IconChartLine,
  IconCirclesRelation,
  IconCoins,
  IconMoneybag,
  IconUsers
} from '@tabler/icons-react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { Skeleton } from '@mui/material';
import { currencyFormatter } from '@/utils';
import IconBuildings from '@/assets/custom/IconBuildings';
import GroupsServices from '../../../modules/deetect/portfolios/services';
import KeyValue from '@/components/keyvalue/keyvalue';
import {
  basePaperSx,
  BasePortfolioDashboardProps,
  dashboardSpacing
} from './types';
import PortfolioDashboardProxemee, { getProxemeeSubGroups } from './proxemee';
import ContainerGroupQuickDashboard from './container';

function PortfolioDashboardGroup(props: BasePortfolioDashboardProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const groupsServices = new GroupsServices();
  const [values, setValues] = useState<GroupAllValues>();

  const iconMapper = (key: string) => {
    switch (key) {
    case 'persons':
      return <IconUsers size='1.2rem' />;
    case 'companies':
      return <IconBuildings size='1.2rem' />;
    case 'persons.companies':
      return <IconBuildings size='1.2rem' />;
    case 'persons.network':
      return <IconCirclesRelation size='1.2rem' />;
    default:
      return <IconUsers size='1.2rem' />;
    }
  };

  const paperSx = (entityType: string) => ({
    ...basePaperSx,
    minWidth: 'fit-content',
    bgcolor: entityType === 'deecPerson' ? theme.palette.primary.light : theme.palette.secondary.light,
    color: entityType === 'deecPerson' ? theme.palette.primary.dark : theme.palette.secondary.dark,
    borderColor: darken(entityType === 'deecPerson' ? theme.palette.primary.light : theme.palette.secondary.light, 0.2)
  });

  useEffect(() => {
    if (props.group.subGroupId && props.group.type !== 'parent') {
      groupsServices.getGroupValues(props.baseGroupId, props.group.subGroupId)
        .then(res => setValues(res.data));
    }
  }, []);

  return (
    <Stack direction='row' width='100%' spacing={dashboardSpacing}>
      <ContainerGroupQuickDashboard {...props}>
        <Grid size='grow' container spacing={dashboardSpacing} width={values ? undefined : '100%'} minWidth='min-content' maxWidth='100%'>
          <Grid size={12}>
            {values ?
              <Paper
                sx={{
                  ...basePaperSx,
                  bgcolor: theme.palette.grey['300'],
                  minWidth: 'fit-content',
                  alignItems: 'center !important',
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: theme.palette.grey['400']
                  }
                }}
                onClick={() => props.callSegmentation?.(values.groupDesc.objectType, props.group.subGroupId)}
              >
                <KeyValue
                  value={values?.values.totalCount || 0}
                  size='big'
                  color={values.groupDesc.objectType === 'deecPerson' ? theme.palette.primary.dark : theme.palette.secondary.dark}
                />
              </Paper> :
              props.group.type !== 'parent' && <Skeleton variant='rounded' height={37} width='100%' sx={{ minWidth: 82 }} />
            }
          </Grid>
          {!values && props.group.type !== 'parent' &&
            [...Array(4)].map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid size='grow' key={`skeleton-${index}`} minWidth={82}>
                <Skeleton variant='rounded' height={48} width='100%' />
              </Grid>
            ))
          }
          {values?.values?.main.weasse_sum !== undefined &&
            <Grid size='grow' minWidth='fit-content'>
              <Paper sx={paperSx(values?.groupDesc.objectType)}>
                <KeyValue
                  Key={t('common.financial.weasse.short')}
                  fullKey={t('common.financial.weasse.sum')}
                  value={currencyFormatter({ value: values?.values?.main.weasse_sum, currency: 'EUR' })}
                  completeness={values?.values?.main.weasse_avgCompleteness}
                  icon={IconMoneybag}
                  size='normal'
                />
              </Paper>
            </Grid>
          }
          {values?.values?.main.extrapolatedWeasse_sum !== undefined &&
            <Grid size='grow' minWidth='fit-content'>
              <Paper sx={paperSx(values?.groupDesc.objectType)}>
                <KeyValue
                  Key={t('common.financial.extrapolatedWeasse.short')}
                  fullKey={t('common.financial.extrapolatedWeasse.sum')}
                  value={currencyFormatter({ value: values?.values?.main.extrapolatedWeasse_sum, currency: 'EUR' })}
                  completeness={values?.values?.main.extrapolatedWeasse_avgCompleteness}
                  icon={IconMoneybag}
                  size='normal'
                />
              </Paper>
            </Grid>
          }
          {values?.values?.main.valuationSum !== undefined &&
            <Grid size='grow' minWidth='fit-content'>
              <Paper sx={paperSx(values?.groupDesc.objectType)}>
                <KeyValue
                  Key={t('common.financial.valuation.short')}
                  fullKey={t('common.financial.valuation.sum')}
                  value={currencyFormatter({ value: values?.values?.main.valuationSum, currency: 'EUR' })}
                  completeness={values?.values?.main.valuationSum_avgCompleteness}
                  icon={IconChartLine}
                  size='normal'
                />
              </Paper>
            </Grid>
          }
          {values?.values?.main.valuationSum_sum !== undefined &&
            <Grid size='grow' minWidth='fit-content'>
              <Paper sx={paperSx(values?.groupDesc.objectType)}>
                <KeyValue
                  Key={t('common.financial.valuation.sumOfSumShort')}
                  fullKey={t('common.financial.valuation.sumOfSum')}
                  value={currencyFormatter({ value: values?.values?.main.valuationSum_sum, currency: 'EUR' })}
                  icon={IconChartLine}
                  size='normal'
                />
              </Paper>
            </Grid>
          }
          {values?.values?.main.ebitdaSum !== undefined &&
            <Grid size='grow' minWidth='fit-content'>
              <Paper sx={paperSx(values?.groupDesc.objectType)}>
                <KeyValue
                  Key={t('common.financial.ebitda.sum')}
                  value={currencyFormatter({ value: values?.values?.main.ebitdaSum, currency: 'EUR' })}
                  icon={IconChartHistogram}
                  size='normal'
                />
              </Paper>
            </Grid>
          }
          {values?.values?.main.capitalSum !== undefined &&
            <Grid size='grow' minWidth='fit-content'>
              <Paper sx={paperSx(values?.groupDesc.objectType)}>
                <KeyValue
                  Key={t('common.financial.capital.sum')}
                  value={currencyFormatter({ value: values?.values?.main.capitalSum, currency: 'EUR' })}
                  icon={IconCoins}
                  size='normal'
                />
              </Paper>
            </Grid>
          }
          {values?.values?.main.turnoverSum !== undefined &&
            <Grid size='grow' minWidth='fit-content'>
              <Paper sx={paperSx(values?.groupDesc.objectType)}>
                <KeyValue
                  Key={t('common.financial.turnover.short')}
                  fullKey={t('common.financial.turnover.sum')}
                  value={currencyFormatter({ value: values?.values?.main.turnoverSum, currency: 'EUR' })}
                  icon={IconArrowBackUp}
                  size='normal'
                />
              </Paper>
            </Grid>
          }
        </Grid>
        {values?.values && Object.keys(values?.values).filter(value => value !== 'main' && value !== 'totalCount').length > 0 &&
          Object.keys(values?.values).filter(value => value !== 'main' && value !== 'totalCount').map(value => (
            <Grid size='auto' key={`active-${value}`} minWidth='min-content' maxWidth='100%'>
              <Paper sx={{ ...basePaperSx, minWidth: 'fit-content', bgcolor: theme.palette.success.light, color: theme.palette.success.dark, borderColor: darken(theme.palette.success.light, 0.2) }}>
                <Grid container spacing={dashboardSpacing} height='100%' width='100%'>
                  <Grid size={12} maxHeight='min-content'>
                    <Stack direction='row' spacing={dashboardSpacing} alignItems='center'>
                      {iconMapper(value)}
                      <Typography variant='h5' whiteSpace='nowrap'>
                        {value === 'persons' ? t('entities.relations.keys.nbActive') : t('entities.companies.keys.nbActive')}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid size={12} minWidth='min-content'>
                    <Paper
                      sx={{
                        ...basePaperSx,
                        minWidth: 'fit-content',
                        bgcolor: darken(theme.palette.success.light, 0.1),
                        borderColor: darken(theme.palette.success.light, 0.3),
                        alignItems: 'center !important',
                        cursor: 'pointer',
                        '&:hover': {
                          bgcolor: darken(theme.palette.success.light, 0.2),
                          borderColor: darken(theme.palette.success.light, 0.2)
                        }
                      }}
                      onClick={() => props.callSegmentation?.(values.groupDesc.objectType, props.group.subGroupId, values.groupDesc.objectType === 'deecPerson' ? 'person1-companies_person2-companies' : 'person1_link_person2')}
                    >
                      <KeyValue
                        value={(values.values[value as keyof typeof values.values] as GroupBaseValues)?.count || 0}
                        size='big'
                        color={theme.palette.success.dark}

                      />
                    </Paper>
                  </Grid>
                  {(values.values[value as keyof typeof values.values] as GroupBaseValues)?.valuationSum !== undefined &&
                    <Grid size='grow' minWidth='fit-content'>
                      <Paper sx={paperSx(value)}>
                        <KeyValue
                          Key={t('common.financial.valuation.short')}
                          fullKey={t('common.financial.valuation.sum')}
                          value={currencyFormatter({ value: (values.values[value as keyof typeof values.values] as GroupBaseValues)?.valuationSum, currency: 'EUR' })}
                          icon={IconChartLine}
                          size='normal'
                        />
                      </Paper>
                    </Grid>
                  }
                  {(values.values[value as keyof typeof values.values] as GroupBaseValues)?.valuationSum_sum !== undefined &&
                    <Grid size='grow' minWidth='fit-content'>
                      <Paper sx={paperSx(value)}>
                        <KeyValue
                          Key={t('common.financial.valuation.sumOfSumShort')}
                          fullKey={t('common.financial.valuation.sumOfSum')}
                          value={currencyFormatter({ value: (values.values[value as keyof typeof values.values] as GroupBaseValues)?.valuationSum_sum, currency: 'EUR' })}
                          icon={IconChartLine}
                          size='normal'
                        />
                      </Paper>
                    </Grid>
                  }
                  {(values.values[value as keyof typeof values.values] as GroupBaseValues)?.ebitdaSum !== undefined &&
                    <Grid size='grow' minWidth='fit-content'>
                      <Paper sx={paperSx(value)}>
                        <KeyValue
                          Key={t('common.financial.ebitda.sum')}
                          value={currencyFormatter({ value: (values.values[value as keyof typeof values.values] as GroupBaseValues)?.ebitdaSum, currency: 'EUR' })}
                          icon={IconChartHistogram}
                          size='normal'
                        />
                      </Paper>
                    </Grid>
                  }
                  {(values.values[value as keyof typeof values.values] as GroupBaseValues)?.capitalSum !== undefined &&
                    <Grid size='grow' minWidth='fit-content'>
                      <Paper sx={paperSx(value)}>
                        <KeyValue
                          Key={t('common.financial.capital.sum')}
                          value={currencyFormatter({ value: (values.values[value as keyof typeof values.values] as GroupBaseValues)?.capitalSum, currency: 'EUR' })}
                          icon={IconCoins}
                          size='normal'
                        />
                      </Paper>
                    </Grid>
                  }
                  {(values.values[value as keyof typeof values.values] as GroupBaseValues)?.turnoverSum !== undefined &&
                    <Grid size='grow' minWidth='fit-content'>
                      <Paper sx={paperSx(value)}>
                        <KeyValue
                          Key={t('common.financial.turnover.short')}
                          fullKey={t('common.financial.turnover.sum')}
                          value={currencyFormatter({ value: (values.values[value as keyof typeof values.values] as GroupBaseValues)?.turnoverSum, currency: 'EUR' })}
                          icon={IconArrowBackUp}
                          size='normal'
                        />
                      </Paper>
                    </Grid>
                  }
                </Grid>
              </Paper>
            </Grid>
          ))
        }
      </ContainerGroupQuickDashboard>
      {props.group.subGroups && props.group.subGroups.length > 0 &&
        <Grid container spacing={dashboardSpacing * 3}>
          {getProxemeeSubGroups(props.group).length > 0 ?
            <Grid size={12} container spacing={dashboardSpacing} flexWrap='nowrap'>
              <Grid pt={3}>
                <IconArrowNarrowRight size='1.8rem' />
              </Grid>
              <Grid size='grow'>
                <PortfolioDashboardProxemee {...props} />
              </Grid>
            </Grid>
            : undefined
          }
          {props.group.subGroups
            .filter(subGroup => (!subGroup.subGroupId?.includes('personal') && !subGroup.subGroupId?.includes('business') && !subGroup.subGroupId?.includes('extended')))
            .map(subGroup => (subGroup ?
              <Grid key={subGroup?.subGroupId || subGroup.subGroupId.split('.').at(-1)} size={12} container spacing={dashboardSpacing} flexWrap='nowrap'>
                <Grid pt={3}>
                  <IconArrowNarrowRight size='1.8rem' />
                </Grid>
                <Grid size='grow'>
                  <PortfolioDashboardGroup {...props} group={subGroup} />
                </Grid>
              </Grid> : undefined
            ))
          }
        </Grid>
      }
    </Stack>
  );
}

export default PortfolioDashboardGroup;
