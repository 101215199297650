import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import createContext from '@/hooks/context';

export const [useFullscreen, FullScreenProvider] = createContext<{ isFullscreen: boolean, setIsFullscreen: Dispatch<SetStateAction<boolean>> }>();

// Export this in fullscreen/fullscreenStyle.tsx
export const filterOnFullscreenStyle = {
  // height: '5vh',
  // width: '100vw',
  // position: 'fixed',
  // top: 0,
  // left: 0,
  // zIndex: 9999,
  // backgroundColor: 'transparent',
  // paddingLeft: '5px',
  // paddingRight: '5px',
  // paddingBottom: '0px',
  // marginTop: '5px'
  height: '5vh',
  width: 'auto',
  position: 'fixed',
  top: 0,
  zIndex: 9999,
  backgroundColor: 'transparent',
  paddingLeft: '5px',
  paddingRight: '5px',
  paddingBottom: '0px',
  marginTop: '5px'
};
export const filterBaseStyle = {};

export const ElementBaseStyle = {
  width: '100%',
  height: '75vh'
};
export const ElementOnFullscreenStyle = {
  height: '100vh',
  width: '100vw',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 9998,
  backgroundColor: '#F8F9FA',
  paddingLeft: '5px',
  paddingRight: '5px'
};

function FullscreenProvider(props: {children: ReactElement}): ReactElement {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsFullscreen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleEscape);

    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, []);

  return (
    <FullScreenProvider value={{ isFullscreen, setIsFullscreen }}>
      {props.children}
    </FullScreenProvider>
  );
};

export default FullscreenProvider;
