import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Topbar, { TopbarProps } from '@/layouts/topbar';
import Sidebar, { SidebarProps } from '@/layouts/sidebar';
import Breadcrumbs from '@/layouts/breadcrumbs';
import Container, { MainerContainerProps } from './container';
import { SidebarContextProvider } from './hooks/sidebar';
import { DispLanguageContextProvider } from './hooks/displanguage';
import LoaderLayout, { LoaderLayoutProps } from '@/layouts/loader';
import { getLayoutStorage, setLayoutStorage } from './storage';
import { LayoutStorageContextProvider } from './hooks/layoutstorage';
import { getLanguageStorage, setLanguageStorage } from '@/i18n/localstorage';
import { LanguageStorageContextProvider } from './hooks/languagestorage';
import { AxiosCancelWrapper } from '@/wrappers/cancel';
import axiosInstance from '@/api';

export const topBarHeight = 64;

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    menu: true,
    menuActive: true,
    menuUser: true,
    menuUserActive: true,
    menuIcon: true,
    menuIconActive: true,
    appIcon: true,
    appIconActive: true,
    icon: true,
    topbarIcon: true,
    sidebarChevron: true
  }
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    hoverElevation1: true,
    hoverElevation2: true,
    hoverElevation3: true,
    hoverElevation4: true
  }
}

export interface LayoutProps extends TopbarProps, SidebarProps, LoaderLayoutProps, Omit<MainerContainerProps, 'breadcrumbs'> {}

function Layout(props: LayoutProps): ReactElement {
  const [layoutStorage, setLayoutStorageState] = useState(getLayoutStorage());
  const [languageStorage, setLanguageStorageState] = useState(getLanguageStorage());
  const [dispLanguage, setDispLanguage] = useState<boolean>(layoutStorage ? layoutStorage.dispLanguage : true);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(layoutStorage ? layoutStorage.sidebar : true);

  const sidebarContextValue = useMemo(() => (
    { sidebarOpen, setSidebarOpen }
  ), [sidebarOpen, setSidebarOpen]);

  const dispLanguageContextValue = useMemo(() => (
    { dispLanguage, setDispLanguage }
  ), [dispLanguage, setDispLanguage]);

  const layoutStorageContextValue = useMemo(() => (
    { layoutStorage, setLayoutStorage: setLayoutStorageState }
  ), [layoutStorage, setLayoutStorage]);

  const languageStorageContextValue = useMemo(() => (
    { languageStorage, setLanguageStorage: setLanguageStorageState }
  ), [languageStorage, setLanguageStorage]);

  useEffect(() => {
    if (layoutStorage) {
      setLayoutStorage(layoutStorage);
      setSidebarOpen(layoutStorage.sidebar);
      setDispLanguage(layoutStorage.dispLanguage);
    }
  }, [layoutStorage]);

  useEffect(() => {
    if (languageStorage) {
      setLanguageStorage(languageStorage);
    }
  }, [languageStorage]);

  return (
    <Box sx={{ width: '100vw', height: '100vh', backgroundColor: 'background.default' }}>
      <AxiosCancelWrapper axiosInstance={axiosInstance}>
        <DispLanguageContextProvider value={dispLanguageContextValue}>
          <SidebarContextProvider value={sidebarContextValue}>
            <LayoutStorageContextProvider value={layoutStorageContextValue}>
              <LanguageStorageContextProvider value={languageStorageContextValue}>
                <LoaderLayout {...props} />
                <Grid container direction='column' height='100%'>
                  <Grid height={topBarHeight}>
                    <Topbar {...props} />
                  </Grid>
                  <Grid size='grow'>
                    <Grid container height={`calc(100vh - ${topBarHeight}px)`}>
                      <Sidebar {...props} />
                      <Container
                        breadcrumbs={
                          layoutStorage ? layoutStorage.breadcrumbs ? <Breadcrumbs /> : undefined : <Breadcrumbs />
                        }
                        {...props}
                      >
                        {props.children}
                      </Container>
                    </Grid>
                  </Grid>
                </Grid>
              </LanguageStorageContextProvider>
            </LayoutStorageContextProvider>
          </SidebarContextProvider>
        </DispLanguageContextProvider>
      </AxiosCancelWrapper>
    </Box>
  );
}

export default Layout;
