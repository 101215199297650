import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import { Button, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import ServiceError from '../api/services/error';
import errorRobot from '@/assets/images/errorRobot.gif';
import img404 from '@/assets/images/404.svg';

interface ErrorPageProps {
  app: string,
  errMessage: string,
  errTitle: string
}

export interface ErrorManagerProps extends Pick<ErrorPageProps, 'app'>{
  error: ServiceError
}

export function ErrorPage(props: ErrorPageProps): ReactElement {
  const navigate = useNavigate();

  return (
    <Paper
      elevation={0}
      style={{
        height: 'calc(100% - 32px)',
        width: 'calc(100% - 32px)',
        margin: '16px',
        backgroundColor: props.errTitle === '404' ? 'transparent' : 'white'
      }}
    >
      <Grid
        container
        spacing={0}
        justifyContent='center'
        style={{ height: '100%' }}
      >
        {props.errTitle === '404' &&
          <>
            <Grid size={12} />
            <Grid size={12} style={{ height: 'min-content', textAlign: 'center' }}>
              <img
                style={{ width: '50%', maxWidth: '600px' }}
                src={img404}
                alt=''
              />
            </Grid>
            <Grid size={12} style={{ height: 'min-content' }}>
              <Typography style={{ fontSize: '3rem', fontWeight: '700', lineHeight: '60px', textAlign: 'center' }}>
                {props.errMessage}
              </Typography>
            </Grid>
          </>
        }
        <Grid>
          <Grid container direction='column' spacing={2} justifyContent='center' style={{ width: 'max-content', maxWidth: '320px', height: '100%' }}>
            {props.errTitle !== '404' &&
              <>
                <Grid>
                  <Typography style={{ fontSize: '5rem', fontWeight: '700', lineHeight: '60px' }}>
                    {props.errTitle}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography style={{ fontSize: '1.6rem', fontWeight: '500', lineHeight: '28px' }}>
                  Something went
                  </Typography>
                </Grid>
                <Grid style={{ marginTop: '-12px' }}>
                  <Typography style={{ fontSize: '1.8rem', fontWeight: '700', lineHeight: '28px' }}>
                    WRONG!
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant='body1'>
                    {props.errMessage}
                  </Typography>
                </Grid>
              </>
            }
            <Grid style={{ width: '200px' }}>
              <Button variant='contained' onClick={() => navigate(-1)} fullWidth>
                Go Back
              </Button>
            </Grid>
            <Grid
              style={{
                width: '200px',
                textAlign: 'center',
                marginTop: props.errTitle !== '404' ? '-12px' : undefined
              }}
            >
              {props.errTitle !== '404' ?
                <Typography variant='caption'>
                  or contact your webmaster
                </Typography> :
                <Link href='/public'>
                  <Typography variant='body2'>
                    Home
                  </Typography>
                </Link>
              }
            </Grid>
          </Grid>
        </Grid>
        {props.errTitle !== '404' ?
          <Grid
            style={{
              height: 'min-content',
              marginTop: 'auto',
              marginBottom: 'auto',
              marginLeft: props.errTitle === '404' ? '16px' : undefined
            }}
          >
            <img
              src={errorRobot}
              alt=''
            />
          </Grid> :
          <>
            <Grid size={12} />
            <Grid size={12} />
          </>
        }
      </Grid>
    </Paper>
  );
}

function ErrorPageManager(props: ErrorManagerProps) : ReactElement {
  if (props.error.isUnAuthenticated()) {
    window.location.reload();
  }

  return (
    <>
      {
        props.error.isAccessDenied() && <ErrorPage errTitle='403' errMessage='Forbidden' {...props} /> ||
        props.error.isNotFound() && <ErrorPage errTitle='404' errMessage='Page Not Found' {...props} /> ||
        props.error.isDataFound() && <h1>data not found</h1> ||
        props.error.isDataError() && <h1>Serveur Error</h1> ||
          <ErrorPage
            errTitle={props.error.httpStatus.toString()}
            errMessage={ServiceError.getErrorMsg(props.error)}
            {...props}
          />
      }
    </>
  );
}

export function ErrorStringManager(props: Omit<ErrorManagerProps, 'app'>) : ReactElement {
  if (props.error.isUnAuthenticated()) {
    window.location.reload();
  }

  return (
    <>
      {
        props.error.isAccessDenied() && <h4>403 : Forbidden</h4> ||
        props.error.isNotFound() && <h4>404 : Page Not Found</h4> ||
        props.error.isDataFound() && <h4>data not found</h4> ||
        props.error.isDataError() && <h4>Serveur Error</h4> ||
          <h4>{props.error.httpStatus.toString()} : {ServiceError.getErrorMsg(props.error)}</h4>
      }
    </>
  );
}

export default ErrorPageManager;

