import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { WidgetWithPersonsAugmentedEntitiesProps } from '@/main/containers/widgets/types';
import BaseWidgetRelationsPositionsRoles from '@/main/containers/widgets/base-position-roles';

function CompanyWidgetRelationsPositionsRoles(props: WidgetWithPersonsAugmentedEntitiesProps): ReactElement {
  const { t } = useTranslation();

  const data = useMemo(() => {
    const dataTmp: Record<'roles' | 'positionTypes', Record<string, number>> = {
      positionTypes: {},
      roles: {}
    };

    props.data?.forEach((augmentedEntity) => {
      const detail = augmentedEntity.details.find(d => d);

      if (detail) {
        if (detail.positionTypes && detail.positionTypes.length > 0) {
          detail.positionTypes?.forEach((positionType) => {
            if (!dataTmp.positionTypes[positionType]) {
              dataTmp.positionTypes[positionType] = 0;
            }
            dataTmp.positionTypes[positionType] += 1;
          });
        } else {
          if (!dataTmp.positionTypes[t('common.utils.notFound')]) {
            dataTmp.positionTypes[t('common.utils.notFound')] = 0;
          }
          dataTmp.positionTypes[t('common.utils.notFound')] += 1;
        }

        if (detail.role && detail.role.text) {
          if (!dataTmp.roles[detail.role.text]) {
            dataTmp.roles[detail.role.text] = 0;
          }
          dataTmp.roles[detail.role.text] += 1;
        } else {
          if (!dataTmp.roles[t('common.utils.notFound')]) {
            dataTmp.roles[t('common.utils.notFound')] = 0;
          }
          dataTmp.roles[t('common.utils.notFound')] += 1;
        }
      }
    });

    return dataTmp;
  }, [props.data]);

  return (<BaseWidgetRelationsPositionsRoles {...props} chartData={data} />);
}

export default CompanyWidgetRelationsPositionsRoles;
