import React, { ReactElement } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconCategory2 } from '@tabler/icons-react';
import dnaLogo from '@/assets/logos/DNA.svg';
import deeligenzLogo from '@/assets/logos/Deeligenz.svg';

export const logos = [
  {
    name: 'dna',
    svg: dnaLogo
  },
  {
    name: 'deeligenz',
    svg: deeligenzLogo
  }
];

export interface AppMenuProps {
  app: string,
  availableApps?: string[]
}

function AppMenu(props: AppMenuProps): ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectToApp = (app: string) => {
    handleClose();
    window.location.assign(`/${app}`);
  };

  return (
    <>
      <Button
        variant={open ? 'appIconActive' : 'appIcon'}
        size='large'
        color='primary'
        onClick={handleClick}
      >
        <IconCategory2 size='1.6rem' />
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {logos.filter(logo => logo.name === props.app || props.availableApps?.includes(logo.name)).map(logo => (
          <MenuItem
            key={logo.name}
            sx={{
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'transparent',
                cursor: 'default'
              }
            }}
            disableTouchRipple
            disableRipple
          >
            <Button
              variant={logo.name === props.app ? 'menuActive' : 'menu'}
              size='large'
              onClick={() => redirectToApp(logo.name === props.app ? props.app : logo.name)}
            >
              <img src={logo.svg} alt={logo.name} height={30} />
            </Button>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default AppMenu;
