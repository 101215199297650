import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyEntity } from '@deecision/dna-interfaces';
import { CompanyGetter } from '@/main/providers/getter';
import VeegilenzCard from '@/main/containers/veegilenz/card';
import BaseWidget from '@/main/containers/widgets/base-widget';
import VEEGILENZ from '@/assets/VEEGILENZ.svg';
import { WidgetProps } from '@/main/containers/widgets/types';

function CompanyWidgetsVeegilenz(props: WidgetProps<CompanyEntity>): ReactElement {
  const { t } = useTranslation();
  const companyGetter = new CompanyGetter({
    data: props.data,
    errorMsg: t('common.utils.unknown')
  });

  return (
    <BaseWidget
      {...props}
      title={<img src={VEEGILENZ} alt='Veegilenz' height={18} />}
    >
      <VeegilenzCard
        score={2}
        veegilenzDatas={{
          directIndex: companyGetter.getVeegilenzLevel(true) as number,
          directIndexReason: companyGetter.getVeegilenzReason()
        }}
      />
    </BaseWidget>
  );
}

export default CompanyWidgetsVeegilenz;
