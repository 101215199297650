import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { WidgetWithPersonsAugmentedEntitiesProps } from '@/main/containers/widgets/types';
import BaseWidgetRelationsPositionsRoles from '@/main/containers/widgets/base-position-roles';

function PersonWidgetRelationsPositionsRoles(props: WidgetWithPersonsAugmentedEntitiesProps): ReactElement {
  const { t } = useTranslation();

  const data = useMemo(() => {
    const dataTmp: Record<'roles' | 'positionTypes', Record<string, number>> = {
      positionTypes: {},
      roles: {}
    };

    props.data?.forEach((augmentedEntity) => {
      augmentedEntity.details.find(detail => detail)?.companies?.forEach((company) => {
        if (company.person2.activePositionTypes.length > 0) {
          company.person2.activePositionTypes.forEach((positionType) => {
            if (!dataTmp.positionTypes[positionType]) {
              dataTmp.positionTypes[positionType] = 0;
            }
            dataTmp.positionTypes[positionType] += 1;
          });
        } else {
          if (!dataTmp.positionTypes[t('common.utils.notFound')]) {
            dataTmp.positionTypes[t('common.utils.notFound')] = 0;
          }
          dataTmp.positionTypes[t('common.utils.notFound')] += 1;
        }

        if (company.person2.activeRoles.length > 0) {
          company.person2.activeRoles.forEach((role) => {
            if (role?.text) {
              if (!dataTmp.roles[role.text]) {
                dataTmp.roles[role.text] = 0;
              }
              dataTmp.roles[role.text] += 1;
            } else {
              if (!dataTmp.roles[t('common.utils.notFound')]) {
                dataTmp.roles[t('common.utils.notFound')] = 0;
              }
              dataTmp.roles[t('common.utils.notFound')] += 1;
            }
          });
        } else {
          if (!dataTmp.roles[t('common.utils.notFound')]) {
            dataTmp.roles[t('common.utils.notFound')] = 0;
          }
          dataTmp.roles[t('common.utils.notFound')] += 1;
        }
      });
    });

    return dataTmp;
  }, [props.data]);

  return (<BaseWidgetRelationsPositionsRoles {...props} chartData={data} />);
}

export default PersonWidgetRelationsPositionsRoles;
