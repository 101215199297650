import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IDataBlock } from '@deecision/dna-interfaces';
import { IconChevronRight } from '@tabler/icons-react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid2';
import { Column } from '@/components/tables/types';
import { DataBlocksQueryService } from '@/api/services/query';
import EvenOddTable from '@/components/tables/evenodd/table';
import DisplayJson from '@/components/displayjson';
import { CurrencyFormatterProps } from '@/utils';

type DataBlockWithId = IDataBlock & { _id: string }

function DataBlocksScreen(): ReactElement {
  const dataBlocksService = new DataBlocksQueryService({ entityType: 'deecCompany' });
  const [dataBlocks] = useState<DataBlockWithId[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    dataBlocksService.findInAllEntities({})
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          // setDataBlocks(res.data);
        }
        setTotalCount(res.totalCount);
      });
  }, []);

  const columns: Column<CurrencyFormatterProps>[] = [
    {
      id: 'entityType',
      label: 'Entity Type'
    },
    {
      id: 'name',
      label: 'Name'
    },
    {
      id: 'path',
      label: 'Path'
    },
    {
      id: 'dataType',
      label: 'DataType'
    },
    {
      id: 'data',
      label: 'Data'
    },
    {
      id: 'voirPlus',
      label: 'Voir Plus'
    }
  ];

  const rows = dataBlocks?.map(dataBlock => ({
    entityType: dataBlock.entityRef.entityType,
    id: dataBlock._id,
    name: <Link href={`../entities/${dataBlock.entityRef.entityId as string}`}
      onClick={() => {
        localStorage.setItem(`entityType${dataBlock.entityRef.entityId}`, dataBlock.entityRef.entityType);
      }}>
      {dataBlock.entityRef.name}<br/><Typography fontSize={12} fontWeight={300}>({dataBlock.entityRef.entityId})</Typography>
    </Link>,
    path: dataBlock.dataInfo.path,
    dataType: dataBlock.dataInfo.dataType,
    data:
    <Box width='300px'>
      <DisplayJson data={dataBlock.data} />
    </Box>,
    voirPlus:
    <Tooltip title={dataBlock._id} >
      <IconButton onClick={() => {
        localStorage.setItem(`entityType${dataBlock._id}`, dataBlock.entityRef.entityType);
        navigate(`${dataBlock._id}`);
      }}>
        <IconChevronRight size='1.6rem' />
      </IconButton>
    </Tooltip>
  }));

  return (
    <Grid container spacing={2} mt='1%' sx={{ flexGrow: 1 }}>
      {/* <Filter
        filterEntityTypeName={filterEntityTypeName}
        filterTypeName={filterTypeName}
        filterSchedulingStatusName={filterSchedulingStatusName}
        handleSetters={handleSetters}
        setResetting={setResetting} /> */}
      <Grid size={12}>
        <EvenOddTable<CurrencyFormatterProps> label='dataBlocks' columns={columns} rows={rows || []} totalCount={totalCount}/>
      </Grid>
    </Grid>
  );
}

export default DataBlocksScreen;
