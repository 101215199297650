import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PersonEntity } from '@deecision/dna-interfaces';
import { WidgetProps } from '@/main/containers/widgets/types';
import BaseWidgetRelationsPositionsRoles from '@/main/containers/widgets/base-position-roles';

function PersonWidgetCompaniesPositionsRoles(props: WidgetProps<PersonEntity>): ReactElement {
  const { t } = useTranslation();

  const data = useMemo(() => {
    const dataTmp: Record<'roles' | 'positionTypes', Record<string, number>> = {
      positionTypes: {},
      roles: {}
    };

    props.data.dataViews?.data?.companies?.stats?.active?.companiesInfo?.forEach((company) => {
      if (company.activePositionTypes.length > 0) {
        company.activePositionTypes.forEach((positionType) => {
          if (!dataTmp.positionTypes[positionType]) {
            dataTmp.positionTypes[positionType] = 0;
          }
          dataTmp.positionTypes[positionType] += 1;
        });
      } else {
        if (!dataTmp.positionTypes[t('common.utils.notFound')]) {
          dataTmp.positionTypes[t('common.utils.notFound')] = 0;
        }
        dataTmp.positionTypes[t('common.utils.notFound')] += 1;
      }

      if (company.activeRoles.length > 0) {
        company.activeRoles.forEach((role) => {
          if (role.text) {
            if (!dataTmp.roles[role.text]) {
              dataTmp.roles[role.text] = 0;
            }
            dataTmp.roles[role.text] += 1;
          } else {
            if (!dataTmp.roles[t('common.utils.notFound')]) {
              dataTmp.roles[t('common.utils.notFound')] = 0;
            }
            dataTmp.roles[t('common.utils.notFound')] += 1;
          }
        });
      } else {
        if (!dataTmp.roles[t('common.utils.notFound')]) {
          dataTmp.roles[t('common.utils.notFound')] = 0;
        }
        dataTmp.roles[t('common.utils.notFound')] += 1;
      }
    });

    return dataTmp;
  }, [props.data]);

  return (<BaseWidgetRelationsPositionsRoles {...props} chartData={data} data={props.data.dataViews?.data?.companies?.stats?.active?.companiesInfo} />);
}

export default PersonWidgetCompaniesPositionsRoles;
