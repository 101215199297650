import React, { ReactElement } from 'react';
import Grid from '@mui/material/Grid2';
import { CustomRouteObject } from '@/router/types';

export interface CustomTopbarMenuComponentsProps {
  routeObjects: CustomRouteObject[][] | CustomRouteObject[]
}

function CustomTopbarMenuComponents(props: CustomTopbarMenuComponentsProps): ReactElement {

  return (
    <>
      {props.routeObjects.map(routeObjectArray =>
        (Array.isArray(routeObjectArray) ?
          routeObjectArray.map(routeObject => (
            <React.Fragment key={`${routeObject.id}-${routeObject.path}`}>
              {routeObject.path && routeObject.handle?.top && !!routeObject.handle?.customComponent &&
                <Grid>
                  {routeObject.handle.customComponent}
                </Grid>
              }
              {routeObject.children && routeObject.children.filter(routeObjectChild => routeObjectChild.path && routeObjectChild.handle?.top && !!routeObjectChild.handle?.customComponent).map(routeObjectChild => (
                <Grid key={`${routeObjectChild.id}-${routeObjectChild.path}`}>
                  {routeObjectChild.handle.customComponent}
                </Grid>
              ))
              }
            </React.Fragment>
          )) :
          <React.Fragment key={`${routeObjectArray.id}-${routeObjectArray.path}`}>
            {routeObjectArray.path && routeObjectArray.handle?.top && !!routeObjectArray.handle?.customComponent &&
              <Grid>
                {routeObjectArray.handle.customComponent}
              </Grid>
            }
            {routeObjectArray.children && routeObjectArray.children.filter(routeObjectChild => routeObjectChild.path && routeObjectChild.handle?.top && !!routeObjectChild.handle?.customComponent).map(routeObjectChild => (
              <Grid key={`${routeObjectChild.id}-${routeObjectChild.path}`}>
                {routeObjectChild.handle.customComponent}
              </Grid>
            ))
            }
          </React.Fragment>
        )
      )}
    </>
  );
}

export default CustomTopbarMenuComponents;
