import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import { IconAlertTriangleFilled, IconBrandCakephp } from '@tabler/icons-react';
import {
  ENTITY_LINK_TYPE_HAS_INVESTED_COMPANY,
  ENTITY_LINK_TYPE_HAS_REPRESENTED_COMPANY
} from '@deecision/dna-interfaces';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import BaseWidget from '@/main/containers/widgets/base-widget';
import { WidgetWithPersonsAugmentedEntitiesProps } from '@/main/containers/widgets/types';
import { CardPart } from '@/components/cards/keyvalue.cards';
import dnaConfig from '@/config/dna.config.json';
import TitleComponent from '@/components/title';

function CompanyWidgetsShareHolders(props: WidgetWithPersonsAugmentedEntitiesProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const augmentedEntities = props.data?.filter(result => result.linkTypes?.includes(ENTITY_LINK_TYPE_HAS_INVESTED_COMPANY))
    .sort((a, b) => (b.details.find(detail => detail)?.sharesPercentage || 0) - (a.details.find(detail => detail)?.sharesPercentage || 0));

  const totalSharesPercentage = augmentedEntities?.reduce((acc, curr) => acc + (curr.details.find(detail => detail?.positionTypes?.includes('sha'))?.sharesPercentage || 0), 0) || 0;

  const shareHoldersDatas = augmentedEntities?.map((augmentedEntity) => {
    const sharesPercentage = augmentedEntity.details.find(detail => detail?.positionTypes?.includes('sha'))?.sharesPercentage;
    const label = sharesPercentage ? `${sharesPercentage}%` : t('common.trust.unknown');
    const hasRepresented = augmentedEntity.linkTypes.includes(ENTITY_LINK_TYPE_HAS_REPRESENTED_COMPANY) && !augmentedEntity.linkTypes.includes(ENTITY_LINK_TYPE_HAS_INVESTED_COMPANY);

    return {
      key: augmentedEntity.entity.name,
      value: hasRepresented ?
        <Stack spacing={2} pb={2} direction='row' alignItems='center'>
          <IconAlertTriangleFilled color={theme.palette.warning.main} size='1.2rem' />
          <Typography variant='h5'>{label}</Typography>
        </Stack>
        : label,
      tooltip: `${hasRepresented ? `${t('common.utils.deductedShareholder')} - ` : ''}${t('common.utils.sharesPercentage')}: ${label}`
    };
  });

  return (
    <BaseWidget
      {...props}
      title={
        <Stack spacing={2}>
          <TitleComponent
            title={t('common.utils.shareHolders')}
            icon={IconBrandCakephp}
            chips={[
              <Chip key='chip-shareholders' label={shareHoldersDatas?.length || 0} size='small' />
            ]}
            small
          />
          <Stack spacing={1}>
            <Typography
              variant='body2'
            >
              {`${t('common.utils.sharePercentageSum')} `}
            </Typography>
            <Stack>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                  <LinearProgress variant='determinate' sx={{ borderRadius: 2 }} value={totalSharesPercentage} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                  <Typography
                    variant='body2'
                    sx={{ color: 'text.secondary' }}
                  >{`${totalSharesPercentage.toFixed(2)}%`}</Typography>
                </Box>
              </Box>
              {totalSharesPercentage < 100 ?
                <Box>
                  <Typography
                    variant='body2'
                    sx={{ color: 'text.secondary' }}
                  >{`${t('common.utils.notFound')}: ${(100 - totalSharesPercentage).toFixed(2)}%`}</Typography>
                </Box>
                : null
              }
            </Stack>
          </Stack>
        </Stack>
      }
    >
      {shareHoldersDatas ?
        <CardPart
          values={shareHoldersDatas}
          selectKey={name => navigate(`${dnaConfig.routes.persons.relativeUrl}/${augmentedEntities?.find(entity => entity.entity.name === name)?.entity.entityId || ''}`)}
        /> :
        <Stack spacing={2}>
          {[...Array(3)].map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Skeleton key={index} variant='rounded' sx={{ minWidth: 400 }} width='100%' height={76}/>
          ))}
        </Stack>
      }
    </BaseWidget>
  );
}

export default CompanyWidgetsShareHolders;
