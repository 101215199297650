import React, { ReactElement, useEffect, useState } from 'react';
import { IDataBlock } from '@deecision/dna-interfaces';
import { IconBrandDatabricks } from '@tabler/icons-react';
import Grid from '@mui/material/Grid2';
import TitleComponent from '@/components/title';
import KeyValueList, { KeyValue } from '@/components/keyvalue/keyvaluelist';
import HeaderComponents from '../../../../components/header';
import { DataBlocksQueryService } from '@/api/services/query';

type DataBlockWithId = IDataBlock & { _id: string }

function DataBlocksDetailsHeader(): ReactElement {
  const dataBlockId = window.location.pathname.split('/')[4];
  const dataBlockService = new DataBlocksQueryService({ entityType: 'deecCompany' });
  const [dataBlocks, setDataBlocks] = useState<IDataBlock>();
  const [identity, setIdentity] = useState<KeyValue[]>([]);
  const [dataInfo, setDataInfo] = useState<KeyValue[]>([]);
  const [source, setSource] = useState<KeyValue[]>([]);

  useEffect(() => {
    dataBlockService.findByCustomQuery({
      _id: dataBlockId
    })
      .then((res) => {
        if (res.data) {
          const elementsWithId = res.data as DataBlockWithId[];

          setDataBlocks(res.data[0]);
          setIdentity([{ key: 'DataBlock id', value: elementsWithId[0]._id },
            { key: 'EntityType', value: elementsWithId[0].entityRef.entityType },
            { key: 'EntityId', value: elementsWithId[0].entityRef.entityId }]);
          setDataInfo([
            { key: 'Path', value: elementsWithId[0].dataInfo.path },
            { key: 'DataType', value: elementsWithId[0].dataInfo.dataType }
          ]);
          setSource([
            { key: 'dataElementId', value: elementsWithId[0]._source?.dataElementId },
            { key: 'originDataBlockId', value: elementsWithId[0]._source?.originDataBlockId },
            { key: 'providerId', value: elementsWithId[0]._source?.provider.id },
            { key: 'providerOther', value: elementsWithId[0]._source?.provider.other }
          ]);
        }
      });
  }, []);

  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid>
        <TitleComponent title={(dataBlocks ? `${dataBlocks?.entityRef.entityType} - ${dataBlocks?.dataInfo.path}`  : 'title')} icon={IconBrandDatabricks} />
      </Grid>
      <Grid size={12}>
        <HeaderComponents
          height={180}
          selects={[]}
          keys={[
            'Identity',
            'DataInfo',
            'Source'
          ]}
          links={[
            '',
            '',
            ''
          ]}
        >
          {[
            <KeyValueList key='Identity' values={[identity]}  />,
            <KeyValueList key='DataInfo' values={[dataInfo]} />,
            <KeyValueList key='Source' values={[source]} />
          ]}
        </HeaderComponents>
      </Grid>
    </Grid>
  );
}

export default DataBlocksDetailsHeader;
